import { IAPSubscriptionData } from "@kvix/shared"
import { RefDialog, useRefDialog } from "@kvix/ui"
import React, { createContext, useContext, useEffect, useState } from "react"
import { withRouter } from "react-router"
import { useLocalStorage } from "react-use"
import { useIsMobile } from "../../hooks/isMobile"
import { getPlatform, Platform } from "../../utils/platform"
import { ExpoContext } from "../expo"
import { LanguageContext } from "../language"
import {
  PurchaseFlowActions as Actions,
  PurchaseFlowConsumerProps as ConsumerProps,
  PurchaseFlowProvided as Provided,
  PurchaseFlowProviderProps as ProviderProps,
} from "./types"

const Context = createContext<Provided>(undefined)

const Provider = withRouter(({ children, history }: ProviderProps) => {
  const isMobile = useIsMobile()
  const platform = getPlatform()
  const expoContext = useContext(ExpoContext)
  const [iapSubscriptions, setIapSubscriptions] = useState<
    IAPSubscriptionData[]
  >([])
  const [context, setContext] = useState<Provided["context"]>(() => ({
    currentLocation: history.location,
    prevLocation: undefined,
    platform,
    iapSubscriptions,
  }))

  const language = useContext(LanguageContext)

  const { open, close } = useRefDialog("Purchase Flow")

  const [hasBeenPrompted, setHasBeenPrompted] = useLocalStorage(
    "IS_PROMPTED",
    false
  )

  const prompt: Actions["prompt"] = (component) => open(component)

  const cleanUp = () => {
    const searchParams = new URLSearchParams(history.location.search)

    // if (searchParams.has('campaignSource')) {
    //   const parameters = new URLSearchParams(window.location.search);
    //   let utm_params = [];
    //   parameters.forEach(function (value, key) {
    //     if (key.startsWith('utm_')) {
    //       utm_params.push(key + '=' + value)
    //     }
    //   });
    //   let utm_search = utm_params.join('&');
    //   window.open(`${document.referrer}?${utm_search}`, "_self");
    // }

    if (searchParams.has("subscribed")) {
      searchParams.delete("subscribed")
      history.replace({
        search: searchParams.toString(),
      })
    }

    if (searchParams.has("signUpRedirect")) {
      searchParams.delete("signUpRedirect")

      if (history.location.pathname.includes('recruitme')) {
        history.push('/');
      } else {
        history.replace({
          search: searchParams.toString(),
        });
      }
    }

    if (searchParams.has("productSignupSuccess")) {
      searchParams.delete("productSignupSuccess")
      history.replace({
        search: searchParams.toString(),
      })
    }

    if (searchParams.has("campaignSource")) {
      searchParams.delete("campaignSource")

      if (searchParams.has("coupon")) {
        searchParams.delete("coupon")
      }

      history.replace(history.location.pathname)

      // history.replace({
      //   search: searchParams.toString(),
      // });
    }

    if (!hasBeenPrompted) {
      setHasBeenPrompted(true)
    }
  }

  const closePrompt: Actions["closePrompt"] = (cleanupCallbacks) => {
    if (cleanupCallbacks && cleanupCallbacks.length >= 1) {
      cleanupCallbacks.forEach((callback) => callback())
    }

    cleanUp()
    close()
  }

  useEffect(() => {
    setContext((context) => ({
      ...context,
      currentLocation: history.location,
    }))

    return () => {
      setContext((context) => ({
        ...context,
        prevLocation: context.currentLocation,
      }))
    }
  }, [history.location.key])

  useEffect(() => {
    const onReactNativeMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return
      }

      if (event.data.type === "set-subscriptions") {
        setIapSubscriptions(event.data.subscriptions)
      }
    }

    window.addEventListener("message", onReactNativeMessage)
    if (platform === Platform.IOS) {
      expoContext.actions.getSubscriptions()
    }

    return () => window.removeEventListener("message", onReactNativeMessage)
  }, [])

  return (
    <Context.Provider
      value={{
        context,
        isPrompted: hasBeenPrompted,
        actions: {
          prompt,
          closePrompt,
          triggerIapPurchase: expoContext.actions.triggerIapPurchase,
          cleanUp,
        },
        iapSubscriptions,
      }}
    >
      {children}
      <RefDialog
        style={{ overflow: "auto" }}
        name="Purchase Flow"
        fullWidth
        // maxWidth={isMobile ? "375px" : "420px"}
        maxWidth={"375px"}
        maxHeight={"92vh"}
        minHeight={"auto"}
        onClose={cleanUp}
        px={0}
        py={0}
        disableBackdropClick
      />
    </Context.Provider>
  )
})

const Consumer = ({ children }: ConsumerProps) => {
  return (
    <Context.Consumer>
      {(context) => {
        if (!context) {
          throw new Error(
            "PurchaseFlowConsumer may not be used outside PurchaseFlowProvider."
          )
        }

        return children(context)
      }}
    </Context.Consumer>
  )
}

export { Context, Provider, Consumer }
