import {
  AbstractCategory,
  ALGOLIA_SESSION_CURATED_LIST_LIMIT,
  ArticleType,
} from "@kvix/shared";
import { makeStyles } from "@material-ui/core";
import {
  addDays,
  eachDayOfInterval,
  format,
  isToday,
  isTomorrow,
} from "date-fns";
import { sv } from "date-fns/locale";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { DisplayArrangement } from "../..";
import { SortOption } from "../../../../../contexts/algolia";
import { ResultRow } from "./ResultRow";

const useStyles = makeStyles((theme) => ({
  row: {
    marginBottom: 32,

    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },

    "&.last": {
      marginBottom: 0,
    },
  },
}));

interface Props {
  filterString: string;
  categories: AbstractCategory[];
  currentCategory: AbstractCategory;
  filterByChip: (key: string, value: any) => void;
  type: ArticleType;
  arrangement: DisplayArrangement;
  toggleFavourite?: (options: {
    programId?: number;
    sessionId?: number;
  }) => void;
}

const Programs: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { categories, ...resultProps } = props;
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <>
      {(props.currentCategory === null || props.currentCategory) && (
        <div className={classes.row}>
          <ResultRow
            {...resultProps}
            title={
              !props.currentCategory
                ? t("curated.programs.popular")
                : t("curated.programs.popularInCategory", {
                    categoryTitle: props.currentCategory.title,
                  })
            }
            filterString={props.filterString}
            sortBy={SortOption.POPULARITY}
          />
        </div>
      )}

      {!props.currentCategory ? (
        <>
          {/* "All" view */}
          {categories?.map((category, index) => (
            <div
              className={`${classes.row} ${
                index === props.categories.length - 1 ? "last" : ""
              }`}
              key={category.id}
            >
              <ResultRow
                {...resultProps}
                title={t(`classes.${category.title}`)}
                filterString={`${props.filterString} AND categories:"${category.slug}"`}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {/* "Curated" category-specific lists */}
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.forBeginners")}
              filterString={`${props.filterString} AND level:"beginner"`}
            />
          </div>
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.requiresEquipment")}
              filterString={`${props.filterString} AND equipment:"true"`}
            />
          </div>
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.underDuration", {
                categoryTitle: props.currentCategory?.title,
              })}
              filterString={`${props.filterString} AND duration < 30`}
            />
          </div>
        </>
      )}
    </>
  );
};

const Anytime: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { categories, ...resultProps } = props;
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <>
      <div className={classes.row}>
        <ResultRow
          {...resultProps}
          title={
            !props.currentCategory
              ? t("curated.anytime.latest")
              : t("curated.anytime.latestInCategory", {
                  categoryTitle: props.currentCategory.title,
                })
          }
          filterString={props.filterString}
          sortBy={SortOption.LATEST}
        />
      </div>
      {!props.currentCategory ? (
        <>
          {/* "All" view */}
          {categories?.map((category, index) => (
            <div
              className={`${classes.row} ${
                index === props.categories.length - 1 ? "last" : ""
              }`}
              key={category.id}
            >
              <ResultRow
                {...resultProps}
                title={t(`classes.${category.title}`)}
                filterString={`${props.filterString} AND categories:"${category.slug}"`}
                sortBy={SortOption.LATEST}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {/* "Curated" category-specific lists */}
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.forBeginners")}
              filterString={`${props.filterString} AND level:"beginner"`}
              sortBy={SortOption.LATEST}
            />
          </div>
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.requiresEquipment")}
              filterString={`${props.filterString} AND equipment:"true"`}
              sortBy={SortOption.LATEST}
            />
          </div>
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.underDuration", {
                categoryTitle: props.currentCategory.title,
              })}
              filterString={`${props.filterString} AND duration < 30`}
              sortBy={SortOption.LATEST}
            />
          </div>
        </>
      )}
    </>
  );
};

const Sessions: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { categories, ...resultProps } = props;
  const now = new Date();
  const currentLanguage = i18next.language;
  const { t, ready } = useTranslation("general");
  const weekDays = eachDayOfInterval({
    start: now,
    end: addDays(now, 6),
  });

  if (!ready) {
    return null;
  }

  return (
    <>
      {weekDays?.map((day, index) => (
        <div className={classes.row} key={index}>
          <ResultRow
            {...resultProps}
            hitsPerPage={ALGOLIA_SESSION_CURATED_LIST_LIMIT}
            title={
              isToday(day)
                ? t("today")
                : isTomorrow(day)
                ? t("tomorrow")
                : format(day, "eeee", {
                    locale: currentLanguage === "sv-SE" && sv,
                  })
            }
            filterString={`${
              isToday(day)
                ? props.filterString.replace(
                    `type:"upcomingSession"`,
                    `type:"upcomingSession" OR type:"anytime"`
                  )
                : props.filterString
            } AND startTimestamp >= ${day.getTime()} AND endTimestamp < ${addDays(
              day,
              1
            ).getTime()} AND deletedAt = 0`}
          />
        </div>
      ))}
    </>
  );
};

const Instructors: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { categories, ...resultProps } = props;
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <>
      <div className={classes.row}>
        <ResultRow
          {...resultProps}
          title={
            !props.currentCategory
              ? t("curated.instructors.popular")
              : t("curated.instructors.popularInCategory", {
                  categoryTitle: props.currentCategory.title,
                })
          }
          filterString={props.filterString}
          sortBy={SortOption.POPULARITY}
        />
      </div>

      {!props.currentCategory ? (
        <>
          {/* "All" view */}
          {categories?.map((category, index) => (
            <div
              className={`${classes.row} ${
                index === props.categories.length - 1 ? "last" : ""
              }`}
              key={category.id}
            >
              <ResultRow
                {...resultProps}
                title={t(`classes.${category.title}`)}
                filterString={`${props.filterString} AND categories:"${category.slug}"`}
              />
            </div>
          ))}
        </>
      ) : (
        <>
          {/* "Curated" category-specific lists */}
          <div className={classes.row}>
            <ResultRow
              {...resultProps}
              title={t("curated.instructors.allInstructorsInCategory", {
                categoryTitle: props.currentCategory.title,
              })}
              filterString={props.filterString}
              sortBy={SortOption.ALPHABETICAL}
            />
          </div>
        </>
      )}
    </>
  );
};

export const CuratedList = {
  Programs,
  Anytime,
  Sessions,
  Instructors,
};
