import {
  AbstractExitReason,
  AbstractKvixProduct,
  CreatePurchaseDto,
  HttpMethods,
  pushGaEvent,
} from "@kvix/shared";
import * as Sentry from "@sentry/react";
import {
  loadStripe,
  PaymentMethod,
  SetupIntent,
  Stripe,
} from "@stripe/stripe-js";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-use";
import { Stripe as ServerStripe } from "stripe";
import { LanguageContext } from "../../../contexts/language";
import { LocationContext } from "../../../contexts/location";
import { KvixUserContext } from "../../../contexts/user";
import { useSocketEvent } from "../../../hooks/socket";
import { useHasDefaultPlan } from "./hooks";
import { constructUserData } from "../../../components/partials/GaEvents";

const useStripeApi = (publicKey: string) => {
  const [stripe, setStripe] = useState<Stripe>(null);

  useEffect(() => {
    if (publicKey) {
      loadStripe(publicKey).then(setStripe);
    }
  }, [publicKey]);

  return stripe;
};

const usePublicKey = () => {
  const [key, setKey] = useState<string>(null);

  useEffect(() => {
    const fetchPublicKey = async () => {
      const response = await fetch("/stripe/public-key");
      const data = await response.json();

      setKey(data.publicKey);
    };

    fetchPublicKey();
  }, []);

  return key;
};

const useAvailablePlans = (
  fetchCoupon: (couponId: string) => Promise<ServerStripe.Coupon>
): [ServerStripe.Plan[], (plans: ServerStripe.Plan[]) => void] => {
  const [plans, setPlans] = useState<ServerStripe.Plan[]>([]);
  const location = useContext(LocationContext);

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await fetch(
        `/stripe/plans?location=${location.currentLocation}`
      );
      const plans = (await response.json()) as ServerStripe.Plan[];

      const order: Array<ServerStripe.Plan["interval"]> = [
        "day",
        "week",
        "month",
        "year",
      ];

      const sortedPlans = plans.sort(
        (a, b) => order.indexOf(a.interval) - order.indexOf(b.interval)
      );

      const sortedAndMappedPlans = await Promise.all(
        sortedPlans.map(async (plan) => {
          const activeCampaign = plan.metadata["activeCampaign"];

          if (activeCampaign && activeCampaign !== "0") {
            try {
              plan["activeCampaign"] = await fetchCoupon(activeCampaign);
            } catch (error) {
              console.error("Could not fetch active campaign coupon", error);
              Promise.reject(error);
            }
          }

          return plan;
        })
      );

      setPlans(sortedAndMappedPlans);
    };

    fetchPlans();
  }, [fetchCoupon, location.currentLocation]);

  return [plans, setPlans];
};

const useAllPlans = (
  fetchCoupon: (couponId: string) => Promise<ServerStripe.Coupon>
): [ServerStripe.Plan[], (plans: ServerStripe.Plan[]) => void] => {
  const [plans, setPlans] = useState<ServerStripe.Plan[]>([]);
  const language = useContext(LanguageContext);

  useEffect(() => {
    const fetchPlans = async () => {
      const response = await fetch(`/stripe/plans`);
      const plans = (await response.json()) as ServerStripe.Plan[];

      const order: Array<ServerStripe.Plan["interval"]> = [
        "day",
        "week",
        "month",
        "year",
      ];

      const sortedPlans = plans.sort(
        (a, b) => order.indexOf(a.interval) - order.indexOf(b.interval)
      );

      const sortedAndMappedPlans = await Promise.all(
        sortedPlans.map(async (plan) => {
          const activeCampaign = plan.metadata["activeCampaign"];

          if (activeCampaign && activeCampaign !== "0") {
            try {
              plan["activeCampaign"] = await fetchCoupon(activeCampaign);
            } catch (error) {
              console.error("Could not fetch active campaign coupon", error);
              Promise.reject(error);
            }
          }

          return plan;
        })
      );

      setPlans(sortedAndMappedPlans);
    };

    fetchPlans();
  }, [fetchCoupon, language.currentLanguage]);

  return [plans, setPlans];
};

const useAvailablePrices = (): [
  ServerStripe.Price[],
  (prices: ServerStripe.Price[]) => void
] => {
  const [prices, setPrices] = useState<ServerStripe.Price[]>([]);

  useEffect(() => {
    const fetchPrices = async () => {
      const response = await fetch("/stripe/prices");
      const prices = (await response.json()) as ServerStripe.Price[];

      setPrices(prices);
    };

    fetchPrices();
  }, []);

  return [prices, setPrices];
};

const useSavedCards = (): [
  [ServerStripe.PaymentMethod[], boolean],
  () => Promise<ServerStripe.PaymentMethod[]>,
  (cards: ServerStripe.PaymentMethod[]) => void,
  (loading: boolean) => void
] => {
  const { user } = useContext(KvixUserContext);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<ServerStripe.PaymentMethod[]>([]);

  const load = useCallback(async () => {
    if (!user) {
      setCards([]);
      return;
    }

    setLoading(true);

    const response = await fetch("/stripe/me/cards");

    if (response.ok) {
      const data = await response.json();
      const cards = data as ServerStripe.PaymentMethod[];

      setCards(cards);
      setLoading(false);

      return cards;
    }

    setLoading(false);
  }, [user]);

  return [[cards, loading], load, setCards, setLoading];
};

const useCustomer = (): [
  ServerStripe.Customer,
  (customer: ServerStripe.Customer) => void
] => {
  const { user } = useContext(KvixUserContext);

  const [customer, setCustomer] = useState<ServerStripe.Customer>(undefined);

  useEffect(() => {
    const fetchCustomer = async () => {
      const response = await fetch("/stripe/me");
      const customer = await response.json();
      setCustomer(customer);
    };

    if (user && user.stripeCustomerId) {
      fetchCustomer();
    } else {
      setCustomer(null);
    }
  }, [user]);

  return [customer, setCustomer];
};

export interface PaymentContextActions {
  checkout: (options: CheckoutOptions) => Promise<void>;
  checkoutPrice: (
    customerId: string,
    paymentMethodId: string,
    priceId: string
  ) => Promise<string>;
  redeemGiftcard: (
    planId: string,
    promoCodeId: string,
    address: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      postal_code: string;
      state?: string;
    }
  ) => Promise<void>;
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<void>;
  attachPaymentMethod: (
    paymentMethod: PaymentMethod,
    customer: ServerStripe.Customer
  ) => Promise<void>;
  removePaymentMethod: (paymentMethodId: string) => Promise<void>;
  createSetupIntent: () => Promise<SetupIntent>;
  cancelSetupIntent: (intentId: string) => Promise<void>;
  cancelSubscription: (
    subscription: ServerStripe.Subscription,
    exitReason: Partial<AbstractExitReason>
  ) => Promise<void>;
  resumeSubscription: (
    subscription: ServerStripe.Subscription
  ) => Promise<void>;
  changeSubscriptionPlan: (
    subscription: ServerStripe.Subscription,
    newPlanId: string
  ) => Promise<void>;
  fetchInvoices: (
    startingAfter?: string
  ) => Promise<ServerStripe.ApiList<ServerStripe.Invoice>>;
  fetchUpcomingInvoice: () => Promise<ServerStripe.Invoice>;
  fetchSubscriptionSchedule: (
    subscriptionId: string
  ) => Promise<ServerStripe.SubscriptionSchedule | null>;
  cancelSubscriptionSchedule: (
    subscriptionId: string
  ) => Promise<ServerStripe.SubscriptionSchedule | null>;
  fetchCoupon: (couponId: string) => Promise<ServerStripe.Coupon>;
  fetchPromoCode: (promoCodeId: string) => Promise<ServerStripe.PromotionCode>;
  fetchRecruitRewardCode: () => Promise<string>;
  fetchSavedCards: () => Promise<ServerStripe.PaymentMethod[]>;
  fetchProductPrices: (productId: string) => Promise<ServerStripe.Price[]>;
  fetchProduct: (productId: string) => Promise<ServerStripe.Product>;
}

export interface PurchaseProduct {
  success: boolean;
  message: string;
}
interface PaymentContextInternalActions {
  purchaseProduct: (purchase: CreatePurchaseDto) => Promise<PurchaseProduct>;
  getProducts: () => Promise<AbstractKvixProduct[]>;
}

interface PaymentContextState {
  publicKey: string | null;
  stripe: Stripe;
  customer: ServerStripe.Customer;
  availablePlans: ServerStripe.Plan[];
  allPlans: ServerStripe.Plan[];
  availablePrices: ServerStripe.Price[];
  savedCards: [ServerStripe.PaymentMethod[], boolean];
  actions: PaymentContextActions;
  hasDefaultPlan: boolean;
  internalActions: PaymentContextInternalActions;
  checkoutProcessing: boolean;
}

export interface CheckoutOptions {
  planId?: string;
  priceId?: string;
  couponId?: string;
  campaignCode?: string;
  success_url?: string;
  cancel_url?: string;
  referrer?: string;
  failedLocation?: Partial<Location>;
  beforeRedirect?: () => void;
}

export const PaymentContext = createContext<PaymentContextState>(null);

export const PaymentContextProvider: React.FC = (props) => {
  const [gotoCheckout, setGotoCheckout] = useState<CheckoutOptions>(null);
  const publicKey = usePublicKey();
  const stripe = useStripeApi(publicKey);
  const history = useHistory();
  const [customer, setCustomer] = useCustomer();
  const [availablePrices, setAvailablePrices] = useAvailablePrices();
  const [checkoutProcessing, setCheckoutProcessing] = useState(false);
  const location = useLocation();

  const [savedCards, fetchSavedCards, setSavedCards, setSavedCardsLoading] =
    useSavedCards();

  const hasDefaultPlan = useHasDefaultPlan();
  const { user } = useContext(KvixUserContext);
  const doCheckout = useCallback(
    async ({ beforeRedirect, ...options }: CheckoutOptions) => {
      setCheckoutProcessing(true);
      try {
        if (options.referrer) {
          /** HABIT & WP CAMPAIGNS REDIRECT QUERY PARAMS **/
          options.referrer = options.referrer.includes('productSignupSuccess') ? 
          options.referrer 
          : 
          `${options.referrer}${window.location.search}`;
        }

        /** GTM track StartingPurchase event **/
        pushGaEvent(
          { category: "Account", action: "StartingPurchase" },
          constructUserData(user).userData,
        );
        
        const response = await fetch("/stripe/checkout", {
          method: HttpMethods.POST,
          body: JSON.stringify({ ...options, customerId: customer?.id }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            const location = options.failedLocation || {
              pathname: "/me/account",
            };

            return history.replace(location);
          }
        }
        const { sessionId } = await response.json();
        if (beforeRedirect instanceof Function) {
          beforeRedirect();
        }
        await stripe.redirectToCheckout({ sessionId });
      } catch (err) {
        console.log(err);
      } finally {
        setCheckoutProcessing(false);
      }
    },
    [stripe, history, location]
  );

  const checkout = (options: CheckoutOptions) => {
    setGotoCheckout(options);
    return Promise.resolve();
  };

  useEffect(() => {
    if (gotoCheckout && stripe) {
      doCheckout(gotoCheckout);
    }
  }, [stripe, gotoCheckout]);

  const checkoutPrice = async (
    customerId: string,
    paymentMethodId: string,
    priceId: string
  ) => {
    const response = await fetch("/stripe/checkout/priceitem", {
      method: HttpMethods.POST,
      body: JSON.stringify({ customerId, paymentMethodId, priceId }),
      headers: { "Content-Type": "application/json" },
    });
    if (response.ok) {
      const paymentObj: { clientSecret: string } = await response.json();
      return paymentObj.clientSecret;
    } else {
      throw response;
    }
  };

  const redeemGiftcard = useCallback(
    async (
      planId: string,
      promoCodeId: string,
      address: {
        city: string;
        country: string;
        line1: string;
        line2?: string;
        postal_code: string;
        state?: string;
      }
    ) => {
      try {
        const response = await fetch("/stripe/redeem", {
          method: HttpMethods.POST,
          body: JSON.stringify({
            planId,
            promoCodeId,
            address,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const customer = await response.json();
          setCustomer(customer);
        }
      } catch (error) {
        Sentry.captureException(error, { tags: { package: "client" } });
        console.log(error);
      }
    },
    [setCustomer]
  );

  const cancelSubscription = async (
    subscription: ServerStripe.Subscription,
    exitReason: Partial<AbstractExitReason>
  ) => {
    const body = JSON.stringify(exitReason);
    const response = await fetch(
      `/stripe/me/subscriptions/${subscription.id}/cancel`,
      {
        method: HttpMethods.POST,
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const customer = await response.json();
      setCustomer(customer);
    }
  };

  const resumeSubscription = async (
    subscription: ServerStripe.Subscription
  ) => {
    const response = await fetch(
      `/stripe/me/subscriptions/${subscription.id}/resume`,
      {
        method: HttpMethods.POST,
      }
    );

    if (response.ok) {
      const customer = await response.json();
      setCustomer(customer);
    }
  };

  const changeSubscriptionPlan = async (
    subscription: ServerStripe.Subscription,
    newPlanId: string
  ) => {
    const response = await fetch(
      `/stripe/me/subscriptions/${subscription.id}/update`,
      {
        method: HttpMethods.POST,
        body: JSON.stringify({ newPlanId }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw await response.text();
    }

    const customer = await response.json();
    setCustomer(customer);
  };

  const createSetupIntent = async () => {
    const response = await fetch("/stripe/setup-intent", {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const intent = await response.json();

    return intent as SetupIntent;
  };

  const cancelSetupIntent = async (intentId: string) => {
    await fetch(`/stripe/setup-intent/${intentId}`, {
      method: HttpMethods.DELETE,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const removePaymentMethod = async (paymentMethodId: string) => {
    setSavedCardsLoading(true);

    const response = await fetch(`/stripe/me/cards/${paymentMethodId}`, {
      method: HttpMethods.DELETE,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      const updatedCards = data as ServerStripe.PaymentMethod[];

      setSavedCards(updatedCards);
    }

    setSavedCardsLoading(false);
  };

  const setDefaultPaymentMethod = async (paymentMethodId: string) => {
    setSavedCardsLoading(true);

    const response = await fetch(`/stripe/me/cards/${paymentMethodId}/active`, {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      const updatedCustomer = data as ServerStripe.Customer;

      setCustomer(updatedCustomer);
    }

    setSavedCardsLoading(false);
  };

  const attachPaymentMethod = async (
    paymentMethod: PaymentMethod,
    customer: ServerStripe.Customer
  ) => {
    setSavedCardsLoading(true);
    console.log("attaching card");
    const response = await fetch(
      `/stripe/me/cards/${paymentMethod.id}/attach`,
      {
        method: HttpMethods.POST,
        body: JSON.stringify({ customer: customer.id }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      const updatedCards = data as ServerStripe.PaymentMethod[];

      setSavedCards(updatedCards);
    } else {
      console.log(response);
    }

    setSavedCardsLoading(false);
  };

  const fetchInvoices = async (startingAfter?: string) => {
    const param = startingAfter ? `?startingAfter=${startingAfter}` : "";

    const response = await fetch(`/stripe/me/invoices${param}`);
    const data = await response.json();
    const invoices = data as ServerStripe.ApiList<ServerStripe.Invoice>;

    return invoices;
  };

  const fetchUpcomingInvoice = async () => {
    const response = await fetch(`/stripe/me/invoices/upcoming`);

    if (response.ok) {
      const data = await response.json();
      const invoices = data as ServerStripe.Invoice;

      return invoices;
    }

    return null;
  };

  const fetchSubscriptionSchedule = async (subscriptionId: string) => {
    const response = await fetch(
      `/stripe/me/subscriptions/${subscriptionId}/schedule`
    );

    const data = await response.json();
    const schedule = data as ServerStripe.SubscriptionSchedule;

    return schedule;
  };

  const cancelSubscriptionSchedule = async (subscriptionId: string) => {
    const response = await fetch(
      `/stripe/me/subscriptions/${subscriptionId}/schedule`,
      {
        method: HttpMethods.DELETE,
      }
    );

    const data = await response.json();
    const schedule = data as ServerStripe.SubscriptionSchedule;

    return schedule;
  };

  const fetchCoupon = useCallback(async (couponId: string) => {
    const response = await fetch(`/stripe/coupon/${couponId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok) {
      return data as ServerStripe.Coupon;
    } else {
      throw data;
    }
  }, []);

  const fetchPromoCode = async (promoCodeId: string) => {
    const response = await fetch(`/stripe/promoCode/${promoCodeId}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (response.ok) {
      return data as ServerStripe.PromotionCode;
    } else {
      throw new Error(data.error as string);
    }
  };

  const fetchRecruitRewardCode = async () => {
    const response = await fetch(`/stripe/rewardCode`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (response.ok) {
      return data as string;
    } else {
      throw new Error(data.error as string);
    }
  };

  const fetchProductPrices = async (productId: string) => {
    const response = await fetch(`/stripe/prices/${productId}`);

    const data = await response.json();

    const prices = data as ServerStripe.ApiList<ServerStripe.Price>;

    return prices.data;
  };

  const fetchProduct = async (productId: string) => {
    const response = await fetch(`/stripe/product/${productId}`);

    const data = await response.json();

    const product = data as ServerStripe.Product;

    return product;
  };

  const getInternalProducts = async () => {
    const response = await fetch(`/api/products`);

    const data = await response.json();

    const products = data as AbstractKvixProduct[];

    return products;
  };

  const purchaseInternalProduct = async (purchase: CreatePurchaseDto) => {
    const response = await fetch(`/api/product/purchase`, {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(purchase),
    });

    return response.json();
  };

  const [availablePlans, setAvailablePlans] = useAvailablePlans(fetchCoupon);
  const [allPlans, setAllPlans] = useAllPlans(fetchCoupon);

  useSocketEvent<ServerStripe.Customer>(
    "[payment] customer updated",
    setCustomer
  );

  useSocketEvent<ServerStripe.Plan[]>(
    "[payment] available plans updated",
    setAvailablePlans
  );

  useSocketEvent<ServerStripe.Plan[]>(
    "[payment] all plans updated",
    setAllPlans
  );

  useSocketEvent<ServerStripe.Price[]>(
    "[payment] available prices updated",
    setAvailablePrices
  );

  return (
    <PaymentContext.Provider
      value={{
        publicKey,
        stripe,
        customer,
        availablePlans,
        allPlans,
        availablePrices,
        savedCards,
        hasDefaultPlan,
        checkoutProcessing,
        actions: {
          checkout,
          checkoutPrice,
          redeemGiftcard,
          cancelSubscription,
          resumeSubscription,
          changeSubscriptionPlan,
          createSetupIntent,
          cancelSetupIntent,
          removePaymentMethod,
          setDefaultPaymentMethod,
          fetchInvoices,
          fetchUpcomingInvoice,
          fetchSubscriptionSchedule,
          cancelSubscriptionSchedule,
          fetchCoupon,
          fetchPromoCode,
          fetchRecruitRewardCode,
          fetchSavedCards,
          fetchProductPrices,
          attachPaymentMethod,
          fetchProduct,
        },
        internalActions: {
          getProducts: getInternalProducts,
          purchaseProduct: purchaseInternalProduct,
        },
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};
