import {
  AlgoliaAnytimeArticle,
  AlgoliaArticle,
  AlgoliaBasicInstructorArticle,
  AlgoliaInstructorArticle,
  AlgoliaProgramArticle,
  AlgoliaSessionArticle,
  ArticleType,
} from "@kvix/shared";
import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  searchResult: AlgoliaArticle[];
  navigateTo: (pathname: string) => void;
  loading: boolean;
}

interface TemplateData {
  imageUrl: string;
  title: string;
  instructor: string;
  type: string;
  link: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    margin: "8px auto 0px auto",
    left: 0,
    right: 0,
    textAlign: "left",
    overflow: "auto",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    width: "145%",
    height: "60vh",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "70vh",
    },
  },
  rootOverflowGradient: {
    "-webkit-mask-image":
      "-webkit-gradient(linear, left 90%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
  },
  hitContainer: {
    fontWeight: 500,
    width: "100%",
    cursor: "pointer",
    background: theme.palette.background.paper,
    marginBottom: "4px",
    borderRadius: "4px",
    display: "flex",

    "&:hover": {
      background:
        theme.palette.type === "dark"
          ? theme.palette.background.default
          : "#ffd1dd",
    },
  },
  image: {
    minWidth: 70,
    minHeight: 70,
    maxWidth: 70,
    maxHeight: 70,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "4px 0 0 4px",
  },
  noHits: {
    fontWeight: 500,
    width: "100%",
    background: theme.palette.background.paper,
    borderRadius: "4px",
    padding: 12,
  },
  loading: {
    fontWeight: 500,
    width: "100%",
    background: theme.palette.background.paper,
    borderRadius: "4px",
    padding: 12,
  },
}));

export const SearchResult: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("content");
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const classes = useStyles(props);
  const handleScroll = (event) => {
    const target = event.target;

    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      setIsScrollBottom(true);
    } else {
      setIsScrollBottom(false);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <div
      className={`${classes.root} ${
        isScrollBottom ? "" : classes.rootOverflowGradient
      }`}
      onScroll={handleScroll}
    >
      {props.loading ? (
        <div className={classes.loading}>{t("search.searchLoading")}</div>
      ) : (
        <>
          {props.searchResult?.length > 0 ? (
            <>
              {props.searchResult.map((article) => (
                <Fragment key={article.objectID}>
                  <Hit article={article} navigateTo={props.navigateTo} />
                </Fragment>
              ))}
            </>
          ) : (
            <div className={classes.noHits}>{t("search.noSearchResults")}</div>
          )}
        </>
      )}
    </div>
  );
};

const Hit: React.FC<{
  article: AlgoliaArticle;
  navigateTo: (pathname: string) => void;
}> = (props) => {
  const templateData = useMemo(() => {
    const type = props.article.type;

    switch (type) {
      case ArticleType.PROGRAM:
        const programArticle = props.article as AlgoliaProgramArticle;
        return {
          imageUrl: programArticle.imageUrl,
          title: programArticle.title,
          instructor: programArticle.instructorName,
          type: programArticle.type,
          link: `/program/${programArticle.id}`,
        };
      case ArticleType.SESSION:
        const sessionArticle = props.article as AlgoliaSessionArticle;
        return {
          imageUrl: sessionArticle.imageUrl,
          title: sessionArticle.title,
          instructor: sessionArticle.instructorName,
          type: sessionArticle.type,
          link: `/replay/${sessionArticle.programId}/${sessionArticle.id}`,
        };
      case ArticleType.ANYTIME:
        const anytimeArticle = props.article as AlgoliaAnytimeArticle;
        return {
          imageUrl: anytimeArticle.imageUrl,
          title: anytimeArticle.title,
          instructor: anytimeArticle.instructorName,
          type: anytimeArticle.type,
          link: `/replay/${anytimeArticle.programId}/${anytimeArticle.id}`,
        };
      case ArticleType.INSTRUCTOR:
        const instructorArticle = props.article as AlgoliaInstructorArticle;
        return {
          imageUrl: instructorArticle.imageUrl,
          title: instructorArticle.title,
          instructor: null,
          type: instructorArticle.type,
          link: `/instructor/${instructorArticle.id}`,
        };
      case ArticleType.BASIC_INSTRUCTOR:
        const basicInstructorArticle =
          props.article as AlgoliaBasicInstructorArticle;
        return {
          imageUrl: basicInstructorArticle.imageUrl,
          title: basicInstructorArticle.title,
          instructor: null,
          type: basicInstructorArticle.type,
          link: `/streamer/${basicInstructorArticle.id}`,
        };

      default:
        return null;
    }
  }, [props]);

  return <HitTemplate data={templateData} navigateTo={props.navigateTo} />;
};

const HitTemplate: React.FC<{
  data: TemplateData;
  navigateTo: (pathname: string) => void;
}> = (props) => {
  const classes = useStyles(props);
  const { data, navigateTo } = props;
  const { t, ready } = useTranslation("globalSearch");

  if (!ready) {
    return null;
  }

  return (
    <div
      className={classes.hitContainer}
      onMouseDown={(e: React.SyntheticEvent) => e.preventDefault()}
      onClick={() => navigateTo(data.link)}
    >
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${data.imageUrl})` }}
      />
      <div
        style={{
          display: "column",
          padding: "4px 8px 0 8px",
        }}
      >
        <Text variant="body2" style={{ fontWeight: 600 }}>
          {data.title}
        </Text>
        <Text variant="body2">{data.instructor}</Text>
        <Text
          variant="body2"
          color="primary"
          style={{ fontSize: 11, fontWeight: 600 }}
        >
          {data.type === "anytime"
            ? `${t("hits.anytime")}`
            : data.type === "upcomingSession"
            ? `${t("hits.upcoming")}`
            : data.type === "program"
            ? `${t("hits.program")}`
            : data.type === "instructor"
            ? `${t("hits.instructor")}`
            : `${t("hits.basicInstructor")}`}
        </Text>
      </div>
    </div>
  );
};
