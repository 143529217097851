import { Button } from "@kvix/ui";
import { makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Stripe } from "stripe";
import { DonateTabState } from "../enums/DonateTabState";
import { CoinPriceList } from "../partials/CoinPriceList";
import { TabContainer } from "../partials/TabContainer";

interface Props {
  tabState: DonateTabState;
  currentCoins: number;
  setTabState: (tabState: DonateTabState) => void;
  selectedPrice: Stripe.Price;
  setSelectedPrice: (price: Stripe.Price) => void;
}

const useStyles = makeStyles((theme) => ({
  titleMargin: {
    marginTop: "70px",
    marginBottom: "20px",
  },
  subtitleMargin: {
    marginBottom: "20px",
  },
  fatText: {
    fontWeight: 600,
  },
  nextButton: {
    marginTop: "42px",
    width: "100%",
    height: "40px",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : "rgb(31, 40, 51)",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : "rgb(31, 40, 51)",
    },
  },
}));

export const BuyTab: FC<Props> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("donateLiveView");
  const toCheckout = () => {
    props.setTabState(DonateTabState.CHECKOUT);
  };

  if (!ready) {
    return null;
  }

  return (
    <TabContainer tabState={props.tabState} visibleState={DonateTabState.BUY}>
      <Typography className={classes.titleMargin} variant={"h4"}>
        Lägg till Kvix Koins
      </Typography>
      <Typography className={classes.subtitleMargin} variant={"subtitle2"}>
        {props.currentCoins === 0 ? (
          <>{t("buy.subtitle.option1")}</>
        ) : (
          <>
            {t("buy.subtitle.option2.part1")}
            <span className={classes.fatText}>{props.currentCoins}</span>
            {t("buy.subtitle.option2.part2")}
          </>
        )}
      </Typography>
      <CoinPriceList
        onSelect={props.setSelectedPrice}
        selectedPrice={props.selectedPrice}
      />
      <Button
        disabled={props.selectedPrice === null}
        color={"secondary"}
        onClick={toCheckout}
        className={classes.nextButton}
      >
        {t("buy.proceed")}
      </Button>
    </TabContainer>
  );
};
