import { generateUid } from "@kvix/shared";
import { useScrollTrigger } from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useRouteMatch } from "react-router";
import { ExpoContext } from "../../contexts/expo";
import { KvixUserContext } from "../../contexts/user";
import { useIsMobile } from "../../hooks/isMobile";

const useIsAppBarVisible = () => {
  const isMobile = useIsMobile();
  const hasScrolled = useScrollTrigger({
    threshold: 500,
  });

  return isMobile ? !hasScrolled : true;
};

interface HeaderToolbarState {
  items: string[];
  register: () => string;
  unregister: (id: string) => void;
  container: React.MutableRefObject<HTMLDivElement>;
}

export const HeaderToolbarContext =
  React.createContext<HeaderToolbarState>(null);

const HeaderToolbarContextProvider: React.FC = (props) => {
  const container = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<string[]>([]);

  const register = useCallback(() => {
    const id = generateUid(8);

    setItems((value) => [...value, id]);

    return id;
  }, []);

  const unregister = useCallback((id: string) => {
    setItems((value) => value.filter((uuid) => uuid !== id));
  }, []);

  return (
    <HeaderToolbarContext.Provider
      value={{ container, items, register, unregister }}
    >
      {props.children}
    </HeaderToolbarContext.Provider>
  );
};

interface HeaderContextState {
  isCompact: boolean;
  isDarkMode: boolean;
  isVisible: boolean;
  isBackgroundVisible: boolean;
  displaySlide: boolean;
  onLoginPage: boolean;
  onHomePage: boolean;
  isNativeApp: boolean;
  activeItem?: string | undefined;
  setActiveItem?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const HeaderContext = React.createContext<HeaderContextState>({
  isCompact: false,
  isDarkMode: false,
  isVisible: true,
  isBackgroundVisible: true,
  displaySlide: true,
  onLoginPage: false,
  onHomePage: false,
  isNativeApp: false,
});

export const HeaderContextProvider: React.FC = (props) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string>(undefined);
  const home = useRouteMatch("/");
  const session = useRouteMatch("/");
  const isMobile = useIsMobile();
  const instructor =
    useRouteMatch(["/instructor/:id", "/streamer/:id"]) && isMobile;
  const isVisible = useIsAppBarVisible();
  const { isNativeApp } = useContext(ExpoContext);
  const { user } = useContext(KvixUserContext);
  const isBackgroundVisible = useScrollTrigger({
    disableHysteresis: true,
    threshold: home.isExact ? 100 : 20,
  });
  const slideRoutes = [
    "/page",
    "/sessions",
    "/programs",
    "/instructors",
    "/anytime",
    "/signup",
    "/exercise",
    "/registration-completion",
    "/successful-streamer-registration",
  ];

  const loggedInOnStartPage = useMemo(() => {
    return user && location.pathname === "/";
  }, [location.pathname, user]);

  const displaySlide =
    !!slideRoutes.find((route) => route === location.pathname) ||
    !!instructor ||
    loggedInOnStartPage;

  const onLoginPage = useMemo(() => {
    return location.pathname === "/login";
  }, [location.pathname]);

  const isCompact = session && isMobile;

  const context = {
    isDarkMode: displaySlide,
    isVisible,
    isCompact,
    isBackgroundVisible,
    displaySlide,
    onLoginPage,
    activeItem,
    setActiveItem,
    onHomePage: home.isExact,
    isNativeApp,
  };

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, []);

  return (
    <HeaderContext.Provider value={context}>
      <HeaderToolbarContextProvider>
        {props.children}
      </HeaderToolbarContextProvider>
    </HeaderContext.Provider>
  );
};
