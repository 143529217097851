import { Button } from "@kvix/ui";
import { makeStyles, Theme } from "@material-ui/core";
import { Location } from "history";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HeaderContext } from "../Header/context";

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    marginRight: "6px",
    padding: "0 12px",
    fontSize: "12px",
    height: "34px",
    transition: "background-color 0.17s ease, color 0.17s ease",
    backgroundColor: (context: any) => {
      if (context.onLoginPage) {
        return "inherit";
      }
      if (context.isDarkMode && !context.isBackgroundVisible) {
        return "white";
      }

      return null;
    },
    color: (context: any) => {
      if (context.onLoginPage) {
        return "inherit";
      }
      if (context.isDarkMode && !context.isBackgroundVisible) {
        return "#FA255E";
      }

      return null;
    },

    "&:hover": {
      backgroundColor: (context: any) => {
        if (context.isDarkMode && !context.isBackgroundVisible) {
          return "#ccc";
        }

        return null;
      },
    },
  },
}));

interface Props {
  type: "free" | "premium";
  targetLocation?: Partial<Location>;
  onClick?: () => void;
}
interface BtnProps {
  type: "free" | "premium";
  onClick?: () => void;
}

const Btn: React.FC<BtnProps> = ({ onClick, type }) => {
  const { t, ready } = useTranslation(["startPage", "sidebar"]);
  const context = useContext(HeaderContext);
  const classes = useStyles(context);

  if (!ready) {
    return null;
  }

  return (
    <Button onClick={onClick} className={classes.root}>
      {type === "free" ? (
        <>{t("startPage:hero.common.getKvixFree")}</>
      ) : (
        <>{t("sidebar:getPremium")}</>
      )}
    </Button>
  );
};
export const HeaderButton: React.FC<Props> = (props) => {
  const { type, targetLocation, onClick } = props;

  return (
    <>
      {onClick ? (
        <Btn type={type} onClick={onClick} />
      ) : (
        <Link to={targetLocation}>
          <Btn type={type} />
        </Link>
      )}
    </>
  );
};
