import { ArticleType } from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterOptions, Option } from "..";
import { SearchFilters } from "../../..";

interface Props {
  open: boolean;
  close: () => void;
  filterOptions: FilterOptions;
  activeFilters: SearchFilters;
  setActiveFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  hideFilterOptions?: boolean;
  type: ArticleType;
}

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginTop: "30%",

    "& .MuiDialog-paperFullScreen": {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: (props: any) =>
      props.ios ? "6% 30px 64px 30px" : "6% 30px 10% 30px",
    flexDirection: "column",
    height: "100%",
  },
  search: {
    width: "100%",
    fontWeight: 500,
    marginTop: 48,

    "& .MuiOutlinedInput-input": {
      zIndex: 1,
    },
  },
  border: {
    borderRadius: 24,
    background: "rgb(254, 233, 238)",
    borderColor: "transparent",
  },
  selectField: {
    height: "100%",
    paddingRight: 0,

    "&.selected": {
      fontWeight: 600,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
      background: theme.palette.background.paper,
      borderRadius: "12px",
    },

    "& .MuiSelect-icon": {
      display: "none",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: 32,
      zIndex: 1,
    },
  },
  endAdornment: {
    position: "absolute",
    right: 6,
    top: 10,
    pointerEvents: "none",
    zIndex: 1,
  },
  buttonRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "0 24px",
    marginTop: 24,
  },
  closeButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilterSelect: React.FC<{
  value: any;
  setValue: (event: any) => void;
  options: Option[];
}> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("program");

  if (!ready) {
    return null;
  }

  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        variant="outlined"
        className={`${classes.selectField} ${
          props.value === null ? "" : "selected"
        }`}
        displayEmpty
        value={props.value === null ? "" : props.value}
        onChange={props.setValue}
        endAdornment={
          <KvixIcon.Chevron.Down
            className={classes.endAdornment}
            color="primary"
          />
        }
      >
        {props.options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {t(option.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const MobileFilters: React.FC<Props> = (props) => {
  const ios = /iPad|iPhone/.test(navigator.userAgent);
  const classes = useStyles({ ios });
  const [tempFilters, setTempFilters] = useState<SearchFilters>(null);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const { t, ready } = useTranslation("content");

  useEffect(() => {
    setTempFilters({ ...props.activeFilters });
    setTempSearchQuery(props.searchQuery);
  }, [props.activeFilters, props.open]);

  const handleClearFilters = () => {
    props.setActiveFilters((activeFilters) => {
      return {
        ...activeFilters,
        instructorName: null,
        level: null,
        duration: null,
        focus: null,
        equipment: null,
      };
    });

    props.setSearchQuery("");
  };

  const handleShowResults = () => {
    props.setActiveFilters(tempFilters);
    props.setSearchQuery(tempSearchQuery);
    props.close();
  };

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={props.close}
      fullScreen
      className={classes.dialog}
    >
      <IconButton
        color="inherit"
        onClick={props.close}
        className={classes.closeButton}
      >
        <KvixIcon.Close color="primary" />
      </IconButton>
      <div className={classes.root}>
        <Box width="100%" textAlign="center">
          <Text variant="h4">{t("filterTitle")}</Text>

          <TextField
            variant="outlined"
            placeholder={t(`search.${props.type}`)}
            style={{ width: "100%" }}
            value={tempSearchQuery}
            onChange={(event) => setTempSearchQuery(event.target.value)}
            InputProps={{
              className: classes.search,
              classes: {
                notchedOutline: classes.border,
              },
            }}
          />
        </Box>

        {!props.hideFilterOptions && (
          <>
            <FilterSelect
              value={tempFilters?.instructorName}
              setValue={(event) =>
                setTempFilters({
                  ...tempFilters,
                  instructorName: event.target.value,
                })
              }
              options={props.filterOptions.instructors}
            />

            <FilterSelect
              value={tempFilters?.level}
              setValue={(event) =>
                setTempFilters({
                  ...tempFilters,
                  level: event.target.value,
                })
              }
              options={props.filterOptions.levels}
            />

            <FilterSelect
              value={tempFilters?.equipment}
              setValue={(event) =>
                setTempFilters({
                  ...tempFilters,
                  equipment: event.target.value,
                })
              }
              options={props.filterOptions.equipment}
            />
            <FilterSelect
              value={tempFilters?.focus}
              setValue={(event) =>
                setTempFilters({
                  ...tempFilters,
                  focus: event.target.value,
                })
              }
              options={props.filterOptions?.focuses}
            />

            <FilterSelect
              value={tempFilters?.duration}
              setValue={(event) =>
                setTempFilters({
                  ...tempFilters,
                  duration: event.target.value,
                })
              }
              options={props.filterOptions.durations}
            />
          </>
        )}
        <Box width="100%">
          <Divider style={{ width: "100%" }} />
          <div className={classes.buttonRow}>
            <Button variant="text" onClick={handleClearFilters}>
              {t("clearShort")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleShowResults}
            >
              {t("showResult")}
            </Button>
          </div>
        </Box>
      </div>
    </Dialog>
  );
};
