import { SessionState } from "@kvix/shared";
import { Box } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { StreamerAdminContext } from "../context";
import { PreviousStreamItem } from "./PreviousStreamItem";
import { StreamsPageDivider } from "./StreamsInfo";

export const PreviousStreamsList = () => {
  const { previousStreams } = useContext(StreamerAdminContext);

  return (
    <Box>
      <StreamsPageDivider />
      {previousStreams.map((stream, index) => (
        <Fragment key={index}>
          <PreviousStreamItem
            stream={stream}
            disabled={stream.state === SessionState.Cancelled}
          />
          <StreamsPageDivider />
        </Fragment>
      ))}
    </Box>
  );
};
