import { Text } from "@kvix/ui";
import { Fade } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppCastState } from "../../../hooks/nativeApp";
import { SlideUp } from "../Transitions/SlideUp";

interface Props {
  poster?: string;
}

const Container = styled.div<Props>`
  width: 100%;
  padding-top: 56.25%;
  display: block;
  position: relative;

  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) => `url(${props.poster})`};
    background-size: cover;
    background-position: center;
    filter: brightness(0.7) saturate(0.7);
  }
`;

const Overlay = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const ReplayCasting: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("replay");
  const [, receiverName] = useAppCastState();

  if (!ready) {
    return null;
  }

  return (
    <Fade in={!!props.poster} timeout={500}>
      <Container poster={props.poster}>
        <Overlay>
          <SlideUp delay={500}>
            <Text variant="h5" color="inherit">
              {t("castingTo", { receiverName })}
            </Text>
          </SlideUp>
        </Overlay>
      </Container>
    </Fade>
  );
};
