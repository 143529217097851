import { Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../../../hooks/isMobile";
import { DonateTabState } from "../enums/DonateTabState";

interface Props {
  setTabState?: (tabState: DonateTabState) => void;
}

const useStyles = makeStyles((theme) => ({
  imageSize: {
    marginLeft: 5,
    width: 80,
    height: 55,
  },
  imageSizeMobile: {
    marginLeft: 5,
    widht: 80,
    height: 55,
  },
  topSpace: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
  },
  readMoreLink: {
    fontWeight: 600,
    cursor: "pointer",
  },
}));

export const WellstarModalFooter: FC<Props> = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { t, ready } = useTranslation("donateView");
  const barncancerfonden = `${window.location.origin}/assets/vi_stodjer_RGB.png`;

  if (!ready) {
    return null;
  }

  return (
    <Grid container direction={"row"} className={classes.topSpace}>
      <Grid style={{ maxWidth: 200 }} item xs>
        <Typography variant={"subtitle2"}>
          {t("footer.infoText")}
          <br />
          {props.setTabState && (
            <Link
              onClick={() => {
                props.setTabState(DonateTabState.READMORE);
              }}
              className={classes.readMoreLink}
            >
              {t("footer.readMore")}
            </Link>
          )}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <img
          className={isMobile ? classes.imageSizeMobile : classes.imageSize}
          src={barncancerfonden}
          alt=""
        ></img>
      </Grid>
    </Grid>
  );
};
