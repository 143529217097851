import { RichText, Text } from "@kvix/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Blogpost } from "../../../../../cms/models/Blogpost";

const Image = styled.img`
  width: 100%;
`;
export const FeaturedBlogpost: React.FC<{
  blogpost: Blogpost;
}> = (props) => {
  const { t, ready } = useTranslation("blog");

  if (!ready) {
    return null;
  }

  return (
    <Link
      to={`/blog/${props.blogpost.slug}`}
      style={{
        position: "sticky",
        top: "0px",
        display: "inline-block",
      }}
    >
      <Image src={props.blogpost.image.url} />
      <Text
        variant="caption"
        color="primary"
        style={{ display: "block", margin: "16px 0" }}
      >
        {props.blogpost.caption}
      </Text>
      <Text variant="h4">{props.blogpost.title}</Text>
      <Text
        variant="body1"
        style={{
          margin: "16px 0",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: "200px",
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
          whiteSpace: "pre-line",
        }}
      >
        <RichText noPadding text={props.blogpost.content} />
      </Text>
      <Text
        style={{
          marginBottom: "10px",
          color: "rgb(144, 144, 144, 1)",
        }}
      >
        [...]
      </Text>
      {!props.blogpost.noAuthorLink ? (
        <Link to={`/instructor/${props.blogpost.userId}`}>
          <Text variant="caption" color="textSecondary">
            {t("byAuthor") + props.blogpost.author}
          </Text>
        </Link>
      ) : (
        <Text variant="caption" color="textSecondary">
          {t("byAuthor") + props.blogpost.author}
        </Text>
      )}
    </Link>
  );
};
