import {
  AbstractBaseUser,
  CompetitionRoundLeaderboard,
  CompetitionRoundState,
  KvixProductType,
} from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Divider, makeStyles, Theme, useTheme } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import GroupOutlined from "@material-ui/icons/GroupOutlined";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompetitionContext } from "../../../../contexts/competition";
import { KvixUserContext } from "../../../../contexts/user";
import { ShareButton } from "../../../partials/SocialShare/ShareButton";

const useStyles = makeStyles<Theme, { bigImage: boolean }>((theme) => ({
  profileRoot: {
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
  },
  profileName: {
    maxWidth: "85%",
    wordBreak: "break-word",
    fontSize: "44px",
    fontWeight: "bold",
    color: theme.palette.common.white,
    width: "80%",
    lineHeight: "52px",
    paddingBottom: "8px",
  },
  profileExpertise: {
    fontSize: "18px",
    color: theme.palette.common.white,
    paddingBottom: "20px",
    fontWeight: 600,
  },
  profileCover: {
    height: "100%",
    width: "100%",
    "&:after": {
      content: "' '",
      position: "absolute",
      height: "100%",
      top: "0",
      left: "0",
      right: "0",
      background:
        "linear-gradient(360deg, rgba(0, 0, 0, 0.2) 25%, rgba(0,0,0,0) 55%)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "120%",
      width: "120%",
      margin: "-10% 0 0 -10%",
    },
  },
  profileContent: {
    position: "absolute",
    width: "100%",
    bottom: "0px",
    padding: (props) => (props.bigImage ? "15px 25px 30px" : "15px"),
  },
  profileStatContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  statDivider: {
    background: "rgba(255, 255, 255, 0.5);",
    margin: "12px 0 3px",
  },
  profileStat: {
    width: "29%",
    fontSize: "14px",
  },
  profileStatNum: {
    fontSize: (props: any) => (props.bigImage ? "24px" : "18px"),
    lineHeight: "20px",
    fontWeight: 600,
    color: theme.palette.common.white,
  },
  profileStatIcon: {
    height: (props: any) => (props.bigImage ? "24px" : "18px"),
    width: (props: any) => (props.bigImage ? "24px" : "18px"),
  },
  profileStatType: {
    fontWeight: 600,
    color: theme.palette.common.white,
    fontSize: (props: any) => (props.bigImage ? "18px" : "14px"),
    [theme.breakpoints.down(347)]: {
      fontSize: (props: any) => (props.bigImage ? "14px" : "12px"),
    },
  },
  statIcon: {
    width: (props: any) => (props.bigImage ? "24px" : "18px"),
    height: (props: any) => (props.bigImage ? "24px" : "18px"),
    marginBottom: "-2px",
  },
  shareButton: {
    position: "absolute",
    top: "15px",
    left: "15px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    backgroundColor: "rgb(0, 0, 0, 0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const numToStatString = (num: number): string => {
  if (num == null || num < 0) {
    return "";
  }
  const str = num.toString();
  if (num < 10000) {
    return str;
  }
  return str.slice(0, str.length - 2) + "k";
};

type StatType = "rank" | "votes" | "followers";

const getStatString = (type: StatType) => {
  switch (type) {
    case "rank":
      return "statWellstarRank";
    case "votes":
      return "statNrVotes";
    case "followers":
      return "statFollowers";
  }
};

const StatIcon = (type: StatType, classes: ClassNameMap) => {
  switch (type) {
    case "rank":
      return <ArrowUpward className={classes.statIcon} />;
    case "votes":
      return <FavoriteBorder className={classes.statIcon} />;
    case "followers":
      return <GroupOutlined className={classes.statIcon} />;
  }
};

const ProfileStat: React.FC<{
  bigImage: boolean;
  type: StatType;
  stat: string;
}> = ({ bigImage, type, stat }) => {
  const classes = useStyles({ bigImage });
  const { t, ready } = useTranslation("streamerAdminAccount");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.profileStat}>
      <Text className={classes.profileStatNum}>
        {type === "rank" && "#\u00a0"}
        {stat}&nbsp;
        {/* TODO: Rewrite code row below as following when we
        have desired functionality: {StatIcon(type, bigImage)} */}
        {type !== "rank" && StatIcon(type, classes)}
      </Text>
      <Divider classes={{ root: classes.statDivider }} />
      <Text className={classes.profileStatType}>{t(getStatString(type))}</Text>
    </Box>
  );
};

type ShareProfileButtonProps = {
  user: AbstractBaseUser;
};
const ShareProfileButton = ({ user }: ShareProfileButtonProps) => {
  const theme = useTheme();
  return (
    <ShareButton
      style={{
        color: theme.palette.common.white,
        marginTop: "-3px",
        padding: "9px",
      }}
    />
  );
};

const getVotes = async (onFinish: (result: number) => void) => {
  const response = await fetch(
    `/api/product/accountTransactions/${KvixProductType.VOTE2021}`
  );
  const result = await response.json();
  onFinish(result.productQuantity ? result.productQuantity : 0);
};

const getRank = async (onFinish: (result: number) => void, userId: number) => {
  const res = await fetch("/api/competition-rounds/active/leaderboard");

  if (!res.ok) {
    return;
  }
  const data: CompetitionRoundLeaderboard = await res.json();
  const rank = data.contestants.findIndex(
    (contestant) => contestant.id === userId
  );
  onFinish(rank === -1 ? rank : rank + 1);
};

type ImageWithStatsProps = {
  showName?: boolean;
  blur?: boolean;
};
export const ImageWithStats = ({
  showName = false,
  blur = false,
}: ImageWithStatsProps) => {
  const { user } = useContext(KvixUserContext);
  const { instructorProfile } = user;
  const classes = useStyles({ bigImage: !showName });

  const [votes, setVotes] = useState<number>(null);
  const [rank, setRank] = useState<number>(null);
  const { activeCompetitionRound } = useContext(CompetitionContext);

  const displayRankAndVotes = useMemo(() => {
    return (
      activeCompetitionRound &&
      !activeCompetitionRound.isFinal &&
      [CompetitionRoundState.RUNNING, CompetitionRoundState.FINISHED].includes(
        activeCompetitionRound.state
      )
    );
  }, [activeCompetitionRound]);

  useEffect(() => {
    getVotes(setVotes);
    getRank(setRank, user.id);
  }, [user.id]);

  return (
    <Box className={classes.profileRoot}>
      <Box
        className={classes.profileCover}
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: blur ? "blur(30px)" : null,
          backgroundImage: `url("${user.imageUrl}")`,
        }}
      />
      <div className={classes.shareButton}>
        <ShareProfileButton user={user} />
      </div>
      <Box className={classes.profileContent}>
        {blur && (
          <img
            alt=""
            style={{
              height: "120px",
              width: "120px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={user.imageUrl}
          />
        )}
        {showName && (
          <Text className={classes.profileName}>{user?.name?.custom}</Text>
        )}
        {showName && (
          <Text className={classes.profileExpertise}>
            {instructorProfile?.title}
          </Text>
        )}
        <Box className={classes.profileStatContainer}>
          {displayRankAndVotes && (
            <>
              <ProfileStat
                bigImage={!showName}
                type="rank"
                stat={numToStatString(rank)}
              />
              <ProfileStat
                bigImage={!showName}
                type="votes"
                stat={numToStatString(votes)}
              />
            </>
          )}
          <ProfileStat
            bigImage={!showName}
            type="followers"
            stat={
              instructorProfile?.followers?.length
                ? numToStatString(instructorProfile?.followers?.length)
                : "0"
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
