import { AbstractUser, GaEvent, handleGtmEvents, pushGaEvent, pushToDataLayer, StripeProduct } from "@kvix/shared";
import { format, parseJSON } from "date-fns";
import { History, Location } from "history";
import React, { useEffect, useRef, useState } from "react";
import shajs from "sha.js";

type Props = {
  history: History<{ accessBlocked?: boolean }>;
  user: AbstractUser;
  children: (redirect: string) => React.ReactNode;
};

const sha256 = (input: string) =>
  input && shajs("sha256").update(input).digest("hex");

/**
 * 
 * @param user 
 * @returns 
 */
export const constructUserData = (user: AbstractUser) => {
  return {
    userId: user && user.id.toString(),
    userData: user && {
      email: sha256(user.email),
      gender: sha256(user.gender),
      dateOfBirth:
        user.dateOfBirth &&
        sha256(format(parseJSON(user.dateOfBirth), "yyyyMMdd")),
      lastName: sha256(user.name.last),
      firstName: sha256(user.name.first),
      fbLoginId: user.facebookId,
      googleLoginId: user.googleId,
      appleLoginId: user.appleId,
    },
  }
}

/**
 * Handles GTM events (from monorepo impl)
 * @param location 
 * @param data 
 * @returns 
 */
const usePageViewHandler = (location: Location, data: {
  userId?: string;
  userData?: {
    email?: string;
    gender?: string;
    dateOfBirth?: string;
    lastName?: string;
    firstName?: string;
    fbLoginId?: string;
    googleLoginId?: string;
    appleLoginId?: string;
  };
}) => {
  const [redirect, setRedirect] = useState<string>(null);
  const gaEvents = useRef<GaEvent[]>([]);

  useEffect(() => {
    const pagePath = location.pathname + location.search + location.hash;
    const result = handleGtmEvents(pagePath);

    if (result.gaEvents.length > 0) {
      gaEvents.current = result.gaEvents;
      setRedirect(result.pagePath);
      return;
    } else {
      setRedirect("");
    }

    pushToDataLayer({event: "virtualPageView", pageTitle: window.document.title, pagePath, ...data }, true);

    if (gaEvents.current.length > 0) {
      gaEvents.current.map((e) => pushGaEvent(e, data.userData));
      gaEvents.current = [];
    }
  }, [location]);

  return redirect;
};


export const GaEvents = ({ history, user, children }: Props) => {

  const redirect = usePageViewHandler(history.location, constructUserData(user));

  return redirect !== null && <React.Fragment>{children(redirect)}</React.Fragment>;
};