import { makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { CoinImageSize } from "../enums/CoinImageSize";

interface Props {
  size: CoinImageSize;
  className?: string;
}

interface StyleProps {
  width: string;
  height: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  image: (props) => ({
    width: props.width,
    height: props.height,
  }),
}));

export const CoinImage: FC<Props> = (props) => {
  const coinImage = `${window.location.origin}/assets/kvix-koin.png`;
  const imageSize = (size: CoinImageSize) => {
    switch (size) {
      case CoinImageSize.XS:
        return { width: "8px", height: "8px" };
      case CoinImageSize.SM:
        return { width: "11px", height: "11px" };
      case CoinImageSize.MD:
        return { width: "15px", height: "15px" };
      case CoinImageSize.LG:
        return { width: "20px", height: "20px" };
      case CoinImageSize.XL:
        return { width: "22px", height: "22px" };
      case CoinImageSize.XXL:
        return { width: "25px", height: "25px" };
    }
  };
  const classes = useStyles(imageSize(props.size));

  return (
    <img
      className={classNames([classes.image, props.className])}
      alt={"coin"}
      src={coinImage}
    />
  );
};
