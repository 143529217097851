import {
  AbstractLiveSession,
  AbstractLiveSessionUserStatisticAggregated,
  HttpMethods,
} from "@kvix/shared";
import { Text } from "@kvix/ui";
import {
  Box,
  Container,
  Divider,
  makeStyles,
  Modal,
  useTheme,
} from "@material-ui/core";
import {
  AccessTime,
  FavoriteBorderOutlined,
  RemoveRedEyeOutlined,
  VisibilityOffOutlined,
} from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-use";
import { ShareButton } from "../../../partials/SocialShare/ShareButton";
import { ReplayContent } from "../../Replay/Content";

const useStyles = makeStyles((theme) => ({
  previousStreamModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -55%)",
    width: "730px",
    background: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      transform: "none",
    },
  },
  previousStreamInfo: {
    width: "auto",
    margin: "35px 60px 40px",
    [theme.breakpoints.down("xs")]: {
      margin: "20px 0 0",
    },
  },
  streamWhen: {
    fontSize: "12px",
    fontWeight: 600,
  },
  streamTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  username: {
    fontSize: "14px",
    fontWeight: 600,
  },
  video: {
    width: "100%",
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  streamStatNr: {
    fontSize: "18px",
    fontWeight: 600,
  },
  streamStatDesc: {
    fontSize: "14px",
    fontWeight: 600,
  },
  streamStat: {
    background: theme.palette.background.paper,
    borderRadius: "5px",
    padding: "10px 10px",
    width: 130,
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 9px)",
    },
  },
  bigDivider: {
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      marginLeft: "calc(-50vw + 50%)",
    },
  },
  streamStatDivider: {
    marginTop: "3px",
  },
  streamStatIcon: {
    width: "0.8em",
    paddingTop: "1px",
    marginLeft: "5px",
  },
  closeButton: {
    zIndex: 2000,
    color: theme.palette.common.white,
    position: "absolute",
    top: "10px",
    left: "10px",
    cursor: "pointer",
  },
  shareButton: {
    cursor: "pointer",
    zIndex: 2000,
    color: theme.palette.common.white,
    position: "absolute",
    top: "0",
    right: "0",
    padding: "15px",
  },
}));

type StreamStatProps = {
  stat: number | string;
  description: string;
  icon: JSX.Element;
};
const StreamStat = ({ stat, description, icon }: StreamStatProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.streamStat}>
      <Box display="flex" alignItems="center">
        <Text className={classes.streamStatNr}>{stat}</Text>
        {icon}
      </Box>
      <Divider className={classes.streamStatDivider} />
      <Text className={classes.streamStatDesc}>{description}</Text>
    </Box>
  );
};

type StreamStatsProps = {
  stream: AbstractLiveSession;
};
const StreamStats = ({ stream }: StreamStatsProps) => {
  const { t, ready } = useTranslation("wellstarStreams");
  const classes = useStyles();
  const statistics =
    useAsync<AbstractLiveSessionUserStatisticAggregated>(async () => {
      const response = await fetch(`/api/statistic?sessionId=${stream.id}`, {
        method: HttpMethods.GET,
        headers: {
          "Content-Type": "application/json",
        },
      });

      return await response.json();
    }, [stream.id]);

  if (!statistics.value || !ready) {
    return null;
  }

  const hours = Math.floor(statistics.value.secondsWatched / 3600);

  return (
    <Box display="flex" justifyContent="space-between" flexWrap="wrap">
      <StreamStat
        stat={statistics.value.attended}
        description={t("attended")}
        icon={<RemoveRedEyeOutlined className={classes.streamStatIcon} />}
      />
      <StreamStat
        stat={hours}
        description={t("hoursWatched")}
        icon={<AccessTime className={classes.streamStatIcon} />}
      />
      <StreamStat
        stat={`${statistics.value.sessionUserCompletion}%`}
        description={t("completion")}
        icon={<VisibilityOffOutlined className={classes.streamStatIcon} />}
      />
      <StreamStat
        stat={statistics.value.sessionVotes}
        description={t("votes")}
        icon={<FavoriteBorderOutlined className={classes.streamStatIcon} />}
      />
    </Box>
  );
};

type PreviousStreamProps = {
  stream: AbstractLiveSession;
};
export const PreviousStream = ({ stream }: PreviousStreamProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");
  const { start, end, title, instructor } = stream;
  const day = format(start, "dd/LL");
  const when = `${day} ${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  const name =
    instructor.name.custom ||
    `${instructor.name.first} ${instructor.name.last}`;

  if (!ready) {
    return null;
  }

  return (
    <Box>
      <ReplayContent
        replay={stream}
        imageUrl={stream.instructor.imageUrl}
        name={stream.instructor.name}
        title={stream.title}
      />
      <Container className={classes.previousStreamInfo}>
        <Text className={classes.streamWhen}>{`${t("airedOn")} ${when}`}</Text>
        <Text className={classes.streamTitle}>{title}</Text>
        <Text className={classes.username}>{name}</Text>
        <Divider className={classes.bigDivider} />
        <StreamStats stream={stream} />
      </Container>
    </Box>
  );
};

type PreviousStreamModalProps = {
  open: boolean;
  onClose: () => void;
  stream: AbstractLiveSession;
};
export const PreviousStreamModal = ({
  open,
  onClose,
  stream,
}: PreviousStreamModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.previousStreamModal}>
        <Box className={classes.closeButton} onClick={onClose}>
          <Close />
        </Box>
        <Box className={classes.shareButton}>
          <ShareButton
            replay={stream}
            style={{ padding: 0, color: theme.palette.common.white }}
          />
        </Box>
        <PreviousStream stream={stream} />
      </Box>
    </Modal>
  );
};
