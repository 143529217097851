import { AbstractLiveSession, getSessionInstructor } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, makeStyles } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import classNames from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PreviousStreamModal } from "./PreviousStream";

const previewWidth = "128px";
const previewMargin = "10px";
const inspectArrowSize = "34px";

const useStyles = makeStyles((theme) => ({
  previewImage: {
    height: "72px",
    width: "128px",
    marginRight: "10px",
    borderRadius: "2px",
    position: "relative",
  },
  playIcon: {
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    background: theme.palette.common.white,
    position: "relative",
    margin: "5px",

    "&:before": {
      content: "''",
      height: "0",
      width: "0",

      display: "block",
      borderTop: "7px solid transparent",
      borderBottom: "7px solid transparent",
      borderLeft: `10px solid ${theme.palette.primary.main}`,
      position: "absolute",
      top: "7px",
      left: "11px",
    },
  },
  streamInfo: {
    margin: "2px 0 3px",
    fontSize: "12px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "calc(100% - 166px)",
  },
  popper: {
    cursor: "pointer",
    width: "200px",
    padding: "5px",
    background: theme.palette.common.white,
    borderRadius: "5px",
    boxShadow: "0 0 10px rgb(0 0 0 / 0.4)",
  },
  inspectArrow: {
    position: "absolute",
    top: "0",
    right: "0",
    background: theme.palette.background.paper,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    color: theme.palette.primary.main,
    cursor: "pointer",

    "&.disabled": {
      cursor: "inherit",
      color: theme.palette.grey[500],
    },
  },
  streamTitle: {
    fontSize: "18px",
    fontWeight: 700,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  streamUsername: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: 600,
    fontSize: "14px",
  },
  streamInfoWhen: {},
  inspectArrowWrapper: {
    height: "100%",
  },
  disabled: {
    textDecoration: "line-through",
    color: theme.palette.grey[500],
  },
  cancelledText: {
    color: theme.palette.common.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    fontWeight: 600,
    textAlign: "center",
    margin: "auto",
    height: "20px",
    width: "100%",
  },
}));

type StreamInfoProps = {
  title: string;
  when: string;
  name: string;
  disabled?: boolean;
};
const StreamInfo = ({ title, when, name, disabled }: StreamInfoProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.streamInfo}>
      <span
        className={classNames(classes.streamInfoWhen, {
          [classes.disabled]: disabled,
        })}
      >
        {when}
      </span>
      <Text
        className={classNames(classes.streamTitle, {
          [classes.disabled]: disabled,
        })}
      >
        {title}
      </Text>
      <Text
        className={classNames(classes.streamUsername, {
          [classes.disabled]: disabled,
        })}
      >
        {name}
      </Text>
    </Box>
  );
};

const Preview = ({
  imgSrc,
  disabled,
}: {
  imgSrc: string;
  disabled?: boolean;
}) => {
  const { t, ready } = useTranslation("wellstarStreams");
  const classes = useStyles();

  if (!ready) {
    return null;
  }

  return (
    <span
      className={classes.previewImage}
      style={{
        background: imgSrc && !disabled ? `url(${imgSrc})` : "#000",
        backgroundSize: "cover",
      }}
    >
      {disabled ? (
        <div className={classes.cancelledText}>{t("cancelled")}</div>
      ) : (
        <div className={classes.playIcon} />
      )}
    </span>
  );
};

type PreviousStreamItemProps = {
  stream: AbstractLiveSession;
  disabled?: boolean;
};
export const PreviousStreamItem = ({
  stream,
  disabled,
}: PreviousStreamItemProps) => {
  const classes = useStyles();
  const { title, start, end, thumbnailUrl } = stream;
  const instructor = getSessionInstructor(stream);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const showModal = () => {
    if (!disabled) {
      setShouldShowModal(true);
    }
  };
  const hideModal = () => setShouldShowModal(false);

  const day = format(start, "dd/LL");
  const when = `${day} ${format(start, "HH:mm")} - ${format(end, "HH:mm")}`;
  const name =
    instructor.name.custom ||
    `${instructor.name.first} ${instructor.name.last}`;

  return (
    <Box display="flex" position="relative" alignItems="center">
      <Preview imgSrc={thumbnailUrl} disabled={disabled} />
      <StreamInfo title={title} when={when} name={name} disabled={disabled} />
      <Box
        className={classNames(classes.inspectArrow, { disabled })}
        onClick={showModal}
      >
        <KeyboardArrowRight />
      </Box>
      <PreviousStreamModal
        open={shouldShowModal}
        onClose={hideModal}
        stream={stream}
      />
    </Box>
  );
};
