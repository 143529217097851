import {
  Box,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { RouteChildrenProps } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .active > button": {
      fontWeight: "bold",
      lineHeight: 2.2,
    },
    marginBotton: "40px",
  },
  links: {
    marginBottom: "40px",
    justifyContent: "flex-end",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
      marginBottom: "0px",
    },
    [theme.breakpoints.down(340)]: {
      fontSize: "11px",
    },
  },
  link: {
    padding: "10px 0",
    margin: "-10px 0",
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#B1B2B3 !important",
    cursor: "pointer",
    fontWeight: "bold",
    transition: "color 0.4s",
    letterSpacing: "1px",
    "&:hover": {
      color: `${theme.palette.primary.main} !important`,
    },
    "&.activeLink": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  select: {
    color: theme.palette.common.white,

    "& .MuiSelect-root": {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
}));

export type NavigationProp = {
  title: string;
  slug: string;
  onClick: () => void;
  selectable?: boolean;
};
export const Navigation: React.FC<{
  routeProps: RouteChildrenProps;
  items: NavigationProp[];
}> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onClick = (item: NavigationProp, index: number) => {
    item.onClick();
    if (item.selectable) {
      setSelectedIndex(index);
    }
  };

  return (
    <Grid container spacing={isMobile ? 0 : 4} className={classes.links}>
      {props.items.map((item, index) => (
        <Grid item key={index} onClick={() => onClick(item, index)}>
          <Box
            className={classNames(classes.link, {
              activeLink: index === selectedIndex,
            })}
          >
            {item.title}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
