import React, { useEffect } from "react";
import { useLocalStorage } from "react-use";
import { useFetchCountry } from "../hooks/http";

interface LocationContextState {
  currentLocation: string;
  changeCurrentLocation: (location: string) => void;
}
export const LocationContext = React.createContext<LocationContextState>(null);

export const LocationContextProvider: React.FC = (props) => {
  const [currentLocation, setCurrentLocation] =
    useLocalStorage<string>("currentLocation");
  const locFromServer = useFetchCountry();
  useEffect(() => {
    if (!currentLocation) {
      setCurrentLocation(locFromServer);
    }
  }, [currentLocation, locFromServer]);

  const changeCurrentLocation = (newLocation: string) => {
    setCurrentLocation(newLocation);
  };
  const value = {
    currentLocation,
    changeCurrentLocation,
  };

  return (
    <LocationContext.Provider value={value}>
      {props.children}
    </LocationContext.Provider>
  );
};
