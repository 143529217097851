import { validateSlug } from "@kvix/shared";
import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { hasPremiumAccess, KvixUserContext } from "../../../contexts/user";
import { useIsMobile } from "../../../hooks/isMobile";
import { FadeIn } from "../../partials/Transitions/SlideUp";
import { HeaderContext } from "../context";
import { HeaderLogotype } from "../Logotype";
import { CampaignLayout } from "./layouts/CampaignLayout";
import { DefaultLayout } from "./layouts/DefaultLayout";
import { RecruitMeLayout } from "./layouts/RecruitMeLayout";

export const ToolbarLayout: React.FC = (props) => {
  const { user } = useContext(KvixUserContext);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState<JSX.Element>();
  const history = useHistory();
  const isMobile = useIsMobile();

  const hideToolbarItems = useMemo(() => {
    return searchExpanded && isMobile;
  }, [isMobile, searchExpanded]);

  useEffect(() => {
    handleSetSelectedLayout();

    history.listen(() => {
      handleSetSelectedLayout();
    });
  }, [user, hideToolbarItems, searchExpanded, setSearchExpanded, history]);

  const handleSetSelectedLayout = () => {
    if (!user && history.location.pathname.includes("/recruitme")) {
      return setSelectedLayout(
        <RecruitMeLayout searchExpanded={searchExpanded} />
      );
    }
    if (
      !hasPremiumAccess(user) &&
      validateSlug(history.location.pathname, "c", 1)
    ) {
      return setSelectedLayout(
        <CampaignLayout searchExpanded={searchExpanded} />
      );
    }

    return setSelectedLayout(
      <DefaultLayout
        searchExpanded={searchExpanded}
        setSearchExpanded={setSearchExpanded}
        hideToolbarItems={hideToolbarItems}
        user={user}
      />
    );
  };

  return (
    <React.Fragment>
      {!hideToolbarItems && (
        <Box mr="auto">
          <FadeIn>
            <HeaderContext.Consumer>
              {({ setActiveItem }) => (
                <Link to="/" onClick={() => setActiveItem(undefined)}>
                  <HeaderLogotype />
                </Link>
              )}
            </HeaderContext.Consumer>
          </FadeIn>
        </Box>
      )}

      {selectedLayout}
    </React.Fragment>
  );
};
