import React, { useContext, useEffect, useState } from "react";
import { BlogAdapter } from "../cms/models/adapters/single/Blog";
import { Blog } from "../cms/models/Blog";
import { LanguageContext } from "./language";

export const STRAPI_URL = window.config.STRAPI_URL;

interface BlogContextState {
  blog: Blog;
}

const useBlog = (): Blog => {
  const language = useContext(LanguageContext);
  const [blog, setBlog] = useState<Blog>();
  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/blog?populate=deep`);

      if (response.ok) {
        const json = await response.json();
        const blog: Blog = await BlogAdapter(json);
        setBlog({
          ...blog,
          blogposts: [
            ...blog.blogposts.sort((a: any, b: any) => a.priority - b.priority),
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return blog;
};

export const BlogContext = React.createContext<BlogContextState>(null);

export const BlogContextProvider: React.FC = (props) => {
  const blog = useBlog();

  const value = {
    blog,
  };
  return (
    <BlogContext.Provider value={value}>{props.children}</BlogContext.Provider>
  );
};
