import {
  Backdrop as MuiBackdrop,
  Dialog,
  Paper as MuiPaper,
} from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useFetchReplay } from "../../../hooks/http";
import { ReplayContent } from "../Replay/Content";

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    overflow: visible;
    background: transparent;
    box-shadow: none;

    video,
    canvas {
      box-shadow: 8px 0 24px 10px rgba(0, 0, 0, 0.15);
    }
  }
`;

const Backdrop = styled(MuiBackdrop)`
  &.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 800px) {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const StreamerReplayModal: React.FC = (props) => {
  const { sessionId } = useParams();
  const replay = useFetchReplay(+sessionId);
  const history = useHistory();

  const back = (event?: React.SyntheticEvent) => {
    if (event) {
      event.stopPropagation();
    }
    history.goBack();
  };

  if (!replay.value) {
    return null;
  }

  return (
    <Dialog
      open
      maxWidth="md"
      PaperComponent={Paper}
      BackdropComponent={Backdrop}
      onBackdropClick={back}
    >
      <ReplayContent
        replay={replay.value}
        imageUrl={replay.value.instructor.imageUrl}
        name={replay.value.instructor.name}
        title={replay.value.title}
      />
    </Dialog>
  );
};

export default StreamerReplayModal;
