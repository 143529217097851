import { makeStyles } from "@material-ui/core";
import React from "react";
import { DisplayArrangement } from "../../..";
import { ArrangeButton } from "../ArrangeButton";
import { FilterButton } from "../Desktop/FilterButton";

interface Props {
  displayingFilters: boolean;
  toggleDisplayFilters: () => void;
  numberOfActiveFilters: number;
  arrangement: DisplayArrangement;
  setArrangement: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    paddingLeft: "16px",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const DesktopOptionsContainer: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <FilterButton {...props} />
      <ArrangeButton
        arrangement={props.arrangement}
        setArrangement={props.setArrangement}
      />
    </div>
  );
};
