import { Role } from "@kvix/shared";
import { Container, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, RouteChildrenProps, useHistory } from "react-router";
import { CmsContext } from "../../../cms/context";
import { CompetitionContext } from "../../../contexts/competition";
import { KvixUserContext } from "../../../contexts/user";
import { useIsMobile } from "../../../hooks/isMobile";
import { Footer } from "../../Footer";
import { Page } from "../../partials/Page";
import { StreamerAdminStateProvider } from "./context";
import { Navigation, NavigationProp } from "./Navigation";
import { ImageWithStats } from "./Profile/ImageWithStats";
import { ProfileInfo } from "./Profile/ProfileInfo";
import { Faq } from "./sections/Faq";
import { StreamsInfo } from "./Streams/StreamsInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .active > button": {
      fontWeight: "bold",
      lineHeight: 2.2,
    },
  },
  profileWrapper: {
    width: "40%",
  },
  mainContent: {
    width: "60%",
  },
  mobileContainer: {
    paddingRight: "15px",
    paddingLeft: "15px",
  },
}));

enum ActiveTabState {
  STREAMS = "streams",
  PROFILE = "profile",
  LEADERBOARD = "leaderboard",
  FAQ = "faq",
  ACCOUNT = "account",
}

const Content: React.FC<RouteChildrenProps> = (props) => {
  const { user } = useContext(KvixUserContext);
  const classes = useStyles();
  const isMobile = useIsMobile();
  const history = useHistory();
  const { t, ready } = useTranslation("wellstarStreams");
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const { faqCategoryCollection } = useContext(CmsContext);

  const faqCategories = useMemo(() => {
    if (faqCategoryCollection) {
      return faqCategoryCollection[0]?.categories;
    }
    return null;
  }, [faqCategoryCollection]);

  const [activeTabState, setActiveTabState] = useState<ActiveTabState>(
    ActiveTabState.STREAMS
  );

  const getNavigationItems = (
    setActiveItem: (slug: string | ActiveTabState) => void
  ): NavigationProp[] => {
    const navigationItems: NavigationProp[] = [];

    for (const key in ActiveTabState) {
      const tabState = ActiveTabState[`${key}`];

      if (tabState === ActiveTabState.LEADERBOARD && !activeCompetitionRound) {
        continue;
      }

      navigationItems.push({
        title: t(`navigation.${tabState}`),
        slug: tabState,
        onClick: () => setActiveItem(tabState),
        selectable: tabState !== ActiveTabState.ACCOUNT,
      });
    }
    return navigationItems;
  };

  const navigationItems = getNavigationItems((slug: ActiveTabState) => {
    if (slug === ActiveTabState.ACCOUNT) {
      return history.push({
        pathname: "/registration-completion",
        state: {
          from: history.location.pathname,
          background: history.location,
        },
      });
    }

    setActiveTabState(slug);
  });

  const bigImage = activeTabState !== ActiveTabState.STREAMS;
  const leftWidth = bigImage ? 6 : 5;
  const rightWidth = bigImage ? 5 : 7;

  if (!ready) {
    return null;
  }

  if (!user.roles.includes(Role.BasicInstructor)) {
    return <Redirect to="/registration-completion" />;
  }

  return (
    <Page>
      {isMobile ? (
        <>
          <div
            style={{
              height: "500px",
              width: "100vw",
              marginLeft: "calc(-50vw + 50%)",
              marginTop: "-72px",
            }}
          >
            <ImageWithStats
              showName
              blur={activeTabState === ActiveTabState.PROFILE}
            />
          </div>
          <Container maxWidth="lg" className={classes.mobileContainer}>
            <Navigation routeProps={props} items={navigationItems} />
            {activeTabState === ActiveTabState.STREAMS && <StreamsInfo />}
            {activeTabState === ActiveTabState.PROFILE && <ProfileInfo />}
            {activeTabState === ActiveTabState.FAQ && (
              <Faq faqCategories={faqCategories} />
            )}
          </Container>
        </>
      ) : (
        <Container maxWidth="lg" style={{ minHeight: "816px" }}>
          <Navigation routeProps={props} items={navigationItems} />

          <Grid container spacing={0} justify="space-between">
            <Grid item xs={12} md={leftWidth} style={{ position: "relative" }}>
              <div
                style={{
                  height: bigImage ? "720px" : "500px",
                  width: bigImage ? "540px" : "350px",
                  maxWidth: "100%",
                  position: "sticky",
                  top: "208px",
                  backgroundColor: "Red",
                }}
              >
                <ImageWithStats showName={!bigImage} />
              </div>
            </Grid>
            <Grid item xs={12} md={rightWidth}>
              {activeTabState === ActiveTabState.STREAMS && <StreamsInfo />}
              {activeTabState === ActiveTabState.PROFILE && <ProfileInfo />}
              {activeTabState === ActiveTabState.FAQ && (
                <Faq faqCategories={faqCategories} />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      <Footer />
    </Page>
  );
};

export const StreamerAdmin: React.FC<RouteChildrenProps> = (props) => {
  return (
    <StreamerAdminStateProvider>
      <Content {...props} />
    </StreamerAdminStateProvider>
  );
};
