import { Box, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles((theme) => ({
  imageSize: {
    width: "auto",
    height: "100%",
  },
  swishImage: {
    width: "auto",
    height: "88%",
    marginTop: 2,
  },
  boxSize: {
    height: "2em",
    marginLeft: "auto",
  },
}));

const Visa: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.boxSize}>
      <img
        className={classes.imageSize}
        src={"/assets/card-vendors/visa.png"}
        alt={"Visa"}
      />
    </Box>
  );
};

const Mastercard: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.boxSize}>
      <img
        className={classes.imageSize}
        src={"/assets/card-vendors/mastercard.png"}
        alt={"Mastercard"}
      />
    </Box>
  );
};

const Swish: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.boxSize}>
      <img
        className={classes.swishImage}
        src={"/assets/card-vendors/swish.png"}
        alt={"Swish"}
      />
    </Box>
  );
};

export default { Visa, Mastercard, Swish };
