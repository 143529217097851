import { makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Meta, MetaData } from "./Meta";

interface PageProps {
  noGutter?: boolean;
  meta?: MetaData;
  background?: string;
}

const useStyles = makeStyles<Theme, PageProps>(() => ({
  root: ({ noGutter, background }) => ({
    display: "flex",
    minHeight: "100%",
    paddingTop: noGutter ? null : "128px",
    flexDirection: "column",
    background: background ? background : "inherit",
  }),
}));

export const Page: React.FC<PageProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Meta meta={props.meta} />
      <>{props.children}</>
    </div>
  );
};
