import { Text } from "@kvix/ui";
import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 36,
    width: (props: any) => props.width || 121,
    backgroundColor: theme.palette.grey[900],
    borderRadius: 18,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.common.white,
    overflow: "hidden",

    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
  voteUp: {
    fontSize: 13,
    fontWeight: "bold",
    color: theme.palette.common.white,
    letterSpacing: "1px",
  },
  emoji: {
    paddingBottom: 2,
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold",
  },
}));

interface Props {
  onClick: () => void;
  width?: number | string;
  voteActive: boolean;
}

export const VoteOrDonateButton: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("wellstarStreams");
  const classes = useStyles(props);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true);
    props.onClick();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };

  if (!ready) {
    return null;
  }

  return (
    <IconButton
      className={classes.root}
      onClick={handleClick}
      component="span"
      disabled={isDisabled}
    >
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, delay: 1, ease: "backOut" }}
      >
        <Text className={classes.voteUp}>
          {t(props.voteActive ? "voteExcl" : "donateExcl")}
        </Text>
      </motion.div>
      <motion.div
        initial={{ y: "100%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, delay: 1.5, ease: "backOut" }}
      >
        <Text className={classes.emoji}><span role='img'>🤘</span></Text>
      </motion.div>
    </IconButton>
  );
};
