import React from "react";
import { Redirect, withRouter } from "react-router";
import { RenderGuardProps } from "./types";

function Render({
  history,
  redirectTo = "/",
  exception = false,
}: RenderGuardProps) {
  if (exception) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo instanceof Function ? redirectTo() : redirectTo,
        state: { accessBlocked: true, from: history.location },
      }}
    />
  );
}

export default withRouter(Render);