import { Button, Text, TextField } from "@kvix/ui";
import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KvixUserContext } from "../../../contexts/user";
import { ForgottenPasswordProps } from "../types";

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    height: "100%",
    width: "375px",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: { width: "100%", height: "100%" },
    "@media (max-width:360px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  topTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: theme.spacing(4),
  },
  fontWeightMedium: {
    fontWeight: 600,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  button: {
    margin: "20px 0 ",
    padding: "0 20px ",
    height: "44px",
    borderRadius: "30px",
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.main,
    "&:active": {
      animation: "$scaleButton linear 300ms ",
    },
  },
  lineHeight: {
    lineHeight: "16px",
  },
  textFieldGridItem: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px !important",
      height: 52,
    },
    paddingBottom: 10,
  },
}));

export const ForgottenPassword = ({}: ForgottenPasswordProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation(["forgotPassword", "error"]);
  const { actions } = useContext(KvixUserContext);
  const [email, setEmail] = React.useState("");
  const [showFinish, setShowFinish] = useState(false);

  const sendResetPassword = async () => {
    await actions.requestForgottenPassword(email);
  };

  if (!ready) {
    return null;
  }

  return (
    <React.Fragment>
      <Box className={classes.ContainerPadding}>
        {!showFinish ? (
          <Grid container direction="column">
            <Grid container className={classes.topTextBlock}>
              <Text variant="h3">{t("forgottenPassword")}</Text>
            </Grid>
            <Grid item className={classes.marginBottom}>
              <Text variant="h5" className={classes.marginBottom}>
                {t("enterEmail")}
              </Text>
            </Grid>
            <Grid item className={classes.marginBottom}>
              <TextField
                autoComplete="false"
                type="email"
                label={t("emailLabel")}
                inputMode="email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textFieldGridItem}
              />
            </Grid>
            <Grid item className={classes.marginBottom}>
              <Button
                onClick={() => {
                  sendResetPassword();
                  setShowFinish(true);
                }}
                fullWidth
              >
                <Text variant="h5">{t("sendResetLink")}</Text>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column">
            <Grid item className={classes.marginBottom}>
              <Text variant="h3">{t("passwordReset")}</Text>
            </Grid>
            <Grid item className={classes.marginBottom}>
              <Text>{t("emailSent", { email: email })}</Text>
            </Grid>
            <Grid item className={classes.marginBottom}>
              <Text variant="h5">{t("emailSent2")}</Text>
            </Grid>
            <Grid item className={classes.marginBottom}>
              <Link to="/contact">
                <Text variant="h5">{t("contactSupport")}</Text>
              </Link>
            </Grid>
          </Grid>
        )}
      </Box>
    </React.Fragment>
  );
};
