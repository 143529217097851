import { ArticleType } from "@kvix/shared";
import { KvixIcon } from "@kvix/ui";
import {
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import { FilterOptions, Option } from "..";
import { SearchFilters } from "../../..";

interface Props {
  display: boolean;
  filterOptions: FilterOptions;
  activeFilters: SearchFilters;
  setActiveFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  type: ArticleType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "DarkGrey",
    rowGap: 12,
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  search: {
    display: "flex",
    height: "100%",
    fontWeight: 500,
  },
  border: {
    borderRadius: 24,
    background: "rgb(254, 233, 238)",
    zIndex: -1,
    borderColor: "transparent",
  },

  selectField: {
    height: "100%",
    paddingRight: 0,

    "&.selected": {
      fontWeight: 600,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
      background: theme.palette.background.paper,
      zIndex: -1,
      borderRadius: "12px",
    },

    "& .MuiSelect-icon": {
      display: "none",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: 32,
    },
  },
  endAdornment: {
    position: "absolute",
    right: 6,
    top: 7,
    pointerEvents: "none",
  },
  selectSizer: {
    width: "15%",
  },
  searchSizer: {
    width: "25%",
  },
}));

const FilterSelect: React.FC<
  SelectProps & {
    value: any;
    setValue: (event: any) => void;
    options: Option[];
  }
> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation(["program"]);

  if (!ready) {
    return null;
  }

  return (
    <FormControl style={{ height: "100%" }} className={classes.selectSizer}>
      <Select
        disabled={props.disabled}
        variant="outlined"
        className={`${classes.selectField} ${!props.value ? "" : "selected"}`}
        displayEmpty
        value={props.value === null ? "" : props.value}
        onChange={props.setValue}
        endAdornment={
          <KvixIcon.Chevron.Down
            className={classes.endAdornment}
            color="primary"
          />
        }
      >
        {props.options?.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {t(option.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const DesktopFilters: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation(["content"]);

  if (!ready) {
    return null;
  }

  return (
    <motion.div
      style={{
        ["height" as any]: 40,
        ["marginTop" as any]: "-42px",
        gap: "8px",
        display: "flex",
        flexDirection: "row",
      }}
      animate={
        props.display
          ? {
              ["marginTop" as any]: "20px",
            }
          : {}
      }
      transition={{ duration: 0.2 }}
      className={classes.root}
    >
      <TextField
        fullWidth
        className={classes.searchSizer}
        variant="outlined"
        placeholder={t(`search.${props.type}`)}
        style={{ height: "100%" }}
        value={props.searchQuery}
        onChange={(event) => props.setSearchQuery(event.target.value)}
        InputProps={{
          className: classes.search,
          classes: {
            notchedOutline: classes.border,
          },
        }}
      />

      {props.type !== ArticleType.INSTRUCTOR && (
        <>
          <FilterSelect
            value={props.activeFilters?.instructorName}
            setValue={(event) =>
              props.setActiveFilters({
                ...props.activeFilters,
                instructorName: event.target.value,
              })
            }
            options={props.filterOptions.instructors}
          />

          <FilterSelect
            value={props.activeFilters?.level}
            setValue={(event) =>
              props.setActiveFilters({
                ...props.activeFilters,
                level: event.target.value,
              })
            }
            options={props.filterOptions.levels}
          />

          <FilterSelect
            value={props.activeFilters?.equipment}
            setValue={(event) =>
              props.setActiveFilters({
                ...props.activeFilters,
                equipment: event.target.value,
              })
            }
            options={props.filterOptions.equipment}
          />
          <FilterSelect
            value={props.activeFilters?.focus}
            setValue={(event) =>
              props.setActiveFilters({
                ...props.activeFilters,
                focus: event.target.value,
              })
            }
            options={props.filterOptions?.focuses}
          />

          <FilterSelect
            value={props.activeFilters?.duration}
            setValue={(event) =>
              props.setActiveFilters({
                ...props.activeFilters,
                duration: event.target.value,
              })
            }
            options={props.filterOptions.durations}
          />
        </>
      )}
    </motion.div>
  );
};
