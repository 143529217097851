const filterDuplicates = <T>(array: T[]) => {
  return array.filter((el, index, self) => self.indexOf(el) === index);
};

const fixLowercaseProperties = (items: string[]) => {
  return items.map(item => {
    if (!item || item.indexOf("-") === -1 || item.toLowerCase() !== item) {
      return item;
    }

    const splitEl = item.split("-");
    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
  });
};

const getUserLocales = () => {
  let languageList: string[] = [];

  if (typeof window !== "undefined") {
    if (window.navigator.languages) {
      languageList = languageList.concat(window.navigator.languages);
    }
    if (window.navigator.language) {
      languageList.push(window.navigator.language);
    }
    if (window.navigator.userLanguage) {
      languageList.push(window.navigator.userLanguage);
    }
    if (window.navigator.browserLanguage) {
      languageList.push(window.navigator.browserLanguage);
    }
    if (window.navigator.systemLanguage) {
      languageList.push(window.navigator.systemLanguage);
    }
  }

  languageList.push("en-US");

  return fixLowercaseProperties(filterDuplicates(languageList));
};

export const [locale] = getUserLocales();
