import { CreateUserEmailDto } from "@kvix/shared";
import { Button, Text, TextField } from "@kvix/ui";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 52,
    position: "relative",

    display: "flex",
    alignItems: "left",
    justifyContent: "left",

    "&.Mui-disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
  big: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 26,
    width: "100%",
    marginBottom: "8px",
  },
  small: {
    backgroundColor: "rgba(0, 0, 0, 0.26)",
    borderRadius: 50,
    width: 130,
  },
  brand: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.25px",
    lineHeight: "20px",
  },
  logotype: {
    display: "flex",
    paddingRigth: "10px",
    paddingLeft: "10px",

    "& img": {
      height: 24,
      width: 24,
    },
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    transition: "opacity 300ms ease 100ms",
  },
  textField: {
    position: "relative",
    width: "100%",
    height: 52,
  },
  textFieldError: {
    color: theme.palette.error.main,
  },
  textFieldDiv: {
    paddingBottom: 10,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px !important",
      height: 52,
    },
  },
}));

interface Props {
  variant: "big" | "small";
}

export const RegisterWithEmailButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  // const { variant } = props;
  const { t, ready } = useTranslation(["signUp", "error"]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [passwordConfirmation, setPasswordConfirmation] = useState("");
  // const [showFields, setShowFields] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const { actions } = useContext(KvixUserContext);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (emailError) {
      setEmailError("");
    }
    if (error) {
      setError("");
    }
  }, [email]);

  useEffect(() => {
    if (passwordError) {
      setPasswordError("");
    }
    if (error) {
      setError("");
    }
  }, [password]);

  // useEffect(() => {
  //   if (passwordConfirmError) {
  //     setPasswordConfirmError("");
  //   }
  //   if (error) {
  //     setError("");
  //   }
  // }, [passwordConfirmation]);

  const handleRegister = () => {
    let hasErrors = false;
    setLoading(true);
    if (!email) {
      setEmailError(t("error:emailRequired"));
      hasErrors = true;
    }
    if (!password) {
      setPasswordError(t("error:passwordRequired"));
      hasErrors = true;
    }
    if (!email || !password) {
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError(t("error:emailNotValid"));
      hasErrors = true;
    } else {
      setEmailError("");
    }

    if (!isValidPassword(password)) {
      setPasswordError(t("error:passwordNotValid"));
      hasErrors = true;
    } else {
      setPasswordError("");
    }

    // if (passwordConfirmation !== password) {
    //   setPasswordConfirmError(t("error:passwordDoesNotMatch"));
    //   hasErrors = true;
    // } else {
    //   setPasswordConfirmError("");
    // }

    const createUser = async () => {
      const user: CreateUserEmailDto = {
        email: email.trim(),
        password,
      };

      const res = await actions.createUserEmail(user, `${window.location.origin}${window.location.search}`);
      if (res.success) {
        await actions.setUser();
      } else {
        if (typeof res.errors[0] !== "string") {
          //Error msg from inputValidationMiddleware
          const obj = res.errors[0];
          setError(obj.msg);
        } else {
          //Error msg from createUserEmail
          setError(res.errors[0] as string);
        }
        setError(res.errors[0] as string);
      }
      setLoading(false);
    };

    if (!hasErrors) {
      createUser();
    } else {
      setLoading(false);
    }
  };

  const isValidPassword = (password: string) => {
    return password.length >= 8 && password.length <= 32;
  };

  const isValidEmail = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim());
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // const handleConfirmPasswordChange = (event) => {
  //   setPasswordConfirmation(event.target.value);
  // };

  if (!ready) {
    return null;
  }

  return (
    <Grid container item direction="column">
      {/* {!showFields ? (
        <Grid container item>
          <Button
            className={variant === "big" ? classes.big : classes.small}
            onClick={() => setShowFields(!showFields)}
          >
            <Grid item>
              {variant === "big" ? (
                <Text className={classes.brand}>{`${t("signUpWith")} ${t(
                  "email"
                )}`}</Text>
              ) : (
                <Text className={classes.brand}>Email</Text>
              )}
            </Grid>
          </Button>
        </Grid>
      ) : ( */}
        <Grid container item direction="column">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Grid item className={classes.textFieldDiv} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                inputMode="email"
                label={t("email")}
                error={emailError}
                onChange={handleEmailChange}
                disabled={loading}
              />
            </Grid>

            <Grid item className={classes.textFieldDiv} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete="new-password"
                label={t("password")}
                onChange={handlePasswordChange}
                error={passwordError}
                type="password"
                disabled={loading}
              />
            </Grid>
            {/* <Grid item className={classes.textFieldDiv} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete="new-password"
                label={t("confirmPassword")}
                onChange={handleConfirmPasswordChange}
                error={passwordConfirmError}
                type="password"
                disabled={loading}
              />
            </Grid> */}
            <Grid container item xs={12} style={{ paddingTop: "10px" }}>
              <Button className={classes.big} onClick={() => handleRegister()}>
                {loading ? (
                  <Grid item>
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Grid item>
                    <Text className={classes.brand}>{t("signup")}</Text>
                  </Grid>
                )}
              </Button>
            </Grid>
            {error !== "" && (
              <Grid item>
                <Text variant="h5" className={classes.textFieldError}>
                  {t(`error:${error}`)}
                </Text>
              </Grid>
            )}
          </motion.div>
        </Grid>
      {/* )} */}
    </Grid>
  );
};
