import React from "react";
import { useParams } from "react-router";
import { ExerciseContextProvider } from "../../../../contexts/exercise";
import { CmsExerciseLanding } from "./Landing";
import { CmsExercisepost } from "./Post";

export const CmsExercise: React.FC = (props) => {
  const { slug } = useParams<{ slug: string }>();
  return (
    <ExerciseContextProvider>
      {slug ? <CmsExercisepost slug={slug} /> : <CmsExerciseLanding />}
    </ExerciseContextProvider>
  );
};
