import { KvixIcon } from "@kvix/ui";
import { CircularProgress, Fade, Theme, useTheme } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  poster?: string;
  onClick?: () => void;
}

const Container = styled.div<Props>`
  width: 100%;
  padding-top: 56.25%;
  display: block;
  position: relative;

  &:before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) => `url(${props.poster})`};
    background-size: cover;
    background-position: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const PlayButton = styled(KvixIcon.Play)<{ theme: Theme }>`
  padding: 10px;
  font-size: 70px;

  color: ${(props) => props.theme.palette.common.white};
  background-color: ${(props) => props.theme.palette.primary.main};
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.3);
  border-radius: 50%;

  transition: all 0.125s ease-out;
`;

export const ReplayPlaceholder: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const loadingTimeout = useRef<number>();

  const onClick = useCallback(() => {
    if (loading) {
      return;
    }
    props.onClick();
    setLoading(true);
    loadingTimeout.current = window.setTimeout(() => setLoading(false), 5000);
  }, [loading, props]);

  useEffect(() => {
    return () => window.clearTimeout(loadingTimeout.current);
  }, []);

  return (
    <Fade in={!!props.poster} timeout={500}>
      <Container poster={props.poster}>
        <Overlay>
          {loading ? (
            <CircularProgress size={38} thickness={4} />
          ) : (
            <PlayButton theme={theme} onClick={onClick} />
          )}
        </Overlay>
      </Container>
    </Fade>
  );
};
