import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import { Button, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { KvixUserContext } from "src/contexts/user";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    textTransform: "none",
    justifyContent: "flex-start",

    "& .MuiButton-label": {
      fontSize: (props: any) => props?.textProps?.fontSize || 12,
      fontWeight: (props: any) => props?.textProps?.fontWeight || 500,
    },
  },
  buttonRoot: {
    height: (props: any) => props?.buttonProps?.height || 28,
    width: (props: any) => props?.buttonProps?.width || "auto",
  },
}));

enum DownloadLinks {
  Appstore = "https://apps.apple.com/se/app/kvix/id1536433992",
  PlayStore = "https://play.google.com/store/apps/details?id=se.kvix.app",
}

interface Props {
  asButton?: boolean;
  buttonProps?: { height: number; width: number | "auto" };
  customText?: string;
  textProps?: { fontSize?: number; fontWeight?: number };
}
export const DownloadApp: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const userAgent = navigator.userAgent;
  const { user } = useContext(KvixUserContext);

  const handleDownload = () => {
    pushClientEvent({
      featureType: 'download.app',
      source: Source.Web,
      user
    });

    /iPhone|iPod|iPad|Macintosh/i.test(userAgent)
      ? window.open(DownloadLinks.Appstore)
      : window.open(DownloadLinks.PlayStore);
  };

  return (
    <Button
      onClick={handleDownload}
      className={props.asButton ? classes.buttonRoot : classes.root}
      variant={props.asButton ? "contained" : "text"}
      color="primary"
    >
      {props.customText || "Ladda ner"}
    </Button>
  );
};
