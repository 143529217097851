import { AbstractBaseUser, ArticleType, mapToCard } from "@kvix/shared";
import { CardList } from "@kvix/ui";
import { Container, Divider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useFetchAllPrograms,
  useFetchRelatedInstructors,
} from "../../../hooks/http";
import { Card } from "../../partials/Content/Card";
import { Partner } from "../../partials/Partner";

interface Props {
  instructor: AbstractBaseUser;
}

export const Appendage: React.FC<Props> = (props) => {
  const instructors = useFetchRelatedInstructors({
    id: props.instructor.id,
    max: 4,
  });
  const allPrograms = useFetchAllPrograms();
  const { t, ready } = useTranslation("instructor");

  if (!ready) {
    return null;
  }

  return (
    <>
      <Container maxWidth="lg">
        <Divider style={{ marginBottom: 24 }} />
      </Container>
      <Partner partnerProfile={props.instructor.partnerProfile} />
      <Container maxWidth="lg">
        <CardList title={t("appendage.allInstructors")} gutterBottom>
          {(instructors.value || []).map((instructor) => (
            <Card
              type={ArticleType.INSTRUCTOR}
              options={mapToCard({ instructor })}
              key={instructor.id}
            />
          ))}
        </CardList>

        <CardList title={t("appendage.allPrograms")} gutterBottom>
          {(allPrograms.value || []).map((program) => (
            <Card
              type={ArticleType.PROGRAM}
              options={mapToCard({ program })}
              key={program.id}
            />
          ))}
        </CardList>
      </Container>
    </>
  );
};
