import { makeStyles, Theme } from "@material-ui/core";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CmsContext } from "../../../cms/context";
import { Page } from "../../../cms/models";
import { LanguageContext } from "../../../contexts/language";
import { useIsMobile } from "../../../hooks/isMobile";

interface StyleProps {
  direction?: "row" | "column";
}

interface Props extends StyleProps {
  translation: string;
  children: (
    item: { to: string; value: string },
    index: number
  ) => React.ReactNode;
}

export type MenuItem = {
  path: string;
  i18nKey: string;
  label: string;
  title?: string;
};

const eng: boolean = true;

export const MENU_ITEMS: Omit<MenuItem, "label">[] = [];

const populateMenuItems = (country: string) => {
  MENU_ITEMS.length = 0;
  MENU_ITEMS.push(
    {
      path: "/anytime",
      i18nKey: "catchUp",
    },
    // {
    //   path: "/sessions",
    //   i18nKey: "live",
    // },
    {
      path: "/programs",
      i18nKey: "allPrograms",
    }
  );

  if (country === "sv-SE") {
    MENU_ITEMS.push(
      {
        path: "/instructors",
        i18nKey: "allInstructors",
      },
      {
        path: "/sweet-deals",
        i18nKey: "sweetDeals",
      },
      // {
      //   path: "/exercise",
      //   i18nKey: "exercises",
      // },
      {
        path: "/blog",
        i18nKey: "blog",
      }
    );
  } else {
    MENU_ITEMS.push({
      path: "/instructors",
      i18nKey: "allInstructors",
    });
  }
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  rootEN: ({ direction = "row" }) => ({
    display: "flex",
    flexDirection: direction,
    listStyle: "none",
    padding: 0,
    margin: direction === "column" ? 0 : "auto",
    paddingRight: 150,
  }),
  rootSE: ({ direction = "row" }) => ({
    display: "flex",
    flexDirection: direction,
    listStyle: "none",
    padding: 0,
    margin: direction === "column" ? 0 : "auto",
  }),
}));

export const SiteMenu = ({ direction, translation, children }: Props) => {
  const classes = useStyles({ direction });
  const { t, ready } = useTranslation(translation);
  const { pages } = useContext(CmsContext);
  const isMobile = useIsMobile();
  const language = useContext(LanguageContext);

  useEffect(() => {
    populateMenuItems(language.currentLanguage);
  }, [language?.currentLanguage]);

  const cmsPages = useCallback(
    (pages: Page[]) =>
      pages
        .filter(({ showInSidebar }) => showInSidebar && isMobile)
        .map(({ slug, title }) => ({
          path: `/page/${slug}`,
          i18nKey: null,
          title: title,
        })),
    [isMobile]
  );

  if (!ready) {
    return null;
  }

  return (
    <ul
      className={
        language.currentLanguage === "sv-SE" ? classes.rootSE : classes.rootEN
      }
    >
      {MENU_ITEMS.concat(cmsPages(pages || [])).map(
        ({ path, i18nKey, title }, index) =>
          children({ to: path, value: title ? title : t(`${i18nKey}`) }, index)
      )}
    </ul>
  );
};
