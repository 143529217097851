import * as Sentry from "@sentry/react";
import React from "react";
import { withRouter } from "react-router";
import { Props, State } from "./types";

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      key: 0,
    };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, {
      tags: { package: "client" },
      extra: info,
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    const { FallbackComponent, children, ...rest } = this.props;

    if (this.state.hasError) {
      return (
        <FallbackComponent
          resetError={this.resetError}
          {...rest}
        />
      );
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
