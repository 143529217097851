import { Paper, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import React, { useContext } from "react";
import { HeaderContext } from "./context";

interface Props {
  slide: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: -16,
    left: 0,
    right: 0,
    height: "calc(100% + 16px)",
  },
  background: {
    width: "100%",
    height: "100%",
    background: theme.palette.background.default,
    transition: "all 300ms ease 100ms",
    boxShadow: "0px -13px 11px 9px rgba(0, 0, 0, 0.3)",
  },
  fade: {
    boxShadow: "none",
    transitionDelay: "0ms",
  },
}));

export const HeaderBackground: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const context = useContext(HeaderContext);

  const classname = classnames(classes.background);

  return (
    <div className={classes.root}>
      <Paper className={classname} />
    </div>
  );
};
