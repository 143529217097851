import { AbstractLiveSession, CompetitionRoundState } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Divider, makeStyles } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CompetitionContext } from "../../../../contexts/competition";
import { StreamerAdminContext } from "../context";
import { PreviousStreamsList } from "./PreviousStreamsList";
import { ScheduledStreamList } from "./ScheduledStreamList";
import { ScheduleStreamModal } from "./ScheduleStreamModal";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .active > button": {
      fontWeight: "bold",
      lineHeight: 2.2,
    },
  },
  profileWrapper: {
    width: "40%",
  },
  mainContent: {
    width: "60%",
  },
  scheduledStreamsHeader: {
    marginBottom: "20px",
  },
  wellstarStreamsInfo: {
    color: theme.palette.common.white,
    fontSize: "18px",
    fontWeight: 500,
    maxWidth: "440px",
  },
  infoText: {
    color: theme.palette.common.white,
    fontSize: "18px",
    fontWeight: 500,
    maxWidth: "440px",
    marginBottom: 10,
  },
  emoji: {
    color: theme.palette.common.black,
  },
  addStreamButton: {
    background: theme.palette.background.paper,
    height: "72px",
    width: "100%",
    border: `1px ${
      theme.palette.type === "light" ? "#FEE9EE" : "#2D2D2D"
    } solid`,
    borderRadius: "50px",
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "18px",
    cursor: "pointer",
    transition: "all 0.15s ease",
    "&:hover:not([disabled])": {
      background: theme.palette.type === "light" ? "#E8E8E7" : "#3b3b3b",
    },
    "&[disabled]": {
      opacity: "0.4",
      cursor: "default",
    },
  },
}));

export const StreamsPageDivider = styled(Divider)`
  margin: 14px 0;
  background-color: #e8e8e9;
`;

type AddStreamButtonProps = {
  onClick: () => void;
  disabled: boolean;
};
const AddStreamButton = ({ onClick, disabled }: AddStreamButtonProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");

  if (!ready) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      className={classes.addStreamButton}
      disabled={disabled}
    >
      {t("addStreamButton")}
    </button>
  );
};

export const StreamsInfo = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const { previousStreams, scheduledStreams } =
    useContext(StreamerAdminContext);
  const [streamToEdit, setStreamToEdit] = useState<AbstractLiveSession>(null);
  const [showScheduleStream, setShowScheduleStream] = useState(false);

  const noOfAvailableStreams = useMemo(() => {
    if (activeCompetitionRound?.contestantSessionLimit) {
      return (
        activeCompetitionRound.contestantSessionLimit -
        previousStreams?.length -
        scheduledStreams?.length
      );
    }
  }, [activeCompetitionRound, previousStreams, scheduledStreams]);

  const disableAddStream = useMemo(() => {
    if (!activeCompetitionRound) {
      return false;
    }

    return (
      activeCompetitionRound?.state !== CompetitionRoundState.RUNNING ||
      noOfAvailableStreams < 1
    );
  }, [activeCompetitionRound, noOfAvailableStreams]);

  const onClickScheduled = (stream) => {
    setShowScheduleStream(true);
    setStreamToEdit(stream);
  };

  const closeScheduleStream = () => {
    setShowScheduleStream(false);
    setStreamToEdit(null);
  };

  if (!ready) {
    return null;
  }

  return (
    <Box>
      <ScheduleStreamModal
        open={showScheduleStream}
        onClose={closeScheduleStream}
        streamToEdit={streamToEdit}
      />
      {(scheduledStreams?.length > 0 || previousStreams?.length > 0) && (
        <>
          <Text className={classes.scheduledStreamsHeader} variant="h4">
            {scheduledStreams?.length}&nbsp;
            {activeCompetitionRound
              ? t("scheduledWellstarStreamsHeader")
              : t("scheduledStreamsHeader")}
          </Text>
          <ScheduledStreamList
            scheduledStreams={scheduledStreams}
            onClick={onClickScheduled}
          />
        </>
      )}
      {activeCompetitionRound && (
        <>
          <Text className={classes.scheduledStreamsHeader} variant="h4">
            {noOfAvailableStreams > 0
              ? `${noOfAvailableStreams} ${t("remainingStreams")}`
              : t("noRemainingStreams")}
          </Text>
          <StreamsPageDivider />
        </>
      )}
      <AddStreamButton
        onClick={() => setShowScheduleStream(true)}
        disabled={disableAddStream}
      />
      <StreamsPageDivider />
      {previousStreams?.length > 0 && (
        <>
          <Text className={classes.scheduledStreamsHeader} variant="h4">
            {previousStreams?.length}&nbsp;{t("previousStreams")}
          </Text>
          <PreviousStreamsList />
        </>
      )}
      {activeCompetitionRound && (
        <>
          <Text className={classes.infoText}>
            {t("infoText")}
            <span className={classes.emoji}> 😉</span>
          </Text>
          <Text className={classes.wellstarStreamsInfo}>
            {t("wellstarStreamsInfo", {
              numStreams: activeCompetitionRound?.contestantSessionLimit,
            })}
          </Text>
        </>
      )}
    </Box>
  );
};
