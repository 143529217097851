import { Text } from "@kvix/ui";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PurchaseFlowContext } from "../../../../contexts/PurchaseFlow";
import devicesImage from "../../../partials/assets/devices.png";
import gaspImage from "../../../partials/assets/gasp.png";
import headbandImage from "../../../partials/assets/headband.png";
import { Flow, NewFlow, RegisterStep } from "../../../PurchaseFlow";
import Cookies from "js-cookie";

const useStyles = makeStyles<Theme, any>((theme) => ({
  // root: { textAlign: "center" },
  ContainerPadding: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: "red",
    [theme.breakpoints.down("xl")]: {
      marginTop: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  paper: {
    boxShadow: `${
      theme.palette.type !== "dark" && "0px 0px 50px 0px #4004a41a"
    }`,
    background: `${
      theme.palette.type === "dark" && theme.palette.background.paper
    }`,
    borderRadius: "22px",
    justifyContent: "space-between",
    alignItems: "baseline",
    rowGap: "24px",
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },

  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      gap: "16px",
    },
  },

  itemImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  image: {
    width: "50%",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
    },
  },
  itemTextWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      marginleft: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
      marginleft: theme.spacing(0.5),
    },
  },
  itemTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  itemSubtitle: {
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export const Promote: React.FC<{
  noPadding?: boolean;
  hideButton?: boolean;
}> = memo((props) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();
  const { t, ready } = useTranslation("startPage");
  const { actions } = useContext(PurchaseFlowContext);

  if (!ready) {
    return null;
  }

  return (
    <Container
      maxWidth="lg"
      className={classes.ContainerPadding}
      // style={props.noPadding && { padding: 0 }}
    >
      <Text variant="h2" className={classes.title}>
        {t("promote.title")}
      </Text>

      <Grid
        container
        direction={isMobile ? "column" : "row"}
        className={classes.paper}
      >
        {/* Item one */}
        <Grid item className={classes.itemWrapper}>
          <Grid item className={classes.itemImageContainer}>
            <img
              src={headbandImage}
              className={classes.image}
              alt={t("promote.first.title")}
            />
          </Grid>
          <Grid item className={classes.itemTextWrapper}>
            <Text variant="h4" className={classes.itemTitle}>
              {t("promote.first.title")}
            </Text>
            <Text variant="body1" className={classes.itemSubtitle}>
              {t("promote.first.text")}
            </Text>
          </Grid>
        </Grid>
        {/* Item two */}
        {/* <Grid item className={classes.itemWrapper}>
          <Grid item className={classes.itemImageContainer}>
            <img
              src={devicesImage}
              className={classes.image}
              alt={t("promote.second.title")}
            />
          </Grid>
          <Grid item className={classes.itemTextWrapper}>
            <Text variant="h4" className={classes.itemTitle}>
              {t("promote.second.title")}
            </Text>
            <Text variant="body1" className={classes.itemSubtitle}>
              {t("promote.second.text")}
            </Text>
          </Grid>
        </Grid> */}
        {/* Item three */}
        <Grid item className={classes.itemWrapper}>
          <Grid item className={classes.itemImageContainer}>
            <img
              src={gaspImage}
              className={classes.image}
              alt={t("promote.third.title")}
            />
          </Grid>
          <Grid item className={classes.itemTextWrapper}>
            <Text variant="h4" className={classes.itemTitle}>
              {t("promote.third.title")}
            </Text>
            <Text variant="body1" className={classes.itemSubtitle}>
              {t("promote.third.text")}
            </Text>
          </Grid>
        </Grid>
        {!props.hideButton && (
          <Grid container className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const wpCampaignParams = Cookies.get('wp-campaign');
                
                if (wpCampaignParams) {
                  history.replace(`${history.location.pathname}${wpCampaignParams}`);
                };

                actions.prompt(
                  <NewFlow registerProps={{ checkoutSource: wpCampaignParams ? 'wp' : 'kvix' }} currentTab={0} />
                )
              }}
            >
              {t("promote.tryButton")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
});
