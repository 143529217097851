import { KvixThemeVariantContext } from "@kvix/ui";
import { useContext, useEffect, useState } from "react";

export const useIsDarkmode = () => {
  const [isDarkmode, setIsDarkmode] = useState(false);
  const variantContext = useContext(KvixThemeVariantContext);

  useEffect(() => {
    setIsDarkmode(variantContext.variant === "dark");
  }, [variantContext.variant]);

  return isDarkmode;
};
