import React from "react";
import {
  Redirect,
  RedirectProps,
  Route as ReactRouterRoute,
  RouteComponentProps,
  RouteProps,
  withRouter,
} from "react-router";

type ProvidedRouteProps = Omit<RouteProps, "location"> & RouteComponentProps;

interface IProps extends ProvidedRouteProps {
  beforeEnter?: (props: ProvidedRouteProps) => boolean;
  onNavigationBlocked?: (
    props: ProvidedRouteProps
  ) => React.ReactElement<RedirectProps>;
}

export function Route({
  history,
  match,
  beforeEnter,
  onNavigationBlocked,
  ...props
}: IProps) {
  if (
    beforeEnter instanceof Function &&
    !beforeEnter({ history, match, ...props })
  ) {
    if (onNavigationBlocked instanceof Function) {
      return onNavigationBlocked({ history, match, ...props });
    }

    return (
      <Redirect
        to={{
          pathname: "/",
          state: { accessBlocked: true, from: history.location.pathname },
        }}
      />
    );
  }

  return <ReactRouterRoute {...props} />;
}

export const KvixRoute = withRouter(Route);
