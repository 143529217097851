import {
  CampaignContextState,
  CampaignTemplates,
  PurchaseProvider,
} from "@kvix/shared";
import { campaignTemplateList, DefaultTemplate } from "@kvix/ui";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, RouteComponentProps } from "react-router";
import { useFetchCampaignFromCode } from "../../../hooks/http";
import { Footer } from "../../Footer";
import { Page } from "../../partials/Page";
import { CampaignContext } from "./context";

interface MatchParams {
  campaignCode: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const CampaignTemplateHandler = ({
  context,
}: Record<"context", CampaignContextState>) => {
  const {
    campaign: {
      campaignContent: { data },
    },
  } = context;

  const templateType = campaignTemplateList[data.name];

  const Component = templateType ? new templateType() : new DefaultTemplate();

  const template = templateType
    ? data
    : { name: CampaignTemplates.DefaultTemplate, blocks: [] };

  return <Component.Render template={template} campaignContext={context} />;
};

export const Campaign = ({
  match: {
    params: { campaignCode },
  },
}: Props) => {
  const { t, ready } = useTranslation("seo");

  const campaignContext = useContext(CampaignContext);

  const { loading, value: campaign } = useFetchCampaignFromCode(
    campaignCode.toLowerCase(),
    PurchaseProvider.STRIPE
  );

  useEffect(() => {
    let subscribed = true;

    const previousCampaign = campaignContext.campaign;

    if (!subscribed) {
      return;
    }

    if (!campaign) {
      return;
    }

    if (
      !previousCampaign ||
      (previousCampaign && previousCampaign.id !== campaign.id)
    ) {
      campaignContext.setCampaign(campaign);
    }

    return () => {
      subscribed = false;
    };
  }, [loading]);

  if (!loading && !campaign) {
    return <Redirect to="/page-not-found" />;
  }

  if (!ready) {
    return null;
  }

  return !campaignContext.campaign ? null : (
    <Page
      meta={{
        title: campaignContext.campaign?.title || t("default.title"),
        description: t("default.description"),
        ogTitle: campaignContext.campaign?.title || t("default.title"),
        ogImage:
          campaignContext.campaign.instructor.instructorProfile.imageUrl ||
          t("default.ogImage"),
      }}
    >
      <CampaignTemplateHandler context={campaignContext} />
      <Footer noGutter layout="campaign" />
    </Page>
  );
};
