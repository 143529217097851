import { Avatar, Grid, makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { BasicUserUpdate } from "../interfaces/BasicUserUpdate";

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: "pointer",
    width: "120px",
    height: "120px",
  },
  input: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
  },
  tooltip: {
    zIndex: 999,
    "& .MuiTooltip-tooltip": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      border: "2px solid #fee9ee",
      fontWeight: 600,
    },
  },
  container: {
    marginBottom: "20px",
  },
}));

interface Props {
  translations: {
    tooltip: string;
  };
  defaultAvatar: string;
  setBasicUserData: (data: Partial<BasicUserUpdate>) => void;
  loading: boolean;
}

export const dataURIToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const AvatarPicker: React.FC<Props> = (props) => {
  const { translations, defaultAvatar, setBasicUserData, loading } = props;
  const classes = useStyles();
  const [imgData, setImgData] = useState<string | ArrayBuffer>(defaultAvatar);
  const avatarInput = useRef<HTMLInputElement>();

  const onChangeAvatar = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (imgData) {
      setBasicUserData({ avatar: imgData as string });
    } else {
      setBasicUserData({ avatar: null });
    }
  }, [imgData]);

  return (
    <Grid item className={classes.container}>
      <Tooltip
        title={translations.tooltip}
        PopperProps={{ className: classes.tooltip }}
      >
        <Avatar
          className={classes.avatar}
          onClick={() => !loading && avatarInput?.current?.click()}
          src={imgData as string}
        />
      </Tooltip>
      <input
        ref={avatarInput}
        className={classes.input}
        type="file"
        accept="image/png, image/jpg, image/jpeg"
        onChange={onChangeAvatar}
      />
    </Grid>
  );
};
