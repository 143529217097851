import { AbstractBaseUser } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useIsMobile } from "../../../hooks/isMobile";

interface Props {
  instructor: AbstractBaseUser;
  noQuote?: boolean;
}

const PartnerTitle: React.FC = (props) => (
  <Text
    variant="body1"
    style={{ color: "#cdcecf", textTransform: "uppercase", fontWeight: 700 }}
  >
    <span style={{ color: "#ff0000", marginRight: 4 }}>•</span>
    {props.children}
  </Text>
);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },

  poster: {
    display: "block",
    position: "sticky",
    top: "128px",
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",

    [theme.breakpoints.up("sm")]: {
      borderRadius: "4px",
    },

    [theme.breakpoints.down("sm")]: {
      maxHeight: "65vh",
      objectFit: "cover",
      objectPosition: "top",
    },
  },

  quote: {
    color: theme.palette.common.white,
    position: "sticky",
    padding: "32px",
    paddingTop: "150px",
    top: 0,
    whiteSpace: "pre-line",

    "&:before": {
      content: "'“'",
    },
    "&:after": {
      content: "'”'",
    },
  },

  name: {
    bottom: 0,
  },

  posterText: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "28px",
    color: theme.palette.common.white,

    "& h2": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        width: "2em",
        height: "2em",
      },
    },

    [theme.breakpoints.down("sm")]: {
      width: "auto",
      padding: "15px",
      "& h2": {
        "& svg": {
          width: "1em !important",
          height: "1em !important",
        },
      },
    },
  },

  hr: {
    backgroundColor: theme.palette.common.white,
    margin: "8px 0",
  },
}));

export const InstructorPoster: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const { instructor, noQuote } = props;

  return (
    <div className={classes.root}>
      <div className={classes.poster}>
        <div style={{ position: "relative" }}>
          <img
            alt="Instructor profile"
            className={classes.poster}
            src={instructor.instructorProfile.imageUrl || instructor.imageUrl}
          />
        </div>
      </div>

      {instructor.quote && !isMobile && !noQuote && (
        <Text className={classes.quote} variant="h4">
          {instructor.quote}
        </Text>
      )}

      {isMobile && (
        <div className={`${classes.name} ${classes.posterText}`}>
          {instructor.partnerProfile && (
            <PartnerTitle>{instructor.partnerProfile.title}</PartnerTitle>
          )}
          <Text variant="h2" style={{ wordBreak: "break-word" }}>
            {instructor.name.first}
            <br />
            {instructor.name.last}
          </Text>
        </div>
      )}
    </div>
  );
};
