import { Text } from "@kvix/ui";
import { Box, makeStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  streamingInfoModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    height: "768px",
    padding: "50px 45px 0",
    background: theme.palette.background.default,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      padding: "50px 20px 0",
    },
  },
  streamingInfoTitle: {
    marginBottom: "20px",
  },
  streamingInfoText: {
    fontWeight: 500,
    fontSize: "16px",
  },
  streamingInfoContent: {
    marginTop: "50px",
  },
  gotItButton: {
    marginTop: "20px",
    cursor: "pointer",
    paddding: "8px 5px 8px 0",
  },
  gotItText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

type StreamingInfoModalProps = {
  onClose: () => void;
};
export const StreamingInfoModal = ({ onClose }: StreamingInfoModalProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.streamingInfoModal}>
      <Box>
        <div onClick={onClose} style={{ cursor: "pointer" }}>
          <Close />
        </div>
      </Box>
      <Box className={classes.streamingInfoContent}>
        <Text className={classes.streamingInfoTitle} variant="h5">
          {t("infoAboutStreamingHeader")}
        </Text>
        <Text className={classes.streamingInfoText}>
          {t("infoAboutStreaming")}
        </Text>
        <div className={classes.gotItButton} onClick={onClose}>
          <Text className={classes.gotItText}>{t("gotIt")}</Text>
        </div>
      </Box>
    </Box>
  );
};
