import React from "react";
import { useParams } from "react-router";
import { BlogContextProvider } from "../../../../contexts/blog";
import { CmsBlogLanding } from "./Landing";
import { CmsBlogpost } from "./Post";

export const CmsBlog: React.FC = (props) => {
  const { slug } = useParams<{ slug: string }>();
  return (
    <BlogContextProvider>
      {slug ? <CmsBlogpost slug={slug} /> : <CmsBlogLanding />}
    </BlogContextProvider>
  );
};
