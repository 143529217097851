import {
  AbstractPlaylist,
  AbstractSession,
  ArticleType,
  IssuedFrom,
  mapToCard,
  Role,
} from "@kvix/shared";
import { CardList, Text } from "@kvix/ui";
import { Container, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, RouteChildrenProps } from "react-router";
import { Guard } from "src/components/partials/Guard";
import { BlogContextProvider } from "../../../contexts/blog";
import { hasPremiumAccess, KvixUserContext } from "../../../contexts/user";
import { useIsInActiveRunningCompetition } from "../../../hooks/competition";
import {
  useFetchCampaignIdsByUserId,
  useFetchInstructor,
  useFetchInstructorComments,
} from "../../../hooks/http";
import { useIsMobile } from "../../../hooks/isMobile";
import { useFetchPlaylistsByUserId } from "../../../hooks/playlist";
import { useAndCaptureParamsId } from "../../../hooks/useAndCaptureParamsId";
import { Footer } from "../../Footer";
import { Banner } from "../../partials/Banner/Banner";
import { Card } from "../../partials/Content/Card";
import { CommentCard } from "../../partials/Content/CommentCard";
import { FollowButton } from "../../partials/Instructor/FollowButton";
import { Page } from "../../partials/Page";
import { ReplayList } from "../../partials/ReplayList";
import { ShareButton } from "../../partials/SocialShare/ShareButton";
import { DonateType } from "../Payment/BuyCoins/enums/DonateType";
import { VoteModal } from "../Payment/BuyCoins/VoteModal";
import { VoteOrDonateButton } from "../Payment/BuyCoins/VoteOrDonateButton";
import { Appendage } from "./Appendage";
import { InstructorBlog } from "./InstructorBlog";
import { InstructorPoster } from "./InstructorPoster";
import { Quote } from "./Quote";

const useStyles = makeStyles((theme) => ({
  root: { padding: 0, marginBottom: "48px" },

  rightColumn: {
    paddingLeft: "100px",

    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      width: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "32px",
    },
  },
  social: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 20,
  },
  bioText: {
    whiteSpace: "pre-line",
  },
  displayMoreButton: {
    textTransform: "none",
    height: "36px",
    padding: "0 14px",
    marginTop: theme.spacing(2),
  },
}));

const PartnerTitle: React.FC = (props) => (
  <Text
    variant="body1"
    style={{ color: "#cdcecf", textTransform: "uppercase", fontWeight: 700 }}
  >
    <span style={{ color: "#ff0000", marginRight: 4 }}>•</span>
    {props.children}
  </Text>
);

export default (props: RouteChildrenProps) => {
  const classes = useStyles(props);
  const { actions, user } = useContext(KvixUserContext);
  const userId = useAndCaptureParamsId("userId");
  const [showDonate, setShowDonate] = useState(false);

  if (isNaN(userId)) {
    return <Redirect to="/page-not-found" />;
  }

  const instructor = useFetchInstructor(+userId);
  const voteActive = useIsInActiveRunningCompetition(instructor);
  const playlists = useFetchPlaylistsByUserId(+userId);
  const comments = useFetchInstructorComments(+userId);
  const campaignIdsAsync = useFetchCampaignIdsByUserId(+userId);
  const { t, ready } = useTranslation(["instructor", "seo"]);
  const isMobile = useIsMobile();

  const instructorReplays = useMemo(() => {
    if (instructor && instructor.programs) {
      let replays: AbstractSession[] = [];
      for (const program of instructor.programs) {
        if (program.sessions) {
          replays.push(
            ...program.sessions.filter(
              (session) => session.mp4Url || session.hlsUrl
            )
          );
        }
      }

      return replays.sort(
        (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
      );
    }
  }, [instructor]);

  const handleClick = () => {
    setShowDonate(!showDonate);
  };

  const hasAccess = (playlist: AbstractPlaylist) => {
    if (user && user.roles.includes(Role.Admin)) {
      return true;
    }

    if (playlist?.campaigns?.length) {
      if (campaignIdsAsync && !campaignIdsAsync?.length) {
        return false;
      }

      const playlistCampaignIds = playlist.campaigns.map((c) => c.campaignId);
      return campaignIdsAsync.some((c) => playlistCampaignIds.includes(c));
    }
  };

  if (!instructor || !ready) {
    return null;
  }

  if (
    !instructor.instructorProfile ||
    !instructor.instructorProfile.bio ||
    !instructor.instructorProfile.imageUrl
  ) {
    return (
      <Page>
        <Container maxWidth="md">
          <Text variant="h3" align="center">
            {t("noProfile")}
          </Text>
        </Container>
      </Page>
    );
  }

  const cardListProps = { slidesPerView: 1, noMargin: true };

  return (
    <Page
      meta={{
        title: t("seo:instructor.title", {
          name: instructor.name.custom
            ? instructor.name.custom
            : `${instructor.name.first} ${instructor.name.last}`,
        }),
        description: instructor.instructorProfile.bio,
        ogTitle: instructor.name.custom
          ? instructor.name.custom
          : `${instructor.name.first} ${instructor.name.last}`,
        ogImage: instructor.instructorProfile.imageUrl,
      }}
      noGutter={isMobile}
    >
      <Container maxWidth="lg" className={classes.root}>
        <VoteModal
          instructor={instructor}
          isMobile={isMobile}
          displayVote={showDonate}
          setDisplayVote={setShowDonate}
          issuedFrom={IssuedFrom.INSTRUCTOR_PAGE}
          donateType={voteActive ? DonateType.VOTE : DonateType.COIN}
        />
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <InstructorPoster instructor={instructor} />
          </Grid>

          <Grid item xs={12} md={6} className={classes.rightColumn}>
            <Grid container direction="column" spacing={6}>
              {!isMobile && (
                <>
                  <Grid className={classes.topContainer}>
                    <VoteOrDonateButton
                      onClick={handleClick}
                      voteActive={voteActive}
                    />
                    <Grid item className={classes.social}>
                      {instructor.instructorProfile && (
                        <Guard.Click>
                          <FollowButton instructor={instructor} />
                        </Guard.Click>
                      )}
                      <ShareButton />
                    </Grid>
                  </Grid>
                  <Grid item>
                    {instructor.partnerProfile && (
                      <PartnerTitle>
                        {instructor.partnerProfile.title}
                      </PartnerTitle>
                    )}
                    <Text variant="h1" style={{ wordBreak: "break-word" }}>
                      {instructor.name.first}
                      <br />
                      {instructor.name.last}
                    </Text>
                  </Grid>
                </>
              )}

              {isMobile && (
                <Grid className={classes.topContainer}>
                  <VoteOrDonateButton
                    onClick={handleClick}
                    voteActive={voteActive}
                  />
                  <Grid item className={classes.social}>
                    {instructor.instructorProfile && (
                      <Guard.Click>
                        <FollowButton instructor={instructor} />
                      </Guard.Click>
                    )}
                    <ShareButton />
                  </Grid>
                </Grid>
              )}

              {instructor.instructorProfile.areas.length > 0 && (
                <Grid item>
                  {instructor.instructorProfile.areas.map((area, i) => (
                    <Text
                      key={i}
                      variant="h4"
                      style={{ marginBottom: "4px", wordBreak: "break-word" }}
                    >
                      {area}
                    </Text>
                  ))}
                </Grid>
              )}

              <Grid item>
                <Text
                  variant="h4"
                  gutterBottom
                  style={{ wordBreak: "break-word" }}
                >
                  {t("bio")}
                </Text>

                <Text className={classes.bioText}>
                  {instructor.instructorProfile.bio}
                </Text>
              </Grid>

              {instructor.instructorProfile.qualifications.length > 0 && (
                <Grid item>
                  <Text
                    variant="h4"
                    gutterBottom
                    style={{ wordBreak: "break-word" }}
                  >
                    {t("qualifications")}
                  </Text>

                  {instructor.instructorProfile.qualifications.map(
                    (qualification, i) => (
                      <Text key={i}>{qualification}</Text>
                    )
                  )}
                </Grid>
              )}

              {comments.value?.length > 0 && (
                <Grid item>
                  <Text variant="h4" gutterBottom>
                    {t("reviews")}
                  </Text>
                  <CardList {...cardListProps}>
                    {(comments.value || []).map((comment) => (
                      <CommentCard comment={comment} key={comment.id} />
                    ))}
                  </CardList>
                </Grid>
              )}

              {instructor?.programs?.length > 0 && (
                <>
                  <Divider />

                  <Grid item>
                    <Text variant="h4" gutterBottom>
                      {t("programs")}
                    </Text>

                    <CardList>
                      {instructor.programs
                        .sort(
                          (a, b) =>
                            (a.priority !== null ? a.priority : Infinity) -
                            (b.priority !== null ? b.priority : Infinity)
                        )
                        .map((program) => (
                          <Card
                            type={ArticleType.PROGRAM}
                            options={mapToCard({
                              program: { ...program, instructor },
                            })}
                            key={program.id}
                          />
                        ))}
                    </CardList>
                  </Grid>
                </>
              )}

              {playlists && campaignIdsAsync && playlists.length > 0 && (
                <>
                  <Divider />

                  <Grid item>
                    <Text variant="h4" gutterBottom>
                      {t("playlists")}
                    </Text>

                    <CardList>
                      {playlists.map((playlist) => (
                        <React.Fragment key={playlist.id}>
                          {hasAccess(playlist) ? (
                            <Card
                              type={ArticleType.PLAYLIST}
                              options={mapToCard({
                                playlist: { ...playlist },
                              })}
                              toggleFavourite={
                                hasPremiumAccess(user)
                                  ? () =>
                                      actions.toggleFavourite({
                                        playlistId: playlist.id,
                                      })
                                  : null
                              }
                              key={playlist.id}
                            />
                          ) : null}
                        </React.Fragment>
                      ))}
                    </CardList>
                  </Grid>
                </>
              )}

              <Grid item style={{ width: "100%" }}>
                {instructorReplays?.length > 0 && (
                  <>
                    <Divider />

                    <Grid item style={{ marginTop: "24px" }}>
                      <Text variant="h4" gutterBottom>
                        {t("latestClasses")}
                      </Text>

                      <ReplayList
                        initialReplays={4}
                        replays={instructorReplays.map((replay) => {
                          return {
                            ...replay,
                            program: instructor.programs.find(
                              (program) => program.id === replay.programId
                            ),
                          };
                        })}
                        programs={instructor.programs}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <BlogContextProvider>
                  <InstructorBlog instructor={instructor} />
                </BlogContextProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {isMobile && <Quote instructor={instructor} />}

      <Appendage instructor={instructor} />

      <Container maxWidth="lg">
        <Banner type="upgrade-premium" size="large" />
      </Container>
      <Footer />
    </Page>
  );
};
