import { Text } from "@kvix/ui";
import { Box, Button, Container } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../partials/Page";
import { FallbackComponentProps } from "../types";

export function LazyRouteFallback({
  history,
  resetError,
}: FallbackComponentProps) {
  const { t, ready } = useTranslation("errorBoundary");

  if (!ready) {
    return null;
  }

  return (
    <Page>
      <Container maxWidth="sm">
        <Box mt="25vh">
          <Text gutterBottom align="center" variant="h4">
            {t("title")}
          </Text>

          <Box maxWidth={350} margin="0 auto">
            <Text align="center">{t("text")}</Text>
          </Box>

          <Box maxWidth={250} margin="32px auto">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                resetError();
                history.replace("/");
              }}
              style={{ height: 40 }}
            >
              {t("buttonText")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
