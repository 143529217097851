import { AbstractBaseUser } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Blogpost } from "../../../cms/models/Blogpost";
import { BlogContext } from "../../../contexts/blog";

interface Props {
  instructor: AbstractBaseUser;
}

const Wrapper = styled(Link)<{ viewport: string; position: string }>`
  display: flex;
  margin: ${(props) =>
    props.position === "primary" && props.viewport === "desktop"
      ? "0 12px 48px 0"
      : "0 12px 18px 0"};
  width: ${(props) =>
    props.viewport === "mobile" ? "calc(50% - 12px)" : "100%"};

  flex-direction: ${(props) =>
    props.viewport === "mobile" || props.position === "primary"
      ? "column"
      : "row"};
`;

const ImageContainer = styled.div<{ isMobile: boolean; isPrimary: boolean }>`
  overflow: hidden;
  margin: 0 24px 0 0;
  position: relative;
  width: ${(props) => (props.isMobile || props.isPrimary ? "100%" : "40%")};
  padding-bottom: ${(props) =>
    props.isMobile || props.isPrimary ? "100%" : "40%"};
`;

const Image = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
`;
const InstructorBlogpost: React.FC<{
  data: Blogpost;
  isPrimary: boolean;
  isMobile: boolean;
}> = (props) => {
  return (
    <Wrapper
      viewport={props.isMobile ? "mobile" : "desktop"}
      position={props.isPrimary ? "primary" : "secondary"}
      to={`/blog/${props.data.slug}`}
    >
      <ImageContainer isMobile={props.isMobile} isPrimary={props.isPrimary}>
        <Image url={props.data.image.url} />
      </ImageContainer>
      <Box
        width={props.isMobile || props.isPrimary ? "100%" : "50%"}
        maxWidth={props.isPrimary && !props.isMobile ? "100%" : "214px"}
      >
        <Text
          variant="caption"
          color="primary"
          style={
            props.isPrimary && !props.isMobile
              ? { display: "block", margin: "12px 0" }
              : null
          }
        >
          {props.data.caption}
        </Text>
        <Text variant={props.isPrimary && !props.isMobile ? "h5" : "h6"}>
          {props.data.title}
        </Text>
      </Box>
    </Wrapper>
  );
};

const InstructorBlogposts: React.FC<{ blogposts: Blogpost[] }> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Grid
      container
      direction={isMobile ? "row" : "column"}
      style={{ marginTop: "36px" }}
    >
      {props.blogposts.map((blogpost, index) => {
        return (
          <InstructorBlogpost
            data={blogpost}
            isPrimary={index === 0 && props.blogposts.length > 1}
            key={blogpost.id}
            isMobile={isMobile}
          />
        );
      })}
    </Grid>
  );
};

export const InstructorBlog: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("instructor");
  const { blog } = useContext(BlogContext);
  const instructorBlogPosts = blog?.blogposts.filter(
    (blogpost) => blogpost.userId === props.instructor.id
  );

  if (!ready) {
    return null;
  }

  return (
    <>
      <Text variant="h4" gutterBottom>
        {t("latestInstructorPosts")}
      </Text>
      {!instructorBlogPosts || instructorBlogPosts.length < 1 ? (
        <Text variant="body1">{t("noBlogposts")}</Text>
      ) : (
        <InstructorBlogposts blogposts={instructorBlogPosts} />
      )}
    </>
  );
};
