import { KvixIcon } from "@kvix/ui";
import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { DisplayArrangement } from "../..";

interface Props {
  arrangement: DisplayArrangement;
  setArrangement: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 32,
    height: 32,
    minWidth: 32,
    width: 32,
    color: theme.palette.getContrastText(theme.palette.background.default),
    background: theme.palette.background.paper,
    borderRadius: 10,
    // marginLeft: "16px",

    // [theme.breakpoints.down("xs")]: {
    //   marginLeft: "16px",
    // },
  },
}));

export const ArrangeButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <Button
      className={classes.root}
      onClick={props.setArrangement}
      disabled={props.disabled}
    >
      {props.arrangement === DisplayArrangement.CARD ? (
        <KvixIcon.GridOn style={{ margin: "3px 0 0 0", fontSize: 22 }} />
      ) : (
        <KvixIcon.Reorder style={{ margin: "3px 0 0 0", fontSize: 22 }} />
      )}
    </Button>
  );
};
