import {
  AbstractSession,
  AbstractUser,
  AlgoliaAnytimeArticle,
  AlgoliaArticle,
  AlgoliaInstructorArticle,
  AlgoliaProgramArticle,
  AlgoliaSessionArticle,
  ArticleType,
  SessionState,
  SessionStateUpdatePayload,
} from "@kvix/shared";
import {
  AnytimeListCard,
  InstructorListCard,
  ProgramListCard,
  SessionListCard,
} from "@kvix/ui";
import React, { useState } from "react";
import { useSocketEvent } from "../../../hooks/socket";

interface Props {
  type: ArticleType;
  article: AlgoliaArticle;
  filterByChip?: (key: string, value: any) => void;
  toggleFavourite?: (options: {
    programId?: number;
    session?: AbstractSession | AlgoliaAnytimeArticle;
  }) => void;
  isLiked?: boolean;
  isLast?: boolean;
  currentTime?: number;
  user: AbstractUser;
}

export const ListCard: React.FC<Props> = (props) => {
  const { user, ...propsWithoutUser } = props;
  const [upcomingCardState, setUpcomingCardState] = useState<SessionState>(
    props.article["state"]
  );
  
  const handleStateUpdate = (payload: SessionStateUpdatePayload) => {
    if (props.article.id === payload.sessionId) {
      setUpcomingCardState(payload.state);
    }
  };

  useSocketEvent(
    `session state updated ${props.article.id}`,
    handleStateUpdate
  );

  switch (props.type) {
    case ArticleType.PROGRAM:
      return (
        <ProgramListCard
          {...propsWithoutUser}
          article={props.article as AlgoliaProgramArticle}
        />
      );

    case ArticleType.ANYTIME:
      return (
        // @ts-ignore
        <AnytimeListCard
          {...propsWithoutUser}
          article={props.article as AlgoliaAnytimeArticle}
          currentTime={props.currentTime}
        />
      );

    case ArticleType.SESSION:
      return (
        // @ts-ignore
        <SessionListCard
          {...props}
          state={upcomingCardState}
          article={props.article as AlgoliaSessionArticle}
        />
      );

    case ArticleType.INSTRUCTOR:
      return (
        <InstructorListCard
          article={props.article as AlgoliaInstructorArticle}
          isLast={props.isLast}
        />
      );
  }
};
