import { AbstractUser, StripeProduct } from "@kvix/shared";
import { KvixIcon, KvixThemeVariantContext } from "@kvix/ui";
import {
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Switch,
} from "@material-ui/core";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LanguageContext } from "../../../../contexts/language";
import { PurchaseFlowContext } from "../../../../contexts/PurchaseFlow";
import {
  hasRequiredProduct,
  isBasicInstructor,
  KvixUserContext,
} from "../../../../contexts/user";
import { DefaultFlow, NewFlow } from "../../../PurchaseFlow";
import { HeaderContext } from "../../context";
import Cookies from "js-cookie";

interface Props {
  active: boolean;
  anchor: Element;
  user: AbstractUser;
}

const useStyles = makeStyles((theme) => ({
  dropDown: {
    display: "flex",
    justifyContent: "space-between",
  },
  dropDownIcon: {
    justifyContent: "flex-end",
  },
}));

export const ProfileMenu = ({ active, anchor, user }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { actions } = useContext(KvixUserContext);
  const { t, ready } = useTranslation("sidebar");
  const { variant, setVariant } = useContext(KvixThemeVariantContext);
  const language = useContext(LanguageContext);
  const {
    actions: { prompt },
  } = useContext(PurchaseFlowContext);

  const handleDarkMode = (event) =>
    event.target.checked ? setVariant("dark") : setVariant("light");

  if (!ready) {
    return null;
  }

  return (
    <Menu
      open={active}
      anchorEl={anchor}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <HeaderContext.Consumer>
        {({ activeItem, setActiveItem }) => (
          <MenuList>
            <MenuItem
              selected={activeItem === "/me/dashboard"}
              className={classes.dropDown}
              onClick={() => {
                setActiveItem("/me/dashboard");
                history.push("/me");
              }}
            >
              <ListItemText>{t("myPage")}</ListItemText>
              <ListItemIcon className={classes.dropDownIcon}>
                <KvixIcon.AccountBox />
              </ListItemIcon>
            </MenuItem>

            <MenuItem
              selected={activeItem === "/me/favourites"}
              className={classes.dropDown}
              onClick={() => {
                setActiveItem("/me/favourites");
                history.push("/me/favourites");
              }}
            >
              <ListItemText>{t("favourites")}</ListItemText>
              <ListItemIcon className={classes.dropDownIcon}>
                <KvixIcon.Heart.Empty />
              </ListItemIcon>
            </MenuItem>

            {hasRequiredProduct(user, StripeProduct.LIVE) && (
              <MenuItem
                selected={activeItem === "/me/recruitments"}
                className={classes.dropDown}
                onClick={() => {
                  setActiveItem("/me/recruitments");
                  history.push("/me/recruitments");
                }}
              >
                <ListItemText>{t("recruitAFriend")}</ListItemText>
                <ListItemIcon className={classes.dropDownIcon}>
                  <KvixIcon.PersonAdd />
                </ListItemIcon>
              </MenuItem>
            )}

            {hasRequiredProduct(user, StripeProduct.LIVE) ? (
              <MenuItem
                selected={activeItem === "/buy-gift-card"}
                className={classes.dropDown}
                onClick={() => {
                  setActiveItem("/buy-gift-card");
                  history.push("/buy-gift-card");
                }}
              >
                <ListItemText>{t("buyGiftcard")}</ListItemText>
                <ListItemIcon className={classes.dropDownIcon}>
                  <CardGiftcardIcon />
                </ListItemIcon>
              </MenuItem>
            ) : (
              <MenuItem
                selected={false}
                className={classes.dropDown}
                onClick={() => {
                  const wpCampaignParams = Cookies.get('wp-campaign');

                  if (wpCampaignParams) {
                    history.replace(`${history.location.pathname}${wpCampaignParams}`);
                  }

                  prompt(<NewFlow registerProps={{ checkoutSource: wpCampaignParams ? 'wp' : 'kvix' }} currentTab={1} />);
                }}
              >
                <ListItemText>{t("getPremium")}</ListItemText>
                <ListItemIcon className={classes.dropDownIcon}>
                  <ShoppingCartIcon />
                </ListItemIcon>
              </MenuItem>
            )}

            {isBasicInstructor(user) && (
              <MenuItem
                selected={
                  activeItem ===
                  (isBasicInstructor(user)
                    ? "/streamer-admin"
                    : "/registration-completion")
                }
                className={classes.dropDown}
                onClick={() => {
                  setActiveItem(
                    isBasicInstructor(user)
                      ? "/streamer-admin"
                      : "/registration-completion"
                  );
                  history.push("/streamer-admin");
                }}
              >
                <ListItemText>{t("streamerAdmin")}</ListItemText>
                <ListItemIcon className={classes.dropDownIcon}>
                  <KvixIcon.VideoCamera />
                </ListItemIcon>
              </MenuItem>
            )}

            {user && (
              <MenuItem className={classes.dropDown}>
                <ListItemText onClick={() => actions.signOut()}>
                  {t("signOut")}
                </ListItemText>
                <ListItemIcon className={classes.dropDownIcon}>
                  <KvixIcon.ExitToApp />
                </ListItemIcon>
              </MenuItem>
            )}

            {/* <MenuItem
              className={classes.dropDown}
              style={{ paddingRight: 0, marginRight: 0 }}
            >
              <ListItemText>{t("themedark")}</ListItemText>

              <Switch
                className={classes.dropDownIcon}
                style={{ paddingRight: 0, marginRight: 0 }}
                checked={variant === "dark"}
                color="primary"
                onChange={handleDarkMode}
              />
            </MenuItem> */}

            <MenuItem
              className={classes.dropDown}
              onClick={() => {
                const updateLang = async () => {
                  const res = await actions.updateAppSettings(user.id, {
                    language: language.otherLanguage,
                  });
                  language.changeLanguage();
                };
                updateLang();
              }}
            >
              <ListItemText>
                {language.currentLanguage !== "sv-SE"
                  ? t("kvixInSwedish")
                  : t("kvixInEnglish")}
              </ListItemText>
              <ListItemIcon className={classes.dropDownIcon}>
                <KvixIcon.Language />
              </ListItemIcon>
            </MenuItem>
          </MenuList>
        )}
      </HeaderContext.Consumer>
    </Menu>
  );
};
