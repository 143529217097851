const isVideoFile = (value: string) => {
  return (
    value.endsWith(".mp4") ||
    value.endsWith(".m3u8") ||
    value.endsWith(".ts") ||
    value.endsWith(".mkv")
  );
};

export const getAssetsPath = (fileName: string) => {
  if (fileName) {
    if (isVideoFile(fileName)) {
      return `https://${window.config.VIDEO_CDN_ENDPOINT}/assets/${fileName}`;
    }
    return `https://${window.config.CDN_ENDPOINT}/assets/${fileName}`;
  }
};
