import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Location } from "history";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { NewFlow } from "../PurchaseFlow";
import { PurchaseFlowContext } from "src/contexts/PurchaseFlow";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    top: "auto",
    width: "100%",
    position: "fixed",
    zIndex: 999,
  },
  registerText: {
    fontSize: "14px",
  },
  containerWrapper: {
    color: theme.palette.common.white,
    height: "64px",
    paddingBottom: "8px",
    background:
      "linear-gradient(230deg,rgba(55, 0, 166, 1) 0%,rgba(250, 37, 94, 1) 80%)",
  },
}));

interface Props {
  type: "free" | "premium";
  targetLocation: Partial<Location>;
}

const TextContainer = styled(Link)`
  height: 100%;
  width: 100%;
  color: "#FFF";
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterButton: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation(["startPage", "sidebar"]);
  const classes = useStyles(props);
  const { type, targetLocation } = props;
  const { scrollY } = useViewportScroll();
  const history = useHistory();
  const { actions: { prompt } } = useContext(PurchaseFlowContext);

  const y = useTransform(scrollY, [70, 340], [100, 0]);

  if (!ready) {
    return null;
  }

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();

    const wpCampaignParams = Cookies.get('wp-campaign');

    if (wpCampaignParams) {
      history.replace(`${window.location.pathname}${wpCampaignParams}`);
    }

    prompt(<NewFlow registerProps={{ checkoutSource: wpCampaignParams ? 'wp' : 'kvix' }} currentTab={targetLocation.pathname === '/subscribe' ? 1 : 0} />);
  }

  return (
    <>
      {type && targetLocation && (
        <motion.div style={{ translateY: y }} className={classes.root}>
          <div className={classes.containerWrapper}>
            {/* <TextContainer to={targetLocation}> */}
            <TextContainer to= '' onClick={handleClick}>
              <Text variant="caption" className={classes.registerText}>
                {type === "free" ? (
                  <>{t("startPage:hero.common.getKvixFree")}</>
                ) : (
                  <>{t("sidebar:getPremium")}</>
                )}
              </Text>
            </TextContainer>
          </div>
        </motion.div>
      )}
    </>
  );
};
