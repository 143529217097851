import { KvixIcon } from "@kvix/ui";
import { Grow, IconButton, makeStyles, Snackbar } from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import { CmsContext } from "../../cms/context";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    "& .MuiSnackbarContent-action": {
      position: "absolute",
      right: "20px",
      width: "48px",
    },
    "& .MuiSnackbarContent-message": {
      width: "100%",
      marginRight: "30px",
      maxWidth: "485px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    "& .MuiSnackbarContent-root": {
      borderRadius: "5px",
      backgroundColor: "#ff9800",
    },
    transform: "translateX(-50%) !important",
    [theme.breakpoints.down("xs")]: {
      transform: "none !important",
    },
  },
}));

export const ServiceMalfunction = React.memo((props) => {
  const { serviceMalfunction } = useContext(CmsContext);
  const [open, setOpen] = useState(true);
  const classes = useStyles(props);

  return (
    <>
      {serviceMalfunction && serviceMalfunction.visible && (
        <Grow in timeout={{ appear: 1000, enter: 1000, exit: 10000000 }}>
          <Snackbar
            className={classes.snackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            message={serviceMalfunction.statusText}
            action={
              <Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setOpen(false)}
                >
                  <KvixIcon.Close />
                </IconButton>
              </Fragment>
            }
          />
        </Grow>
      )}
    </>
  );
});
