import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import {
  AbstractBaseUser,
  AbstractChannel,
  AbstractLiveSession,
} from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import { IconButton, makeStyles, useTheme } from "@material-ui/core";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "src/contexts/user";
import { ShareButtons } from "./components/ShareButtons";
import ShareModal from "./components/ShareModal";

interface Props {
  onClose?: () => void;
  instructor?: AbstractBaseUser;
  channel?: AbstractChannel;
  replay?: AbstractLiveSession;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 32,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
}));
export const ShareButton: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("instructor");
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles(props);
  const theme = useTheme();
  const { user } = useContext(KvixUserContext);

  const handleOnClick = useCallback(async () => {
    setShowModal(true);
    pushClientEvent({
      featureType: "social.share.clicked",
      source: Source.Web,
      user,
    });
  }, []);

  const handleOnClose = useCallback(async (shareEvent) => {
    setShowModal(false);

    if (props.onClose) {
      props.onClose();
    }

    pushClientEvent({
      featureType: `social.share.${shareEvent}`,
      source: Source.Web,
      user,
    });
  }, []);

  const url = useMemo(() => {
    if (props.replay) {
      return `https://${window.location.host}/replay/${props.replay.id}?channel=${props.replay.channel.channelNumber}`;
    }
    return window.location.href;
  }, [props.replay]);

  const color = (props.style as any)?.color || theme.palette.text.primary; // Sue me

  if (!ready) {
    return null;
  }

  return (
    <>
      <IconButton
        color="secondary"
        onClick={handleOnClick}
        style={{ ...props.style, color }}
        className="share--button-icon"
      >
        <KvixIcon.IosShare />
      </IconButton>

      <ShareModal {...props} open={showModal} onClose={handleOnClose} divider>
        <Text
          variant="body1"
          className={classes.title}
          color="textPrimary"
          gutterBottom
        >
          {t("streamerPage:modal:share")}
        </Text>
        <ShareButtons
          url={url}
          onClick={handleOnClose}
          texts={{
            facebookQuote: t("share.facebookQuote"),
            facebookHashtag: t("share.facebookHashtag"),
            whatsappTitle: t("share.whatsappTitle"),
            emailSubject: t("share.emailSubject"),
            emailBody: t("share.emailBody"),
            smsBody: t("share.smsBody"),
          }}
        />
      </ShareModal>
    </>
  );
};
