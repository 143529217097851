import { AbstractCompetitionRound } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Container, Grid, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Footer } from "../../../cms/models/Footer";
import { CompetitionContext } from "../../../contexts/competition";
import { LanguageContext } from "../../../contexts/language";
import { KvixUserContext } from "../../../contexts/user";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",

    "& > div": {
      backgroundColor:
        theme.palette.type === "dark" ? "#131415" : theme.palette.text.primary,
    },
  },
  title: {
    fontWeight: "bold",
  },
  section: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  social: {
    marginTop: "42px",
  },
  copyright: {
    marginTop: "12px",
  },
  communityButtonRoot: {
    width: "164px",
    padding: "0 12px",
    height: 44,
    backgroundColor: theme.palette.type === "dark" ? "#425784" : "#3A5898",
    borderRadius: 8,
    position: "relative",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  brand: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "1.25px",
    lineHeight: "20px",
    textTransform: "uppercase",
    transform: "translateY(-0.12em)",
    whiteSpace: "nowrap",
  },
  logotype: {
    marginRight: 10,
    "& img": {
      height: 22,
      width: 22,
    },
  },
}));

interface Props {
  footer: Footer;
}
const showChangeLanguage = (index: number, listSize: number): boolean => {
  if (listSize === 1) {
    return index === 0;
  }
  if (listSize === 2) {
    return index === 1;
  }
  if (listSize === 3) {
    return index === 1;
  }

  return index === listSize - 2;
};

const showBarncancerfonden = (
  index: number,
  listSize: number,
  activeCompetitionRound: AbstractCompetitionRound
): boolean => {
  if (!activeCompetitionRound) {
    return false;
  }
  if (listSize === 0) {
    return false;
  }
  if (listSize === 1) {
    return index === 0;
  }
  if (listSize === 2) {
    return index === 1;
  }
  return index === listSize - 2;
};

const LogoCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 7px;
  background: #c5c6c7;
  transition: all 100ms ease-in-out;

  &:hover {
    transition: all 100ms ease-in-out;
    background: #fff;
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

export const DefaultLayout: React.FC<Props> = (props) => {
  const { footer } = props;
  const classes = useStyles(props);
  const history = useHistory();
  const { t, ready } = useTranslation(["footer", "leaderboard"]);
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const language = useContext(LanguageContext);
  const { actions, user } = useContext(KvixUserContext);
  const barncancerfonden = `/assets/vi_stodjer_RGB.png`;

  if (!ready) {
    return null;
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} justify="space-between">
        {footer?.columns.map((column, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={6} md={2} className={classes.section}>
              <Text className={classes.title}>{column.title}</Text>
              {column.links.map((link) => (
                <Text key={link.id}>
                  {link.url.includes("https") ? (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.text}
                    </a>
                  ) : (
                    <Link
                      to={{
                        pathname: link.url,
                        ...(link.url === "/contact" && {
                          state: {
                            from: history.location.pathname,
                            background: link.openAsModal
                              ? history.location
                              : null,
                          },
                        }),
                      }}
                    >
                      {link.text}
                    </Link>
                  )}
                </Text>
              ))}
              {showChangeLanguage(index, footer?.columns.length) && (
                <Text
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const updateLang = async () => {
                      const res = await actions.updateAppSettings(user.id, {
                        language: language.otherLanguage,
                      });
                      language.changeLanguage();
                    };
                    if (user) {
                      updateLang();
                    } else {
                      language.changeLanguage();
                    }
                  }}
                >
                  {t("footer:changeLanguage")}
                </Text>
              )}
            </Grid>
            {showBarncancerfonden(
              index,
              footer?.columns.length,
              activeCompetitionRound
            ) && (
              <Grid
                item
                key={column.id}
                xs={12}
                sm={6}
                md={2}
                className={classes.section}
              >
                <div>
                  <img
                    alt="We support Barncancerfonden"
                    style={{ height: "73px", paddingBottom: "20px" }}
                    src={barncancerfonden}
                  />
                  <Text style={{ fontSize: "13px" }}>
                    {t("leaderboard:barncancerfondenText0")}
                  </Text>
                </div>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>

      <Grid container direction="row" className={classes.social} spacing={2}>
        <Grid item>
          <a
            href="https://www.facebook.com/kvixlive"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoCircle>
              <Logo alt="Facebook" src="/assets/brands/facebook-f-brands.svg" />
            </LogoCircle>
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.instagram.com/kvixlive/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoCircle>
              <Logo
                alt={t("footer:social.instagram")}
                src="/assets/brands/instagram-brands.svg"
              />
            </LogoCircle>
          </a>
        </Grid>
      </Grid>
      <Text className={classes.copyright} variant="subtitle2">
        Kvix AB
        <br />
        Kaserngatan 2<br />
        90347 Umeå
        <br />
        Orgnr: 559198-1146
        <br />
      </Text>
      <Text className={classes.copyright} variant="subtitle2">
        {t("footer:copyright")}
      </Text>
    </Container>
  );
};
