import React, { useContext, useEffect, useState } from "react";
import { ExerciseAdapter } from "../cms/models/adapters/single/Exercise";
import { Exercise } from "../cms/models/Exercise";
import { LanguageContext } from "./language";

export const STRAPI_URL = window.config.STRAPI_URL;

interface ExerciseContextState {
  exercise: Exercise;
}

const useExercise = (): Exercise => {
  const language = useContext(LanguageContext);
  const [exercise, setExercise] = useState<Exercise>();
  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/exercise?populate=deep`);

      if (response.ok) {
        const json = await response.json();
        setExercise(await ExerciseAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return exercise;
};

export const ExerciseContext = React.createContext<ExerciseContextState>(null);

export const ExerciseContextProvider: React.FC = (props) => {
  const exercise = useExercise();
  const value = {
    exercise,
  };

  return (
    <ExerciseContext.Provider value={value}>
      {props.children}
    </ExerciseContext.Provider>
  );
};
