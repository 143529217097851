import { CmsRichText } from "../../CmsRichTexts";
import { getDataMany } from "../adapter";

interface RawRichText {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    identifier: string;
    text: string;
  };
}
export const RichTextsAdapter = async (
  rawJSON: RawRichText[]
): Promise<CmsRichText[]> => {
  const rawRichTexts = await getDataMany<RawRichText[]>(rawJSON);
  const richTexts: CmsRichText[] = rawRichTexts.map(
    ({ id, attributes: { createdAt, updatedAt, ...rest } }) => ({
      id: id,
      created_at: createdAt,
      updated_at: updatedAt,
      ...rest,
    })
  );
  return richTexts;
};
