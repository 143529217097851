import { AbstractBaseUser, CompetitionRoundState } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import { GroupOutlined } from "@material-ui/icons";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompetitionContext } from "../../../contexts/competition";
import { useFetchStreamerRank } from "../../../hooks/http";
import { useIsMobile } from "../../../hooks/isMobile";

interface Props {
  instructor: AbstractBaseUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  wrapper: {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    "&:after": {
      content: "' '",
      position: "absolute",
      height: "100%",
      top: "0",
      left: "0",
      right: "0",
      background:
        "linear-gradient(360deg, rgba(0, 0, 0, 0.2) 25%, rgba(0,0,0,0) 55%)",
    },
  },
  poster: {
    display: "block",
    position: "sticky",
    top: "128px",
    width: "auto",
    height: "82.5vh",
    [theme.breakpoints.down(1104)]: {
      height: "60vh",
    },

    [theme.breakpoints.up("sm")]: {
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    [theme.breakpoints.down("sm")]: {
      display: "block",
      maxHeight: "65vh",
      width: "100%",
      objectFit: "cover",
      objectPosition: "top",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "40vh",
    },
  },

  name: {
    bottom: 114,
  },

  posterText: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    padding: "28px",
    color: theme.palette.common.white,

    "& h2": {
      display: "flex",
      alignItems: "center",
      "& svg": {
        width: "2em",
        height: "2em",
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      "& h2": {
        "& svg": {
          width: "1em !important",
          height: "1em !important",
        },
      },
    },
  },

  hr: {
    backgroundColor: theme.palette.common.white,
    margin: "8px 0",
  },

  statItem: {
    width: "33%",

    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },

    [theme.breakpoints.down("xs")]: {
      width: "47.5%",
    },

    "&.spacing": {
      marginLeft: "10%",

      [theme.breakpoints.down("xs")]: {
        marginLeft: "5%",
      },
    },
  },
  statTitle: {
    fontSize: 44,
    lineHeight: 1,

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  statSubtitle: {
    fontSize: 24,
    lineHeight: 1,

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

export const StreamerPoster: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("streamerPage");
  const isMobile = useIsMobile();
  const { instructor } = props;
  const rank = useFetchStreamerRank(instructor.id);
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const numOfFollowers = instructor.instructorProfile?.followers?.length;

  const displayRank = useMemo(() => {
    return (
      rank?.value &&
      activeCompetitionRound &&
      !activeCompetitionRound.isFinal &&
      [CompetitionRoundState.RUNNING, CompetitionRoundState.FINISHED].includes(
        activeCompetitionRound.state
      )
    );
  }, [activeCompetitionRound, rank]);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.poster}>
        <div
          className={classes.wrapper}
          style={{ backgroundImage: `url("${instructor.imageUrl}")` }}
        />
        <div className={classes.posterText}>
          {isMobile && (
            <Text variant="h2" style={{ marginBottom: 12 }}>
              {instructor.name.custom}
            </Text>
          )}
          <Grid
            container
            style={{ paddingRight: isMobile ? 15 : 0, alignItems: "center" }}
          >
            {displayRank && (
              <Grid item className={classes.statItem}>
                <Text variant="h2" className={classes.statTitle}>
                  {`# ${rank.value}`}
                </Text>
                <Divider className={classes.hr} />
                <Text weight={600} className={classes.statSubtitle}>
                  {t("wellstarRank")}
                </Text>
              </Grid>
            )}

            {numOfFollowers !== undefined && (
              <Grid item className={`${classes.statItem} spacing`}>
                <Text variant="h2" className={classes.statTitle}>
                  {`${numOfFollowers}`}
                  <GroupOutlined
                    style={{
                      margin: isMobile ? "0 0 -2px 4px" : "0 0 -8px 8px",
                    }}
                  />
                </Text>
                <Divider className={classes.hr} />
                <Text weight={600} className={classes.statSubtitle}>
                  {t("followers")}
                </Text>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};
