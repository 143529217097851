import { Text } from "@kvix/ui";
import { Box, Button, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const SWISH_LINK = "swish://paymentrequest";

interface Props {
  paymentRequest: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: 20,
  },
  title: {
    textAlign: "center",
  },
  button: {
    marginBottom: 60,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const AcceptSwish: FC<Props> = (props) => {
  const userAgent = navigator.userAgent;
  const swishAppUrl = `${SWISH_LINK}?token${props.paymentRequest}`;
  const { t, ready } = useTranslation("swish");
  const classes = useStyles();
  const isMobileDevice =
    /webOS|iPhone|Android|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent);

  const handleOpen = () => {
    window.open(swishAppUrl);
  };

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Text className={classes.title}>{t("title")}</Text>
      {isMobileDevice && (
        <Button className={classes.button} onClick={handleOpen}>
          {t("openThisUnit")}
        </Button>
      )}
    </Box>
  );
};
