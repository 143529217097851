import { KvixIcon } from "@kvix/ui";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { SortOption } from "../../../../../contexts/algolia";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingRight: 0,
    fontWeight: 600,

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },

    "& .MuiSelect-icon": {
      display: "none",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: 32,
      zIndex: 1,
    },
  },
  endAdornment: {
    position: "absolute",
    right: 6,
    top: 13,
    pointerEvents: "none",
    zIndex: 1,
  },
}));

interface Props {
  sortBy: SortOption;
  handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortOptions: SortOption[];
}

export const SortSelect: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("sortOptions");

  if (!ready) {
    return null;
  }

  return (
    <Select
      className={classes.root}
      variant="outlined"
      onChange={props.handleSelect}
      value={props.sortBy}
      displayEmpty
      endAdornment={
        <KvixIcon.Chevron.Down
          className={classes.endAdornment}
          color="primary"
        />
      }
    >
      <MenuItem value={""}>{t("default")}</MenuItem>
      {props.sortOptions.map((option, index) => (
        <MenuItem value={option} key={index}>
          {t(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
