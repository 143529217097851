import { AbstractLiveSession } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Divider, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompetitionContext } from "../../../../contexts/competition";
import { ShareButtons } from "../../../partials/SocialShare/components/ShareButtons";
import { ScheduledStreamItem } from "../../../partials/Streamer/ScheduledStreamItem";

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: "20px 0",
  },
  divider: {
    margin: "20px 0",
  },
  text: {
    fontWeight: 500,
    marginBottom: "20px",
  },
}));

type SuccessfullyScheduledStreamProps = {
  stream: AbstractLiveSession;
};
export const SuccessfullyScheduledStream = ({
  stream,
}: SuccessfullyScheduledStreamProps) => {
  const { t, ready } = useTranslation(["wellstarStreams", "instructor"]);
  const classes = useStyles();
  const url = `https://${window.location.host}/replay/${stream.id}?channel=${stream.channel.channelNumber}`;
  const { activeCompetitionRound } = useContext(CompetitionContext);

  if (!ready) {
    return null;
  }

  return (
    <Box>
      <Box className={classes.heading}>
        <Text variant="h4">{t("successSchedule")}</Text>
      </Box>
      <ScheduledStreamItem stream={stream} />
      <Divider className={classes.divider} />
      <Text className={classes.text}>
        {activeCompetitionRound
          ? t("successScheduleTextWellstar")
          : t("successScheduleText")}
      </Text>
      <ShareButtons
        url={url}
        onClick={() => null}
        texts={{
          facebookQuote: t("instructor:share.facebookQuote"),
          facebookHashtag: t("instructor:share.facebookHashtag"),
          whatsappTitle: t("instructor:share.whatsappTitle"),
          emailSubject: t("instructor:share.emailSubject"),
          emailBody: t("instructor:share.emailBody"),
          smsBody: t("instructor:share.smsBody"),
        }}
      />
    </Box>
  );
};
