import { KvixIcon, Text } from "@kvix/ui";
import { IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, { FC, useContext } from "react";
import { ExpoContext } from "../../../../../contexts/expo";
import { CoinImageSize } from "../enums/CoinImageSize";
import { DonateTabState } from "../enums/DonateTabState";
import { CoinImage } from "./CoinImage";

interface Props {
  currentCoins: number;
  prevTabState: DonateTabState[];
  onClick: () => void;
  setTabState: (tabState: DonateTabState) => void;
}

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    width: "100%",
    zIndex: 1,
    display: "flex",
    alignItems: "start",
  },
  balance: {
    padding: "5px",
    borderRadius: "18px",
    alignItems: "center",
    marginLeft: "auto",
    height: "28px",
    width: "auto",
    display: "flex",
    top: 0,
    right: 0,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "& .MuiTypography-body1": {
      marginRight: "auto",
      marginLeft: "auto",
      fontWeight: 600,
      marginBottom: "2px",
      paddingLeft: "8px",
    },
  },
  currentCoins: {
    color: theme.palette.common.white,
  },
  coin: {
    margin: 5,
  },
  pointer: {
    cursor: "pointer",
  },
  iconButton: {
    padding: 0,
    color: theme.palette.text.primary,
    width: "24px !important",
  },
}));

export const ModalHeader: FC<Props> = (props) => {
  const classes = useStyles();
  const { isNativeApp } = useContext(ExpoContext);

  return (
    <div className={classes.modalHeader}>
      <IconButton
        onClick={() => props.onClick()}
        className={classes.iconButton}
      >
        {props.prevTabState.length !== 0 ? (
          <KvixIcon.Arrow.Back />
        ) : (
          <KvixIcon.Close />
        )}
      </IconButton>
      <div
        className={classNames([
          classes.balance,
          !isNativeApp && classes.pointer,
        ])}
        onClick={() => {
          !isNativeApp && props.setTabState(DonateTabState.BUY);
        }}
      >
        <Text className={classes.currentCoins}>{props.currentCoins}</Text>
        <CoinImage size={CoinImageSize.LG} className={classes.coin} />
      </div>
    </div>
  );
};
