import i18next from "i18next";
import { Media } from "../common";

export interface RawMedia {
  data: {
    id: number;
    attributes: Media;
  };
}

export interface RawFaqCategory {
  id: number;
  categoryName: string;
  faqs: {
    data: RawFAQ[];
  };
}
export interface RawFAQ {
  id: number;
  attributes: {
    question: string;
    answer: string;
    createdAt: Date;
    updatedAt: Date;
    sortOrder: number;
  };
}

export interface RawMediaManyObject {
  id: number;
  attributes: Media;
}

export const getData = async <T>(
  rawJSON: T,
  forcedLang?: string
): Promise<T> => {
  const lang = forcedLang ? forcedLang : i18next.language;
  if (lang === "sv-SE") {
    return rawJSON["data"];
  } else {
    return rawJSON["data"].attributes.localizations.data[0];
  }
};
export const getDataMany = async <T>(rawJSON: T): Promise<T> => {
  const lang = JSON.stringify(i18next.language);
  if (lang === '"sv-SE"') {
    return rawJSON["data"];
  } else {
    const rawDataArr = rawJSON["data"]
      .filter((rawData) => rawData.attributes.localizations.data[0])
      .map((data) => data.attributes.localizations.data[0]);
    return rawDataArr;
  }
};
