import { StripeProduct, validateSlug } from "@kvix/shared";
import { Location } from "history";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  hasPremiumAccess,
  isAdmin,
  KvixUserContext,
} from "../../contexts/user";
import { useIsMobile } from "../../hooks/isMobile";
import { useShowPromotions } from "../../hooks/promotions";
import { CampaignContext } from "../pages/Campaign/context";
import { FooterButton } from "./FooterButton";
import { HeaderButton } from "./HeaderButton";

type buttonType = "free" | "premium";

interface Props {
  placement: "header" | "footer";
}
enum Pages {
  Login = "login",
  Signup = "signup",
  Campaign = "c",
  KvixTv = "kvix-tv",
  Anytime = "anytime",
  Home = "",
}

type PageObject = {
  [key in Pages]: PageOptions;
};

interface PageOptions {
  header: boolean;
  footer: boolean;
  loggedOutPrem?: boolean;
  loggedInPrem?: boolean;
  slug?: string;
}

const hidden: PageObject = {
  [Pages.Login]: { header: false, footer: false },
  [Pages.Signup]: { header: false, footer: false },
  [Pages.KvixTv]: { header: false, footer: false },
  [Pages.Campaign]: {
    header: true,
    footer: false,
    loggedOutPrem: true,
  },
  [Pages.Home]: {
    header: true,
    footer: true,
    loggedInPrem: true,
    loggedOutPrem: false,
  },
  [Pages.Anytime]: {
    header: true,
    footer: true,
    loggedInPrem: true,
    loggedOutPrem: true,
  },
};

export const SignupButton: React.FC<Props> = (props) => {
  const { user } = useContext(KvixUserContext);
  const history = useHistory();
  const showPromotion = useShowPromotions();
  const premiumUser = user && hasPremiumAccess(user);
  const hasAdminRole = user && isAdmin(user);
  const isMobile = useIsMobile();
  const [currentPage, setCurrentPage] = useState<PageOptions>();
  const [type, setType] = useState<buttonType>();
  const [targetLocation, setTargetLocation] = useState<Partial<Location>>();
  const campaignContext = useContext(CampaignContext);

  const checkCurrentPage = () => {
    const keys = Object.keys(hidden);
    for (const k of keys) {
      if (validateSlug(history.location.pathname, k, 1)) {
        const selection: PageOptions = hidden[k];
        selection.slug = k;
        setCurrentPage(selection);
        break;
      }
    }
  };

  useEffect(() => {
    checkCurrentPage();

    history.listen(() => {
      checkCurrentPage();
    });
  }, [history, checkCurrentPage]);

  useEffect(() => {
    handleSetType();

    history.listen(() => {
      handleSetType();
    });
  }, [currentPage, history]);

  const handleSetType = () => {
    if (premiumUser || !showPromotion) {
      return setType(null);
    }

    if (currentPage?.loggedOutPrem) {
      handleSetTargetLocation("premium");
      return setType("premium");
    }

    if (user && !premiumUser && currentPage?.loggedInPrem) {
      handleSetTargetLocation("premium");
      return setType("premium");
    }

    if (!user) {
      handleSetTargetLocation("free");
      return setType("free");
    }

    return setType(null);
  };

  const handleSetTargetLocation = (type: buttonType) => {
    const queryParams = queryString.parse(history.location.search);
    const recruitCode = queryParams.code as string;
    const recruitParam = recruitCode ? `&recruit=${recruitCode}` : "";

    if (user && !user.stripeProducts.includes(StripeProduct.LIVE)) {
      return setTargetLocation({
        pathname: "/subscribe",
        state: {
          from: history.location.pathname,
        },
      });
    }

    setTargetLocation({
      pathname: "/signup",
      search: `?membership=${type}${recruitParam}`,
      state: {
        from: history.location.pathname,
        background: history.location,
      },
    });
  };

  return (
    <>
      {/* Display in header */}
      {props.placement === "header" &&
        !hasAdminRole &&
        currentPage?.header &&
        !isMobile && (
          <HeaderButton
            type={type}
            onClick={
              currentPage.slug === "c"
                ? () => campaignContext.openModal(campaignContext)
                : null
            }
            targetLocation={targetLocation}
          />
        )}

      {/* Display in footer */}
      {props.placement === "footer" && currentPage?.footer && isMobile && user && !user.stripeProducts.includes(StripeProduct.LIVE) && (
        <FooterButton type={type} targetLocation={targetLocation} />
      )}
    </>
  );
};
