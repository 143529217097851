import { Button, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    margin: "auto",
    padding: (props: any) => (props.asModal ? "85px" : "15vh 45px 45px 45px"),
    width: "100%",
    overflow: "hidden",
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    maxWidth: "560px",
    position: "relative",
    "& .MuiInputLabel-filled": {
      color: "#ffffff87",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.common.white,
      background: "#333b45",
    },
    [theme.breakpoints.down(350)]: {
      paddingTop: (props: any) => (props.asModal ? 64 : "12vh"),
    },
  },
  fileContainer: {
    margin: "20px auto",
    width: "100%",
    textAlign: "left",
  },
  selectedFiles: {
    margin: "10px 5px",
  },
}));

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<FileList>>;
  files: FileList;
}

export const FilePicker: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("contactForm");
  const uploadRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const fileErrors: string[] = t("filepicker.incorrectFileErrors", {
    returnObjects: true,
  });
  const selectedFiles = useMemo(() => {
    if (props.files) {
      return Array.from(props.files).map((file) => file.name);
    }
  }, [props.files]);
  const [incorrectFiles, setIncorrectFiles] = useState(false);

  const fileValidation = (files: FileList) => {
    const acceptedTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (files.length > 5) {
      return false;
    }
    for (let i = 0; i < files.length; i++) {
      //5MB
      if (files[i].size > 5000000) {
        return false;
      }
      if (!acceptedTypes.includes(files[i].type)) {
        return false;
      }
    }
    return true;
  };

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.fileContainer}>
      <div>
        <input
          accept="image/*"
          multiple
          style={{ display: "none" }}
          ref={uploadRef}
          type="file"
          onChange={(e) => {
            if (fileValidation(e.target.files)) {
              setIncorrectFiles(false);
              props.setFiles(e.target.files);
            } else {
              setIncorrectFiles(true);
            }
          }}
        />
        <Button
          onClick={() => uploadRef.current.click()}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            height: "32px",
            fontSize: "13px",
            textTransform: "none",
            fontWeight: 500,
          }}
        >
          {t("upload")}
        </Button>
      </div>
      <div className={classes.selectedFiles}>
        {incorrectFiles ? (
          <ul style={{ color: "red" }}>
            {fileErrors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        ) : (
          <>
            {selectedFiles?.map((file, index) => (
              <div key={index}>{file}</div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
