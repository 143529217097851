import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export interface MetaData {
  title?: string;
  description?: string;
  ogSiteName?: string;
  ogTitle?: string;
  ogImage?: string;
}

export const Meta: React.FC<{
  meta?: MetaData;
}> = (props) => {
  const { t, ready } = useTranslation(["seo"]);

  const metaDefault: MetaData = t("default", {
    returnObjects: true,
    location: document.location,
  });

  if (!ready) {
    return null;
  }

  return (
    <Helmet>
      <title>{props.meta?.title || metaDefault.title}</title>
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:site_name"
        content={props.meta?.ogSiteName || metaDefault.ogSiteName}
      />
      <meta
        property="og:image"
        content={props.meta?.ogImage || metaDefault.ogImage}
      />
      <meta
        property="og:title"
        content={props.meta?.ogTitle || metaDefault.ogTitle}
      />
      <meta
        name="description"
        content={props.meta?.description || metaDefault.description}
      />
      <meta
        name="og:description"
        content={props.meta?.description || metaDefault.description}
      />
      <meta
        name="twitter:card"
        content={props.meta?.description || metaDefault.description}
      />
    </Helmet>
  );
};
