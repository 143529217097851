import { Hostname } from "@kvix/shared"
import { Container, SocialOauthButtons, Text } from "@kvix/ui"
import { Box, Grid } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles, useTheme } from "@material-ui/core"
import { SignInProps } from "../types"
import { LoginWithEmailButton } from "./Email/emailLoginButton"
import headbandImg from "../../partials/assets/headband.png"

export const SignIn = ({
  origin,
  checkoutSource,
  changeToRegister,
  changeToForgotPassword,
  headerText,
  checkoutParams,
  plan,
}: SignInProps) => {
  const useStyles = makeStyles((theme) => ({
    ContainerPadding: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      // height: "712px",
      height: "100%",
      width: "375px",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: { width: "100%", height: "100%" },
      "@media (max-width:360px)": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    topTextBlock: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginBottom: theme.spacing(4),
      justifyContent: "center",
      alignItems: "center",
    },
    fontWeightMedium: {
      fontWeight: 600,
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    marginTopBottom: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: "20px 0 ",
      padding: "0 20px ",
      height: "44px",
      borderRadius: "30px",
      textTransform: "capitalize",
      backgroundColor: theme.palette.primary.main,
      "&:active": {
        animation: "$scaleButton linear 300ms ",
      },
    },
    lineHeight: {
      lineHeight: "16px",
    },
    bottomBar: {
      backgroundColor:
        theme.palette.type !== "dark" ? "#F8F8F7" : "rgb(32, 32, 32)",
      padding: "30px 48px 13px 48px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignSelf: "center",
      width: "100%",
      height: "72px",
      top: "auto",
      bottom: 0,
      zIndex: 999,
      position: "sticky",
    },
    topWrapper: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "center",
      maxHeight: "720px",
      [theme.breakpoints.down("xs")]: {
        height: checkoutSource ? "calc(100dvh - 110px)" : "calc(100dvh - 48px)",
      },
    },
    image: {
      maxWidth: "100px",
      marginLeft: "40%",
      marginRight: "60%",
    },
    headerText: {
      fontSize: "20px",
      lineHeight: "32px",
      marginBottom: "4px",
    },
  }))

  const classes = useStyles()
  const theme = useTheme()
  const { t, ready } = useTranslation(["logIn", "error"])

  if (!ready) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.ContainerPadding}>
        <Grid container direction="column" style={{ flex: 1 }}>
          <Grid
            container
            style={{ flex: 1 }}
            direction="column"
            alignItems="center"
          >
            <img
              src={headbandImg}
              className={classes.image}
              alt={"hduwahdwa"}
              style={{ margin: "0 auto" }}
            />
            <Grid container className={classes.topTextBlock}>
              <Text
                variant="h5"
                className={classes.headerText}
                style={{ textTransform: "capitalize" }}
              >
                {headerText}
              </Text>
              <Text variant="h5">{t("readyGoals")}</Text>
            </Grid>
            <Grid container style={{ marginBottom: 8 }}>
              <LoginWithEmailButton
                changeToForgotPassword={() => changeToForgotPassword()}
                variant="big"
              />
            </Grid>
            <Grid container>
              <SocialOauthButtons
                text={t("loginWith")}
                type="login"
                origin={origin}
                host={Hostname.auth.register()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid style={{ flex: 1 }}></Grid>
      <Grid container style={{ flex: 1 }}>
        <Grid className={classes.bottomBar}>
          <Text variant="h5">
            {t("notAMember")}
            <br />
            <u
              onClick={() => changeToRegister()}
              style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            >
              {t("clickHere")}
            </u>
          </Text>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
