import { AbstractLiveSession } from "@kvix/shared";
import { Box, Divider } from "@material-ui/core";
import React, { Fragment } from "react";
import styled from "styled-components";
import { PreviousStreamItem } from "./PreviousStreamItem";

export const StreamsPageDivider = styled(Divider)`
  margin: 14px 0;
`;

interface Props {
  previousStreams: AbstractLiveSession[];
  onClick: (stream: AbstractLiveSession) => void;
  showChannel?: boolean;
}

export const PreviousStreamsList = (props: Props) => {
  const { previousStreams, onClick, showChannel } = props;

  return (
    <Box>
      <StreamsPageDivider />
      {previousStreams.map((stream, index) => (
        <Fragment key={index}>
          <PreviousStreamItem
            stream={stream}
            onClick={onClick}
            showChannel={showChannel}
          />
          <StreamsPageDivider />
        </Fragment>
      ))}
    </Box>
  );
};
