import { Link, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnce } from "@react-spring/shared";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpoContext } from "../../../../../contexts/expo";
import { DonateTabState } from "../enums/DonateTabState";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    fontWeight: 600,
  },
  alert: {
    marginTop: "8px",
    marginBottom: "28px",
    fontWeight: 500,
  },
}));

interface Props {
  alertObject: AbstractDonationAlert;
  setTabState: (tabState: DonateTabState) => void;
}

interface BuyMoreProps {
  setTabState: (tabState: DonateTabState) => void;
}

export interface AbstractDonationAlert {
  severity: DonationAlertSeverity;
  message: string;
}

const BuyMore: FC<BuyMoreProps> = (props) => {
  const { setTabState } = props;
  const classes = useStyles();
  const { isNativeApp } = useContext(ExpoContext);
  const { t, ready } = useTranslation("donateLiveView");
  const onClick = () => {
    setTabState(DonateTabState.BUY);
  };

  if (!ready) {
    return null;
  }

  return (
    !isNativeApp && (
      <Link className={classes.link} onClick={onClick}>
        {t("vote.subtitle.part3")}
      </Link>
    )
  );
};

export const DonationAlert: FC<Props> = (props) => {
  const [showBuyMore, setShowBuyMore] = useState(false);
  const { t, ready } = useTranslation("donateLiveView");
  const classes = useStyles();

  useOnce(() => {
    if (props.alertObject.message === t("alert.notEnoughCoins")) {
      setShowBuyMore(true);
    }
  });

  if (!ready) {
    return null;
  }

  return (
    <Alert
      icon={false}
      severity={props.alertObject.severity}
      className={classes.alert}
    >
      {props.alertObject.message}
      <br />
      {showBuyMore && <BuyMore setTabState={props.setTabState} />}
    </Alert>
  );
};
