import {
  AbstractBaseUser,
  CompetitionRoundState,
  IssuedFrom,
  KvixProductType,
} from "@kvix/shared";
import { Button, Text } from "@kvix/ui";
import {
  Avatar,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Reward, { RewardConfig, RewardElement } from "react-rewards";
import { CompetitionContext } from "../../../../../contexts/competition";
import { ExpoContext } from "../../../../../contexts/expo";
import { PaymentContext, PurchaseProduct } from "../../context";
import { DonateTabState } from "../enums/DonateTabState";
import { DonateType } from "../enums/DonateType";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";
import {
  AbstractDonationAlert,
  DonationAlert,
} from "../partials/DonationAlert";
import { Selection } from "../partials/Selection";
import { TabContainer } from "../partials/TabContainer";
import { TotalPriceDisplay } from "../partials/TotalPriceDisplay";
import { SendButton } from "../SendButton";

interface Props {
  tabState: DonateTabState;
  setTabState: (tabState: DonateTabState) => void;
  donationRecipient: AbstractBaseUser;
  currentCoins: number;
  setDonationAlert: (message: string, severity: DonationAlertSeverity) => void;
  donationAlert: AbstractDonationAlert;
  issuedFrom: IssuedFrom;
  sessionId?: number;
  closeAlert: () => void;
}

const useStyles = makeStyles((theme) => ({
  recipientAvatar: {
    width: "72px",
    height: "72px",
  },
  fatText: {
    fontWeight: 600,
  },
  textLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: "pointer",
  },
  nameTitle: {
    marginLeft: "12px",
  },
  textInput: {
    fontWeight: 600,
    marginTop: "1px",
    marginBottom: "20px",
    width: "100%",
    height: "40px",
    borderRadius: "20px",
    borderWidth: 0,
    backgroundColor: "rgb(237, 237, 237)",
    color: "rgb(51, 51, 51)",
    paddingLeft: "12px",
    "&:focus": {
      borderColor: "rgb(0, 153, 255)",
      borderWidth: 1,
      outline: "none",
    },
    "&::placeholder": {
      color: "rgba(51, 51, 51, 0.4)",
    },
  },
  sendButton: {
    height: "40px",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : "rgb(31, 40, 51)",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : "rgb(31, 40, 51)",
    },
    width: "100%",
  },
  voteComplete: {
    backgroundColor: "rgb(109, 212, 0)",
    "&:hover": {
      backgroundColor: "rgb(109, 212, 0)",
    },
  },
  secondaryText: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginBottom: "20px",
  },
  profileContainer: {
    marginBottom: "20px",
  },
  rewardStyles: {
    "& span": {
      position: "fixed !important",
    },
  },
  isSlowWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Spinner = withStyles({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
})(CircularProgress);

export const VoteTab: FC<Props> = (props) => {
  const { isNativeApp } = useContext(ExpoContext);
  const classes = useStyles();
  const [reward, setReward] = useState<RewardElement>();
  const [voteComplete, setVoteComplete] = useState<boolean>(false);
  const [voteSelected, setVoteSelected] = useState<number | null>(null);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSlow, setIsSlow] = useState(false);
  const rejectionTimeout = useRef(null);
  const isSlowTimeout = useRef(null);
  const [hasSent, setHasSent] = useState(false);
  const { t, ready } = useTranslation("donateLiveView");
  const rewardConfig: RewardConfig = {
    angle: 75,
    spread: 100,
    emoji: ["❤️"],
    elementCount: 20,
  };
  const { internalActions } = useContext(PaymentContext);
  const { activeCompetitionRound } = useContext(CompetitionContext);

  const voteOnClick = (v) => {
    setVoteSelected(v);
  };

  useEffect(() => {
    if (props.tabState !== DonateTabState.VOTE) {
      resetTab();
    }
  }, [props.tabState]);

  const resetTab = () => {
    setMessage("");
    setVoteSelected(null);
    setVoteComplete(false);
  };

  const handleClick = async () => {
    setIsLoading(true);
    props.closeAlert();
    if (activeCompetitionRound?.state === CompetitionRoundState.RUNNING) {
      try {
        const res = await new Promise<PurchaseProduct>(
          async (resolve, reject) => {
            rejectionTimeout.current = setTimeout(() => {
              reject("Invoice timed out");
            }, 30000);

            isSlowTimeout.current = setTimeout(() => {
              setIsSlow(true);
            }, 5000);

            const res = await internalActions.purchaseProduct({
              receiverId: props.donationRecipient.id,
              quantity: voteSelected,
              message,
              productId: KvixProductType.VOTE2021,
              issuedFrom: props.issuedFrom,
              sessionId: props.sessionId,
            });

            window.clearTimeout(rejectionTimeout.current);
            window.clearTimeout(isSlowTimeout.current);
            setIsSlow(false);
            resolve(res);
          }
        );

        if (res.success) {
          window.setTimeout(() => {
            setIsLoading(false);
            setVoteComplete(true);
            setHasSent(true);
            setMessage("");
            setVoteSelected(null);
            reward.rewardMe();
          }, 1500);
          window.setTimeout(() => {
            setHasSent(false);
            setVoteComplete(false);
          }, 5000);
        } else {
          if (res.message === "Not enough funds.") {
            props.setDonationAlert(
              t("alert.notEnoughCoins"),
              DonationAlertSeverity.ERROR
            );
          }
          setIsLoading(false);
        }
      } catch (error) {
        props.setDonationAlert(
          t("alert.timedOut"),
          DonationAlertSeverity.ERROR
        );
        window.clearTimeout(rejectionTimeout.current);
        window.clearTimeout(isSlowTimeout.current);
        setIsSlow(false);
        setIsLoading(false);
      }
    }
  };

  const messageOnChange = (e) => {
    e.target.value !== "" ? setMessage(e.target.value) : setMessage(null);
  };

  if (!ready) {
    return null;
  }

  return (
    <TabContainer visibleState={DonateTabState.VOTE} tabState={props.tabState}>
      <Typography variant={"body1"} className={classes.secondaryText}>
        {t("vote.title.part1")} <br /> {t("vote.title.part2")}
      </Typography>
      <Grid container direction={"row"} className={classes.profileContainer}>
        <Grid item className={classes.rewardStyles}>
          <Reward
            type={"emoji"}
            config={rewardConfig}
            ref={(ref) => {
              setReward(ref);
            }}
          >
            <Avatar
              src={props.donationRecipient?.imageUrl}
              className={classes.recipientAvatar}
            />
          </Reward>
        </Grid>
        <Grid item>
          <Typography variant={"h4"} className={classes.nameTitle}>
            {props.donationRecipient?.name?.first} <br />
            {props.donationRecipient?.name?.last}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"body2"} className={classes.secondaryText}>
        {t("vote.subtitle.part1")}
        <span className={classes.fatText}>{props.currentCoins}</span>
        {t("vote.subtitle.part2")}
        {!isNativeApp && (
          <Link
            onClick={() => {
              props.setTabState(DonateTabState.BUY);
            }}
            className={classes.textLink}
          >
            {t("vote.subtitle.part3")}
          </Link>
        )}
      </Typography>
      <TotalPriceDisplay
        price={voteSelected * 50}
        donateType={DonateType.VOTE}
        setTabState={props.setTabState}
        quantitySelected={voteSelected}
      />
      <Grid container direction={"column"}>
        <Grid item>
          <Selection
            setValue={voteOnClick}
            setDonationAlert={props.setDonationAlert}
            currentCoins={props.currentCoins}
            isCompleted={voteComplete}
            setComplete={setVoteComplete}
            disableButtons={hasSent || isLoading}
            donateType={DonateType.VOTE}
          />
        </Grid>
        {props.donationAlert !== null && (
          <Grid item>
            <DonationAlert
              alertObject={props.donationAlert}
              setTabState={props.setTabState}
            />
          </Grid>
        )}
        <Grid item>
          <input
            value={message}
            placeholder={t("vote.writeMessage")}
            maxLength={140}
            onChange={messageOnChange}
            className={classes.textInput}
          ></input>
        </Grid>
        <Grid item>
          <Button
            className={classNames([
              classes.sendButton,
              hasSent && classes.voteComplete,
            ])}
            disabled={!voteSelected || message === null || hasSent || isLoading}
            onClick={handleClick}
          >
            {isLoading ? (
              <Spinner size={24} />
            ) : (
              <SendButton hasSent={hasSent} />
            )}
          </Button>
          {isSlow && (
            <div className={classes.isSlowWrapper}>
              <Text variant="subtitle2">{t("isSlow")}</Text>
            </div>
          )}
        </Grid>
      </Grid>
    </TabContainer>
  );
};
