import { makeStyles } from "@material-ui/core";
import React from "react";
import { Page } from "../../partials/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    width: "100%",
    height: "100%",
  },
}));

export const BuyGiftcard = React.memo((props) => {
  const classes = useStyles(props);
  const GIFTUP_CHECKOUT_URL =
    "https://giftup.app/place-order/d124a28d-b9fd-4a4a-9c51-8ca6775d8076?platform=Hosted";
  return (
    <Page>
      <iframe
        title="Gift card"
        src={GIFTUP_CHECKOUT_URL}
        className={classes.root}
      />
    </Page>
  );
});
