import { AbstractUser, StripeProduct } from "@kvix/shared";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { hasRequiredProduct, KvixUserContext } from "../../../contexts/user";
import { DefaultFlow, NewFlow } from "../../PurchaseFlow";
import { ClickGuardProps, Matchers } from "./types";
import Cookies from "js-cookie";

export const Click = ({
  children,
  disabled = false,
  FlowComponent,
  exception = false,
  requiredProduct = StripeProduct.LIVE
}: ClickGuardProps) => {
  const { user } = useContext(KvixUserContext);
  const history = useHistory();

  const findHref = (path: EventTarget[]): string | undefined => {
    const anchor = path.find((element) => element instanceof HTMLAnchorElement);

    return anchor instanceof HTMLAnchorElement ? anchor.href : undefined;
  };

  const constructMatchers = (nativeEvent: Event): Matchers => {
    return {
      closestHref: findHref(nativeEvent.composedPath()),
    };
  };

  const hasException = (nativeEvent: Event): boolean => {
    return exception instanceof Function
      ? exception(constructMatchers(nativeEvent))
      : exception;
  };

  const hasAccess = (hasException: boolean, user: AbstractUser): boolean => {
    return (
      hasException ||
      disabled ||
      (user && hasRequiredProduct(user, requiredProduct))
    );
  };

  return (
    <PurchaseFlowConsumer>
      {({ actions }) => (
        <span
          onClickCapture={(event) => {
            if (hasAccess(hasException(event.nativeEvent), user)) {
              return event;
            }

            const wpCampaignParams = Cookies.get('wp-campaign');

            if (wpCampaignParams) {
              history.replace(`${history.location.pathname}${wpCampaignParams}`);
            }

            event.preventDefault();
            event.stopPropagation();
            actions.prompt(FlowComponent ? FlowComponent : <NewFlow registerProps={{ checkoutSource: 'wp' }} currentTab={0} />);
          }}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { ...child.props, disabled })
          )}
        </span>
      )}
    </PurchaseFlowConsumer>
  );
};
