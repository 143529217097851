import { RichText, Text } from "@kvix/ui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { FAQCategory } from "../../../../cms/models/components/Common/FAQCategory";

const useStyles = makeStyles((theme) => ({
  categoryName: {
    padding: 16,
  },
  categoryContainer: {
    "& .MuiAccordion-root": {
      "&:last-of-type": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  accordion: {
    backgroundColor: theme.palette.background.default,
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    "& .MuiAccordionSummary-content": {
      marginTop: "8px",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "none",
    },
  },
  accordionIcon: {
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  expandedAccordionIcon: {
    transform: "rotate(135deg)",
  },
}));

const removeOrPush = (
  numbers: number[],
  toCheck: number,
  setter: (arr: number[]) => any
) => {
  const exists = numbers.includes(toCheck);
  if (exists) {
    setter([...numbers.filter((val) => val !== toCheck)]);
  } else {
    setter([...numbers, toCheck]);
  }
};

export const Faq: React.FC<{ faqCategories: FAQCategory[] }> = ({
  faqCategories,
}) => {
  const classes = useStyles();

  return (
    <Box>
      {faqCategories &&
        faqCategories.map(
          ({ categoryName, faqs }, categoryIndex) => (
            <Box key={categoryIndex} className={classes.categoryContainer}>
              <Text
                className={classes.categoryName}
                weight={500}
                color="textPrimary"
              >
                {categoryName}
              </Text>
            </Box>
          )
        )}
    </Box>
  );
};
