import React, { useContext, useEffect, useState } from "react";
import { useHasDefaultPlan } from "../components/pages/Payment/hooks";
import { LanguageContext } from "../contexts/language";
import { KvixUserContext } from "../contexts/user";
import { useShowPromotions } from "../hooks/promotions";
import { Banner, FaqCategoryCollectionItem, Page } from "./models";
import { FaqCategoryCollectionAdapter } from "./models/adapters/collection/FaqCategoryCollection";
import { PageAdapter } from "./models/adapters/collection/Page";
import { RichTextsAdapter } from "./models/adapters/collection/RichTexts";
import { FooterAdapter } from "./models/adapters/single/Footer";
import { HeroCarouselAdapter } from "./models/adapters/single/HeroCarousel";
import { ServiceMalfunctionAdapter } from "./models/adapters/single/ServiceMalfunction";
import { CmsRichText } from "./models/CmsRichTexts";
import { Footer } from "./models/Footer";
import { HeroCarousel } from "./models/HeroCarousel";
import { ServiceMalfunction } from "./models/ServiceMalfunction";

export const STRAPI_URL = window.config.STRAPI_URL;

interface CmsContextState {
  banners: Banner[];
  pages: Page[];
  faqCategoryCollection: FaqCategoryCollectionItem[];
  footer: Footer;
  heroCarousel: HeroCarousel;
  serviceMalfunction: ServiceMalfunction;
  cmsRichTexts: CmsRichText[];
}

const useBanners = (): Banner[] => {
  const { user, loaded } = useContext(KvixUserContext);
  const [banner, setBanner] = useState<Banner[]>();
  const language = useContext(LanguageContext);
  const isUser = loaded && !!user;
  const isCustomer = loaded && user?.stripeProducts;

  const getVisibility = (): Banner["visibility"] => {
    if (!isUser) {
      return "guest";
    }

    if (!isCustomer) {
      return "user_without_subscription";
    }

    return "user_with_subscription";
  };

  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/banners?populate=deep`);
      const banners: Banner[] = [];
      if (response.ok) {
        const res = await response.json();
        res.data.forEach((banner) => {
          banners.push(banner.attributes);
        });
      }

      setBanner(banners);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return banner;
};

const usePages = (): Page[] => {
  const [pages, setPages] = useState<Page[]>();
  const language = useContext(LanguageContext);

  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/pages?populate=deep`);
      if (response.ok) {
        const json = await response.json();
        setPages(await PageAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return pages;
};

const useFooter = (): Footer => {
  const language = useContext(LanguageContext);
  const [footer, setFooter] = useState<Footer>();

  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/footer?populate=deep`);

      if (response.ok) {
        const json = await response.json();
        setFooter(await FooterAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return footer;
};

const useHeroCarousel = (): HeroCarousel => {
  const hasDefaultPlan = useHasDefaultPlan();
  const showPromotions = useShowPromotions();
  const language = useContext(LanguageContext);
  const [heroCarousel, setHeroCarousel] = useState<HeroCarousel>();

  const asyncLoad = async () => {
    try {
      const response = await fetch(
        `${STRAPI_URL}/api/hero-carousel?populate=deep`
      );

      if (response.ok) {
        const res = await response.json();
        let heroCarousel: HeroCarousel = await HeroCarouselAdapter(res);

        if (hasDefaultPlan || !showPromotions) {
          const hero_carousel_slides = heroCarousel?.hero_carousel_slides?.filter((slide) => !slide.promotion);
          heroCarousel = { ...heroCarousel, hero_carousel_slides };
        }
        setHeroCarousel(heroCarousel);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return heroCarousel;
};

const useServiceMalfunction = (): ServiceMalfunction => {
  const language = useContext(LanguageContext);
  const [serviceMalfunction, setServiceMalfunction] =
    useState<ServiceMalfunction>();
  const asyncLoad = async () => {
    try {
      const response = await fetch(
        `${STRAPI_URL}/api/service-malfunction?populate=deep`
      );
      if (response.ok) {
        const json = await response.json();
        setServiceMalfunction(await ServiceMalfunctionAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return serviceMalfunction;
};

const useCmsRichTexts = (): CmsRichText[] => {
  const [richTexts, setRichTexts] = useState<CmsRichText[]>();
  const language = useContext(LanguageContext);
  const asyncLoad = async () => {
    try {
      const response = await fetch(
        `${STRAPI_URL}/api/rich-texts?populate=deep`
      );

      if (response.ok) {
        const json = await response.json();
        setRichTexts(await RichTextsAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return richTexts;
};

const useFaqCategories = (): FaqCategoryCollectionItem[] => {
  const language = useContext(LanguageContext);
  const [faqCategoryColl, setFaqCategoryColl] =
    useState<FaqCategoryCollectionItem[]>();
  const asyncLoad = async () => {
    try {
      const response = await fetch(
        `${STRAPI_URL}/api/faq-categories?populate=deep`
      );

      if (response.ok) {
        const json = await response.json();
        setFaqCategoryColl(await FaqCategoryCollectionAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return faqCategoryColl;
};

export const CmsContext = React.createContext<CmsContextState>(null);

export const CmsContextProvider: React.FC = (props) => {
  const pages = usePages();
  const banners = useBanners();
  const footer = useFooter();
  const heroCarousel = useHeroCarousel();
  const serviceMalfunction = useServiceMalfunction();
  const cmsRichTexts = useCmsRichTexts();
  const faqCategoryCollection = useFaqCategories();

  const value = {
    pages,
    banners,
    faqCategoryCollection,
    footer,
    heroCarousel,
    serviceMalfunction,
    cmsRichTexts,
  };

  return (
    <CmsContext.Provider value={value}>{props.children}</CmsContext.Provider>
  );
};
