import {
  AbstractLiveSession,
  Duration as StreamDuration,
  HttpMethods,
} from "@kvix/shared";
import { Button, Text } from "@kvix/ui";
import {
  Box,
  CircularProgress,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import classnames from "classnames";
import { format } from "date-fns";
import { sv } from "date-fns/locale";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StreamerAdminContext } from "../context";
import { RestrictedDatePicker } from "../RestrictedDatePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "40px",
  },
  section: {
    paddingBottom: "20px",
    width: "100%",
  },
  availableTimeSlot: {
    display: "flex",
    height: "40px",
    borderRadius: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.paper,
    padding: "0 10px",
    margin: "5px 0",
    fontWeight: 600,
  },
  availableTimeSlotTime: {
    color: theme.palette.primary.main,
  },
  availableTimeSlotDate: {
    textTransform: "capitalize",
  },
  addStreamFooterRoot: {
    background: "#202020",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
    position: "sticky",
    bottom: "0",
    margin: "0 -45px",
    [theme.breakpoints.down("xs")]: {
      margin: "0 -15px",
    },
    zIndex: 5,

    "& button": {
      marginBottom: 16,
    },
  },
  selected: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "& $selectedPrimary": {
      color: theme.palette.primary.main,
    },
  },
  addStreamButton: {
    "&.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.12)",
    },
  },
  setTitle: {
    margin: "20px 0 40px",
    width: "100%",
  },
  selectedPrimary: {},
  unselected: {
    background: theme.palette.background.paper,
  },
  selectable: {
    transition: "all 0.2s ease-out",
    cursor: "pointer",
  },
  selectStreamDuration: {
    height: "65px",
    width: "65px",
    borderRadius: "10px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  preferredTimeItem: {
    height: "40px",
    width: "65px",
    borderRadius: "10px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionHeader: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#575E66",
    marginBottom: "10px",
  },
  streamRestrictions: {
    fontWeight: 500,
    color: "#575E66",
    fontSize: "16px",
  },
  errorText: {
    fontWeight: 600,
    color: "red",
    margin: "0px 16px 16px 16px",
    whiteSpace: "pre-wrap",
  },
}));

interface TimeSlot {
  start: Date;
  end: Date;
}

enum TimeOfDay {
  TwentyFourToSix = "00-06",
  SixToTwelve = "06-12",
  TwelveToEighteen = "12-18",
  EighteenToTwentyFour = "18-24",
  ALL = "all",
}

type StreamSelectDurationItemProps = {
  minutes: string;
  selected: boolean;
  onClick: () => void;
};
const StreamSelectDurationItem = ({
  minutes,
  selected,
  onClick,
}: StreamSelectDurationItemProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classnames(
        classes.selectStreamDuration,
        classes.selectable,
        selected ? classes.selected : classes.unselected
      )}
      onClick={onClick}
    >
      <div className={classes.selectedPrimary}>{minutes}</div>
      <div>min</div>
    </Box>
  );
};

const getStreamDuration = (stream: AbstractLiveSession): StreamDuration => {
  if (!stream) {
    return StreamDuration.FIFTEEN;
  }
  const diffMs = stream.end.getTime() - stream.start.getTime();
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  return Object.values(StreamDuration).includes(diffMins)
    ? diffMins
    : StreamDuration.FIFTEEN;
};

type StreamDurationSelectProps = {
  selected: StreamDuration;
  setSelectedDuration: (selected: StreamDuration) => void;
};
const StreamDurationSelect = ({
  selected,
  setSelectedDuration,
}: StreamDurationSelectProps) => {
  const streamDurations = Object.keys(StreamDuration)
    .filter((key) => !isNaN(Number(StreamDuration[key])))
    .map((key) => StreamDuration[key]);

  return (
    <Box display="flex" justifyContent="space-between">
      {streamDurations.map((duration) => {
        return (
          <StreamSelectDurationItem
            key={duration}
            minutes={duration}
            selected={selected === duration}
            onClick={() => setSelectedDuration(duration)}
          />
        );
      })}
    </Box>
  );
};

type PreferedTimeItemProps = {
  text: string;
  selected: boolean;
  onClick: () => void;
};
const PreferredTimeItem = ({
  text,
  selected,
  onClick,
}: PreferedTimeItemProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classnames(
        classes.preferredTimeItem,
        classes.selectable,
        selected ? classes.selected : classes.unselected
      )}
      onClick={onClick}
    >
      <div className={classes.selectedPrimary}>{text}</div>
    </Box>
  );
};

type PreferedTimeListProps = {
  selected: TimeOfDay;
  onSelect: (selected: TimeOfDay) => void;
};
const PreferedTimeList = ({ selected, onSelect }: PreferedTimeListProps) => {
  const timeSlots = Object.values(TimeOfDay);
  const { t, ready } = useTranslation("wellstarStreams");

  if (!ready) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-between">
      {timeSlots.map((slot, index) => {
        return (
          <PreferredTimeItem
            key={index}
            text={slot === TimeOfDay.ALL ? t(slot) : slot}
            selected={selected === slot}
            onClick={() => onSelect(slot)}
          />
        );
      })}
    </Box>
  );
};

type TimeSlotProps = {
  start: Date;
  end: Date;
  selected: boolean;
  onSelect: () => void;
};
const AvailableTimeSlot = ({
  start,
  end,
  selected,
  onSelect,
}: TimeSlotProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classnames(
        classes.availableTimeSlot,
        classes.selectable,
        selected ? classes.selected : classes.unselected
      )}
      onClick={onSelect}
    >
      <div className={classes.availableTimeSlotTime}>{`${format(
        start,
        "HH:mm"
      )} - ${format(end, "HH:mm")}`}</div>
      <div className={classes.availableTimeSlotDate}>
        {format(start, "cccc d MMM", { locale: sv }).replace(".", "")}
      </div>
    </Box>
  );
};

type AddStreamFooterProps = {
  disabled: boolean;
  onClick: () => void;
  isLoading: boolean;
  update?: boolean;
  scheduleStreamError?: boolean;
};
const AddStreamFooter = ({
  disabled,
  onClick,
  isLoading,
  update = false,
  scheduleStreamError,
}: AddStreamFooterProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");
  const buttonLabel = update ? t("updateStream") : t("scheduleStream");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.addStreamFooterRoot}>
      {scheduleStreamError && (
        <Text className={classes.errorText}>{t("errorSchedulingStream")}</Text>
      )}
      <Button
        className={classes.addStreamButton}
        color={disabled ? "white" : "primary"}
        disabled={disabled}
        onClick={onClick}
        variant="contained"
      >
        <>
          {buttonLabel}
          {isLoading && (
            <CircularProgress size={20} style={{ marginLeft: "16px" }} />
          )}
        </>
      </Button>
    </Box>
  );
};

const getAvailableTimeSlots = async (
  duration: number,
  date: string,
  timeOfDay: string,
  onDone: (result: TimeSlot[]) => void
) => {
  const timezone =
    Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "Europe/Stockholm";
  const query = queryString.stringify({
    date,
    duration,
    timeOfDay,
    fromChannels: true,
    timezone,
  });
  const response = await fetch(
    `/api/streamer-admin/session-manager/time-slots?${query}`
  );
  const result = response.ok ? await response.json() : [];
  const parsed = result.map((session) => {
    return { start: new Date(session.start), end: new Date(session.end) };
  });
  onDone(parsed);
};

const submitScheduleSession = async (
  title: string,
  start: Date,
  duration: StreamDuration,
  handleResponse: (stream: AbstractLiveSession) => void,
  handleError: () => void
) => {
  const scheduledStream = {
    title,
    start,
    duration,
  };

  const response = await fetch(
    `/api/streamer-admin/session-manager/channel/sessions`,
    {
      method: HttpMethods.POST,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(scheduledStream),
    }
  );
  if (response.ok) {
    const result = await response.json();
    handleResponse({
      ...result,
      start: new Date(result.start),
      end: new Date(result.end),
    });
  } else {
    const error = await response.json();
    console.error("Could not schedule session", error.message);
    handleError();
  }
};

const updateScheduleSession = async (
  sessionId: number,
  title: string,
  start: Date,
  duration: StreamDuration,
  handleResponse: (stream: AbstractLiveSession) => void,
  handleError: () => void
) => {
  const scheduledStream = {
    title,
    start,
    duration,
  };

  const response = await fetch(
    `/api/streamer-admin/session-manager/channel/sessions/${sessionId}`,
    {
      method: HttpMethods.PUT,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(scheduledStream),
    }
  );
  if (response.ok) {
    const result = await response.json();
    handleResponse({
      ...result,
      start: new Date(result.start),
      end: new Date(result.end),
    });
  } else {
    const error = await response.json();
    console.error("Could not update session", error.message);
    handleError();
  }
};

const getInitialDatePickerIndex = (stream: AbstractLiveSession) => {
  if (!stream) {
    return 0;
  }
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(
    Math.abs((stream.start.getTime() - new Date().getTime()) / oneDay)
  );
};

type ScheduleStreamProps = {
  onScheduledStream: (scheduledStream: AbstractLiveSession) => void;
  streamToEdit?: AbstractLiveSession;
};
export const ScheduleStream = ({
  onScheduledStream,
  streamToEdit,
}: ScheduleStreamProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");
  const { updateStreamsLists } = useContext(StreamerAdminContext);
  const initialDatepickerIndex = getInitialDatePickerIndex(streamToEdit);

  const [isLoading, setIsLoading] = useState(false);

  const [currentTimeSlot, setCurrentTimeSlot] = useState<TimeSlot>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlot>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<TimeSlot[]>([]);
  useEffect(() => {
    const currentTimeSlot: TimeSlot = streamToEdit
      ? { start: streamToEdit.start, end: streamToEdit.end }
      : null;
    setCurrentTimeSlot(currentTimeSlot);
    setSelectedTimeSlot(currentTimeSlot);
  }, [streamToEdit]);
  const [noTitleError, setNoTitleError] = useState(false);
  const [streamTitle, setStreamTitle] = useState(
    streamToEdit ? streamToEdit.title : ""
  );
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStreamTitle(e.target.value);
    setNoTitleError(!e.target.value);
  };

  const [selectedDuration, setSelectedDuration] = useState(
    getStreamDuration(streamToEdit)
  );

  const [selectedDate, setSelectedDate] = useState(
    streamToEdit
      ? format(streamToEdit.start, "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd")
  );
  const onSelectDate = (date: Date) =>
    setSelectedDate(format(date, "yyyy-MM-dd"));
  const [selectedTime, setSelectedTime] = useState(TimeOfDay.ALL);

  const [scheduleStreamError, setScheduleStreamError] = useState(false);

  useEffect(() => {
    getAvailableTimeSlots(
      selectedDuration,
      selectedDate,
      selectedTime,
      setAvailableTimeSlots
    );
    setSelectedTimeSlot(currentTimeSlot);
  }, [selectedDuration, selectedDate, selectedTime, currentTimeSlot]);

  const addOrUpdate = () => {
    setScheduleStreamError(false);
    setIsLoading(true);

    const handleResponse = (stream: AbstractLiveSession) => {
      onScheduledStream(stream);
      updateStreamsLists();
      setIsLoading(false);
    };
    const handleError = () => {
      setScheduleStreamError(true);
      setIsLoading(false);
    };
    if (streamToEdit) {
      updateScheduleSession(
        streamToEdit.id,
        streamTitle,
        selectedTimeSlot.start,
        selectedDuration,
        handleResponse,
        handleError
      );
    } else {
      submitScheduleSession(
        streamTitle,
        selectedTimeSlot.start,
        selectedDuration,
        handleResponse,
        handleError
      );
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      <Box component="form" className={classes.root}>
        <Text className={classes.section} variant="h4">
          {streamToEdit ? t("updateStreamHeader") : t("scheduleStreamHeader")}
        </Text>
        <Text
          className={classnames(classes.section, classes.streamRestrictions)}
        >
          {t("scheduleStreamTimeRestriction")}
        </Text>
        <FormControl variant="filled" className={classes.setTitle}>
          <TextField
            variant="filled"
            error={noTitleError}
            label={t("streamTitle")}
            value={streamTitle}
            helperText={noTitleError ? t("titleHelper") : null}
            onChange={onTitleChange}
            inputProps={{
              style: { fontWeight: 500 },
            }}
          />
        </FormControl>
        <Box className={classes.section}>
          <Text className={classes.sectionHeader}>
            {t("howLongWillYouStream")}
          </Text>
          <StreamDurationSelect
            selected={selectedDuration}
            setSelectedDuration={setSelectedDuration}
          />
        </Box>
        <Box className={classes.section}>
          <Text className={classes.sectionHeader}>{t("yourStreamDate")}</Text>
          <RestrictedDatePicker
            onSelect={onSelectDate}
            initial={initialDatepickerIndex}
          />
        </Box>
        <Box className={classes.section}>
          <Text className={classes.sectionHeader}>{t("preferredTime")}</Text>
          <PreferedTimeList
            selected={selectedTime}
            onSelect={setSelectedTime}
          />
        </Box>
        <FormControl className={classes.section}>
          {currentTimeSlot && (
            <Box className={classes.section}>
              <Text className={classes.sectionHeader}>
                {t("currentBooked")}
              </Text>
              <AvailableTimeSlot
                start={currentTimeSlot.start}
                end={currentTimeSlot.end}
                selected={selectedTimeSlot === currentTimeSlot}
                onSelect={() => setSelectedTimeSlot(currentTimeSlot)}
              />
            </Box>
          )}
          <Text className={classes.sectionHeader}>
            {availableTimeSlots.length
              ? `${availableTimeSlots.length} ${t("availableTimeSlots")}`
              : t("noAvailableTimeSlots")}
          </Text>
          {availableTimeSlots.map((session, index) => (
            <AvailableTimeSlot
              key={index}
              start={session.start}
              end={session.end}
              selected={selectedTimeSlot === session}
              onSelect={() => setSelectedTimeSlot(session)}
            />
          ))}
        </FormControl>
      </Box>
      <AddStreamFooter
        disabled={!streamTitle || selectedTimeSlot === null}
        onClick={addOrUpdate}
        isLoading={isLoading}
        update={!!streamToEdit}
        scheduleStreamError={scheduleStreamError}
      />
    </>
  );
};
