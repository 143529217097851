import { ArticleType, mapToCard } from "@kvix/shared";
import { CardList } from "@kvix/ui";
import { Box, Container, makeStyles } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import {
  useBannerComments,
  useFetchAllInstructors,
} from "../../../../hooks/http";
import { Footer } from "../../../Footer";
import { Banner } from "../../../partials/Banner/Banner";
import { BlogContainer } from "../../../partials/BlogContainer";
import { Content } from "../../../partials/Content";
import { Card } from "../../../partials/Content/Card";
import { Hero } from "../../../partials/Hero";
import { Page } from "../../../partials/Page";

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    // backgroundColor: "DarkCyan",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
}));

const AnytimeFeed: React.FC = (props) => {
  const classes = useStyles(props);
  const NO_OF_CARDS = 4;
  const { user } = useContext(KvixUserContext);
  const { t, ready } = useTranslation(["startPage", "categoryPicker"]);
  const [instructors] = useFetchAllInstructors(NO_OF_CARDS);
  const comments = useBannerComments();

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <Content type={ArticleType.ANYTIME} />

      <Container
        className={classes.ContainerPadding}
        maxWidth="xl"
        style={{ position: "relative" }}
      >
        {!user && <Banner type="quotes" comments={comments} />}
        <CardList
          title={t("feeds.instructors.title")}
          description={t("feeds.instructors.description")}
        >
          {instructors?.value?.map((instructor) => (
            <Card
              type={ArticleType.INSTRUCTOR}
              options={mapToCard({ instructor })}
              key={instructor.id}
            />
          ))}
        </CardList>
        <BlogContainer
          title={t("feeds.blog.title")}
          description={t("feeds.blog.description")}
          explore={{ text: t("feeds.blog.explore"), url: "/blog" }}
        />
      </Container>

      <Banner type="upgrade-premium" size="large" />
    </Fragment>
  );
};

export const Anytime = React.memo(() => {
  return (
    <Page>
      <Hero.Anytime />
      <AnytimeFeed />
      <Footer />
    </Page>
  );
});
