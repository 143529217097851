import { AbstractLiveSession, SessionState } from "@kvix/shared";
import { Box, Button, Divider, Theme, useTheme } from "@material-ui/core";
import { differenceInMinutes } from "date-fns";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ScheduledStreamItem } from "../../../partials/Streamer/ScheduledStreamItem";
import { ScheduledStreamPopupButton } from "./ScheduledStreamPopupButton";

interface Props {
  scheduledStreams: AbstractLiveSession[];
  onClick?: (stream: AbstractLiveSession) => void;
}

export const StreamsPageDivider = styled(Divider)`
  margin: 14px 0;
  background-color: #e8e8e9;
`;

const GoToStreamButton = styled(Button)<{ theme: Theme }>`
  height: 34px;
  width: 100%;
  text-transform: none;
  font-weight: 600;
  background: ${(props) =>
    props.theme.palette.getContrastText(
      props.theme.palette.background.default
    )};
  color: ${(props) => props.theme.palette.background.default};

  &:hover {
    background: ${(props) =>
      props.theme.palette.getContrastText(
        props.theme.palette.background.default
      )};
  }
`;

export const ScheduledStreamList = (props: Props) => {
  const { t, ready } = useTranslation("wellstarStreams");
  const { scheduledStreams, onClick } = props;
  const { push } = useHistory();
  const theme = useTheme();

  if (!ready) {
    return null;
  }

  return (
    <Box>
      <StreamsPageDivider />
      {scheduledStreams.map((stream, index) => (
        <Fragment key={index}>
          <ScheduledStreamItem
            stream={stream}
            finished={stream.state === SessionState.Finished}
            button={
              differenceInMinutes(stream.start, new Date()) <= 10 ? (
                <GoToStreamButton
                  variant="contained"
                  theme={theme}
                  onClick={() => push(`session/${stream.id}/broadcast`)}
                >
                  {t("gotoStreamRoom")}
                </GoToStreamButton>
              ) : null
            }
            endAdornment={
              <ScheduledStreamPopupButton
                stream={stream}
                onClickEdit={() => onClick(stream)}
                onClickNavigate={() => push(`session/${stream.id}/broadcast`)}
                disabled={[
                  SessionState.Cancelled,
                  SessionState.Finished,
                ].includes(stream.state)}
              />
            }
          />
          <StreamsPageDivider />
        </Fragment>
      ))}
    </Box>
  );
};
