import { makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  sentTextWrapper: {
    width: "100%",
    height: "100%",
    marginBottom: 14,
  },
  sentText: {
    position: "absolute",
    left: 0,
    right: 0,
  },
}));

interface Props {
  hasSent: boolean;
}

export const SendButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { hasSent } = props;
  const { t, ready } = useTranslation("donateButtons");

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.sentTextWrapper}>
      <motion.div
        className={classes.sentText}
        initial={{
          opacity: hasSent ? 0 : 1,
          y: hasSent ? "100%" : 0,
        }}
        animate={{
          opacity: hasSent ? 0 : 1,
          y: hasSent ? "100%" : 0,
        }}
        transition={{ duration: 0.5, delay: hasSent ? 0 : 0.4 }}
      >
        {t("send")}
      </motion.div>
      <motion.div
        className={classes.sentText}
        initial={{
          opacity: hasSent ? 1 : 0,
          y: hasSent ? 0 : "100%",
        }}
        animate={{
          opacity: hasSent ? 1 : 0,
          y: hasSent ? 0 : "100%",
        }}
        transition={{ duration: 0.5, delay: hasSent ? 0.4 : 0 }}
      >
        {t("sent")}
      </motion.div>
    </div>
  );
};
