import { AbstractSession, getSessionLink, SessionState } from "@kvix/shared";
import { KvixIcon, Text, TimeRangeIndicator } from "@kvix/ui";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { KvixUserContext } from "../../contexts/user";
import { useIsSessionFinished, useIsSessionRunning } from "../../hooks/session";

interface Props {
  variant?: "default" | "light";
  session: AbstractSession;
  displayfavourite?: boolean;
  disableLazyload?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    position: "relative",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  image: {
    backgroundImage: (props) => `url(${props.session?.program?.imageUrl})`,
  },
  imagePlaceholder: {
    backgroundColor: "#bbb",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: "100%",
    borderRadius: 4,
    width: 70,
    position: "relative",
  },

  title: {
    marginTop: 4,
    lineHeight: 1.3,
    wordBreak: "break-word",
  },
  host: {
    fontSize: 14,
  },
  instructorWarning: {
    display: "flex",
    alignItems: "center",
  },
  instructorWarningText: {
    fontSize: 13,
    fontWeight: (props) => (props.variant === "light" ? 700 : 600),
  },
  instructorWarningIcon: {
    display: "inline-block",
    position: "relative",
    marginRight: 5,
    width: 24,
    height: 24,

    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      borderRadius: "50%",
      transform: "scale(0.75)",
    },

    "& svg": {
      position: "absolute",
      transform: "translateY(-1px)",
    },
  },
  program: {
    marginTop: 8,
    marginRight: 28,
    width: "100%",
  },
  settingsContainer: {
    position: "absolute",
    right: 0,
    top: 30,
  },
  playButton: {
    "&.MuiFab-root": {
      position: "absolute",
      color: theme.palette.common.white,
      height: 30,
      width: 30,
      minHeight: 30,
      margin: "2px 0",
      boxShadow: "none",
      top: 20,
      left: 20,
    },
    "&.MuiFab-root svg": {
      height: "0.9em",
      width: "0.9em",
    },
  },
  heart: {
    color: `${theme.palette.primary.main}`,
    animation: "$bounce",
    animationDuration: "3s",
  },
  "@keyframes bounce": {
    "0%": {
      transform: "scale(1)",
    },
    "5%": {
      transform: "scale(1.200)",
    },
    "10%": {
      transform: "scale(1)",
    },
  },
}));

export const ScheduledSessionCard: React.FC<Props> = (props) => {
  const isLive = useIsSessionRunning(props.session);
  const classes = useStyles(props);
  const context = useContext(KvixUserContext);
  const isFinished = useIsSessionFinished(props.session);
  const { t, ready } = useTranslation(["program"]);

  if (!props.session.program) {
    throw new Error(`Missing property "program" on props.session`);
  }

  const isCancelled = props.session.state === SessionState.Cancelled;

  const variant = props.variant || "default";

  const link = getSessionLink(props.session, context.user);

  const [isLiked, setIsLiked] = useState<boolean>();

  useEffect(() => {
    setIsLiked(
      context.favouritedProgramIds.indexOf(props.session.program.id) >= 0
    );
  }, [context.favouritedProgramIds, props.session.program.id]);

  if (!ready) {
    return null;
  }

  return (
    <Link to={link}>
      <Grid className={classes.root} container wrap="wrap">
        <Grid className={classes.program} item>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            <Grid item>
              {props.disableLazyload ? (
                <div className={`${classes.imagePlaceholder} ${classes.image}`}>
                  {isFinished && props.session && (
                    <Fab className={classes.playButton} color="primary">
                      <KvixIcon.Play />
                    </Fab>
                  )}
                </div>
              ) : (
                <LazyLoad
                  height={70}
                  placeholder={<div className={classes.imagePlaceholder} />}
                >
                  <div
                    className={`${classes.imagePlaceholder} ${classes.image}`}
                  >
                    {isFinished && props.session && (
                      <Fab className={classes.playButton} color="primary">
                        <KvixIcon.Play />
                      </Fab>
                    )}
                  </div>
                </LazyLoad>
              )}
            </Grid>

            <Grid item>
              <TimeRangeIndicator
                start={props.session.start}
                end={props.session.end}
                isFinished={isFinished}
                isCancelled={isCancelled}
                isLive={isLive}
              />

              <Text
                className={classes.title}
                variant="h5"
                color={variant === "light" ? "inherit" : "primary"}
              >
                {props.session.program.title}
              </Text>

              <Text className={classes.host}>
                {props.session.program.instructor?.name &&
                  t("program:details.withInstructor", {
                    firstName: props.session.program.instructor?.name?.first,
                    lastName: props.session.program.instructor?.name?.last,
                  })}
              </Text>
            </Grid>
          </Grid>
        </Grid>

        <Box ml={1} className={classes.settingsContainer}>
          {context.user && props.displayfavourite && (
            <IconButton
              size="small"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();
                context.actions.toggleFavourite({
                  programId: props.session.program.id,
                });
              }}
            >
              {isLiked ? (
                <KvixIcon.Heart.Filled className={classes.heart} />
              ) : (
                <KvixIcon.Heart.Empty className={classes.heart} />
              )}
            </IconButton>
          )}
        </Box>
      </Grid>
    </Link>
  );
};
