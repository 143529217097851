import { wait } from "@kvix/shared";
import { Button, CodeInput, Text } from "@kvix/ui";
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  Fade,
  Grow,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import classNames from "classnames";
import { TFunction } from "i18next";
import iso3311a2 from "iso-3166-1-alpha-2";
import queryString from "query-string";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useAsyncFn, useThrottleFn } from "react-use";
import InternalStripe from "stripe";
import styled from "styled-components";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { Page } from "../../../partials/Page";
import { PaymentContext, PaymentContextProvider } from "../context";
import {
  SubscribeContext,
  SubscribeContextProvider,
} from "../Plan/Subscribe/context";

const useStyles = makeStyles((theme) => ({
  addressMargin: {
    margin: "6px 0",
  },
  dropdownHeight: {
    height: "56px",
    "& .MuiInputBase-input": {
      height: "27px",
    },
  },
}));

const Actions = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-areas: "button";

  > * {
    grid-area: button;
  }
`;

const getCouponAmount = (coupon: InternalStripe.Coupon) => {
  if (coupon.percent_off) {
    return `${coupon.percent_off}%`;
  }

  if (coupon.amount_off) {
    return formatCurrency(coupon.amount_off / 100, coupon.currency);
  }
};

const getCouponDescription = (coupon: InternalStripe.Coupon, t: TFunction) => {
  const amount = getCouponAmount(coupon);

  if (["repeating", "once"].includes(coupon.duration)) {
    return t(`giftcard:couponDescriptions.${coupon.duration}`, {
      amount: amount,
      months: coupon.duration_in_months,
    });
  }

  if (coupon.duration === "forever") {
    return t("giftcard:couponDescriptions.forever", { amount: amount });
  }
};

const INITIAL_CHARS = ["", "", "", "", "", ""];

export const RedeemContainer: FC = (props) => {
  const classes = useStyles();
  const context = useContext(SubscribeContext);
  const { availablePlans, actions, customer } = useContext(PaymentContext);
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const queryParamsCode = queryParams?.code?.length === 6 && queryParams.code;

  const [line1, setLine1] = useState<string>("");
  const [line2, setLine2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [stateAddress, setStateAddress] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const [showCodeInput, setShowCodeInput] = useState(true);
  const [chars, setChars] = useState(
    queryParamsCode ? (queryParamsCode as string)?.split("") : INITIAL_CHARS
  );
  const code = useThrottleFn((chars) => chars.join(""), 500, [chars]);
  const { t, ready } = useTranslation(["giftcard", "general", "signup"]);

  const [state, submit] = useAsyncFn(
    async (code: string) => {
      if (code === context.coupon?.id) {
        await wait(300);
        return context.promoCode;
      }
      const promoCode = await actions.fetchPromoCode(code);
      return promoCode;
    },
    [actions, context.coupon]
  );

  useEffect(() => {
    if (
      line1 &&
      line1.length > 0 &&
      city &&
      city.length > 0 &&
      postalCode &&
      postalCode.length > 0 &&
      country &&
      country.length > 0
    ) {
      setFormComplete(true);
    }
  }, [line1, city, postalCode, country]);

  const promoCode = state.value;

  const [confirmState, confirm] = useAsyncFn(async () => {
    const monthlyPlan =
      availablePlans?.length > 0 &&
      availablePlans.filter((p: any) => p.currency !== "gbp" && !p.metadata.trial_period_days && p.amount !== 0).find((plan) => plan.interval === "month");
    const address = {
      line1,
      line2,
      city,
      state: stateAddress,
      postal_code: postalCode,
      country,
    };

    await actions.redeemGiftcard(monthlyPlan.id, promoCode.id, address);

    return true;
  }, [
    availablePlans,
    promoCode,
    line1,
    line2,
    city,
    stateAddress,
    postalCode,
    country,
  ]);

  const errorMessage = useMemo(() => {
    if (customer) {
      const subscription = customer.subscriptions?.data[0];
      if (subscription?.discount?.coupon) {
        setShowCodeInput(false);
        return "giftcard:error.hasCoupon";
      }
      if (subscription?.items.data[0]?.price.recurring.interval === "year") {
        setShowCodeInput(false);
        return "giftcard:error.hasYearInterval";
      }
    }

    if (state.error) {
      if (state.error.message === "NOT_AVAILABLE") {
        return "giftcard:error.notAvailable";
      }
      if (state.error.message === "NOT_FOUND") {
        return "giftcard:error.notFound";
      }
    }

    return "";
  }, [state, customer, setShowCodeInput]);

  useEffect(() => {
    if (!code) {
      return;
    }

    if (code.length === chars.length) {
      submit(code);
    }
  }, [chars.length, code, submit]);

  useEffect(() => {
    if (context.coupon) {
      const element = document.activeElement as HTMLElement;

      if (element) {
        element.blur();
      }
    }
  }, [context.coupon]);

  const countries = Object.keys(iso3311a2.countries).map((key) => {
    return { key, value: iso3311a2.countries[key] };
  });

  if (!ready) {
    return null;
  }

  return (
    <Page>
      <Container maxWidth="xs">
        <Grow in>
          {confirmState.value ? (
            <div>
              <Text align="center" variant="h6" color="primary">
                {t("giftcard:redeemGiftcard")}
              </Text>

              <Box maxWidth={300} margin="0 auto">
                <Text align="center" variant="h2" gutterBottom>
                  {t("signUp:welcome")}
                </Text>
              </Box>

              <Box maxWidth={360} margin="0 auto">
                <Text align="center" gutterBottom>
                  {t("signUp:subscriptionSuccess")}
                </Text>
              </Box>

              <Box width="100%" maxWidth={300} margin="0 auto" pt={4}>
                <Button fullWidth onClick={() => history.replace("/")}>
                  {t("signUp:finish")}
                </Button>
              </Box>
            </div>
          ) : (
            <div>
              <Text align="center" variant="h6" color="primary">
                {t("giftcard:redeemGiftcard")}
              </Text>

              <Text
                align="center"
                variant="h3"
                style={{ lineHeight: 1.3 }}
                gutterBottom
              >
                {t("giftcard:title")}
              </Text>

              <Text align="center" gutterBottom>
                {t("giftcard:subtitle")}
              </Text>

              <div style={{ margin: "12px 0" }}>
                {showCodeInput && (
                  <CodeInput
                    values={chars}
                    onChange={setChars}
                    autofocus
                    alphanumeric
                  />
                )}
              </div>

              <Box
                minHeight={50}
                width="100%"
                position="relative"
                marginTop={"20px"}
                marginBottom={"6px"}
              >
                <Fade in={!!state.loading}>
                  <Box
                    position="absolute"
                    top={4}
                    left={0}
                    right={0}
                    display="flex"
                    justifyContent="center"
                  >
                    <CircularProgress />
                  </Box>
                </Fade>

                <Collapse in={!state.loading}>
                  <Grow mountOnEnter unmountOnExit in={!!promoCode?.coupon}>
                    <Alert severity="success" variant="filled">
                      {promoCode?.coupon &&
                        getCouponDescription(promoCode?.coupon, t)}
                    </Alert>
                  </Grow>

                  <Grow mountOnEnter unmountOnExit in={!!errorMessage}>
                    <Alert severity="error" variant="filled">
                      {t(errorMessage)}
                    </Alert>
                  </Grow>
                </Collapse>
              </Box>

              <Grow
                in={!!promoCode?.coupon && !errorMessage}
                mountOnEnter
                unmountOnExit
              >
                <Box minHeight={50} width="100%" position="relative">
                  <TextField
                    autoFocus
                    required
                    className={classes.addressMargin}
                    name="line1"
                    label="Line 1"
                    onChange={(event) => {
                      setLine1(event.currentTarget.value);
                    }}
                    fullWidth
                    variant="filled"
                  />
                  <TextField
                    className={classes.addressMargin}
                    name="line2"
                    label="Line 2"
                    onChange={(event) => {
                      setLine2(event.currentTarget.value);
                    }}
                    fullWidth
                    variant="filled"
                  />
                  <TextField
                    required
                    className={classes.addressMargin}
                    name="city"
                    label="City"
                    onChange={(event) => {
                      setCity(event.currentTarget.value);
                    }}
                    fullWidth
                    variant="filled"
                  />
                  <TextField
                    required
                    className={classes.addressMargin}
                    name="postalCode"
                    label="Postal code"
                    onChange={(event) => {
                      setPostalCode(event.currentTarget.value);
                    }}
                    fullWidth
                    variant="filled"
                  />
                  <TextField
                    className={classes.addressMargin}
                    name="state"
                    label="State"
                    onChange={(event) => {
                      setStateAddress(event.currentTarget.value);
                    }}
                    fullWidth
                    variant="filled"
                  />
                  <Autocomplete
                    className={classNames([
                      classes.addressMargin,
                      classes.dropdownHeight,
                    ])}
                    options={countries}
                    getOptionLabel={(c) => c.value}
                    onChange={(_, c: { key: string; value: string }) => {
                      if (c) {
                        setCountry(c.key);
                      } else {
                        setCountry("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label={"Country"}
                        fullWidth
                        variant="filled"
                      />
                    )}
                  />
                </Box>
              </Grow>

              <Actions>
                <Grow
                  in={!!promoCode?.coupon && !errorMessage}
                  mountOnEnter
                  unmountOnExit
                >
                  <Button
                    loading={context.loading || confirmState.loading}
                    disabled={!formComplete}
                    onClick={() => confirm()}
                    fullWidth
                  >
                    {t("giftcard:buttonRedeem")}
                  </Button>
                </Grow>
                <Button
                  variant="text"
                  size="small"
                  style={{ marginTop: !!promoCode?.coupon ? "54px" : 0 }}
                  onClick={() => history.goBack()}
                >
                  {t("general:buttons.cancel")}
                </Button>
              </Actions>
            </div>
          )}
        </Grow>
      </Container>
    </Page>
  );
};

export const Redeem: React.FC = (props) => {
  return (
    <PaymentContextProvider>
      <SubscribeContextProvider>
        <RedeemContainer />
      </SubscribeContextProvider>
    </PaymentContextProvider>
  );
};
