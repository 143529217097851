import { Button, Text } from "@kvix/ui";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { KvixUserContext } from "../../contexts/user";
import { useShowPromotions } from "../../hooks/promotions";
import { Meta } from "./Meta";

interface BackgroundImageProps {
  src: string;
  className?: string;
}

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    let listeners = {
      load: null,
      error: null,
    };

    const addListeners = () => {
      image.addEventListener("load", listeners.load);
      image.addEventListener("error", listeners.error);
    };

    const removeListeners = () => {
      image.removeEventListener("load", listeners.load);
      image.removeEventListener("error", listeners.error);
    };

    listeners.load = () => {
      removeListeners();
      resolve();
    };

    listeners.error = (error?: Error) => {
      removeListeners();
      reject(error);
    };

    addListeners();

    image.src = src;

    if (image.complete) {
      resolve();
    }
  });
};

const BackgroundImageElementContainer = styled.div`
  background-color: #fa255e;
  overflow: hidden;
`;

const BackgroundImageElement = styled(animated.div)`
  will-change: transform, opacity;
`;

const BackgroundImage: React.FC<BackgroundImageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  useEffect(() => {
    let timer = null;

    const run = async () => {
      setLoaded(false);
      setShowTransition(false);

      timer = window.setTimeout(() => {
        setShowTransition(true);
      }, 100);

      await loadImage(props.src);

      setLoaded(true);
    };

    run();

    return () => {
      window.clearTimeout(timer);
    };
  }, [props.src]);

  const spring = useSpring({
    opacity: loaded ? 1 : 0,
    y: loaded ? 0 : 5,
    scale: loaded ? 1 : 1.05,
    config: {
      mass: 0.1,
      tension: 30,
    },
    reset: !showTransition,
    immediate: !showTransition,
  });

  return (
    <BackgroundImageElementContainer>
      <BackgroundImageElement
        className={props.className}
        style={{
          ...spring,
          backgroundImage: `url(${props.src})`,
        }}
      />
    </BackgroundImageElementContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  buttonNeg: {
    backgroundColor: "white",
    color: "#1f2833",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    padding: "50px 0px",
    color: theme.palette.common.white,
  },
  imageContainer: {
    height: "720px",
    width: "100%",
    textAlign: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "590px",
    },

    "&.instructors": {
      backgroundPosition: "84% 50%",
    },
    "&.exercise": {
      backgroundPosition: "84% 50%",
    },
  },
  containerPadding: {
    height: "100%",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },

    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}));

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const HeroTitle = styled(Text)`
  &.MuiTypography-root {
    font-size: 80px;
    line-height: 1;
    white-space: pre-line;

    @media screen and (max-width: 960px) {
      font-size: 60px;
    }
  }
`;

const HeroText = styled(Text)`
  &.MuiTypography-root {
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-line;
    font-weight: 500;
    max-width: 40ch;
  }
`;

interface BaseProps {
  background: string;
  imageClassName?: string;
}

const Base: React.FC<BaseProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Box mt={-16} className={classes.root}>
      <BackgroundImage
        src={props.background}
        className={`${classes.imageContainer} ${props.imageClassName}`}
      />

      <Content>
        <Container
          disableGutters
          maxWidth="lg"
          className={classes.containerPadding}
          // style={{ height: "100%" }}
        >
          <div className={classes.content}>{props.children}</div>
        </Container>
      </Content>
    </Box>
  );
};

const SignupButton = () => {
  const { t, ready } = useTranslation("banner");
  const { user } = useContext(KvixUserContext);
  const showPromotions = useShowPromotions();
  const location = useLocation();
  const classes = useStyles();

  if (user || !showPromotions || !ready) {
    return null;
  }

  return (
    <Link
      to={{
        pathname: "/signup",
        state: { from: location.pathname, background: location },
      }}
    >
      <Button className={classes.buttonNeg}>{t("banner:actionText")}</Button>
    </Link>
  );
};

const Start: React.FC = (props) => {
  const theme = useTheme();
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base
      background="/assets/hero-images/start-hero.jpg"
      imageClassName="start"
    >
      <Grid
        container
        direction="column"
        spacing={4}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit" theme={theme}>
            {t("hero.start.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <SignupButton />
        </Grid>
      </Grid>
    </Base>
  );
};

const Sessions: React.FC = (props) => {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base background="/assets/hero-images/sessions-hero.jpg">
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit">
            {t("hero.sessions.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <HeroText variant="body2" color="inherit">
            {t("hero.sessions.sloganText")}
          </HeroText>
        </Grid>

        <Grid item>
          <SignupButton />
        </Grid>
      </Grid>
    </Base>
  );
};

const Programs: React.FC = (props) => {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base background="/assets/hero-images/programs-hero.jpg">
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit">
            {t("hero.programs.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <HeroText variant="body2" color="inherit">
            {t("hero.programs.sloganText")}
          </HeroText>
        </Grid>

        <Grid item>
          <SignupButton />
        </Grid>
      </Grid>
    </Base>
  );
};

const Anytime: React.FC = (props) => {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base
      background="/assets/hero-images/anytime-hero.jpg"
      imageClassName="instructors"
    >
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit">
            {t("hero.anytime.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <HeroText variant="body2" color="inherit">
            {t("hero.anytime.sloganText")}
          </HeroText>
        </Grid>

        <Grid item>
          <SignupButton />
        </Grid>
      </Grid>
    </Base>
  );
};

const Instructors: React.FC = (props) => {
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base
      background="/assets/hero-images/instructors-hero.jpg"
      imageClassName="instructors"
    >
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit">
            {t("hero.instructors.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <HeroText variant="body2" color="inherit">
            {t("hero.instructors.sloganText")}
          </HeroText>
        </Grid>

        <Grid item>
          <SignupButton />
        </Grid>
      </Grid>
    </Base>
  );
};

const Exercise: React.FC = (props) => {
  const image = `${window.location.origin}/assets/hero-images/exercise.jpg`;
  const compact = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Base background={image} imageClassName="exercise">
      <Meta
        meta={{
          title: `KVIX - ${t("hero.exercise.sloganTitle")}`,
          description: t("hero.exercise.sloganText"),
          ogTitle: t("hero.exercise.sloganTitle"),
          ogImage: image,
        }}
      />
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginTop: compact ? "auto" : "132px" }}
      >
        <Grid item>
          <HeroTitle variant="h1" color="inherit">
            {t("hero.exercise.sloganTitle")}
          </HeroTitle>
        </Grid>

        <Grid item>
          <HeroText variant="body2" color="inherit">
            {t("hero.exercise.sloganText")}
          </HeroText>
        </Grid>
      </Grid>
    </Base>
  );
};

export const Hero = {
  Start,
  Sessions,
  Programs,
  Instructors,
  Anytime,
  Exercise,
};
