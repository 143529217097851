import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PurchaseFlowContext } from "../../../../../contexts/PurchaseFlow";
import { NewFlow } from "../../../../PurchaseFlow";
import imageDesktop from "./assets/Subscribe01-1040-400.jpg";
import imageMobile from "./assets/Subscribe01-640-480.jpg";
import Template from "./template";
import Cookies from "js-cookie";

interface Props {
  size?: "small" | "large";
  marginTop?: number;
  marginBottom?: number;
}

export const SubscribeBanner = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("banner");
  const { actions } = useContext(PurchaseFlowContext);
  const history = useHistory();

  if (!ready) {
    return null;
  }

  const price = t("getFree.freeForever");
  const button = t("subscribe.button");
  const title = t("subscribe.title");
  const subtitle = t("subscribe.subtitle");
  const buttonSubText = t("subscribe.buttonSubText");
  const size = props.size || "large";
  const image = isMobile ? imageMobile : imageDesktop;

  /** BANNER ON BILLING PAGE IN PROFILE **/
  return (
    <Template
      size={size}
      price={{
        value: price,
      }}
      backgroundImage={image}
      plan={{
        value: title,
        outlined: false,
      }}
      title={subtitle}
      button={{
        onClick: () => {
          const wpCampaignParams = Cookies.get('wp-campaign');
          
          if (wpCampaignParams) {
            history.replace(`${history.location.pathname}${wpCampaignParams}`);
          }

          actions.prompt(<NewFlow registerProps={{ checkoutSource: wpCampaignParams ? 'wp' : 'kvix' }} currentTab={1} />);
        },
        text: button,
      }}
      buttonSubText={buttonSubText}
    />
  );
};
