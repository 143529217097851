import { AbstractBaseUser, IssuedFrom, KvixProductType } from "@kvix/shared";
import { Button, Text } from "@kvix/ui";
import {
  Avatar,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Reward, { RewardConfig, RewardElement } from "react-rewards";
import { ExpoContext } from "../../../../../contexts/expo";
import { PaymentContext, PurchaseProduct } from "../../context";
import { DonateTabState } from "../enums/DonateTabState";
import { DonateType } from "../enums/DonateType";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";
import {
  AbstractDonationAlert,
  DonationAlert,
} from "../partials/DonationAlert";
import { Selection } from "../partials/Selection";
import { TabContainer } from "../partials/TabContainer";
import { SendButton } from "../SendButton";

interface Props {
  tabState: DonateTabState;
  setTabState: (tabState: DonateTabState) => void;
  donationRecipient: AbstractBaseUser;
  currentCoins: number;
  setDonationAlert: (message: string, severity: DonationAlertSeverity) => void;
  donationAlert: AbstractDonationAlert;
  issuedFrom: IssuedFrom;
  sessionId?: number;
  closeAlert: () => void;
}

const useStyles = makeStyles((theme) => ({
  recipientAvatar: {
    width: "72px",
    height: "72px",
  },
  fatText: {
    fontWeight: 600,
  },
  textLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: "pointer",
  },
  nameTitle: {
    marginLeft: "12px",
  },
  textInput: {
    fontWeight: 600,
    marginTop: "1px",
    marginBottom: "20px",
    width: "100%",
    height: "40px",
    borderRadius: "20px",
    borderWidth: 0,
    backgroundColor: "rgb(237, 237, 237)",
    color: "rgb(51, 51, 51)",
    paddingLeft: "12px",
    "&:focus": {
      borderColor: "rgb(0, 153, 255)",
      borderWidth: 1,
      outline: "none",
    },
    "&::placeholder": {
      color: "rgba(51, 51, 51, 0.4)",
    },
  },
  sendButton: {
    height: "40px",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : "rgb(31, 40, 51)",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : "rgb(31, 40, 51)",
    },
    width: "100%",
  },
  donateComplete: {
    backgroundColor: "rgb(109, 212, 0)",
    "&:hover": {
      backgroundColor: "rgb(109, 212, 0)",
    },
  },
  secondaryText: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginBottom: "20px",
  },
  profileContainer: {
    marginBottom: "20px",
  },
  rewardStyles: {
    "& span": {
      position: "fixed !important",
    },
  },
  isSlowWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Spinner = withStyles({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
})(CircularProgress);

export const DonateTab: FC<Props> = (props) => {
  const { isNativeApp } = useContext(ExpoContext);
  const classes = useStyles();
  const [reward, setReward] = useState<RewardElement>();
  const [donateComplete, setDonateComplete] = useState<boolean>(false);
  const [donateSelected, setDonateSelected] = useState<number | null>(null);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSlow, setIsSlow] = useState(false);
  const rejectionTimeout = useRef(null);
  const isSlowTimeout = useRef(null);
  const [hasSent, setHasSent] = useState(false);
  const { t, ready } = useTranslation("donateLiveView");
  const rewardConfig: RewardConfig = {
    angle: 75,
    spread: 100,
    emoji: ["❤️"],
    elementCount: 20,
  };
  const { internalActions } = useContext(PaymentContext);

  const donateOnClick = (v) => {
    setDonateSelected(v);
  };

  useEffect(() => {
    if (props.tabState !== DonateTabState.DONATE) {
      resetTab();
    }
  }, [props.tabState]);

  const resetTab = () => {
    setMessage("");
    setDonateSelected(null);
    setDonateComplete(false);
  };

  const handleClick = async () => {
    setIsLoading(true);
    props.closeAlert();
    try {
      const res = await new Promise<PurchaseProduct>(
        async (resolve, reject) => {
          rejectionTimeout.current = setTimeout(() => {
            reject("Invoice timed out");
          }, 30000);

          isSlowTimeout.current = setTimeout(() => {
            setIsSlow(true);
          }, 5000);

          const res = await internalActions.purchaseProduct({
            receiverId: props.donationRecipient.id,
            quantity: donateSelected,
            message,
            productId: KvixProductType.DONATION,
            issuedFrom: props.issuedFrom,
            sessionId: props.sessionId,
          });

          window.clearTimeout(rejectionTimeout.current);
          window.clearTimeout(isSlowTimeout.current);
          setIsSlow(false);
          resolve(res);
        }
      );

      if (res.success) {
        window.setTimeout(() => {
          setIsLoading(false);
          setDonateComplete(true);
          setHasSent(true);
          setMessage("");
          setDonateSelected(null);
          reward.rewardMe();
        }, 1500);
        window.setTimeout(() => {
          setHasSent(false);
          setDonateComplete(false);
        }, 5000);
      } else {
        if (res.message === "Not enough funds.") {
          props.setDonationAlert(
            t("alert.notEnoughCoins"),
            DonationAlertSeverity.ERROR
          );
        }
        setIsLoading(false);
      }
    } catch (error) {
      props.setDonationAlert(t("alert.timedOut"), DonationAlertSeverity.ERROR);
      window.clearTimeout(rejectionTimeout.current);
      window.clearTimeout(isSlowTimeout.current);
      setIsSlow(false);
      setIsLoading(false);
    }
  };

  const messageOnChange = (e) => {
    e.target.value !== "" ? setMessage(e.target.value) : setMessage(null);
  };

  if (!ready) {
    return null;
  }

  return (
    <TabContainer
      visibleState={DonateTabState.DONATE}
      tabState={props.tabState}
    >
      <Typography variant={"body1"} className={classes.secondaryText}>
        {t("donate.title.part1")} <br /> {t("donate.title.part2")}
      </Typography>
      <Grid container direction={"row"} className={classes.profileContainer}>
        <Grid item className={classes.rewardStyles}>
          <Reward
            type={"emoji"}
            config={rewardConfig}
            ref={(ref) => {
              setReward(ref);
            }}
          >
            <Avatar
              src={props.donationRecipient?.imageUrl}
              className={classes.recipientAvatar}
            />
          </Reward>
        </Grid>
        <Grid item>
          <Typography variant={"h4"} className={classes.nameTitle}>
            {props.donationRecipient?.name?.first} <br />
            {props.donationRecipient?.name?.last}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant={"body2"} className={classes.secondaryText}>
        {t("donate.subtitle.part1")}
        <span className={classes.fatText}>{props.currentCoins}</span>
        {t("donate.subtitle.part2")}
        {!isNativeApp && (
          <Link
            onClick={() => {
              props.setTabState(DonateTabState.BUY);
            }}
            className={classes.textLink}
          >
            {t("donate.subtitle.part3")}
          </Link>
        )}
      </Typography>
      <Grid container direction={"column"}>
        <Grid item>
          <Selection
            setValue={donateOnClick}
            setDonationAlert={props.setDonationAlert}
            currentCoins={props.currentCoins}
            isCompleted={donateComplete}
            setComplete={setDonateComplete}
            disableButtons={hasSent || isLoading}
            donateType={DonateType.COIN}
          />
        </Grid>
        {props.donationAlert !== null && (
          <Grid item>
            <DonationAlert
              alertObject={props.donationAlert}
              setTabState={props.setTabState}
            />
          </Grid>
        )}
        <Grid item>
          <input
            value={message}
            placeholder={t("donate.writeMessage")}
            maxLength={140}
            onChange={messageOnChange}
            className={classes.textInput}
          ></input>
        </Grid>
        <Grid item>
          <Button
            className={classNames([
              classes.sendButton,
              hasSent && classes.donateComplete,
            ])}
            disabled={
              !donateSelected || message === null || hasSent || isLoading
            }
            onClick={handleClick}
          >
            {isLoading ? (
              <Spinner size={24} />
            ) : (
              <SendButton hasSent={hasSent} />
            )}
          </Button>
          {isSlow && (
            <div className={classes.isSlowWrapper}>
              <Text variant="subtitle2">{t("isSlow")}</Text>
            </div>
          )}
        </Grid>
      </Grid>
    </TabContainer>
  );
};
