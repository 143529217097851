import {
  AbstractBaseUser,
  AbstractLiveSession,
  getSessionInstructor,
  IssuedFrom,
} from "@kvix/shared";
import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { Donate } from "./Donate";
import { DonateType } from "./enums/DonateType";

const useStyles = makeStyles((theme) => ({
  mobileModal: {
    borderRadius: "18px 18px 0px 0px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: "80vh",
    width: "100%",
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
  },
  desktopModal: {
    "&& .MuiDialog-paper": {
      backgroundColor: "transparent",
      borderRadius: "18px",
    },
  },
  desktopModalContent: {
    borderRadius: "18px",
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    width: "420px",
    "&&.MuiDialogContent-root:first-child": {
      padding: 0,
    },
  },
}));

interface Props {
  isMobile?: boolean;
  displayVote: boolean;
  setDisplayVote: React.Dispatch<React.SetStateAction<boolean>>;
  session?: AbstractLiveSession;
  issuedFrom: IssuedFrom;
  instructor?: AbstractBaseUser;
  onRedirect?: () => void;
  donateType: DonateType;
}
export const VoteModal: React.FC<Props> = (props) => {
  const { session } = props;
  const classes = useStyles();
  const instructor = props.session
    ? getSessionInstructor(session)
    : props.instructor;

  const handleClose = () => {
    props.setDisplayVote(false);
  };

  return (
    <>
      {props.isMobile ? (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: props.displayVote ? 0 : "100%" }}
          transition={{ duration: 0.5, type: "ease-in" }}
          className={classes.mobileModal}
        >
          <Donate
            donateType={props.donateType}
            close={handleClose}
            donationRecipient={instructor}
            issuedFrom={props.issuedFrom}
            sessionId={session?.id}
            onRedirect={props.onRedirect}
          />
        </motion.div>
      ) : (
        <Dialog
          open={props.displayVote}
          onClose={() => props.setDisplayVote(false)}
          className={classes.desktopModal}
        >
          <DialogContent className={classes.desktopModalContent}>
            <Donate
              donateType={props.donateType}
              close={handleClose}
              donationRecipient={instructor}
              issuedFrom={props.issuedFrom}
              sessionId={session?.id}
              onRedirect={props.onRedirect}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
