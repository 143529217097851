import React, { useContext } from "react";
import { CampaignContext } from "../../../pages/Campaign/context";
import { ShareButton } from "../../../partials/SocialShare/ShareButton";
import { SignupButton } from "../../../SignupButton";

interface Props {
  searchExpanded: boolean;
}
export const CampaignLayout: React.FC<Props> = (props) => {
  const { campaign } = useContext(CampaignContext);
  const { searchExpanded } = props;
  return (
    <>
      {!searchExpanded && <SignupButton placement="header" />}
      <ShareButton instructor={campaign?.instructor} />
    </>
  );
};
