import { Button, Text } from "@kvix/ui";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Location } from "history";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import StripeInternal from "stripe";
import styled from "styled-components";
import { CampaignIndicator } from "../../../CampaignIndicator/CampaignIndicator";

interface Price {
  value: string | number;
  campaignValue?: string | number;
  currency?: string;
  subtitle?: string;
}

interface Props {
  plan?: {
    value: string;
    outlined?: boolean;
  };
  title: string;
  titleMobile?: string;
  price?: Price;
  defaultPlan?: StripeInternal.Plan;
  button: {
    text: string;
    link?: Partial<Location>;
    onClick?: () => void;
  };
  buttonSubText: string;
  backgroundImage: string;
  size: "large" | "small";

  marginTop?: number;
  marginBottom?: number;
}

interface ContainerProps {
  imageUrl: any;
  isMobile: boolean;
  size: "large" | "small";
  marginTop?: number;
  marginBottom?: number;
}

interface PriceTextProps {
  displayAsOld?: boolean;
  isMobile: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: ${(props) => (props.isMobile ? "16px" : "16px")};
  color: white;
  background: url(${(props) => props.imageUrl});
  background-position: ${(props) =>
    props.size === "large" ? (props.isMobile ? "22% 0" : "0 -34px") : "0 20%"};
  background-size: ${(props) => props.isMobile && "cover"};
  background-repeat: no-repeat;
  user-select: none;
  margin-bottom: ${(props) => (props.isMobile ? "24px" : "80px")};
  position: relative;
  background-size: cover;
`;

const OutlinedText = styled(Text)`
  line-height: 1;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ffffff;
`;

const PriceText = styled(({ displayAsOld, isMobile, ...rest }) => (
  <Text {...rest} />
))<PriceTextProps>`
  text-decoration: ${(props) => (props.displayAsOld ? "line-through" : "none")};
  color: ${(props) => (props.displayAsOld ? "#000" : "inherit")};
  margin-right: ${(props) =>
    props.displayAsOld ? (props.isMobile ? "8px" : "12px") : 0};
  font-weight: ${(props) => (props.displayAsOld ? 500 : 700)};
  font-size: ${(props) =>
    props.displayAsOld
      ? props.isMobile
        ? "24px"
        : "34px"
      : props.isMobile
      ? "34px"
      : "44px"};
`;

const Price: FC<Pick<Props, "price" | "size">> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isNumber = typeof props.price.value === "number";

  return (
    <Box mb={props.size === "large" ? 3 : 2}>
      <Box display="flex" alignItems="baseline">
        {!isNumber && (
          <>
            <PriceText
              isMobile={isMobile}
              displayAsOld={!!props.price.campaignValue}
              nowrap
            >
              {props.price.value}
            </PriceText>
            {props.price.campaignValue && (
              <PriceText isMobile={isMobile} nowrap>
                {props.price.campaignValue}
              </PriceText>
            )}
          </>
        )}

        {isNumber && (
          <>
            <PriceText
              isMobile={isMobile}
              displayAsOld={!!props.price.campaignValue}
              nowrap
            >
              {props.price.value}
            </PriceText>
            {props.price.campaignValue && (
              <PriceText nowrap isMobile={isMobile}>
                {props.price.campaignValue}
              </PriceText>
            )}

            {props.price.currency && (
              <Text
                color="inherit"
                variant={isMobile ? "h4" : "h3"}
                nowrap
                style={{ marginLeft: "6px" }}
              >
                {props.price.currency}
              </Text>
            )}
          </>
        )}
      </Box>

      {props.price.subtitle && (
        <Text
          color="inherit"
          variant="body2"
          weight={500}
          style={{ fontSize: isMobile ? "12px" : "inherit" }}
        >
          {props.price.subtitle}
        </Text>
      )}
    </Box>
  );
};

export default (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      imageUrl={props.backgroundImage}
      isMobile={isMobile}
      size={props.size}
      style={{ maxWidth: "1400px", marginLeft: "auto", marginRight: "auto" }}
    >
      {props.plan && (
        <>
          {props.price?.campaignValue && props.defaultPlan && (
            <CampaignIndicator plan={props.defaultPlan} template="banner" />
          )}
          <Box
            maxWidth={props.plan.outlined ? "300px" : null}
            mb={isMobile && props.plan.outlined ? 0.25 : 0}
          >
            {props.plan.outlined && (
              <OutlinedText
                variant="h2"
                style={{
                  lineHeight: props.size === "small" ? "46px" : "48px",
                  textShadow: "0px 0px 20px rgba(150, 150, 150, 0.51)",
                  wordSpacing: "9999rem",
                }}
                gutterBottom
              >
                {props.plan.value}
              </OutlinedText>
            )}

            {!props.plan.outlined && (
              <Text
                variant="h2"
                style={{
                  lineHeight: props.size === "small" ? "46px" : "48px",
                  textShadow: "0px 0px 20px rgba(150, 150, 150, 0.51)",
                  wordSpacing: "9999rem",
                }}
                gutterBottom
              >
                {props.plan.value}
              </Text>
            )}
          </Box>
        </>
      )}

      {props.title && (
        <Box
          // maxWidth={props.size === "large" ? "100px" : null}
          mt={isMobile ? 0 : 1}
        >
          {props.size === "large" && (
            <Text
              variant={isMobile ? "h5" : "h4"}
              color="inherit"
              weight={500}

              // style={{ lineHeight: 1 }}
            >
              {props.title}
            </Text>
          )}

          {props.size === "small" && (
            <Box mt={isMobile ? 1 : 0.5}>
              <Text
                variant={isMobile ? "h5" : "h4"}
                color="inherit"
                weight={500}
                gutterBottom
              >
                {isMobile
                  ? props.titleMobile
                    ? props.titleMobile
                    : props.title
                  : props.title}
              </Text>
            </Box>
          )}
        </Box>
      )}

      <Price price={props.price} size={props.size} />

      {(props.button?.link || props.button?.onClick) &&
        props.button?.text &&
        props.buttonSubText && (
          <Grid container alignItems="center" spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} sm="auto">
              {props.button.onClick ? (
                <Button
                  fullWidth={isMobile}
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                  onClick={props.button.onClick}
                >
                  {props.button?.text}
                </Button>
              ) : (
                <Link to={props.button.link}>
                  <Button
                    fullWidth={isMobile}
                    color="primary"
                    size={isMobile ? "small" : "medium"}
                  >
                    {props.button?.text}
                  </Button>
                </Link>
              )}
            </Grid>

            <Grid item xs={12} sm="auto">
              <Text
                color="inherit"
                variant="body2"
                weight={500}
                align={isMobile ? "center" : "left"}
              >
                {props.buttonSubText}
              </Text>
            </Grid>
          </Grid>
        )}
    </Container>
  );
};
