import {
  Box,
  Button,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CoinImageSize } from "../enums/CoinImageSize";
import { DonateTabState } from "../enums/DonateTabState";
import { DonateType } from "../enums/DonateType";
import { CoinImage } from "./CoinImage";

export interface Props {
  donateType: DonateType;
  quantitySelected: number;
  price: number;
  setTabState: (tabState: DonateTabState) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  innerContainer: {
    display: "flex",
    width: "100%",
  },
  divider: {
    marginTop: "6px",
    marginBottom: "6px",
  },
  quantityButton: {
    color: theme.palette.text.primary,
    height: "40px",
  },
  quantitySpan: {
    color: theme.palette.text.primary,
    height: "40px",
    whiteSpace: "nowrap",
    lineHeight: "40px",
    fontWeight: 700,
  },
  rightText: {
    width: "100%",
    textAlign: "right",
    lineHeight: "36px",
  },
  coinImage: {
    verticalAlign: "text-bottom",
    marginLeft: "5px",
  },
}));
interface BuyDisplayProps {
  buttonOnClick: () => void;
  quantitySelected: number;
  price: number;
}
const BuyDisplay: FC<BuyDisplayProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.quantityButton}
        onClick={props.buttonOnClick}
        variant={"text"}
      >
        {props.quantitySelected}&nbsp;
        <CoinImage size={CoinImageSize.XL} />
      </Button>
      <Typography className={classes.rightText}>
        {props.price + " kr"}
      </Typography>
    </>
  );
};
interface VoteDisplayProps {
  quantitySelected: number;
  price: number;
}
const VoteDisplay: FC<VoteDisplayProps> = (props) => {
  const [displaySelection, setDisplaySelection] = useState<number[]>([0, 0]);
  const { t, ready } = useTranslation("donateLiveView");
  const classes = useStyles();

  useEffect(() => {
    setDisplaySelection([
      props.quantitySelected !== null ? props.quantitySelected : 0,
      props.price !== null ? props.price : 0,
    ]);
  }, [props.quantitySelected, props.price]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <span className={classes.quantitySpan}>
        {displaySelection[0] + " " + t("vote.votes")}
      </span>
      <Typography className={classes.rightText}>
        {displaySelection[1] + " "}
        <CoinImage size={CoinImageSize.XL} className={classes.coinImage} />
      </Typography>
    </>
  );
};

export const TotalPriceDisplay: FC<Props> = (props) => {
  const classes = useStyles();
  const quantityButtonOnClick = () => {
    props.setTabState(DonateTabState.BUY);
  };

  return (
    <Box className={classes.container}>
      <Divider className={classes.divider} />
      <Box className={classes.innerContainer}>
        {props.donateType === DonateType.COIN ? (
          <BuyDisplay
            price={props.price}
            buttonOnClick={quantityButtonOnClick}
            quantitySelected={props.quantitySelected}
          />
        ) : (
          <VoteDisplay
            price={props.price}
            quantitySelected={props.quantitySelected}
          />
        )}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};
