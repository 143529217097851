import { AbstractProgram, AbstractSession } from "@kvix/shared";
import React, { createContext, useContext, useEffect, useState } from "react";
import { isBasicInstructor, isInstructor, KvixUserContext } from "./user";

export interface State {
  programs: AbstractProgram[];
  streamerSessions: AbstractSession[];
}

export const KvixInstructorContext = createContext<State>({
  programs: [],
  streamerSessions: [],
});

const StateProvider: React.FC = (props) => {
  const { user, socket } = useContext(KvixUserContext);
  const [programs, setPrograms] = useState<AbstractProgram[]>([]);
  const [streamerSessions, setStreamerSessions] = useState<AbstractSession[]>(
    []
  );

  useEffect(() => {
    socket
      .on<AbstractProgram[]>("update instructor programs")
      .subscribe(setPrograms);

    socket
      .on<AbstractSession[]>("update streamer sessions")
      .subscribe(setStreamerSessions);
  }, [socket]);

  if (user && (isInstructor(user) || isBasicInstructor(user))) {
    return (
      <KvixInstructorContext.Provider value={{ programs, streamerSessions }}>
        {props.children}
      </KvixInstructorContext.Provider>
    );
  } else {
    return <>{props.children}</>;
  }
};

const StateConsumer = KvixInstructorContext.Consumer;

export {
  StateProvider as KvixInstructorStateProvider,
  StateConsumer as KvisInstructorStateConsumer,
};
