import { AbstractBaseUser } from "@kvix/shared";
import { Button, KvixIcon } from "@kvix/ui";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { motion } from "framer-motion";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../contexts/user";

interface Props {
  instructor: AbstractBaseUser;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 70,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
    },
    transition: "transform 150ms ease-out",

    "&.isFollowing": {
      transform: "translateX(-48px)",
      transition: "transform 150ms ease-out",
      marginRight: "-48px",
      background: theme.palette.background.default,
      color: theme.palette.common.black,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 16,
    marginBottom: 32,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
  },
  notificationBell: {
    color: theme.palette.text.primary,
  },
}));

export const FollowButton: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("streamerPage");
  const [loading, setLoading] = useState(false);
  const { actions, followedInstructors, user } = useContext(KvixUserContext);
  const classes = useStyles(props);

  const isFollowing = useMemo(() => {
    if (props.instructor?.instructorProfile && followedInstructors) {
      return followedInstructors.hasOwnProperty(
        props.instructor.instructorProfile.id
      );
    }
    return null;
  }, [props.instructor, followedInstructors]);

  const allowNotifications = useMemo(() => {
    if (props.instructor?.instructorProfile && followedInstructors) {
      return followedInstructors[`${props.instructor.instructorProfile.id}`];
    }
  }, [props.instructor, followedInstructors]);

  const handleFollow = async () => {
    setLoading(true);
    if (isFollowing) {
      await actions.removeInstructorFollow(
        props.instructor?.instructorProfile.id
      );
    } else {
      await actions.updateInstructorFollow(
        props.instructor?.instructorProfile.id,
        true
      );
    }
    setLoading(false);
  };

  const handleNotificationUpdate = async () => {
    await actions.updateInstructorFollow(
      props.instructor?.instructorProfile.id,
      !allowNotifications
    );
  };

  if (user?.id === props.instructor.id || isFollowing === null || !ready) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="contained"
        color="secondary"
        size="small"
        lowercase
        loading={loading}
        onClick={handleFollow}
        className={classNames(classes.button, { isFollowing })}
        disableRipple
      >
        {isFollowing ? t("button.unfollow") : t("button.follow")}
      </Button>
      {isFollowing && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          <IconButton onClick={handleNotificationUpdate}>
            {allowNotifications ? (
              <KvixIcon.Notifications.Active
                className={classes.notificationBell}
              />
            ) : (
              <KvixIcon.Notifications.Inactive
                className={classes.notificationBell}
              />
            )}
          </IconButton>
        </motion.div>
      )}
    </Box>
  );
};
