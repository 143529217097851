import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";

export interface LanguageContextState {
  currentLanguage: string;
  otherLanguage: string;
  changeLanguage: () => void;
  setToSwedish: () => void;
  setToEnglish: () => void;
}
export const LanguageContext = React.createContext<LanguageContextState>(null);

export const LanguageContextProvider: React.FC = (props) => {
  const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);
  const [currentLanguage, setCurrentLanguage] =
    useLocalStorage<string>("currentLanguage");

  const otherLanguage = useMemo(() => {
    return availableLanguages.find((lng) => lng !== currentLanguage);
  }, [availableLanguages, currentLanguage]);

  const changeLanguage = () => {
    setCurrentLanguage(otherLanguage);
  };

  const handleChangeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
  };

  const setToSwedish = () => {
    handleChangeLanguage(`sv-SE`);
  };

  const setToEnglish = () => {
    handleChangeLanguage(`en-GB`);
  };

  const setCorrectLang = (language: string) => {
    switch (language) {
      case "sv-SE":
        return "sv-SE";
      case "en-GB":
        return "en-GB";
      default:
        return language;
    }
  };

  useEffect(() => {
    if (availableLanguages?.length === 0) {
      fetch(
        "https://api.locize.app/languages/3b1b964f-7d32-43a3-ac48-1c427468f4c5",
        {
          method: "GET",
        }
      ).then((result) => {
        result.json().then((json) => {
          if (json) {
            setAvailableLanguages(Object.keys(json));
          }
        });
      });
    }
  }, [availableLanguages]);

  useEffect(() => {
    if (currentLanguage && currentLanguage !== "undefined") {
      i18next.changeLanguage(currentLanguage);
    } else if (i18next.language) {
      // setCurrentLanguage(setCorrectLang(i18next.language));
      setCurrentLanguage('sv-SE');
    } else {
      // navigator.language === "sv" ? setToSwedish() : setToEnglish();
      setToSwedish();
    }
  }, [currentLanguage, i18next.language, navigator.language]);

  const value = {
    currentLanguage,
    otherLanguage,
    changeLanguage,
    setToSwedish,
    setToEnglish,
  };

  return (
    <LanguageContext.Provider value={value}>
      {props.children}
    </LanguageContext.Provider>
  );
};
