import {
  AppBar,
  Container,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
} from "@material-ui/core";
import React, { useContext } from "react";
import { APP_HEADER_PADDING } from "../../contexts/expo";
import { HeaderBackground } from "./Background";
import { HeaderContext } from "./context";

interface Props {
  toolbarRef: React.MutableRefObject<any>;
  slide: boolean;
}

const useStyles = makeStyles<Theme, React.ContextType<typeof HeaderContext>>(
  (theme) => ({
    root: {
      backgroundColor: "transparent",
      transition: "transform 400ms ease",
      transform: `translateY(0px)`,
    },
    container: {
      padding: 0,
      position: "relative",
      paddingTop: (context) =>
        `${context?.isNativeApp ? APP_HEADER_PADDING : 0}px`,
    },
    menuButton: {
      "& svg": {
        fontSize: 32,
        width: 32,
        height: 32,
      },
    },
  })
);

export const HeaderContainer: React.FC<Props> = (props) => {
  const context = useContext(HeaderContext);
  const classes = useStyles(context);

  return (
    <Slide appear={false} direction="down" in={true}>
      <AppBar
        position="relative"
        color="transparent"
        elevation={0}
        ref={props.toolbarRef}
        className={classes.root}
      >
        <HeaderBackground slide={props.slide} />

        <Container className={classes.container} maxWidth="xl">
          <Toolbar>{props.children}</Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
};
