import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Text } from "@kvix/ui";

export const TosText = () => {
  const { t, ready } = useTranslation("signUpCampaign");

  if (!ready) {
    return null;
  }

  return (
    <Text variant="h5">
      {t("tocTextBeforeLink")}
      <Link to="/page/terms">
        <u>{t("tocLinkText")}</u>
      </Link>
      {t("tocTextAfterLink")}
    </Text>
  );
};
