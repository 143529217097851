import { KvixIcon } from "@kvix/ui";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import screenfull from "screenfull";
import { ExpoContext } from "../../../../contexts/expo";

interface Props {
  size: "default" | "large";
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
  },
  fullscreen: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "block",
    height: "100%",
    width: "100%",
    zIndex: 1101,
    background: theme.palette.common.black,
  },
}));

export const FullscreenControl: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { exit, enter, isFullscreen } = useContext(FullscreenContext);

  return (
    <IconButton
      className={classes.button}
      onClick={isFullscreen ? exit : enter}
    >
      {isFullscreen ? (
        <KvixIcon.Fullscreen.Exit
          style={{
            transform: `scale(${props.size === "large" ? 1.4585 : 1})`,
          }}
        />
      ) : (
        <KvixIcon.Fullscreen.Enter
          style={{
            transform: `scale(${props.size === "large" ? 1.4585 : 1})`,
          }}
        />
      )}
    </IconButton>
  );
};

interface FullscreenAreaModel {
  area: React.MutableRefObject<HTMLDivElement>;
  onChange?: (fullscreen: boolean) => void;
  disable?: boolean;
  isFullscreen: boolean;
  enter: () => void;
  exit: () => void;
}

interface FullscreenAreaProps {
  disabled?: boolean;
  onChange?: (fullscreen: boolean) => void;
  children: (isFullscreen: boolean) => React.ReactElement;
}

export const FullscreenContext = React.createContext<FullscreenAreaModel>(null);

export const FullscreenArea: React.FC<FullscreenAreaProps> = (props) => {
  const classes = useStyles();
  const { onChange = () => {}, disabled: disable = false } = props;
  const area = useRef<HTMLDivElement>();
  const [isFullscreen, setIsFullscreen] = useState(
    screenfull.isEnabled && screenfull.isFullscreen
  );
  const nativeApp = useContext(ExpoContext);

  const enter = () => {
    if (screenfull.isEnabled) {
      screenfull.request(area.current);
    } else {
      document.body.style.background = "#000000";
    }
    setIsFullscreen(true);
    nativeApp.actions.fullscreen(true);
  };

  const exit = useCallback(() => {
    if (screenfull.isEnabled) {
      screenfull.exit();
    } else {
      document.body.style.background = "#ffffff";
    }
    setIsFullscreen(false);
    nativeApp.actions.fullscreen(false);
  }, [nativeApp.actions]);

  useEffect(() => {
    if (disable) {
      exit();
    }
  }, [disable, exit, onChange]);

  useEffect(() => {
    const callback = () => {
      setIsFullscreen(screenfull.isEnabled && screenfull.isFullscreen);
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", callback);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", callback);
      }
    };
  });

  useEffect(() => {
    onChange(isFullscreen);
  }, [onChange, isFullscreen]);

  return (
    <FullscreenContext.Provider
      value={{ area, onChange, disable, enter, exit, isFullscreen }}
    >
      <Grid
        container
        item
        className={classNames({
          [classes.fullscreen]: isFullscreen,
        })}
        ref={area}
      >
        {props.children(isFullscreen)}
      </Grid>
    </FullscreenContext.Provider>
  );
};
