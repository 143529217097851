import { AbstractPartnerProfile } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Collapse, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks/isMobile";

const useStyles = makeStyles((theme) => ({
  readMoreText: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  bio: {
    whiteSpace: "pre-wrap",
  },
  partnerLogo: {
    maxWidth: 200,
  },
  imageUrl: {
    maxWidth: "100%",
  },
}));

export const Partner: React.FC<{
  partnerProfile: AbstractPartnerProfile;
}> = (props) => {
  const { t, ready } = useTranslation("general");
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const [readMore, setReadMore] = useState(false);

  const readMoreLink = useMemo(
    () =>
      props.partnerProfile?.longBio && (
        <span
          onClick={() => setReadMore(!readMore)}
          className={classes.readMoreText}
        >
          {readMore ? t("showLess") : t("showMore")}
        </span>
      ),
    [readMore, classes.readMoreText]
  );

  if (!ready) {
    return null;
  }

  return props.partnerProfile ? (
    <Container style={{ marginTop: 48 }} maxWidth="lg">
      <Box mb={2}>
        <Text variant="h3" style={{ fontSize: 18 }}>
          <span style={{ color: "#ff0000", marginRight: 4 }}>•</span>
          {t("inAssociationWith")}
        </Text>
      </Box>
      <Box mb={2}>
        <img
          className={classes.partnerLogo}
          alt={props.partnerProfile.title}
          src={props.partnerProfile.logoUrl}
        />
      </Box>
      <Box>
        {isMobile ? (
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item className={classes.bio}>
              {props.partnerProfile.bio} {!readMore && readMoreLink}
            </Grid>
            <Grid item style={{ margin: "16px 0px" }}>
              <img
                className={classes.imageUrl}
                alt={props.partnerProfile.title}
                src={props.partnerProfile.imageUrl}
              />
            </Grid>
            <Collapse in={readMore}>
              <Grid item className={classes.bio}>
                {props.partnerProfile.longBio} {readMoreLink}
              </Grid>
            </Collapse>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={5} className={classes.bio}>
              {props.partnerProfile.bio} {!readMore && readMoreLink}
              <Collapse in={readMore}>
                {"\n" + props.partnerProfile.longBio} {readMoreLink}
              </Collapse>
            </Grid>
            <Grid item xs={6}>
              <img
                className={classes.imageUrl}
                alt={props.partnerProfile.title}
                src={props.partnerProfile.imageUrl}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  ) : null;
};
