import { Box, makeStyles } from "@material-ui/core";
import React, { useContext, useRef } from "react";
import { Experiment, Variant } from "react-optimize";
import { AppPromotion } from "../partials/AppPromotion";
import { HeaderContainer } from "./Container";
import { HeaderContext, HeaderToolbarContext } from "./context";
import { ToolbarLayout } from "./Toolbars";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateAreas: `"toolbar"`,

    "& > *": {
      gridArea: "toolbar",
    },
  },
}));

interface Props {
  slide?: boolean;
  setPromotionActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toolbar = React.memo<{ containerRef: React.MutableRefObject<any> }>(
  (props) => {
    const classes = useStyles(props);
    const toolbarContext = useContext(HeaderToolbarContext);

    const isToolbarOverridden = toolbarContext.items.length === 0;

    return (
      <>
        <div className={classes.root} ref={toolbarContext.container} />

        {isToolbarOverridden && <ToolbarLayout />}
      </>
    );
  }
);

export const Header: React.FC<Props> = (props) => {
  const toolbarRef = useRef();
  const context = useContext(HeaderContext);

  if (context.isNativeApp && !context.onHomePage) {
    return null;
  }

  return (
    <Box position="fixed" top={0} right={0} width="100%" zIndex={1299}>
      <AppPromotion setPromotionActive={props.setPromotionActive} />
      <HeaderContainer slide={context.displaySlide} toolbarRef={toolbarRef}>
        <Toolbar containerRef={toolbarRef} />
        <Experiment id="-C7znSfqRCWpOkENU-1Iyg">
          <Variant id="0">
            <div
              dangerouslySetInnerHTML={{
                __html: `<!-- Google Optimize: Original -->`,
              }}
            />
          </Variant>
          <Variant id="1">
            <div
              dangerouslySetInnerHTML={{
                __html: `<!-- Google Optimize: Variant 1 -->`,
              }}
            />
          </Variant>
        </Experiment>
      </HeaderContainer>
    </Box>
  );
};
