import { ArticleType } from "@kvix/shared";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useLocalStorage } from "react-use";
import { SortOption } from "../../../contexts/algolia";
import { useFetchCategories } from "../../../hooks/http";
import { FilterSelector } from "./components/FilterSelector";
import { Result } from "./components/Result";

interface Props {
  type: ArticleType;
}

export interface SearchFilters {
  instructorName: string;
  level: string;
  equipment: string;
  focus: string;
  duration: number;
  type: ArticleType;
  categories: string;
}

export enum DisplayArrangement {
  CARD = "card",
  LIST = "list",
}

export const Content: React.FC<Props> = (props) => {
  const categories = useFetchCategories();
  const history = useHistory();
  const contentRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [arrangement, setArrangement] = useLocalStorage<DisplayArrangement>(
    "arrangement",
    DisplayArrangement.CARD
  );
  const [filter, setFilter] = useState<SearchFilters>({
    duration: null,
    focus: null,
    equipment: null,
    instructorName: null,
    level: null,
    type: props.type,
    categories: null,
  });
  const [sortBy, setSortBy] = useState<SortOption>(SortOption.RELEVANCY);

  useEffect(() => {
    const pathCategory = history.location.pathname.split("/")[2];
    const queryFilters = queryString.parse(history.location.search);
    const parsedQueryFilters =
      queryFilters.filters && JSON.parse(queryFilters.filters as string);

    setFilter((filter) => ({
      ...filter,
      categories:
        categories.value?.find((category) => category.slug === pathCategory)
          ?.slug || null,
      ...parsedQueryFilters,
    }));
  }, [history, setFilter, categories]);

  const handleSetFilter = (value: SearchFilters) => {
    const basePath = history.location.pathname.split("/")[1];
    const filters = Object.keys(value).reduce((obj, key) => {
      if (value[key] && key !== "categories" && key !== "type") {
        obj[key] = value[key];
      }
      return obj;
    }, {});

    history.replace({
      pathname: value.categories
        ? `/${basePath}/${value.categories}`
        : `/${basePath}`,
      ...(Object.keys(filters).length > 0 && {
        search: `?filters=${encodeURIComponent(JSON.stringify(filters))}`,
      }),
    });

    setFilter(value);
  };

  return (
    <>
      <div ref={contentRef} />
      <FilterSelector
        {...props}
        categories={categories}
        filter={filter}
        searchQuery={searchQuery}
        setFilter={handleSetFilter}
        setSearchQuery={setSearchQuery}
        arrangement={arrangement}
        setArrangement={() =>
          setArrangement(
            arrangement === DisplayArrangement.CARD
              ? DisplayArrangement.LIST
              : DisplayArrangement.CARD
          )
        }
      />
      <Result
        {...props}
        filter={filter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        categories={categories?.value}
        setFilter={handleSetFilter}
        arrangement={arrangement}
        sortBy={sortBy}
        setSortBy={setSortBy}
        contentRef={contentRef}
      />
    </>
  );
};
