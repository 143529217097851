import {
  AbstractLiveSession,
  AbstractSession,
  isSessionFinished,
  isSessionJoinable,
  isSessionRunning,
  translateProgramsIntoFlatSessionList,
} from "@kvix/shared";
import {
  differenceInSeconds,
  formatDistance,
  formatDistanceStrict,
  parseJSON,
} from "date-fns";
import { sv } from "date-fns/locale";
import i18next from "i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { KvixInstructorContext } from "../contexts/instructor";
import {
  isBasicInstructor,
  isInstructor,
  KvixUserContext,
} from "../contexts/user";

type Session = AbstractLiveSession | AbstractSession;

const getPrettyTimeUntilSessionStart = (session: Session) => {
  const now = new Date();
  const then = parseJSON(session.start);
  const currentLanguage = i18next.language;

  if (differenceInSeconds(then, now) < 60) {
    return formatDistanceStrict(then, now, {
      locale: currentLanguage === "sv-SE" && sv,
    });
  }

  return formatDistance(then, now, {
    includeSeconds: true,
    locale: currentLanguage === "sv-SE" && sv,
  });
};

export const usePrettyTimeUntilSessionStart = (session: Session) => {
  const [text, setText] = useState(getPrettyTimeUntilSessionStart(session));

  useEffect(() => {
    const interval = window.window.setInterval(() => {
      const current = getPrettyTimeUntilSessionStart(session);

      if (text !== current) {
        setText(current);
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [text, session]);

  return text;
};

export const useInstructorSessions = (): Session[] => {
  const userContext = useContext(KvixUserContext);
  const instructorContext = useContext(KvixInstructorContext);

  const sessions = useMemo(() => {
    return translateProgramsIntoFlatSessionList(instructorContext.programs);
  }, [instructorContext.programs]);

  const { user } = userContext;

  if (!isInstructor(user)) {
    return [];
  }

  return sessions;
};

export const useStreamerSessions = (): AbstractSession[] => {
  const { user } = useContext(KvixUserContext);
  const { streamerSessions } = useContext(KvixInstructorContext);

  if (!isBasicInstructor(user)) {
    return [];
  }

  return streamerSessions;
};

export const useIsSessionJoinable = (session: Session) => {
  const [joinable, setJoinable] = useState(isSessionJoinable(session));

  useEffect(() => {
    const interval = window.setInterval(() => {
      const current = isSessionJoinable(session);

      if (joinable !== current) {
        setJoinable(current);
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [joinable, session]);

  return joinable;
};

export const useIsSessionRunning = (session: Session) => {
  const [running, setRunning] = useState(isSessionRunning(session));

  useEffect(() => {
    const interval = window.setInterval(() => {
      const current = isSessionRunning(session);

      if (running !== current) {
        setRunning(current);
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [running, session]);

  return running;
};

export const useIsSessionFinished = (session: Session) => {
  const [finished, setFinished] = useState(isSessionFinished(session));

  useEffect(() => {
    const interval = window.setInterval(() => {
      const current = isSessionFinished(session);

      if (finished !== current) {
        setFinished(current);
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [finished, session]);

  return finished;
};
