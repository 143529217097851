import { Button } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
const useLogotypeStyles = makeStyles<any>((theme) => ({
  root: {
    transition: "transform 300ms ease",
    transformOrigin: "left",
    flexGrow: 1,

    height: (props: any) => props.height || 32,

    [theme.breakpoints.down("xs")]: {
      height: (props: any) => (props.height || 32) * 0.8125,
    },
  },
}));

const useDuSkulleBaraVågaTaBortDenna = () => {
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    if (clicks > 0) {
      const timer = window.setInterval(
        () => setClicks((clicks) => clicks - 1),
        300
      );

      if (clicks > 10) {
        setClicks(0);

        try {
          const src =
            "https://rawgit.com/socialwolves/easter-egg/master/nyan/index.html";

          const style =
            "border:0;width:100vw;height:100vh;position:fixed;top:0;z-index:1337";

          (document.body
            .innerHTML as any) += `<iframe src="${src}" style="${style}" allowtransparency="true"></iframe>`;

          const root = document.getElementById("root");

          root?.animate?.(
            [{ transform: "scale(1.1)" }, { transform: "scale(1.2)" }],
            {
              duration: 300,
              iterations: Infinity,
              direction: "alternate",
              fill: "both",
              easing: "ease-in-out",
              composite: "add",
            }
          );

          root?.animate?.(
            [{ transform: "rotate(-2deg)" }, { transform: "rotate(2deg)" }],
            {
              duration: 1000,
              iterations: Infinity,
              direction: "alternate",
              fill: "both",
              easing: "ease-in-out",
              composite: "add",
            }
          );

          root?.animate?.(
            [
              { filter: "hue-rotate(0deg) contrast(1.4)" },
              { filter: "hue-rotate(360deg) contrast(1.4)" },
            ],
            {
              duration: 5000,
              iterations: Infinity,
              easing: "linear",
              fill: "both",
            }
          );
        } catch {}
      }

      return () => {
        window.clearInterval(timer);
      };
    }
  }, [clicks]);

  return () => setClicks((clicks) => clicks + 1);
};

interface Props {
  linkToHomepage?: boolean;
  height?: number;
}
export const HeaderLogotype: React.FC<Props> = (props) => {
  const classes = useLogotypeStyles(props);
  const history = useHistory();

  const increment = useDuSkulleBaraVågaTaBortDenna();
  const goHome = () => {
    history.push("/");
  };

  return (
    <Button
      variant="text"
      color="white"
      onClick={props.linkToHomepage ? goHome : increment}
    >
      <img
        alt="logotype"
        className={classes.root}
        src={"/logotype_1/kvix-pos.svg"}
      />
    </Button>
  );
};
