import { Exercise } from "../../Exercise";
import { getData, RawMedia } from "../adapter";

interface RawExercise {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    title: string;
    exerciseposts: RawExerciseposts;
  };
}

interface RawExerciseposts {
  data: [
    {
      id: number;
      attributes: {
        caption: string;
        content: string;
        createdAt: Date;
        updatedAt: Date;
        image: RawMedia;
        ingress: string;
        priority: number;
        slug: string;
        title: string;
        video: RawMedia;
      };
    }
  ];
}
export const ExerciseAdapter = async (
  rawJSON: RawExercise
): Promise<Exercise> => {
  const {
    id,
    attributes: { createdAt, updatedAt, exerciseposts, title },
  } = await getData<RawExercise>(rawJSON);

  const posts = exerciseposts.data.map(
    ({ id, attributes: { image, video, createdAt, updatedAt, ...rest } }) => ({
      ...rest,
      id: id,
      image: image.data.attributes,
      video: video.data.attributes,
      created_at: createdAt,
      updated_at: updatedAt,
    })
  );
  return {
    created_at: createdAt,
    exerciseposts: posts,
    id: id,
    title: title,
    updated_at: updatedAt,
  };
};
