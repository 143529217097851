import { AbstractCategory, ArticleType } from "@kvix/shared";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { DisplayArrangement } from "../..";
import { CuratedList } from "./CuratedList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 32,
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
    },
  },
  rowWrapper: {
    marginBottom: 32,

    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },

    "&.last": {
      marginBottom: 0,
    },
  },
}));

interface Props {
  filterString: string;
  categories: AbstractCategory[];
  currentCategory: AbstractCategory;
  filterByChip: (key: string, value: any) => void;
  type: ArticleType;
  arrangement: DisplayArrangement;
  toggleFavourite?: (options: {
    programId?: number;
    sessionId?: number;
  }) => void;
}

export const ResultRowContainer: React.FC<Props> = (props) => {
  const { type } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {type === ArticleType.PROGRAM && <CuratedList.Programs {...props} />}

      {type === ArticleType.ANYTIME && <CuratedList.Anytime {...props} />}

      {type === ArticleType.SESSION && <CuratedList.Sessions {...props} />}

      {type === ArticleType.INSTRUCTOR && (
        <CuratedList.Instructors {...props} />
      )}
    </div>
  );
};
