import { AbstractSession } from "@kvix/shared";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { PlayArrow } from "@material-ui/icons";
import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { useIsMobile } from "../../../../../../hooks/isMobile";
import { FullscreenArea } from "../../../partials/Fullscreen";
import { useVideoJs } from "./useVideoJs";
import { VideoJSToolbar } from "./VideoJSToolbar";

interface Props {
  onReady?: (player: videojs.Player) => void;

  session: AbstractSession;
  onEndedCallback?: () => void;
}

const useStyles = makeStyles((theme) => ({
  frame: {
    "& .video-js": {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      "& .vjs-tech": {
        width: "100vw",
        height: "100vh",
        maxWidth: "100vw",
        maxHeight: "100vh",
        objectFit: "contain",
      },
    },
  },
  frameMobile: {
    height: "inherit",
    "& .video-js": {
      position: "relative !important",
      inset: 0,
      width: "100vw",
      height: "auto",
      maxWidth: "100vw",
      maxHeight: "100vh",
      "& .vjs-big-play-button": {
        display: "none",
      },
      "& .vjs-tech": {
        position: "relative !important",
        width: "100vw",
        maxWidth: "100vw",
        maxHeight: "100%",
        objectFit: "contain",
      },
    },
  },
  frameAnytime: {
    display: "flex",
    justifyContent: "center",
    "& .video-js": {
      position: "relative !important",
      width: "100%",
      height: "auto",
      cursor: "pointer",

      "& .vjs-big-play-button": {
        display: "none",
      },
      "& .vjs-tech": {
        position: "relative !important",
        width: "100%",
        height: "100%",
      },
      "& .vjs-control-text": {
        display: "none",
      },
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "rgba(0, 0, 0, 0.5)",
    transition: "opacity 0.5s",
    cursor: "pointer",
  },
  playButton: {
    position: "relative",
    top: "calc(50% - 50px)",
    left: "calc(50% - 50px)",
    width: "100px",
    height: "100px",
    color: "rgba(255, 255, 255, 1)",
    zIndex: 1,

    "& .MuiSvgIcon-root": {
      height: "100%",
      width: "100%",
    },
  },
}));

export const VideoJS: FC<Props> = (props) => {
  const { session, onEndedCallback } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const player = useVideoJs(playerRef.current, session, onEndedCallback);

  const frameClass = useMemo(() => {
    if (!player.isVod) {
      return isMobile ? classes.frameMobile : classes.frame;
    }
    return classes.frameAnytime;
  }, [player, isMobile, classes]);

  const handlePlayButton = useCallback(() => {
    if (!player.isVod) {
      player.onSeek();
    }
    player.play();
  }, [player]);

  const handleHover = useCallback(() => {
    if (!showToolbar) {
      setShowToolbar(true);
      setTimeout(() => {
        setShowToolbar(false);
      }, 2000);
    }
  }, [showToolbar]);

  const handleClick = useCallback(() => {
    if (player.isVod) {
      player.isPlaying ? player.pause() : player.play();
    }
  }, [player]);

  if (
    !session ||
    (!session.hlsUrl && !session.mp4Url && !session.wowzaStream)
  ) {
    return null;
  }

  return (
    <FullscreenArea>
      {(isFullscreen) => (
        <Grid
          className={frameClass}
          onMouseMove={() => handleHover()}
          container
          item
        >
          <Grid
            item
            data-vjs-player
            ref={videoRef}
            onClick={() => handleClick()}
          >
            <video className="video-js" playsInline ref={playerRef} />
            {!player.isPlaying ? (
              <Grid item className={classes.overlay} onClick={handlePlayButton}>
                <Button className={classes.playButton}>
                  <PlayArrow />
                </Button>
              </Grid>
            ) : null}
          </Grid>

          {!/kvixapp/.test(navigator.userAgent) && !isMobile ? (
            <Grid container item style={{ width: "inherited" }}>
              <VideoJSToolbar
                onPlay={() => player.play()}
                onPause={() => player.pause()}
                isPlaying={player.isPlaying}
                onVolumeChange={(volume) => player.setVolume(volume)}
                visible={showToolbar}
                duration={player.duration}
                currentTime={player.currentTime}
                setCurrentTime={player.setPlayerTime}
                sliderValue={[
                  0,
                  player.scrubberValue,
                  player.bufferScrubberValue,
                ]}
                setSliderValue={player.setScrubberValue}
                isLive={!player.isVod}
                onChromeCastClick={player.handleChromeCastClick}
                onLiveClick={player.onSeek}
              />
            </Grid>
          ) : null}
          {props.children}
        </Grid>
      )}
    </FullscreenArea>
  );
};
