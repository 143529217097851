import { AbstractLiveSession } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, makeStyles, Modal, Theme } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ScheduleStream } from "./ScheduleStream";
import { StreamingInfoModal } from "./StreamingInfoModal";
import { SuccessfullyScheduledStream } from "./SuccessfullyScheduledStream";

const useStyles = makeStyles<Theme, { small: boolean }>((theme) => ({
  scheduleStreamModal: {
    maxHeight: "100vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.background.default,
    overflowY: "scroll",
    [theme.breakpoints.up("sm")]: {
      width: (props: any) => (props.small ? "370px" : "540px"),
      height: (props: any) => (props.small ? "500px" : "768px"),
      padding: (props: any) => (props.small ? "50px 20px 0" : "50px 45px 0"),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      padding: "50px 15px 0",
    },
  },
  scheduleHeader: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    zIndex: 5,
    position: "sticky",
    margin: "-50px 0 15px",
    paddingTop: (props) => (props.small ? "15px" : "45px"),
    paddingBottom: (props) => (props.small ? "10px" : "20px"),
    top: "-50px",
  },
  closeButton: {
    fontWeight: 600,
    cursor: "pointer",
    padding: "5px",
    margin: "-5px",
  },
}));

type ScheduleStreamModalHeaderProps = {
  onClose: () => void;
  onInfo: () => void;
  small: boolean;
  edit: boolean;
};
const ScheduleStreamModalHeader = ({
  onClose,
  onInfo,
  small,
  edit,
}: ScheduleStreamModalHeaderProps) => {
  const classes = useStyles({ small });
  const { t, ready } = useTranslation("wellstarStreams");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.scheduleHeader}>
      {small ? (
        <div onClick={onClose} className={classes.closeButton}>
          <Close />
        </div>
      ) : (
        <>
          <div onClick={onClose} className={classes.closeButton}>
            {t("closeStreamModal")}
          </div>
          <Text variant="h5">
            {edit ? t("editStream") : t("scheduleStream")}
          </Text>
          <div onClick={onInfo} style={{ cursor: "pointer" }}>
            <InfoOutlined />
          </div>
        </>
      )}
    </Box>
  );
};

type ScheduleStreamModalProps = {
  open: boolean;
  onClose: () => void;
  streamToEdit?: AbstractLiveSession;
};
export const ScheduleStreamModal = ({
  open,
  onClose,
  streamToEdit,
}: ScheduleStreamModalProps) => {
  const [showStreamingInfo, setShowStreamingInfo] = useState(false);
  const [scheduledStream, setScheduledStream] =
    useState<AbstractLiveSession>(null);
  const small = !!scheduledStream;
  const classes = useStyles({ small });

  const close = () => {
    setScheduledStream(null);
    setShowStreamingInfo(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={close}>
      <Box className={classes.scheduleStreamModal}>
        {showStreamingInfo ? (
          <StreamingInfoModal onClose={() => setShowStreamingInfo(false)} />
        ) : (
          <>
            <ScheduleStreamModalHeader
              onClose={close}
              onInfo={() => setShowStreamingInfo(true)}
              small={small}
              edit={!!streamToEdit}
            />
            {!scheduledStream ? (
              <ScheduleStream
                onScheduledStream={setScheduledStream}
                streamToEdit={streamToEdit}
              />
            ) : (
              <SuccessfullyScheduledStream stream={scheduledStream} />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};
