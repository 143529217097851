import { Text } from "@kvix/ui";
import { Box, Container, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Footer } from "../../../cms/models/Footer";
import { HeaderLogotype } from "../../Header/Logotype";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  copyRight: {
    display: "flex",
    justifyContent: "center",
    margin: "24px 0",

    "& > *:not(:last-child)": {
      marginRight: 6,
    },

    [theme.breakpoints.down("sm")]: {
      margin: "14px 0",
      flexDirection: "column",

      "& > *:not(:last-child)": {
        marginRight: 0,
      },
    },
  },
}));

interface Props {
  footer: Footer;
}

const LogoCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding: 7px;
  background: #c5c6c7;
  transition: all 100ms ease-in-out;

  &:hover {
    transition: all 100ms ease-in-out;
    background: #fff;
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

export const CampaignLayout: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("footer");

  if (!ready) {
    return null;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <HeaderLogotype height={56} />
      <div className={classes.copyRight}>
        <Text variant="subtitle2">{t("copyright")}</Text>
        <Text variant="subtitle2">
          Kaserngatan 2 90347 Umeå Orgnr: 559198-1146
        </Text>
      </div>
      <Box display="flex" justifyContent="center">
        <Box mr={2}>
          <a
            href="https://www.facebook.com/kvixlive"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoCircle>
              <Logo alt="Facebook" src="/assets/brands/facebook-f-brands.svg" />
            </LogoCircle>
          </a>
        </Box>
        <Box>
          <a
            href="https://www.instagram.com/kvixlive/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoCircle>
              <Logo
                alt={t("social.instagram")}
                src="/assets/brands/instagram-brands.svg"
              />
            </LogoCircle>
          </a>
        </Box>
      </Box>
    </Container>
  );
};
