import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Container, Text } from "@kvix/ui";
import { Box, Chip, CircularProgress, Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Stripe } from "stripe";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { CheckoutOptions, PaymentContext } from "../../pages/Payment/context";
import { CheckoutStepProps } from "../types"
import BankCardsPlusKlarna, { BankCards } from "../components/BankCards"
import signupImg from "../../partials/assets/premium.png"
import klarnaImg from "../../partials/assets/klarnaXS.svg"

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    // height: "712px",
    height: "100%",
    width: "375px",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: { width: "100%", height: "100%" },
    "@media (max-width:360px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  topTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginBottom: theme.spacing(2),
  },
  plansButtonBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: theme.spacing(4),
    overflowX: 'initial',
  },
  fontWeightMedium: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: 500,
    backgoundColor: "yellow",
  },
  headerText: {
    fontSize: "20px",
    lineHeight: "32px",
    marginBottom: "4px",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  foot: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  image: {
    maxWidth: "72px",
  },
  mainWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {},
  },
  productCardsGroupWrapper: {
    width: "100%",
    flexGrow: 1,
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    position: "relative",
    boxSizing: "border-box",
    marginBottom: "8px",
    [theme.breakpoints.down("xs")]: {},
  },
  productCardWrapper: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "relative",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {},
  },

  footWrapper: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
    boxSizing: "border-box",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {},
  },

  priceWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "baseline",
    width: "100%",
    height: "auto",
    gap: "12px",
    [theme.breakpoints.down("xs")]: {},
  },
  descriptionWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "auto",
    gap: "4px",
    [theme.breakpoints.down("xs")]: {},
  },
  descriptionTwoWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "22px",
    gap: "4px",
    [theme.breakpoints.down("xs")]: {},
  },
  discountedPriceTitle: {
    fontSize: "20px",
    lineHeight: "20px",
    fontWeight: 600,
    color: "#a1a1a1",
  },
  standardPriceTitle: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 600,
    textDecoration: "line-through",
    color: "#575E66",
  },
  descriptionH5: {
    fontWeight: 500,
    letterSpacing: 0,
    paddingTop: "1px",
  },
  chipWrapper: {
    position: "absolute",
    top: "8px",
    right: "8px",
    borderRadius: "12px",
    height: "28px",
    fontWeight: 600,
    lineHeight: "14px",
    boxSizing: "border-box",
    // backgroundColor: "#2b2b2b",
    // color: '#fff',
    [theme.breakpoints.down("xs")]: {},
    '& span': {
      overflow: 'visible',
    }
  },
  klarnaLogo: {
    width: "55px",
    height: "22px",
  },
}))

export const CheckoutStep = ({ headerText, plan, checkoutParams, checkoutSource }: CheckoutStepProps) => {
  const classes = useStyles()
  const {
    availablePlans,
    availablePrices,
    checkoutProcessing,
    actions: { checkout },
  } = useContext(PaymentContext);

  const { t, ready, i18n } = useTranslation([
    "account",
    "payment",
    "signUp",
    "signUpCampaign",
    "banner",
    "preCheckoutStep",
  ])

  const [checkoutPlan, setPlan] = useState<any | undefined>(null);
  const [selectedBox, setSelectedBox] = useState<null | string>();

  const handleCheckout = async (checkoutOptions: CheckoutOptions) => {
    await checkout(checkoutOptions)
  }

  const mapBadgeProps = useCallback(
    (plan: any, getDiscount?: boolean) => {
      const price = plan['type'] !== 'one_time' ? plan.amount / 100 : plan.unit_amount / 100;
      const discountedPrice = getDiscount ? 
        Math.trunc(price - (checkoutParams.coupon?.amount_off / 100 || price * (checkoutParams.coupon?.percent_off * 0.01))) 
        : 
        0;
      const decimalPrice = price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const currency = plan.currency.toUpperCase();

      if (plan.interval) {
        switch (plan.interval) {
          case "month": {
            return {
              title: t("account:account.planDetails.planMapping.month"),
              text: `${decimalPrice} kr / ${t(
                "signUpCampaign:interval.month"
              )}`,
              usp:
                currency === "SEK"
                  ? t("banner:getPremium.onlyAmountPerDay", {
                      amount: Math.ceil(price / 30),
                      currency: plan.currency,
                    })
                  : t("payment:plan.perks.unlimitedAccess"),
              discountedPrice: `${discountedPrice} kr `
            }
          }
          case "year": {
            return {
              title: t("account:account.planDetails.planMapping.year"),
              text: `${decimalPrice} kr / ${t(
                "signUpCampaign:interval.year"
              )}`,
              usp:
                currency === "SEK"
                  ? `${t("banner:campaignIndicator.save")} ${(price / 10) * 2} ${
                      plan.currency
                    }`
                  : t("payment:plan.perks.unlimitedAccess"),
              discountedPrice: `${discountedPrice} kr `
            }
          }
        }
      }

      return { text: `${decimalPrice} kr `, discountedPrice: `${discountedPrice} kr `};
    },
    [checkoutPlan, checkoutParams]
  );

  useEffect(() => {
    setPlan(plan);

    if (plan) {
      setSelectedBox(plan.id);
    }
  }, [plan]);

  if (!ready) {
    return null
  }

  return (
    <PurchaseFlowConsumer>
      {({ context, actions }) => (
        <Container
          className={classes.ContainerPadding}
          scrollableY
          disableScrollbar
        >
          <img src={signupImg} className={classes.image} alt={"hduwahdwa"} />
          { checkoutSource !== 'wp' &&
             <Text variant="h5" className={classes.headerText} style={{ textTransform: "capitalize" }}>{headerText}</Text>
          }
          { plan && i18n.exists(`preCheckoutStep:${checkoutParams.lookupKey}.header.${checkoutParams.coupon?.id}`) && checkoutParams.coupon?.id &&
            <>
              <Text variant="h5" className={classes.headerText}>
                {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.header.${checkoutParams.coupon?.id}`).split('*')[0]}&nbsp;
              </Text>
              <Text variant="h5" className={classes.headerText}>
                {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.header.${checkoutParams.coupon?.id}`).split('*')[1]}
              </Text>
            </>
          }
          { plan && !checkoutParams.coupon?.id &&
            <>
              <Text variant="h5" className={classes.headerText}>
                {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.header.default`).split('*')[0]}&nbsp;
              </Text>
              <Text variant="h5" className={classes.headerText}>
                {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.header.default`).split('*')[1]}
              </Text>
            </>
          }
          <Grid container className={classes.topTextBlock}>
            { checkoutSource !== 'wp' &&
              <Text variant="h5" className={classes.fontWeightMedium}>
                {t("payment:trialInformation")}
              </Text>
            }
            { plan && i18n.exists(`preCheckoutStep:${checkoutParams.lookupKey}.header.${checkoutParams.coupon?.id}`) && checkoutParams.coupon?.id &&
              <>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.${checkoutParams.coupon?.id}`).split('*')[0]}&nbsp;
                </Text>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.${checkoutParams.coupon?.id}`).split('*')[1]}
                </Text>
              </>
            }
            { plan && !checkoutParams.coupon?.id &&
              <>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.default`).split('*')[0]}&nbsp;
                </Text>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.default`).split('*')[1]}
                </Text>
              </>
            }
          </Grid>
          <Container className={classes.plansButtonBlock}>
            {/* <RadioGroup
              defaultValue="monthly"
              onChange={setPlan}
              options={availablePlans
                .filter((p: any) => p.currency !== "gbp" && !p.metadata.trial_period_days && p.amount !== 0)
                .map((plan) => ({
                  value: plan.id,
                  component: (
                    <Badge variant="secondary" {...mapBadgeProps(plan)} />
                  ),
                  checkedComponent: (
                    <Badge variant="primary" {...mapBadgeProps(plan)} />
                  ),
                }))}
            /> */}
             <Box className={classes.productCardsGroupWrapper}>
              {(plan ? [plan] : [...availablePlans, ...availablePrices].filter(p => p.metadata.common)).map((plan) => (
                <Box
                  className={classes.productCardWrapper}
                  key={plan.id}
                  onClick={() => {
                    setSelectedBox(plan.id);
                    setPlan({ id: plan.id, type: plan['type'] })}
                  }
                  style={{
                    transform:
                      selectedBox === plan.id ? "scale(1.03)" : "scale(1)",
                    transition: "transform 0.3s",
                    cursor: "pointer",
                    border:
                      selectedBox === plan.id
                        ? "2px solid #fa255e"
                        : "2px solid #E5E5E5",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ textTransform: "uppercase", textAlign: "left" }}
                  >
                    {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.title.default`)}
                  </Typography>
                  <Box className={classes.priceWrapper}>
                    <Typography
                      variant="h4"
                      className={classes.discountedPriceTitle}
                      color="secondary"
                    >
                      {checkoutSource === 'wp' && checkoutParams.coupon ? mapBadgeProps(plan, true).discountedPrice || '' : mapBadgeProps(plan).text}
                    </Typography>
                    <Typography variant="h4" className={classes.standardPriceTitle}>
                      {checkoutSource === 'wp' && checkoutParams.coupon ? mapBadgeProps(plan).text : plan.metadata.standardPrice ? plan.metadata.standardPrice : ''}
                    </Typography>
                  </Box>
                  <Box className={classes.descriptionWrapper}>
                    <Typography variant="h5" className={classes.descriptionH5}>
                      { i18n.exists(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.descriptionOne.default`) ?
                        t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.descriptionOne.default`)
                        :
                        ''
                      }
                    </Typography>
                    <Box className={classes.descriptionTwoWrapper}>
                      <Typography variant="h5" className={classes.descriptionH5}>
                        {t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.descriptionTwo.default`)}
                      </Typography>
                      {plan['type'] === 'one_time' && (
                        <img
                          className={classes.klarnaLogo}
                          src={klarnaImg}
                          alt="Klarna Logo"
                        />
                      )}
                    </Box>
                  </Box>
                  <Chip
                    className={classes.chipWrapper}
                    label={t(`preCheckoutStep:${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.chip.default`)}
                  />
                </Box>
              ))}
            </Box>
          </Container>
          <Grid container className={classes.footWrapper}>
            <Button
                icon={
                  checkoutProcessing && (
                    <CircularProgress size={18} color="inherit" />
                  )
                }
                disabled={checkoutPlan == undefined}
                onClick={() => {
                  if (plan) {
                    handleCheckout(checkoutParams);
                  } else {
                    let checkoutParams: any = {
                      referrer: `${window.location.origin}${window.location.search}`,
                      beforeRedirect: () => actions.closePrompt(),
                    };
    
                    checkoutPlan.type === 'one_time' ? checkoutParams.priceId = checkoutPlan.id : checkoutParams.planId = checkoutPlan.id;
                    handleCheckout(checkoutParams);
                  }
                }}
              >
                {t("signUp:registerBtn")}
              </Button>
            { checkoutPlan && checkoutPlan.type === 'one_time' ? <BankCardsPlusKlarna /> : <BankCards />}
          </Grid>
        </Container>
      )}
    </PurchaseFlowConsumer>
  )
}
