import { AbstractInstructorProfile } from "@kvix/shared";
import { KvixIcon, KvixThemeVariantContext } from "@kvix/ui";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import {
  Facebook,
  HomeSharp,
  Instagram,
  LinkedIn,
  YouTube,
} from "@material-ui/icons";
import React, { useContext } from "react";
import { getLinkUrl } from "../../../utils/url";

const useStyles = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

type SocialMediaProfile =
  | "homepage"
  | "facebook"
  | "youtube"
  | "instagram"
  | "tiktok"
  | "linkedin";

interface SocialMediaProfileProps {
  instructorProfile: AbstractInstructorProfile;
}

export const hasSocialMediaProfile = (
  instructorProfile: AbstractInstructorProfile
) => {
  const {
    homepageUrl,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    linkedinUrl,
    ticktockUrl,
  } = instructorProfile;
  const urls = {
    homepage: homepageUrl,
    facebook: facebookUrl,
    instagram: instagramUrl,
    youtube: youtubeUrl,
    linkedin: linkedinUrl,
    tiktok: ticktockUrl,
  };
  let hasProfile = false;
  Object.keys(urls).map((key) => {
    if (urls[key]) {
      hasProfile = true;
    }
  });
  return hasProfile;
};

export const SocialMediaProfiles: React.FC<SocialMediaProfileProps> = (
  props
) => {
  const classes = useStyles(props);
  const {
    homepageUrl,
    facebookUrl,
    instagramUrl,
    youtubeUrl,
    linkedinUrl,
    ticktockUrl,
  } = props.instructorProfile;
  const urls = {
    homepage: homepageUrl,
    facebook: facebookUrl,
    instagram: instagramUrl,
    youtube: youtubeUrl,
    linkedin: linkedinUrl,
    tiktok: ticktockUrl,
  };
  const { variant } = useContext(KvixThemeVariantContext);
  const isDarkMode = variant === "dark";
  const theme = useTheme();

  const getIcon = (type: SocialMediaProfile) => {
    switch (type) {
      case "homepage":
        return <HomeSharp htmlColor={theme.palette.background.default} />;
      case "facebook":
        return <Facebook htmlColor={theme.palette.background.default} />;
      case "instagram":
        return <Instagram htmlColor={theme.palette.background.default} />;
      case "youtube":
        return <YouTube htmlColor={theme.palette.background.default} />;
      case "linkedin":
        return <LinkedIn htmlColor={theme.palette.background.default} />;
      case "tiktok":
        return <KvixIcon.Tiktok type={isDarkMode ? "pos" : "neg"} />;
    }
  };

  return (
    <Box display="flex" flexWrap="wrap" paddingBottom={6}>
      {Object.keys(urls).map(
        (key, index) =>
          urls[key] && (
            <a href={getLinkUrl(urls[key])}>
              <Box
                key={index}
                bgcolor={theme.palette.text.primary}
                borderRadius="50%"
                height={48}
                width={48}
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginRight={2}
                marginTop={2}
                className={classes.hover}
              >
                {getIcon(key as SocialMediaProfile)}
              </Box>
            </a>
          )
      )}
    </Box>
  );
};
