import React, { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { KvixUserContext } from "../../../contexts/user";
import { Flow } from "../components/Flow";
import { AccessLevel, useUserAccessLevel } from "../hooks/useUserAccessLevel";
import { CampaignStep } from "../steps/Campaign";
import { CampaignFlowProps } from "../types";

export const CampaignFlow = ({ campaignContext }: CampaignFlowProps) => {
  const { user } = useContext(KvixUserContext);
  const accessLevel = useUserAccessLevel(user);
  const location = useLocation();

  const closeAndRedirect = (actions: any) => {
    actions.closePrompt();
    return (
      <Redirect
        to={{
          pathname: `${location.pathname}?gotoCheckout=true`,
          state: {
            from: `${location.pathname}?signUpRedirect=true`,
          },
        }}
      />
    );
  };

  return (
    <PurchaseFlowConsumer>
      {({ actions }) => (
        <Flow>
          {
            <>
              {accessLevel === AccessLevel.FULL || !user ? (
                <CampaignStep campaignContext={campaignContext} />
              ) : (
                closeAndRedirect(actions)
              )}
            </>
          }
        </Flow>
      )}
    </PurchaseFlowConsumer>
  );
};
