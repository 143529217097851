export enum SwishValidationError {
  FF08,
  RP03,
  BE18,
  RP01,
  PA02,
  AM02,
  AM03,
  AM06,
  RP02,
  RP06,
  ACMT03,
  ACMT01,
  ACMT07,
  VR01,
  VR02,
  DEFAULT,
}
