import {
  AbstractLiveSession,
  AbstractSession,
  CastContentType,
  CastMessage,
  CastMessageType,
  HttpMethods,
  Metadata,
} from "@kvix/shared";
import { useCallback, useEffect, useState } from "react";

const API_URL = `${window.location.protocol}//${
  process.env.NODE_ENV === "development"
    ? `${window.location.hostname}:8080`
    : window.location.host
}/api`;

const fetchAccessToken = async () => {
  try {
    const response = await fetch(`/auth/accessToken`, {
      method: HttpMethods.GET,
    });
    const result = (await response.json()) as { accessToken?: string };

    return result.accessToken;
  } catch {
    return null;
  }
};

const useIsCastAvaiable = (contentType: string) => {
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    let retries = 0;
    const limit = 5 * 2;

    const check = () => {
      if (window.cast?.framework?.CastContext?.getInstance()) {
        console.log("Is available");
        setIsAvailable(true);
      } else if (retries++ < limit) {
        setTimeout(check, 500);
      }
    };

    if (contentType === "anytime") {
      window.__onGCastApiAvailable = (isAvailable: boolean) => {
        setIsAvailable(isAvailable);
      };
    } else {
      check();
    }
  }, [contentType]);

  return isAvailable;
};

export const useKvixCast = (
  contentType: CastContentType,
  session: AbstractSession | AbstractLiveSession,
  metadata: Metadata
) => {
  const [isCasting, setIsCasting] = useState(false);
  const isAvailable = useIsCastAvaiable(contentType);

  const sendMessage = useCallback(
    async (castInstance: cast.framework.CastContext, type: CastMessageType) => {
      const accessToken = await fetchAccessToken();
      console.log("Seinding casting");
      if (!accessToken) {
        return;
      }

      const message: CastMessage = {
        type,
        contentType,
        sessionId: session.id,
        metadata,
        apiUrl: API_URL,
        accessToken,
        wowzaStreamId: session.wowzaStreamId,
      };

      castInstance
        .getCurrentSession()
        .sendMessage("urn:x-cast:se.kvix.cast", message);
    },
    [contentType, metadata, session.id]
  );

  useEffect(() => {
    if (!isAvailable) {
      return;
    }
    const castInstance = window.cast?.framework?.CastContext?.getInstance();

    if (castInstance) {
      let retries = 0;
      const timer = window.setInterval(() => {
        if (
          castInstance.getCastState() === cast.framework.CastState.CONNECTED
        ) {
          setIsCasting(true);
          sendMessage(castInstance, "init");
          window.clearInterval(timer);
        } else if (++retries >= 5) {
          window.clearInterval(timer);
        }
      }, 500);
    }

    const onCastChanged = (e: cast.framework.CastStateEventData) => {
      if (e.castState === cast.framework.CastState.CONNECTED) {
        setIsCasting(true);
        sendMessage(castInstance, "init");
      } else if (e.castState === cast.framework.CastState.NOT_CONNECTED) {
        setIsCasting(false);
      }
    };
    castInstance.addEventListener(
      cast.framework.CastContextEventType.CAST_STATE_CHANGED,
      onCastChanged
    );

    return () => {
      castInstance &&
        castInstance.removeEventListener(
          cast.framework.CastContextEventType.CAST_STATE_CHANGED,
          onCastChanged
        );
    };
  }, [isAvailable, sendMessage]);

  return isCasting;
};
