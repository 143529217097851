import { Button, Text } from "@kvix/ui";
import { Box, makeStyles, TextField } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import { dataURIToBlob } from "../../Profile/pages/Account/sections/basic/AvatarPicker";

const useStyles = makeStyles((theme) => ({
  editProfileModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px",
    height: "768px",
    padding: "50px 45px 0",
    background: theme.palette.background.default,
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      margin: "50px 20px 0",
    },
  },
  editProfileHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  profileInput: {
    width: "100%",
    marginBottom: "20px",
    "& p": {
      color: "#D44",
    },
  },
  profileImage: {
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  profileImageWrapper: {
    position: "relative",
  },
  changeImageIconWrapper: {
    height: "20px",
    width: "20px",
    background: theme.palette.primary.main,
    position: "absolute",
    top: "-3px",
    right: "-3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  changeImageIcon: {
    height: "20px",
    width: "20px",
    color: theme.palette.common.white,
  },
  profileImageRoot: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0 30px",
  },
  socialMediaHeader: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  formFields: {
    marginBottom: "30px",
  },
  errorText: {
    marginBottom: "30px",
    color: "red",
    fontWeight: 600,
  },
}));

const isValidURL = (url: string) => {
  if (!url) {
    return true;
  }
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z@\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(url);
};

type EditProfileModalHeaderProps = {
  onClose: () => void;
  onSave: () => void;
};
const EditProfileHeader = ({
  onClose,
  onSave,
}: EditProfileModalHeaderProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("streamerAdminAccount");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.editProfileHeader}>
      <Button lowercase variant="oval" onClick={onClose}>
        {t("cancel")}
      </Button>
      <Text variant="h5">{t("changeYourProfile")}</Text>
      <Button variant="oval" color="primary" lowercase onClick={onSave}>
        {t("save")}
      </Button>
    </Box>
  );
};

const instagramBaseString = "https://instagram.com/";
const facebookBaseString = "https://facebook.com/";
const youtubeBaseString = "https://youtube.com/channel/";
const tiktokBaseString = "https://tiktok.com/@";
const linkedinBaseString = "https://linkedin.com/";

const maxCustomNameLength = 40;
const maxTitleLength = 50;
const maxBioLength = 500;

const validCustomName = (custom: string) =>
  custom && custom.trim() && custom.trim().length <= maxCustomNameLength;
const validTitle = (title: string) =>
  title && title.trim() && title.trim().length <= maxTitleLength;
const validBio = (bio: string) =>
  bio && bio.trim() && bio.trim().length <= maxBioLength;

const emptyIfEqual = (val: string, base: string) => {
  return val === base ? "" : val;
};

const setPrefilled = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  base: string,
  set: React.Dispatch<React.SetStateAction<string>>
) => {
  if (!e.currentTarget.value) {
    set(base);
  } else if (!e.currentTarget.value.startsWith(base)) {
    return;
  } else {
    set(e.currentTarget.value);
  }
};

type EditProfileProps = {
  onClose: () => void;
};
export const EditProfile = ({ onClose }: EditProfileProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("streamerAdminAccount");
  const { user, actions } = useContext(KvixUserContext);
  const { instructorProfile } = user;

  const [custom, setCustom] = useState(user.name?.custom || user.name?.first);
  const [title, setTitle] = useState("");
  const [bio, setBio] = useState("");
  const [homepageUrl, setHomepageUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tiktokUrl, setTiktokUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [profileImgUri, setProfileImgUri] = useState<any>(user.imageUrl);
  const [imgData, setImgData] = useState<string | ArrayBuffer>(null);
  const [fileError, setFileError] = useState("");

  const [errorText, setErrorText] = useState("");

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files[0]) {
      return;
    }
    if (e.target.files[0]?.size > 5000000) {
      setFileError(t("profileImageSize", { maxFileSize: "5MB" }));
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
      setProfileImgUri(reader.result);
    });
    reader.readAsDataURL(e.target.files[0]);
    setFileError("");
  };

  useEffect(() => {
    if (instructorProfile) {
      setBio(instructorProfile.bio || "");
      setTitle(instructorProfile.title || "");
      setHomepageUrl(instructorProfile.homepageUrl || "");
      setInstagramUrl(instructorProfile.instagramUrl || instagramBaseString);
      setFacebookUrl(instructorProfile.facebookUrl || facebookBaseString);
      setYoutubeUrl(instructorProfile.youtubeUrl || youtubeBaseString);
      setTiktokUrl(instructorProfile.ticktockUrl || tiktokBaseString);
      setLinkedinUrl(instructorProfile.linkedinUrl || linkedinBaseString);
    }
  }, [instructorProfile]);

  useEffect(() => {
    setErrorText("");
  }, [
    custom,
    title,
    bio,
    homepageUrl,
    facebookUrl,
    youtubeUrl,
    instagramUrl,
    tiktokUrl,
    linkedinUrl,
  ]);

  const hasValidFields: () => boolean = useCallback(
    () =>
      validCustomName(custom) &&
      validTitle(title) &&
      validBio(bio) &&
      isValidURL(homepageUrl) &&
      isValidURL(facebookUrl) &&
      isValidURL(youtubeUrl) &&
      isValidURL(instagramUrl) &&
      isValidURL(tiktokUrl) &&
      isValidURL(linkedinUrl),
    [
      custom,
      title,
      bio,
      homepageUrl,
      facebookUrl,
      youtubeUrl,
      instagramUrl,
      tiktokUrl,
      linkedinUrl,
    ]
  );

  const handleOnSubmit = useCallback(async () => {
    if (!hasValidFields()) {
      setErrorText(t("editProfileGeneralError"));
    } else {
      const image =
        profileImgUri !== user.imageUrl ? dataURIToBlob(profileImgUri) : null;

      try {
        setErrorText("");
        await actions.updateProfileInfo({
          custom,
          bio,
          title,
          homepageUrl,
          facebookUrl: emptyIfEqual(facebookUrl, facebookBaseString),
          youtubeUrl: emptyIfEqual(youtubeUrl, youtubeBaseString),
          instagramUrl: emptyIfEqual(instagramUrl, instagramBaseString),
          tiktokUrl: emptyIfEqual(tiktokUrl, tiktokBaseString),
          linkedinUrl: emptyIfEqual(linkedinUrl, linkedinBaseString),
          image,
        });
        onClose();
      } catch (e) {
        setErrorText(t("updateProfileError"));
        console.error("Failed to update profile", e);
      }
    }
  }, [
    t,
    custom,
    title,
    bio,
    homepageUrl,
    facebookUrl,
    youtubeUrl,
    instagramUrl,
    tiktokUrl,
    linkedinUrl,
    hasValidFields,
    profileImgUri,
    actions,
    onClose,
    user.imageUrl,
  ]);

  const getInputProps = (validate: () => boolean, gray: boolean = false) => {
    return {
      style: {
        fontWeight: 500,
        border: validate() ? "" : "1px solid red",
        color: gray ? "#888" : null,
      },
    };
  };

  if (!ready) {
    return null;
  }

  return (
    <form>
      <EditProfileHeader onClose={onClose} onSave={handleOnSubmit} />
      <Box className={classes.profileImageRoot}>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="edit-profile-image"
          type="file"
          onChange={onChangeAvatar}
        />
        <label
          style={{ cursor: "pointer" }}
          htmlFor="edit-profile-image"
          className={classes.profileImageWrapper}
        >
          <img
            alt="Change profile"
            className={classes.profileImage}
            src={(imgData as string) || user.imageUrl}
          />
          <Box className={classes.changeImageIconWrapper}>
            <Add className={classes.changeImageIcon} />
          </Box>
        </label>
      </Box>
      {fileError && <Text className={classes.errorText}>{fileError}</Text>}
      {errorText && <Text className={classes.errorText}>{errorText}</Text>}
      <Box className={classes.formFields}>
        <TextField
          inputProps={{
            ...getInputProps(() => validCustomName(custom)),
            maxLength: maxCustomNameLength,
          }}
          className={classes.profileInput}
          label={t("streamerName")}
          helperText={validCustomName(custom) ? "" : t("obligatoryField")}
          value={custom}
          variant="filled"
          onChange={(e) => setCustom(e.currentTarget.value)}
        />
        <TextField
          inputProps={{
            ...getInputProps(() => validTitle(title)),
            maxLength: maxTitleLength,
          }}
          className={classes.profileInput}
          label={t("category")}
          helperText={validTitle(title) ? "" : t("obligatoryField")}
          value={title}
          variant="filled"
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
        <TextField
          inputProps={{
            ...getInputProps(() => validBio(bio)),
            maxLength: maxBioLength,
          }}
          className={classes.profileInput}
          label={"Bio"}
          helperText={validBio(bio) ? "" : t("obligatoryField")}
          value={bio}
          variant="filled"
          multiline
          InputLabelProps={{ shrink: true }}
          rows={8}
          rowsMax={10}
          onChange={(e) => setBio(e.currentTarget.value)}
        />

        <Text variant="h4" className={classes.socialMediaHeader}>
          {t("addSocialMedia")}
        </Text>
        <TextField
          inputProps={getInputProps(() => isValidURL(homepageUrl))}
          className={classes.profileInput}
          value={homepageUrl}
          helperText={isValidURL(homepageUrl) ? "" : t("invalidUrl")}
          label={t("yourHomePage")}
          variant="filled"
          onChange={(e) => setHomepageUrl(e.currentTarget.value)}
        />
        <TextField
          inputProps={getInputProps(
            () => isValidURL(instagramUrl),
            instagramUrl === instagramBaseString
          )}
          className={classes.profileInput}
          value={instagramUrl}
          helperText={isValidURL(instagramUrl) ? "" : t("invalidUrl")}
          label={"Instagram"}
          variant="filled"
          onChange={(e) =>
            setPrefilled(e, instagramBaseString, setInstagramUrl)
          }
        />
        <TextField
          inputProps={getInputProps(
            () => isValidURL(facebookUrl),
            facebookUrl === facebookBaseString
          )}
          className={classes.profileInput}
          value={facebookUrl}
          helperText={isValidURL(facebookUrl) ? "" : t("invalidUrl")}
          label={"Facebook"}
          variant="filled"
          onChange={(e) => setPrefilled(e, facebookBaseString, setFacebookUrl)}
        />
        <TextField
          inputProps={getInputProps(
            () => isValidURL(youtubeUrl),
            youtubeUrl === youtubeBaseString
          )}
          className={classes.profileInput}
          value={youtubeUrl}
          helperText={isValidURL(youtubeUrl) ? "" : t("invalidUrl")}
          label={"YouTube"}
          variant="filled"
          onChange={(e) => setPrefilled(e, youtubeBaseString, setYoutubeUrl)}
        />
        <TextField
          inputProps={getInputProps(
            () => isValidURL(tiktokUrl),
            tiktokUrl === tiktokBaseString
          )}
          className={classes.profileInput}
          value={tiktokUrl}
          helperText={isValidURL(tiktokUrl) ? "" : t("invalidUrl")}
          label={"TikTok"}
          variant="filled"
          onChange={(e) => setPrefilled(e, tiktokBaseString, setTiktokUrl)}
        />
        <TextField
          inputProps={getInputProps(
            () => isValidURL(linkedinUrl),
            linkedinUrl === linkedinBaseString
          )}
          className={classes.profileInput}
          value={linkedinUrl}
          helperText={isValidURL(linkedinUrl) ? "" : t("invalidUrl")}
          label={"LinkedIn"}
          variant="filled"
          onChange={(e) => setPrefilled(e, tiktokBaseString, setTiktokUrl)}
        />
      </Box>
    </form>
  );
};
