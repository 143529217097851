import {
  AbstractBaseUser,
  AbstractLiveSession,
  SnapshotOptions,
} from "@kvix/shared";
import { Socket } from "./socket";

class KvixSocket extends Socket {
  async signIn(user: AbstractBaseUser) {
    return this.emit("sign in", user);
  }

  async joinSessionAsInstructor(
    sessionId: number,
    profile: AbstractBaseUser,
    reconnected: boolean = false
  ) {
    return this.emit("join session as instructor", {
      sessionId,
      profile,
      reconnected,
    });
  }

  async joinSessionAsParticipant(sessionId: number, profile: AbstractBaseUser) {
    return this.emit("join session as participant", { sessionId, profile });
  }

  async joinSessionAsAdmin(sessionId: number, profile: AbstractBaseUser) {
    return this.emit("join session as admin", { sessionId, profile });
  }

  async leaveSession(): Promise<void> {
    return this.emit("leave session");
  }

  async joinChannel(channelNumber: number, profile: AbstractBaseUser) {
    return this.emit("join channel", { channelNumber, profile });
  }

  async leaveChannel(): Promise<void> {
    return this.emit("leave channel");
  }

  async streamCancelled(): Promise<void> {
    return this.emit("stream cancelled");
  }

  async streamStarting(): Promise<void> {
    return this.emit("stream starting");
  }

  async streamStarted(): Promise<void> {
    return this.emit("stream started");
  }

  async streamFinished(): Promise<void> {
    return this.emit("stream finished");
  }

  async sendChatMessage(message: string): Promise<void> {
    return this.emit("send chat message", message);
  }

  async registerSnapshotProducer(): Promise<void> {
    return this.emit("register snapshot producer");
  }

  async unregisterSnapshotProducer(): Promise<void> {
    return this.emit("unregister snapshot producer");
  }

  async sendSnapshot(snapshot: Blob): Promise<void> {
    return this.emit("send snapshot", snapshot);
  }

  async requestSnapshot(options: SnapshotOptions): Promise<void> {
    return this.emit("request snapshot", options);
  }

  async sendReaction(reaction: string): Promise<void> {
    return this.emit("send reaction", reaction);
  }

  async removeChatMessage(messageId: number): Promise<void> {
    return this.emit("remove chat message", messageId);
  }

  async restoreChatMessage(messageId: number): Promise<void> {
    return this.emit("restore chat message", messageId);
  }

  async muteUser(userId: number): Promise<void> {
    return this.emit("mute user", userId);
  }
  async unmuteUser(userId: number): Promise<void> {
    return this.emit("unmute user", userId);
  }
  async getSessions(): Promise<void> {
    return this.emit("get sessions");
  }

  async __test__restartSession(): Promise<void> {
    return this.emit("restart session");
  }

  async __TESTING__reset_backend(sessions: AbstractLiveSession[]) {
    return this.emit("TEST TEST reset backend TEST TEST", sessions);
  }
}

const socket = new KvixSocket();

export { socket };
