import { AbstractUser, StripeProduct } from "@kvix/shared";
import { useMemo } from "react";
import { hasRequiredProduct, isAdmin, isInstructor } from "src/contexts/user";

export enum AccessLevel {
  NONE = "NONE",
  VIEW = "VIEW",
  FULL = "FULL"
}

export const useUserAccessLevel = (user: AbstractUser) => {

  const accessLevel = useMemo(() => {

    if (!user) {
      return AccessLevel.NONE;
    }
    
    if (isAdmin(user) || isInstructor(user)) {
      return AccessLevel.FULL;
    }

    if (user && !hasRequiredProduct(user, StripeProduct.LIVE)) {
      return AccessLevel.VIEW;
    }

    if (user && hasRequiredProduct(user, StripeProduct.LIVE)) {
      return AccessLevel.FULL;
    }
  }, [user, user?.stripeProducts.length]);

  return accessLevel || AccessLevel.NONE;
}
