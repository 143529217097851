import { AbstractSession } from "@kvix/shared";
import { useCallback, useEffect, useRef, useState } from "react";

export const useVideoThumbnail = (
  session: AbstractSession,
  options?: { fallbackToPoster?: boolean }
): [string | null, boolean] => {
  const [uri, setUri] = useState<string>(null);
  const [loading, setLoading] = useState(true);

  const video = useRef<HTMLVideoElement>();
  const canvas = useRef<HTMLCanvasElement>();

  const resize = useCallback(() => {
    canvas.current.width = video.current.videoWidth;
    canvas.current.height = video.current.videoHeight;
  }, []);

  const capture = useCallback(() => {
    const context = canvas.current.getContext("2d");

    context.drawImage(
      video.current,
      0,
      0,
      video.current.videoWidth,
      video.current.videoHeight
    );

    const uri = canvas.current.toDataURL("image/jpeg");

    setUri(uri);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (session.thumbnailUrl) {
      setUri(session.thumbnailUrl);
      setLoading(false);
      return;
    } else if (options?.fallbackToPoster) {
      setUri(session?.program?.posterUrl);
      setLoading(false);
      return;
    }

    const url = session.mp4Url || session.hlsUrl;

    setUri(null);
    setLoading(true);

    video.current = document.createElement("video");
    canvas.current = document.createElement("canvas");

    video.current.defaultMuted = true;
    video.current.muted = true;
    video.current.volume = 0;
    video.current.preload = "metadata";

    video.current.setAttribute("crossOrigin", "anonymous");
    video.current.setAttribute("playsInline", "");
    video.current.setAttribute("controls", "");

    video.current.addEventListener("loadedmetadata", resize);
    video.current.addEventListener("loadeddata", capture);

    video.current.src = url;
    video.current.currentTime = 3;
    video.current.load();

    return () => {
      video.current?.removeEventListener("loadedmetadata", resize);
      video.current?.removeEventListener("loadeddata", capture);
      video.current?.remove();
      canvas.current?.remove();

      video.current = null;
      canvas.current = null;
    };
  }, [capture, session.hlsUrl, session.mp4Url, session.thumbnailUrl, resize]);

  return [uri, loading];
};
