import { StripeProduct } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Box, Button, makeStyles, useTheme } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import Stripe from "stripe";
import { useProductPrices } from "../../hooks";
import { CoinImageSize } from "../enums/CoinImageSize";
import { CoinImage } from "./CoinImage";

interface Props {
  onSelect: (price: Stripe.Price) => void;
  selectedPrice: Stripe.Price;
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  button: {
    height: "40px",
    textTransform: "none",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    borderWidth: "2px",
    borderRadius: "20px",
    width: "100%",
    marginBottom: "14px",
    "& .MuiTypography-body1": {
      color: theme.palette.text.primary,
    },
    "&:hover": {
      "& .MuiTypography-body1": {
        color: theme.palette.background.default,
      },
      backgroundColor: theme.palette.text.primary,
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  selected: {
    backgroundColor: theme.palette.text.primary,
    "& .MuiTypography-body1": {
      color: theme.palette.background.default,
    },
  },
  fontHeavy: {
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "20px",
  },
  fontSlim: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
  },
  imageMargin: {
    margin: "0 5px -1px 5px",
  },
}));

export const CoinPriceList: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const prices = useProductPrices(StripeProduct.KVIX_CURRENCY);
  const { t, ready } = useTranslation("donateLiveView");
  const theme = useTheme();

  const sortPrices = prices.value?.sort(
    (a, b) => a.unit_amount - b.unit_amount
  );

  if (!ready) {
    return null;
  }

  if (prices.loading) {
    return (
      <h2 style={{ color: theme.palette.common.black }}>
        {t("buy.fetchData")}
      </h2>
    );
  }

  return (
    <Box className={classes.root}>
      {sortPrices?.map((price) => (
        <Button
          variant={"outlined"}
          size={"small"}
          key={price.id}
          className={classNames(classes.button, {
            [classes.selected]: price.id === props.selectedPrice?.id,
          })}
          onClick={() => {
            props.onSelect(price);
          }}
        >
          <Text className={classes.fontHeavy}>
            {price.transform_quantity.divide_by}
          </Text>
          <CoinImage size={CoinImageSize.LG} className={classes.imageMargin} />
          <Text className={classes.fontSlim}>{`${
            price.unit_amount / 100
          } kr`}</Text>
        </Button>
      ))}
    </Box>
  );
};
