import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { Platform } from "src/utils/platform";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { isBasicInstructor, KvixUserContext } from "../../../contexts/user";
import { Flow } from "../components/Flow";
import { AccessLevel, useUserAccessLevel } from "../hooks/useUserAccessLevel";
import { CheckoutStep } from "../steps/Checkout";
import { IosStep } from "../steps/Ios";
import { RegisterStep } from "../steps/Register";
import { DefaultFlowProps } from "../types";

export const DefaultFlow = ({
  login = false,
  checkoutProps,
  registerProps,
}: DefaultFlowProps) => {
  const { user } = useContext(KvixUserContext);
  const accessLevel = useUserAccessLevel(user);

  return (
    <PurchaseFlowConsumer>
      {({ context: { platform } }) => (
        <Flow>
          <Grid style={{ overflowY: "scroll" }}>
            {platform === Platform.IOS && <IosStep />}
            {accessLevel === AccessLevel.NONE && (
              <RegisterStep
                tabState={login ? "login" : "register"}
                {...registerProps}
              />
            )}
            {accessLevel === AccessLevel.VIEW && !isBasicInstructor(user) && (
              <CheckoutStep {...checkoutProps} />
            )}
          </Grid>
        </Flow>
      )}
    </PurchaseFlowConsumer>
  );
};
