import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import { AlgoliaArticle } from "@kvix/shared";
import { KvixIcon, TextField } from "@kvix/ui";
import { IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { KvixUserContext } from "src/contexts/user";
import { AlgoliaContext } from "../../../../contexts/algolia";
import { SearchResult } from "./SearchResult";

interface Props {
  iconClassName: string;
  backgroundVisible: boolean;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  input: {
    borderRadius: 24,
    background: theme.palette.background.paper,
    animation: "300ms $expand forwards",
    height: 40,
    alignSelf: "flex-end",

    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },

    "&.closing": {
      animation: "300ms $detract forwards",
    },
  },
  noBorder: {
    border: 0,
  },
  textField: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  "@keyframes expand": {
    "0%": {
      width: "26%",
    },
    "100%": {
      width: "100%",
    },
  },
  "@keyframes detract": {
    "0%": {
      opacity: 1,
      width: "100%",
    },
    "100%": {
      opacity: 0,
      width: "26%",
    },
  },
}));

export const HeaderItemSearch: React.FC<Props> = (props) => {
  const [closing, setClosing] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>();
  const searchTimeout = useRef<number>();
  const { search } = useContext(AlgoliaContext);
  const classes = useStyles(props);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<AlgoliaArticle[]>();
  const history = useHistory();
  const { t, ready } = useTranslation("general");
  const { user } = useContext(KvixUserContext);

  const handleClick = () => {
    pushClientEvent({
      featureType: "page.search.icon.clicked",
      source: Source.Web,
      user,
    });
    props.setExpanded(true);
  };

  const handleBlur = () => {
    setSearchResult(null);
    setClosing(true);
    clearTimeout(searchTimeout.current);
    setLoading(false);

    setTimeout(() => {
      props.setExpanded(false);
      setClosing(false);
    }, 300);
  };

  const handleSearch = (value: string) => {
    clearTimeout(searchTimeout.current);
    if (value) {
      setLoading(true);
      searchTimeout.current = window.setTimeout(async () => {
        const result = await search({
          query: value,
          filters:
            'type:"program" OR type:"instructor" OR type:"basicInstructor" AND deletedAt != 1',
        });
        setSearchResult(result.hits as AlgoliaArticle[]);
        return setLoading(false);
      }, 1000);
    } else {
      setLoading(false);
    }
    setSearchResult(null);
  };

  const clear = (e: React.SyntheticEvent) => {
    textFieldRef.current.value = "";
    clearTimeout(searchTimeout.current);
    setLoading(false);
    setSearchResult(null);
  };

  const handleNavigation = (pathname: string) => {
    setSearchResult(null);
    props.setExpanded(false);
    pushClientEvent({
      featureType: "page.search.result.clicked",
      source: Source.Web,
      user,
      pathTo: pathname,
    });
    history.push(pathname);
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      {props.expanded ? (
        <div className={classes.root}>
          <TextField
            className={classes.textField}
            ref={textFieldRef}
            icon={
              textFieldRef.current?.value.length > 0 ? (
                <KvixIcon.Close
                  style={{ cursor: "pointer" }}
                  onMouseDown={(e: React.SyntheticEvent) => e.preventDefault()}
                  onClick={clear}
                />
              ) : (
                <KvixIcon.Search />
              )
            }
            iconPosition="end"
            autoFocus
            onBlur={handleBlur}
            InputProps={{
              className: `${classes.input} ${closing ? "closing" : ""}`,
              classes: { notchedOutline: classes.noBorder },
            }}
            onChange={(event) => handleSearch(event.target.value)}
            placeholder={t("search")}
          />
          {(searchResult || loading) && (
            <SearchResult
              searchResult={searchResult}
              navigateTo={handleNavigation}
              loading={loading}
            />
          )}
        </div>
      ) : (
        <IconButton
          className={props.iconClassName}
          aria-label="search"
          onClick={handleClick}
        >
          <KvixIcon.Search />
        </IconButton>
      )}
    </>
  );
};
