import { createBrowserLocalStorageCache } from "@algolia/cache-browser-local-storage";
import { version } from "@algolia/client-common";
import { SearchResponse } from "@algolia/client-search";
import algoliasearch, { AlgoliaSearchOptions } from "algoliasearch";
import React from "react";

interface AlgoliaContextState {
  search: (
    options: SearchOptions
  ) => Readonly<Promise<SearchResponse<unknown>>>;
}

export interface SearchOptions {
  query: string;
  filters?: string;
  sortBy?: SortOption;
  page?: number;
  hitsPerPage?: number;
}

export enum SortOption {
  ALPHABETICAL = "alphabetical",
  POPULARITY = "popularity",
  LATEST = "time",
  RATING = "rating",
  RELEVANCY = "",
}

//Use local storage cache in development environment
const cacheOptions: AlgoliaSearchOptions = {
  responsesCache: createBrowserLocalStorageCache({
    key: `${version}-${window.config.ALGOLIA_APP_ID}`,
  }),
};

const useSearchClient = () => {
  const searchClient = algoliasearch(
    window.config.ALGOLIA_APP_ID,
    window.config.ALGOLIA_SEARCH_API_KEY,
    { ...(window.config.RELEASE_ENV !== "production" && cacheOptions) }
  );

  return searchClient;
};

export const AlgoliaContext = React.createContext<AlgoliaContextState>(null);

export const AlgoliaContextProvider: React.FC = (props) => {
  const searchClient = useSearchClient();
  const defaultIndex = searchClient.initIndex(window.config.ALGOLIA_INDEX);

  const search = (options: SearchOptions) => {
    const { sortBy, query, filters, page = 0, hitsPerPage = 20 } = options;

    if (sortBy) {
      const replicaIndex = searchClient.initIndex(
        `${window.config.ALGOLIA_INDEX}_${sortBy}`
      );
      return replicaIndex.search(query, {
        filters,
        page,
        hitsPerPage,
      });
    }
    return defaultIndex.search(query, {
      filters,
      page,
      hitsPerPage,
    });
  };
  const value = { search };

  return (
    <AlgoliaContext.Provider value={value}>
      {props.children}
    </AlgoliaContext.Provider>
  );
};
