import { makeStyles } from "@material-ui/core";
import React from "react";

interface Props {
  selected: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.background.default),
    borderRadius: 8,
    padding: "8px 8px 9px 8px",
    marginRight: 8,
    userSelect: "none",
    flexShrink: 0,
    cursor: "pointer",
    fontWeight: 500,
    lineHeight: "16.5px",

    "&.selected": {
      background: theme.palette.getContrastText(
        theme.palette.background.default
      ),
      color: theme.palette.background.default,
    },

    "&:last-child": {
      margin: 0,
    },
  },
}));

export const DesktopCategory: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${props.selected ? "selected" : ""}`}>
      {props.children}
    </div>
  );
};
