import {
  CastAnytimeMessage,
  CastData,
  CastLiveMessage,
  ShareMessage,
  WebviewMessageType,
} from "@kvix/shared";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppCastData, useTrackingPermission } from "../hooks/nativeApp";
import { LanguageContext } from "./language";
import { KvixUserContext } from "./user";

export const APP_HEADER_PADDING = window.KvixApp?.statusBarHeight || 0;

interface ExpoContextActions {
  share: (shareData: ShareMessage) => void;
  fullscreen: (fullscreen: boolean) => void;
  getSubscriptions: () => void;
  triggerIapPurchase: (sku: string) => void;
}

export interface State {
  isNativeApp: boolean;
  constants: {
    version: string;
    trackingTransparencyAvailable: boolean;
  };
  cast: CastData;
  actions: ExpoContextActions;
}
export const ExpoContext = createContext<State>(null);

const ContextProvider: React.FC = (props) => {
  const { user } = useContext(KvixUserContext);
  const isNativeApp = useIsExpo();
  const version = getAppVersion();
  const { pathname } = useLocation();
  const { currentLanguage } = useContext(LanguageContext);
  const cast = useAppCastData();
  const constants = useMemo(
    () => ({
      version,
      trackingTransparencyAvailable:
        !!window.KvixApp?.trackingTransparencyAvailable,
    }),
    [version]
  );

  useTrackingPermission();

  useEffect(() => {
    postWebViewMessage("navigation", { pathname });
  }, [pathname]);

  useEffect(() => {
    postWebViewMessage("user", user);
  }, [user]);

  return (
    <ExpoContext.Provider
      value={{
        isNativeApp,
        constants,
        cast,
        actions: {
          share,
          fullscreen,
          getSubscriptions,
          triggerIapPurchase,
        },
      }}
    >
      {props.children}
    </ExpoContext.Provider>
  );
};

const ContextConsumer = ExpoContext.Consumer;

export {
  ContextProvider as ExpoContextProvider,
  ContextConsumer as ExpoContextConsumer,
};

const useIsExpo = () => {
  return !!window.KvixApp;
};

const getAppVersion = () => {
  const userAgent = navigator.userAgent;
  const appVersion = / kvixapp/.test(userAgent) && userAgent.split("/").pop();
  return appVersion;
};

const postWebViewMessage = <T extends object>(
  type: WebviewMessageType,
  data: T
) => {
  if (window.ReactNativeWebView) {
    const message = JSON.stringify({
      type,
      data,
    });

    window.ReactNativeWebView.postMessage(message);
  }
};

const share = (shareData: ShareMessage) => {
  postWebViewMessage("share", shareData);
};

const fullscreen = (fullscreen: boolean) => {
  postWebViewMessage("fullscreen", { fullscreen });
};

const getSubscriptions = () => {
  postWebViewMessage("get-subscriptions", {});
};

const triggerIapPurchase = (sku: string) => {
  postWebViewMessage("trigger-iap-purchase", { sku });
};

export const sendCastAnytime = (data: CastAnytimeMessage) => {
  postWebViewMessage("cast-anytime", data);
};

export const sendCastLive = (data: CastLiveMessage) => {
  postWebViewMessage("cast-live", data);
};
