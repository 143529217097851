import { ClickableTitle, KvixIcon, Text } from "@kvix/ui";
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { BlogContext, BlogContextProvider } from "../../contexts/blog";
import { BlogpostFC } from "../pages/CMS/Blog/Landing/Blogpost";

interface Props {
  title: string;
  description: string;
  explore: ExploreProps;
  noMargin?: boolean;
}

interface ExploreProps {
  text: string;
  url: string;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  ContainerPadding: {
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  titleRow: {
    display: "flex",
    alignItems: "baseline",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
}));

const ExploreButton: React.FC<ExploreProps> = memo((props) => {
  return (
    <Link to={props.url} style={{ display: "flex", alignItems: "flex-end" }}>
      <Text color="primary" variant="body2" style={{ fontWeight: 700 }}>
        {props.text}
      </Text>
      <KvixIcon.Arrow.Forward color="primary" style={{ fontSize: "16px" }} />
    </Link>
  );
});

const BlogpostContainer: React.FC<Props> = memo((props) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const { blog } = useContext(BlogContext);

  const blogposts = useMemo(() => {
    if (!blog) {
      return null;
    }
    return blog.blogposts;
  }, [blog]);

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const BLOGPOSTS_NUMBER = isMobile ? 4 : 3;

  return (
    <>
      {blog && (
        <>
          {blogposts && blogposts.length > 0 && (
            <Grid
              container
              item
              className={classes.ContainerPadding}
              style={{ maxWidth: "xl" }}
            >
              {props.title && (
                <div className={classes.titleRow}>
                  <ClickableTitle title={props.title} titleUrl={"/blog"} />
                  {props.explore && (
                    <ExploreButton
                      text={props.explore.text}
                      url={props.explore.url}
                    />
                  )}
                </div>
              )}
              {props.description && (
                <Text
                  variant={!isMobile ? "body1" : "body2"}
                  className={classes.description}
                >
                  {props.description}
                </Text>
              )}
              <Grid container spacing={3}>
                {blogposts.slice(0, BLOGPOSTS_NUMBER).map((blogpost) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    key={blogpost.id}
                    style={{ backgroundColor: "none" }}
                  >
                    <BlogpostFC blogpost={blogpost} isTablet />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
});

export const BlogContainer: React.FC<Props> = (props) => {
  return (
    <BlogContextProvider>
      <BlogpostContainer {...props} />
    </BlogContextProvider>
  );
};
