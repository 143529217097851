import { AbstractCompetitionRound } from "@kvix/shared";
import React, { useEffect, useState } from "react";
import { useFetchActiveCompetitionRound } from "../hooks/http";

interface CompetitionContextState {
  activeCompetitionRound: AbstractCompetitionRound;
  loading: boolean;
}

export const CompetitionContext = React.createContext<CompetitionContextState>({
  activeCompetitionRound: null,
  loading: false,
});

export const CompetitionContextProvider: React.FC = (props) => {
  const activeCompetitionRound = useFetchActiveCompetitionRound();
  const [value, setValue] = useState<CompetitionContextState>({
    activeCompetitionRound: null,
    loading: false,
  });

  useEffect(() => {
    setValue((value) => {
      return {
        ...value,
        activeCompetitionRound: activeCompetitionRound?.value,
        loading: activeCompetitionRound.loading,
      };
    });
  }, [activeCompetitionRound]);

  return (
    <CompetitionContext.Provider value={value}>
      {props.children}
    </CompetitionContext.Provider>
  );
};
