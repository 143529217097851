import { RichText, Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { CmsContext } from "../../../../../cms/context";
import { DonateTabState } from "../enums/DonateTabState";
import { TabContainer } from "../partials/TabContainer";

interface Props {
  tabState: DonateTabState;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    maxHeight: 500,
  },
  title: {
    marginTop: "60px",
  },
  info: {
    marginTop: "12px",
  },
}));

export const TermsTab: FC<Props> = (props) => {
  const classes = useStyles();
  const { pages } = useContext(CmsContext);
  const termsPage = pages?.find((p) => p.slug === "terms-checkout");

  return (
    <TabContainer tabState={props.tabState} visibleState={DonateTabState.TERMS}>
      <div className={classes.root}>
        <div className={classes.title}>
          <Text variant="h4">{termsPage?.title}</Text>
        </div>
        <div className={classes.info}>
          <RichText text={termsPage?.content} />
        </div>
      </div>
    </TabContainer>
  );
};
