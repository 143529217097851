import { Button, Text } from "@kvix/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const NotFound = styled.main`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const NotFoundTitle = styled(Text)``;

const NotFoundSubtitle = styled(Text)`
  &.MuiTypography-root {
    font-size: 24px;
    margin-bottom: 50px;
  }
`;

const NotFoundButton = styled(Button)`
  &.MuiButton-root {
    width: 240px;
  }
`;

export const PageNotFound: React.FC = (props) => {
  const history = useHistory();
  const { t, ready } = useTranslation("pageNotFound");

  if (!ready) {
    return null;
  }

  return (
    <NotFound>
      <NotFoundTitle align="center" gutterBottom variant="h1">
        {t("title")}
      </NotFoundTitle>

      <NotFoundSubtitle align="center">{t("text")}</NotFoundSubtitle>

      <NotFoundButton onClick={() => history.goBack()}>
        {t("buttonText")}
      </NotFoundButton>
    </NotFound>
  );
};
