import { AbstractBaseUser, IssuedFrom } from "@kvix/shared";
import { Button } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/isMobile";
import { DonateType } from "../Payment/BuyCoins/enums/DonateType";
import { VoteModal } from "../Payment/BuyCoins/VoteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 36,
    width: (props: any) => props.width || 121,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 18,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  button: {
    minWidth: 154,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  voteUp: {
    fontSize: 13,
    fontWeight: "bold",
    color: theme.palette.common.white,
  },
  emoji: {
    paddingBottom: 2,
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold",
  },
}));

interface Props {
  streamer: AbstractBaseUser;
  hideName?: boolean;
  voteActive: boolean;
}

const StreamerVoteButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("streamerPage");
  const { streamer, voteActive } = props;
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile();

  const handleOnClick = useCallback(async () => {
    setShowModal(true);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Button
        color="secondary"
        lowercase
        onClick={handleOnClick}
        className={classes.button}
      >
        {props.hideName ? (
          <>
            {t(
              voteActive ? "button.voteWithoutName" : "button.donateWithoutName"
            )}
          </>
        ) : (
          <>
            {t(voteActive ? "button.vote" : "button.donate", {
              name: streamer.name.custom || streamer.name.first,
            })}
          </>
        )}

        <span role="img" aria-label="sheep" style={{ paddingLeft: 4 }}>
          🤘
        </span>
      </Button>
      <VoteModal
        issuedFrom={IssuedFrom.STREAMER_PAGE}
        instructor={streamer}
        isMobile={isMobile}
        displayVote={showModal}
        setDisplayVote={setShowModal}
        donateType={voteActive ? DonateType.VOTE : DonateType.COIN}
      />
    </>
  );
};

export default StreamerVoteButton;
