import React from "react";
import { animated, useSpring } from "react-spring";

interface Props {
  delay?: number;
  tension?: number;
}

export const SlideUp: React.FC<Props> = (props) => {
  const style = useSpring({
    delay: props.delay || 0,
    config: {
      tension: 60,
      friction: 15,
    },
    from: {
      opacity: 0,
      transform: "translate3d(0, 20px, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  });

  return <animated.div style={style}>{props.children}</animated.div>;
};

export const SlideRight: React.FC<Props> = (props) => {
  const style = useSpring({
    delay: props.delay || 0,
    config: {
      tension: 60,
      friction: 15,
    },
    from: {
      opacity: 0,
      transform: "translate3d(-20px, 0, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0, 0, 0)",
    },
  });

  return <animated.div style={style}>{props.children}</animated.div>;
};

export const FadeIn: React.FC<Props> = (props) => {
  const style = useSpring({
    delay: props.delay || 0,
    config: {
      tension: props.tension || 60,
      friction: 15,
    },
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  return <animated.div style={style}>{props.children}</animated.div>;
};
