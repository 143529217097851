import { AbstractBaseUser } from "@kvix/shared";
import { KvixIcon } from "@kvix/ui";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompetitionContext } from "../../../contexts/competition";
import { useIsNativeApp } from "../../../hooks/nativeApp";
import { FollowButton } from "../Instructor/FollowButton";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      margin: ({ isNative }: any) => (isNative ? 16 : 32),
      marginTop: ({ isNative }: any) => (isNative ? 88 : 32),
      [theme.breakpoints.down("xs")]: {
        margin: ({ isNative }: any) => (isNative ? 16 : 15),
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: ({ isNative }: any) =>
        isNative ? "calc(100% - 124px)" : "calc(100% - 64px)",
    },
  },
  dialogContainer: {
    width: ({ isNative }: any) => (isNative ? "auto" : "415px"),
    maxWidth: "100%",
  },
  inlineFlex: {
    display: "inline-flex",
  },
  avatar: {
    width: "72px",
    height: "72px",
    marginRight: "6px",
  },
  titleOccupation: {
    lineHeight: "24px",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    fontWeight: 600,
  },
  bioText: {
    marginBottom: "20px",
  },
  profileLink: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    "&&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  displayRankContainer: {
    marginTop: "18px",
  },
  displayRankText: {
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: 500,
  },
  starIcon: {
    width: "18px",
    height: "18px",
    marginBottom: "-2px",
  },
  rankText: {
    fontWeight: 500,
    lineHeight: "1em",
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  instructor: AbstractBaseUser;
  rank: number;
}

const DisplayRank: FC<{ rank: number }> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("scheduledStreamModal");

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.displayRankContainer}>
      <Typography className={classes.displayRankText}>
        {props.rank}
        <KvixIcon.StarBorder className={classes.starIcon} />
      </Typography>
      <Divider style={{ margin: "10px 0" }} />
      <Typography className={classes.rankText}>{t("rank")}</Typography>
    </Box>
  );
};

export const ScheduledStreamModal: FC<Props> = (props) => {
  const isNative = useIsNativeApp();
  const classes = useStyles({ isNative });
  const { t, ready } = useTranslation("scheduledStreamModal");
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const [showRank, setShowRank] = useState(false);

  useEffect(() => {
    setShowRank(activeCompetitionRound && !activeCompetitionRound?.isFinal);
  }, [activeCompetitionRound]);

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={classes.dialog}
    >
      <Box className={classes.dialogContainer}>
        <Box>
          <IconButton onClick={props.onClose}>
            <KvixIcon.Close />
          </IconButton>
        </Box>
        <DialogTitle>
          <Box className={classes.inlineFlex}>
            <Avatar
              className={classes.avatar}
              src={props.instructor?.imageUrl}
            />
            <Box>
              <Typography variant={"h4"}>
                {props.instructor?.name.custom}
              </Typography>
              <Typography className={classes.titleOccupation}>
                {props.instructor?.instructorProfile?.title}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant={"h4"} style={{ textTransform: "uppercase" }}>
            {t("bio")}
          </Typography>
          <Typography
            className={classes.bioText}
            variant={"body2"}
            style={{ whiteSpace: "pre-line", marginTop: 8 }}
          >
            {props.instructor?.instructorProfile?.bio}
          </Typography>
          <Link
            className={classes.profileLink}
            href={"/streamer/" + props.instructor?.id}
          >
            {t("goToProfile")}
          </Link>
          {showRank && <DisplayRank rank={props.rank} />}
        </DialogContent>
        <DialogActions>
          <FollowButton instructor={props.instructor} />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
