import { HttpMethods } from "@kvix/shared";
import { Text } from "@kvix/ui";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grow,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { KvixUserContext } from "../../../contexts/user";
import { emailValidation } from "../../../utils/RegexValidation/emailValidation";
import { FilePicker } from "./components/FilePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    background: theme.palette.text.primary,
    minHeight: "100%",
    width: "100%",
    overflow: "auto",
    minWidth: "520px",
    [theme.breakpoints.down(520)]: {
      minWidth: "300px",
    },
  },
  container: {
    textAlign: "center",
    padding: (props: any) => (props.asModal ? "45px" : "15vh 45px 45px 45px"),
    width: "100%",
    background: theme.palette.text.primary,
    maxWidth: "600px",
    color: theme.palette.common.white,
    position: "relative",
    "& .MuiInputLabel-filled": {
      color: "#ffffff87",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.common.white,
      background: "#333b45",
    },
    [theme.breakpoints.down(350)]: {
      paddingTop: (props: any) => (props.asModal ? 64 : "12vh"),
    },
  },
  formContainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiFilledInput-input": {
      padding: "15px 12px",
    },
  },
  formRow: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-evenly",
  },
  here: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  fileContainer: {
    display: "flex",
    textAlign: "left",
    marginTop: "16px",
    marginLeft: "8px",
  },
  selectedFiles: {
    marginLeft: "20px",
  },
  unitBrowserPicker: {
    marginTop: "16px",
    "& .MuiSelect-select.MuiSelect-select": {
      textAlign: "left",
      paddingLeft: "12px",
    },
  },
  displayError: {
    color: "red",
    width: "230px",
    margin: "20px auto",
  },
  desc: {
    "& .MuiFilledInput-multiline": {
      paddingLeft: "0px",
    },
  },
}));

export const Contact: React.FC<{ asModal?: boolean }> = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { t, ready } = useTranslation("contactForm");
  const { user } = useContext(KvixUserContext);
  const theme = useTheme();

  const [name, setName] = useState(
    `${user ? `${user.name.first} ${user.name.last}` : ""}`
  );
  const [email, setEmail] = useState(user?.email || "");
  const [unit, setUnit] = useState("");
  const [browser, setBrowser] = useState("");
  const [desc, setDesc] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [files, setFiles] = useState<FileList>();
  const [isLoading, setIsLoading] = useState(false);

  const units: string[] = t("units", {
    returnObjects: true,
  });
  const browsers: string[] = t("browsers", {
    returnObjects: true,
  });

  useEffect(() => {
    if (hasSubmitted) {
      if (!name || !email || !unit || !browser || !desc) {
        setDisplayError(true);
      } else {
        if (!emailValidation(email)) {
          setDisplayError(true);
        }
        setDisplayError(false);
      }
    }
  }, [name, email, unit, browser, desc]);

  const onSubmit = async () => {
    setHasSubmitted(true);
    setIsLoading(true);

    if (name && emailValidation(email) && unit && browser && desc) {
      var formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("desc", desc);
      formData.append("unit", unit);
      formData.append("browser", browser);
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("files", files[i]);
        }
      }
    } else {
      setDisplayError(true);
    }

    try {
      const res = await fetch("/api/support/contact/submit", {
        method: HttpMethods.POST,
        body: formData,
      });

      if (res.ok) {
        setIsLoading(false);
        history.goBack();
      } else {
        setIsLoading(false);
        setDisplayError(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error({ error });
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grow in appear={!props.asModal}>
        <div className={classes.container}>
          <Box mt={4}>
            <Text variant="h4">{t("title")}</Text>
          </Box>
          <Box
            mt={4}
            style={{ margin: "20px auto", whiteSpace: "break-spaces" }}
          >
            <Text variant="body2">{t("description")}</Text>
          </Box>
          <Box>
            <Text variant="body2">
              {t("featureRequest")}{" "}
              <span
                className={classes.here}
                onClick={() => history.push("/feedback")}
              >
                {t("here")}
              </span>
            </Text>
          </Box>
          <form className={classes.formContainer} noValidate autoComplete="off">
            <div>
              <Box mt={6}>
                <FormControl>
                  <label style={{ textAlign: "left" }}>
                    {t("labels.name")}
                  </label>
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="filled"
                  />
                </FormControl>
              </Box>
              <Box mt={2}>
                <FormControl>
                  <label style={{ textAlign: "left" }}>
                    {t("labels.email")}
                  </label>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    variant="filled"
                  />
                </FormControl>
              </Box>
              <div className={classes.unitBrowserPicker}>
                <FormControl style={{ color: theme.palette.common.white }}>
                  <label style={{ textAlign: "left" }}>{t("unit")}</label>
                  <Select
                    style={{ marginTop: "5px" }}
                    value={unit}
                    onChange={(e) => setUnit(e.target.value as string)}
                  >
                    {units.map((unit) => {
                      return <MenuItem value={unit}>{unit}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  style={{
                    color: theme.palette.common.white,
                    marginTop: "10px",
                  }}
                >
                  <label style={{ textAlign: "left" }}>{t("browser")}</label>
                  <Select
                    style={{ marginTop: "5px" }}
                    value={browser}
                    onChange={(e) => setBrowser(e.target.value as string)}
                  >
                    {browsers.map((browser) => {
                      return <MenuItem value={browser}>{browser}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              <Box mt={3} className={classes.desc}>
                <FormControl>
                  <TextField
                    label={t("labels.desc")}
                    multiline
                    rows={6}
                    defaultValue=""
                    variant="filled"
                    value={desc}
                    onChange={(e) => {
                      if (e.target.value.length <= 500) {
                        setDesc(e.target.value);
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <FilePicker files={files} setFiles={setFiles} />
              <Box className={classes.displayError}>
                {displayError && <Text variant="body2">{t("error")}</Text>}
              </Box>
              <Box mt={3}>
                <Button
                  onClick={() => onSubmit()}
                  size="medium"
                  variant="contained"
                  style={{ marginBottom: "10px" }}
                >
                  {isLoading ? (
                    <CircularProgress size={40} />
                  ) : (
                    t("labels.submit")
                  )}
                </Button>
              </Box>
            </div>
          </form>
        </div>
      </Grow>
    </div>
  );
};

export default (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Contact />
    </div>
  );
};
