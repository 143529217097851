import {
  AbstractCategory,
  AlgoliaInstructorArticle,
  ArticleType,
  Duration,
  Focus,
} from "@kvix/shared";
import { Placeholder } from "@kvix/ui";
import {
  Container,
  Divider,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AsyncState } from "react-use/lib/useAsync";
import { DisplayArrangement, SearchFilters } from "../..";
import { AlgoliaContext } from "../../../../../contexts/algolia";
import { ArrangeButton } from "./ArrangeButton";
import { DesktopCategoryContainer } from "./Desktop/DesktopCategoryContainer";
import { DesktopFilters } from "./Desktop/DesktopFilters";
import { DesktopOptionsContainer } from "./Desktop/DesktopOptionsContainer";
import { CategorySelect } from "./Mobile/CategorySelect";
import { FilterButton } from "./Mobile/FilterButton";
import { MobileFilters } from "./Mobile/MobileFilters";

interface Props {
  type: ArticleType;
  categories: AsyncState<AbstractCategory[]>;
  filter: SearchFilters;
  searchQuery: string;
  setFilter: (value: SearchFilters) => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  arrangement: DisplayArrangement;
  setArrangement: () => void;
}
export interface FilterOptions {
  instructors: Option[];
  levels: Option[];
  equipment: Option[];
  focuses: Option[];
  durations: Option[];
}
export interface Option {
  title: string;
  value: string | number;
}

const defaultOptions = {
  instructors: [{ title: "instructors.all", value: "" }],
  levels: [
    { title: "levels.all", value: "" },
    { title: "levels.beginner", value: "beginner" },
    { title: "levels.intermediate", value: "intermediate" },
    { title: "levels.advanced", value: "advanced" },
  ],
  equipment: [
    { title: "equipment.all", value: "" },
    { title: "equipment.noEquipment", value: "false" },
    { title: "equipment.equipment", value: "true" },
  ],
  focuses: [
    { title: "focus.all", value: "" },
    ...Object.values(Focus).map((focus) => {
      return { title: `focus.${focus}`, value: focus };
    }),
  ],
  durations: [
    { title: "durations.all", value: "" },
    ...Object.values(Duration)
      .filter((value) => typeof value === "number")
      .map((value) => {
        return { title: `durations.${value}`, value };
      }),
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.background.default,
    position: "relative",
    zIndex: 1,
  },
  mobileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "12px 0",
  },
  desktopContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.default,
    position: "relative",
    zIndex: 1,
    // backgroundColor: "yellow",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  filterContainer: {
    // backgroundColor: "green",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}));
export const FilterSelector: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const [displayFilters, setDisplayFilters] = useState(false);
  const { search } = useContext(AlgoliaContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(defaultOptions);

  useEffect(() => {
    (async () => {
      const searchResult = await search({
        query: "",
        filters: 'type:"instructor"',
        hitsPerPage: 500,
      });
      const instructors = searchResult.hits.map(
        (instructor: AlgoliaInstructorArticle) => {
          return {
            title: instructor.title,
            value: instructor.title,
          } as Option;
        }
      );

      setFilterOptions((filterOptions) => {
        return {
          ...filterOptions,
          instructors: [...filterOptions.instructors, ...instructors],
        };
      });
    })();
  }, [search, setFilterOptions]);

  const numberOfActiveFilters = useMemo(() => {
    if (props.filter) {
      const activeFilters = Object.keys(props.filter).filter(
        (f) => f !== "categories" && f !== "type" && props.filter[f]
      );

      const activeSearchQuery = props.searchQuery.length > 0;
      return activeSearchQuery
        ? activeFilters.length + 1
        : activeFilters.length;
    }
  }, [props.filter, props.searchQuery]);

  if (props.categories.loading && !props.categories.value) {
    return (
      <Container maxWidth="lg">
        <Placeholder.CategoryPicker />
      </Container>
    );
  }

  return (
    <>
      {/* <Container style={isMobile ? { padding: "0 16px" } : { maxWidth: 1140 }}> */}
      <Container className={classes.desktopContainer} maxWidth="xl">
        {/* <div className={classes.root}> */}
        {/* Mobile */}
        {isMobile ? (
          <div className={classes.mobileContainer}>
            <CategorySelect
              categories={props.categories.value}
              currentCategory={props.filter.categories}
              setCategory={(category: string) =>
                props.setFilter({ ...props.filter, categories: category })
              }
            />
            <FilterButton
              numberOfActiveFilters={numberOfActiveFilters}
              openMobileFilters={() => setDisplayFilters(true)}
            />
            <ArrangeButton
              arrangement={props.arrangement}
              setArrangement={props.setArrangement}
              disabled={numberOfActiveFilters > 0}
            />
            <MobileFilters
              open={displayFilters}
              filterOptions={filterOptions}
              activeFilters={props.filter}
              searchQuery={props.searchQuery}
              setActiveFilters={props.setFilter}
              setSearchQuery={props.setSearchQuery}
              close={() => setDisplayFilters(false)}
              hideFilterOptions={props.type === ArticleType.INSTRUCTOR}
              type={props.type}
            />
          </div>
        ) : (
          <>
            {/* Desktop */}
            <DesktopCategoryContainer
              categories={props.categories.value}
              currentCategory={props.filter.categories}
              setCategory={(category: string) =>
                props.setFilter({ ...props.filter, categories: category })
              }
            />
            <DesktopOptionsContainer
              displayingFilters={displayFilters}
              toggleDisplayFilters={() => setDisplayFilters(!displayFilters)}
              numberOfActiveFilters={numberOfActiveFilters}
              arrangement={props.arrangement}
              setArrangement={props.setArrangement}
            />
          </>
        )}
        {/* </div> */}
      </Container>

      <Divider />

      <Container maxWidth="xl" className={classes.filterContainer}>
        {!isMobile && (
          <DesktopFilters
            display={displayFilters}
            filterOptions={filterOptions}
            activeFilters={props.filter}
            setActiveFilters={props.setFilter}
            searchQuery={props.searchQuery}
            setSearchQuery={props.setSearchQuery}
            type={props.type}
          />
        )}
      </Container>
    </>
  );
};
