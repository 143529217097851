import { AbstractBaseUser } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React from "react";

interface Props {
  instructor: AbstractBaseUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
  },

  image: {
    width: "100%",
    maxHeight: "65vh",
    objectFit: "cover",
  },

  quoteContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
  },

  quote: {
    padding: "0 32px",
    color: theme.palette.common.white,

    "&:before": {
      content: "'“'",
    },
    "&:after": {
      content: "'”'",
    },
  },
}));

export const Quote: React.FC<Props> = (props) => {
  const classes = useStyles(props);

  if (!props.instructor.quote) {
    return null;
  }

  return (
    <div className={classes.root}>
      <img
        alt="program"
        className={classes.image}
        src={
          props.instructor.instructorProfile.imageUrl ||
          props.instructor.imageUrl
        }
      />
      <div className={classes.quoteContainer}>
        <Text className={classes.quote} variant="h3">
          {props.instructor.quote}
        </Text>
      </div>
    </div>
  );
};
