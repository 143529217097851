import { AbstractBaseUser, AbstractChannel } from "@kvix/shared";
import { Button, Text } from "@kvix/ui";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  instructor?: AbstractBaseUser;
  channel?: AbstractChannel;
  onClose: (shareEvent: string) => void;
  divider?: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down("xs")]: {
        margin: "15px !important",
      },
    },
  },
}));

export const FullName: React.FC<{ firstName: string; lastName: string }> = ({
  firstName,
  lastName,
}) => (
  <>
    {firstName}
    <br />
    {lastName}
  </>
);

const ShareModal: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("general");
  const classes = useStyles();

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      className={classes.modal}
      open={props.open}
      onBackdropClick={() => props.onClose("stop")}
    >
      <DialogContent>
        {props.instructor && (
          <Box mb={3}>
            <Box display="flex" mb={2} alignItems="center">
              <Avatar
                src={props.instructor.imageUrl}
                style={{ width: 80, height: 80, marginRight: 8 }}
              />
              <Text variant="h4" style={{ marginLeft: 8 }}>
                {props.instructor.name.custom || (
                  <FullName
                    firstName={props.instructor.name.first}
                    lastName={props.instructor.name.last}
                  />
                )}
              </Text>
            </Box>
            {props.divider && <Divider />}
          </Box>
        )}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          lowercase
          color="primary"
          onClick={() => props.onClose("stop")}
        >
          {t("buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
