import { Button, KvixIcon, Text } from "@kvix/ui";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import { CardElement, Elements, useElements } from "@stripe/react-stripe-js";
import {
  PaymentMethod,
  Stripe,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stripe as ServerStripe } from "stripe";
import styled from "styled-components";
import { PaymentContext, PaymentContextActions } from "../../context";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";

interface AddCardFormProps {
  loading: boolean;
  setLoading: (boolean) => void;
  onSuccess: () => void;
  donationAlert: (message: string, severity: DonationAlertSeverity) => void;
  setNewPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

interface SaveCardProps {
  onClick: () => void;
  loading: boolean;
}

interface CreditCardFieldProps {
  loading: boolean;
}

interface CardFormProps {
  stripe: Stripe;
  actions: PaymentContextActions;
  customer: ServerStripe.Customer;
  loading: boolean;
  setLoading: (boolean) => void;
  onSuccess: () => void;
  donationAlert: (message: string, severity: DonationAlertSeverity) => void;
  setNewPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const useStyles = makeStyles((theme) => ({
  fullWidthButton: {
    width: "100%",
  },
  marginAutoTop: {
    marginTop: "79px",
  },
}));

const CardElementContainer = styled.div<{ dark: boolean }>`
  .StripeElement {
    filter: ${(props) =>
      props.dark ? "invert(90%) hue-rotate(180deg)" : null};

    box-sizing: border-box;
    height: 44px;
    padding: 12px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #f3f3f3;

    transition: background 150ms ease;

    &--focus {
      background-color: ${(props) => (props.dark ? "white" : "#e6e6e6")};
    }

    &--invalid {
      border-color: #fa755a;
    }

    &--webkit-autofill {
      background-color: white !important;
    }
  }
`;

const useCardElementState = () => {
  const elements = useElements();
  const [state, setState] = useState<StripeCardElementChangeEvent>(null);

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        cardElement.on("change", setState);
      }
    }
  }, [elements]);

  return state;
};

const SaveCardButton: FC<SaveCardProps> = (props) => {
  const classes = useStyles();
  const cardState = useCardElementState();
  const { t, ready } = useTranslation("donateLiveView");

  if (!ready) {
    return null;
  }

  return (
    <Button
      color={"primary"}
      onClick={props.onClick}
      loading={props.loading}
      disabled={props.loading || !cardState?.complete}
      className={classes.fullWidthButton}
    >
      {t("card.save")}
    </Button>
  );
};

const CardErrors: FC = () => {
  const cardState = useCardElementState();

  let error = cardState?.error;

  if (!error) {
    return null;
  }

  return (
    <Text color="error" variant="body2">
      <Box
        component="span"
        display="grid"
        gridTemplateColumns="auto 1fr"
        gridGap={8}
      >
        <KvixIcon.Error />

        <Box component="span" mt="1px" fontWeight={500}>
          {error.message}
        </Box>
      </Box>
    </Text>
  );
};

const CreditCardField: FC<CreditCardFieldProps> = (props) => {
  const theme = useTheme();

  return (
    <CardElementContainer dark={theme.palette.type === "dark"}>
      <CardElement options={{ hidePostalCode: true, iconStyle: "solid" }} />
    </CardElementContainer>
  );
};

const CardForm: FC<CardFormProps> = (props) => {
  const stripe = props.stripe;
  const actions = props.actions;
  const customer = props.customer;
  const cardState = useCardElementState();
  const elements = useElements();
  const classes = useStyles();

  const save = () => {
    props.setLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (cardState?.complete) {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then((result) => {
          if (result.error) {
            props.setLoading(false);
            props.donationAlert(
              result.error.message,
              DonationAlertSeverity.ERROR
            );
          } else {
            actions
              .attachPaymentMethod(result.paymentMethod, customer)
              .then((compete) => {
                props.setNewPaymentMethod(result.paymentMethod);
                props.setLoading(false);
                props.onSuccess();
              })
              .catch((reason) => {
                props.setLoading(false);
                props.donationAlert(reason, DonationAlertSeverity.ERROR);
              });
          }
        });
    }
  };

  return (
    <>
      <CreditCardField loading={props.loading} />
      <Box className={classes.marginAutoTop}>
        <CardErrors />
        <SaveCardButton onClick={save} loading={props.loading} />
      </Box>
    </>
  );
};

export const AddCardForm: FC<AddCardFormProps> = (props) => {
  const { stripe, actions, customer } = useContext(PaymentContext);
  return (
    <Elements stripe={stripe}>
      <CardForm
        stripe={stripe}
        actions={actions}
        customer={customer}
        loading={props.loading}
        setLoading={props.setLoading}
        onSuccess={props.onSuccess}
        donationAlert={props.donationAlert}
        setNewPaymentMethod={props.setNewPaymentMethod}
      />
    </Elements>
  );
};
