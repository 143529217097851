import {
  AbstractSession,
  AlgoliaAnytimeArticle,
  AnytimeCardProps,
  ArticleType,
  InstructorCardProps,
  PlaylistCardProps,
  ProgramCardProps,
  SessionCardProps,
  SessionState,
  SessionStateUpdatePayload,
  SessionThumbnailUpdatePayload,
  StripeProduct,
} from "@kvix/shared";
import {
  AnytimeCard,
  InstructorCard,
  PlaylistCard,
  ProgramCard,
  StreamFinishedCard,
  UpcomingSessionCard,
} from "@kvix/ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { KvixUserContext } from "../../../contexts/user";
import { useIsLiked } from "../../../hooks/isLiked";
import { useSocketEvent } from "../../../hooks/socket";
import { Guard } from "../Guard";
import { DefaultFlow, NewFlow } from "src/components/PurchaseFlow";
import Cookies from "js-cookie";

type CardProps = Partial<
  ProgramCardProps &
    AnytimeCardProps &
    SessionCardProps &
    InstructorCardProps &
    PlaylistCardProps
>;

interface Props {
  type: ArticleType;
  options: CardProps;
  filterByChip?: (key: string, value: any) => void;
  displayDateLabel?: boolean;
  toggleFavourite?: (options: {
    programId?: number;
    session?: AbstractSession | AlgoliaAnytimeArticle;
    playlistId?: number;
  }) => void;
  displayLess?: boolean;
  wide?: boolean;
  fullWidth?: boolean;
  displayMeta?: boolean;
}
export const Card: React.FC<Props> = (props) => {
  const isLiked = useIsLiked();
  const { sessions, user } = useContext(KvixUserContext);
  const history = useHistory();
  const [upcomingCardState, setUpcomingCardState] = useState<SessionState>(
    props.options?.state
  );
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(
    props.options?.thumbnailUrl
  );
  const [wide, setWide] = useState(props.wide);
  const [hideLike, setHideLike] = useState<boolean>(true);

  useEffect(() => {
    if (props.type === ArticleType.PLAYLIST) {
      if (!props.options.campaigns || props.options.campaigns.length === 0) {
        if (props.options.requiredProduct?.includes(StripeProduct.LIVE)) {
          if (user.stripeProducts.includes(StripeProduct.LIVE)) {
            setHideLike(false);
          }
        } else {
          setHideLike(false);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    setWide(props.wide && upcomingCardState === SessionState.Running);
  }, [upcomingCardState, props.wide]);

  const handleStateUpdate = (payload: SessionStateUpdatePayload) => {
    if (props.options.id === payload.sessionId) {
      setUpcomingCardState(payload.state);
    }
  };

  const handleThumbnailUpdated = (payload: SessionThumbnailUpdatePayload) => {
    if (props.options.id === payload.sessionId) {
      setThumbnailUrl(payload.thumbnailUrl);
    }
  };

  useSocketEvent(
    `session state updated ${props.options?.id}`,
    handleStateUpdate
  );
  useSocketEvent(
    `thumbnail updated ${props.options?.id}`,
    handleThumbnailUpdated
  );

  const handleFilterByChip = useCallback(
    (pathname: string, key: string, value: any) => {
      if (props.filterByChip) {
        return props.filterByChip(key, value);
      }

      const obj = {};
      obj[key] = value;

      history.push({
        pathname,
        search: "?filters=" + encodeURIComponent(JSON.stringify(obj)),
      });
    },
    []
  );

  switch (props.type) {
    case ArticleType.PROGRAM:
      return (
        <ProgramCard
          isLiked={isLiked(props.options.id, props.type)}
          toggleFavourite={props.toggleFavourite}
          filterByChip={(key: string, value: any) =>
            handleFilterByChip("/programs", key, value)
          }
          options={props.options as ProgramCardProps}
        />
      );

    case ArticleType.ANYTIME:
      return props.displayLess ? (
        <StreamFinishedCard
          options={props.options as AnytimeCardProps}
          progress={{
            startTimestamp:
              props.options.actualStartTimestamp ||
              props.options.startTimestamp,
            endTimestamp:
              props.options.actualEndTimestamp || props.options.endTimestamp,
            currentTime: sessions.watched[props.options.id],
          }}
        />
      ) : (
        <AnytimeCard
          isLiked={isLiked(props.options.id, props.type)}
          toggleFavourite={props.toggleFavourite}
          filterByChip={(key: string, value: any) =>
            handleFilterByChip("/anytime", key, value)
          }
          options={props.options as AnytimeCardProps}
          progress={{
            startTimestamp:
              props.options.actualStartTimestamp ||
              props.options.startTimestamp,
            endTimestamp:
              props.options.actualEndTimestamp || props.options.endTimestamp,
            currentTime: sessions.watched[props.options.id],
          }}
        />
      );

    case ArticleType.SESSION:
      return (
        <Guard.Click
          exception={({ closestHref }) =>
            (user && !props.options.requiredProductLive) ||
            !closestHref.includes("session")
          }
          FlowComponent={
            props.options.requiredProductLive ? (
              <NewFlow registerProps={{ checkoutSource:  Cookies.get('wp-campaign') ? 'wp' : 'kvix' }} currentTab={user ? 1 : 0} />
            ) : (
              <DefaultFlow registerProps={{ hidePrice: true }} />
            )
          }
        >
          <UpcomingSessionCard
            isLiked={isLiked(props.options.id, props.type)}
            filterByChip={(key: string, value: any) =>
              handleFilterByChip("/sessions", key, value)
            }
            options={props.options as SessionCardProps}
            user={user}
            displayDateLabel={props.displayDateLabel}
            state={upcomingCardState}
            thumbnailUrl={thumbnailUrl}
            wide={wide}
            fullWidth={props.fullWidth}
          />
        </Guard.Click>
      );

    case ArticleType.INSTRUCTOR:
    case ArticleType.BASIC_INSTRUCTOR:
      return (
        <InstructorCard
          type={props.type}
          options={props.options as InstructorCardProps}
          displayMeta={props.displayMeta}
        />
      );

    case ArticleType.PLAYLIST:
      return (
        <PlaylistCard
          isLiked={isLiked(props.options.id, props.type)}
          toggleFavourite={!hideLike && props.toggleFavourite}
          options={props.options as PlaylistCardProps}
        />
      );
  }
};
