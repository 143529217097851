import { ArticleType } from "@kvix/shared";
import { useContext } from "react";
import { KvixUserContext } from "../contexts/user";

const isLiked = (id: number, ids: number[]) => {
  return ids.indexOf(id) >= 0;
};

export const useIsLiked = () => {
  const {
    favouritedProgramIds,
    favouritedSessionIds,
    favouritedPlaylistIds,
  } = useContext(KvixUserContext);

  return (id: number, type: ArticleType) => {
    switch (type) {
      case ArticleType.ANYTIME:
        return isLiked(id, favouritedSessionIds);

      case ArticleType.SESSION:
        return isLiked(id, favouritedSessionIds);

      case ArticleType.PROGRAM:
        return isLiked(id, favouritedProgramIds);

      case ArticleType.PLAYLIST:
        return isLiked(id, favouritedPlaylistIds);
    }
  };
};
