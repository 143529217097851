import { CommentMessage } from "@kvix/shared";
import { KvixRating, Text } from "@kvix/ui";
import { Avatar, Box, Grid, makeStyles, Theme } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  comment: CommentMessage;
  maxNumberOfLines?: number;
  asCompact?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    display: "flex",
    width: 350,

    [theme.breakpoints.down("sm")]: {
      width: 300,
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  name: {},
  meta: {
    display: "flex",
    flexDirection: "column",
    position: "relative",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rating: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -4,
    },
  },
  date: {
    fontSize: 14,

    [theme.breakpoints.down("sm")]: {
      marginTop: -2,
    },
  },
  avatar: {
    width: 52,
    height: 52,
    marginRight: 32,

    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
      marginRight: 20,
    },
  },
  text: {
    fontSize: 18,
    lineHeight: "28px",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": (props) =>
      props.maxNumberOfLines ? props.maxNumberOfLines : "initial",
    overflow: "hidden",
  },
}));

export const Comment: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("program");

  if (!ready) {
    return null;
  }

  return (
    <Grid container wrap="wrap">
      <div style={{ display: `${props.asCompact ? "block" : "flex"}` }}>
        <Grid item className={classes.container}>
          <Avatar
            className={classes.avatar}
            sizes="large"
            src={props.comment.user.imageUrl}
          />

          <div className={classes.meta}>
            <Text className={classes.name} variant="h5" gutterBottom>
              {props.comment.user.name?.first}{" "}
              {props.comment.user.name?.last?.substring(0, 1)}
            </Text>

            <Text className={classes.date}>
              {t("comments.submitted", {
                submitDate: format(
                  new Date(props.comment.created),
                  "yyyy-MM-dd"
                ),
              })}
            </Text>

            <div className={classes.rating}>
              <Box mt={1}>
                <KvixRating value={props.comment.rating} readOnly />
              </Box>
            </div>
          </div>
        </Grid>

        <Box flex="1 1 0">
          <Text className={classes.text}>{props.comment.comment}</Text>
        </Box>
      </div>
    </Grid>
  );
};
