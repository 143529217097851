import { AbstractSession } from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import { IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../contexts/user";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  heart: {
    color: `${theme.palette.primary.main}`,
    animation: "$bounce",
    animationDuration: "3s",
  },
  saveText: {
    marginRight: "5px",
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  "@keyframes bounce": {
    "0%": {
      transform: "scale(1)",
    },
    "5%": {
      transform: "scale(1.200)",
    },
    "10%": {
      transform: "scale(1)",
    },
  },
}));

interface SessionFavouriteProps {
  session: AbstractSession;
  withText?: boolean;
}
export const FavouriteSession: React.FC<SessionFavouriteProps> = (props) => {
  const { session } = props;
  const classes = useStyles(props);
  const context = useContext(KvixUserContext);
  const { favouritedSessionIds } = context;
  const [isLiked, setIsLiked] = useState<boolean>();
  const { t, ready } = useTranslation("account");

  useEffect(() => {
    setIsLiked(
      favouritedSessionIds.some((sessionId) => sessionId === session?.id)
    );
  }, [favouritedSessionIds, session?.id]);

  const handleToggleFavourite = (e: React.SyntheticEvent) => {
    e.preventDefault();
    context.actions.toggleFavourite({ session });
  };

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root}>
      {context.user && (
        <IconButton
          size="medium"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={handleToggleFavourite}
          style={{
            backgroundColor: "transparent",
            padding: props.withText ? "0px 4px 12px" : "12px",
          }}
        >
          {props.withText && (
            <Text className={classes.saveText} variant="body2">
              {t("favourites.save")}
            </Text>
          )}
          {isLiked ? (
            <KvixIcon.Heart.Filled className={classes.heart} />
          ) : (
            <KvixIcon.Heart.Empty className={classes.heart} />
          )}
        </IconButton>
      )}
    </div>
  );
};
