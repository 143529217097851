import { Box, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { addDays, format, set } from "date-fns";
import { sv } from "date-fns/locale";
import React, { useContext, useState } from "react";
import { CompetitionContext } from "../../../contexts/competition";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "& $selectedPrimary": {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    color: theme.palette.grey[300],
    pointerEvents: "none",
  },
  selectedPrimary: {
    paddingBottom: "5px",
  },
  unselected: {
    background: theme.palette.background.paper,
  },
  selectable: {
    transition: "all 0.2s ease-out",
    cursor: "pointer",
  },
  dateItem: {
    height: "72px",
    width: "40px",
    borderRadius: "40px",
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const getDateList = (numDays: number): Date[] => {
  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return [...Array(numDays)].map((_, i) => addDays(today, i));
};

type DateItemProps = {
  date: Date;
  onSelect: (date: Date) => void;
  selected: boolean;
  disabled?: boolean;
};
const DateItem = ({ date, onSelect, selected, disabled }: DateItemProps) => {
  const classes = useStyles();
  return (
    <Box
      onClick={(e) => {
        onSelect(date);
      }}
      className={classnames(
        classes.dateItem,
        classes.selectable,
        selected ? classes.selected : classes.unselected,
        disabled ? classes.disabled : ""
      )}
    >
      <div className={classes.selectedPrimary}>
        {format(date, "EEEEE", { locale: sv })}
      </div>
      <div>{date.getDate()}</div>
    </Box>
  );
};

export type RestrictedDatePickerProps = {
  onSelect: (date: Date) => void;
  numDays?: number;
  initial?: number;
};
export const RestrictedDatePicker = ({
  onSelect,
  numDays = 7,
  initial,
}: RestrictedDatePickerProps) => {
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const [selected, setSelected] = useState(initial ? initial : 0);
  const dateList = getDateList(numDays);
  const lastDate =
    activeCompetitionRound?.end && new Date(activeCompetitionRound.end);

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      {dateList.map((date, index) => {
        const onClick = (date: Date) => {
          setSelected(index);
          onSelect(date);
        };
        return (
          <DateItem
            key={index}
            date={date}
            onSelect={onClick}
            selected={index === selected}
            disabled={lastDate && lastDate.getTime() <= date.getTime()}
          />
        );
      })}
    </Box>
  );
};
