import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import cardsWithClarna from "../../partials/assets/bankCardsPlusKlarna.svg";
import cardsWithoutClarna from "../../partials/assets/bankCards.svg";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bankCardsPlusKlarna: {
    width: "230px",
    height: "20px",
  },
  bankCards: {
    width: "176px",
    height: "20px",
  },
}));

const fadeInOut = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
      type: "spring",
    },
  },
};

export default function BankCardsPlusKlarna() {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.mainWrapper}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={fadeInOut}
    >
      <img
        className={classes.bankCardsPlusKlarna}
        src={cardsWithClarna}
        alt="Bank Cards Plus Klarna"
      />
    </motion.div>
  );
}

export function BankCards() {
  const classes = useStyles();
  return (
    <motion.div
      className={classes.mainWrapper}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={fadeInOut}
    >
      <img
        className={classes.bankCards}
        src={cardsWithoutClarna}
        alt="Bank Cards"
      />
    </motion.div>
  );
}
