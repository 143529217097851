import { backend, translation } from "@kvix/shared";
import { startI18n } from "@kvix/ui";
import { init as initSentry } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "core-js/stable/object/entries";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import "swiper/swiper-bundle.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

polyfillCountryFlagEmojis();

if (
  process.env.NODE_ENV !== "development" &&
  !/Prerender/.test(window.navigator.userAgent)
) {
  initSentry({
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications",
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:2",
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:1",
      "ResizeObserver loop limit exceeded",
    ],
    dsn: window.config.SENTRY_DSN,
    environment: window.config.RELEASE_ENV,
    release: window.config.SENTRY_RELEASE,
    integrations: [new Integrations.BrowserTracing() as any],
    tracesSampleRate: 0.2,
    autoSessionTracking: true,
  });
}

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init(translation);
// Kalla changeLanguage vid byte av objekt ovan ^
// i18n.changeLanguage("sv-SE"), yes;
startI18n();
// ReactDOM.createRoot(document.getElementById("root")).render(<App />);
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
