import { Button } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

interface CompletePaymentButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  buyButton: {
    width: "100%",
    height: "40px",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : "rgb(31, 40, 51)",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : "rgb(31, 40, 51)",
    },
  },
}));

export const CompletePaymentButton: FC<CompletePaymentButtonProps> = (
  props
) => {
  const classes = useStyles();
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      className={classes.buyButton}
    >
      {props.children}
    </Button>
  );
};
