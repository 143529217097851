import { AbstractLiveSession, HttpMethods } from "@kvix/shared";
import { Text } from "@kvix/ui";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Popper,
} from "@material-ui/core";
import { DeleteOutlined, PlayArrowOutlined } from "@material-ui/icons";
import EditOutlined from "@material-ui/icons/EditOutlined";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import classNames from "classnames";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import { ShareButton } from "../../../partials/SocialShare/ShareButton";
import { StreamerAdminContext } from "../context";

const useStyles = makeStyles((theme) => ({
  popper: {
    width: "200px",
    padding: "8px 14px",
    background: theme.palette.background.default,
    borderRadius: "10px",
    boxShadow: "0 0 10px rgb(0 0 0 / 0.4)",
  },
  moreButton: {
    cursor: "pointer",
    background: theme.palette.background.paper,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    color: theme.palette.primary.main,

    "&.disabled": {
      color: theme.palette.grey[500],
      cursor: "inherit",
    },
  },
  popperItem: {
    cursor: "pointer",
  },
  popperDivider: {
    margin: "8px -14px",
  },
  action: {
    fontWeight: 500,
  },
}));

interface ConfirmDialogProps {
  open: boolean;
  onConfirm: (event: React.SyntheticEvent) => void;
  onCancel: (event: React.SyntheticEvent) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { t, ready } = useTranslation("wellstarStreams");
  const { open } = props;

  if (!ready) {
    return null;
  }

  return (
    <Dialog open={open} maxWidth="sm">
      <DialogTitle>{t("deleteStreamDialog.title")}</DialogTitle>

      <DialogContent>{t("deleteStreamDialog.text")}</DialogContent>

      <DialogActions>
        <Button onClick={props.onCancel} variant="text">
          {t("deleteStreamDialog.cancel")}
        </Button>

        <Button onClick={props.onConfirm}>
          {t("deleteStreamDialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ScheduledStreamPopupButtonProps = {
  stream: AbstractLiveSession;
  disabled?: boolean;
  onClickEdit: () => void;
  onClickNavigate: () => void;
};
export const ScheduledStreamPopupButton = ({
  onClickEdit,
  onClickNavigate,
  stream,
  disabled,
}: ScheduledStreamPopupButtonProps) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("wellstarStreams");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
  const { updateStreamsLists } = React.useContext(StreamerAdminContext);

  const handleToggleClick = (event: React.SyntheticEvent) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleDeletion = React.useCallback(async () => {
    await fetch(
      `/api/streamer-admin/session-manager/channel/sessions/${stream.id}`,
      {
        method: HttpMethods.DELETE,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    updateStreamsLists();
    setOpenDeleteConfirm(false);
  }, [stream.id, updateStreamsLists]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classNames(classes.moreButton, { disabled })}>
          <MoreHoriz onClick={handleToggleClick} />
          <Popper placement="top-end" open={open} anchorEl={anchorEl}>
            <Box className={classes.popper}>
              <Box
                className={classes.popperItem}
                display="flex"
                justifyContent="space-between"
                onClick={() => onClickNavigate()}
              >
                <Text className={classes.action}>{t("gotoClass")}</Text>
                <PlayArrowOutlined style={{ paddingTop: "1px" }} />
              </Box>
              <Divider className={classes.popperDivider} />
              <Box
                className={classes.popperItem}
                display="flex"
                justifyContent="space-between"
              >
                <Text className={classes.action}>{t("shareStream")}</Text>
                <ShareButton
                  replay={stream}
                  onClose={handleClickAway}
                  style={{
                    padding: 0,
                    position: "absolute",
                    left: 0,
                    width: "100%",
                    paddingLeft: "calc(100% - 52px)",
                    backgroundColor: "transparent",
                  }}
                />
              </Box>
              <Divider className={classes.popperDivider} />
              <Box
                className={classes.popperItem}
                display="flex"
                justifyContent="space-between"
                onClick={onClickEdit}
              >
                <Text className={classes.action}>{t("editStreamPopup")}</Text>
                <EditOutlined />
              </Box>
              <Divider className={classes.popperDivider} />
              <Box
                className={classes.popperItem}
                display="flex"
                justifyContent="space-between"
                onClick={() => setOpenDeleteConfirm(true)}
              >
                <Text className={classes.action}>{t("deleteStream")}</Text>
                <DeleteOutlined style={{ paddingTop: "1px" }} />
              </Box>
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
      <ConfirmDialog
        onConfirm={handleDeletion}
        onCancel={() => setOpenDeleteConfirm(false)}
        open={openDeleteConfirm}
      />
    </>
  );
};
