import { AbstractProgram, AbstractSession } from "@kvix/shared";
import { Button, ReplayListItem } from "@kvix/ui";
import { Box, Divider, Grid, makeStyles } from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { KvixUserContext } from "../../contexts/user";
import { FavouriteSession } from "./GetFavourite";

interface Props {
  replays: AbstractSession[];
  initialReplays: number;
  programs: AbstractProgram[];
}

const useStyles = makeStyles((theme) => ({
  displayMoreButton: {
    textTransform: "none",
    height: "36px",
    padding: "0 14px",
    marginTop: theme.spacing(2),
  },
}));

export const ReplayList: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("general");
  const { replays, initialReplays, programs } = props;
  const classes = useStyles(props);
  const location = useLocation();
  const history = useHistory();
  const { sessions } = useContext(KvixUserContext);
  const INCREASE_NUMBER = 3;
  const [numberOfDisplayedReplays, setNumberOfDisplayedReplays] =
    useState(initialReplays);

  const handleDisplaymore = () => {
    setNumberOfDisplayedReplays(numberOfDisplayedReplays + INCREASE_NUMBER);
  };

  const getProgram = (replayProgramId: number) => {
    return programs.find((program) => program.id === replayProgramId);
  };

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <Box mt={4}>
        <Grid container direction="row" spacing={2}>
          {replays.map((replay, index) => (
            <Fragment key={replay.id}>
              {index >= numberOfDisplayedReplays ? null : (
                <>
                  {index > 0 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box>
                      <ReplayListItem
                        likeable={<FavouriteSession session={replay} />}
                        size={index === 0 ? "large" : "default"}
                        replay={replay as any}
                        program={getProgram(+replay.programId) as any}
                        link={`/replay/${replay.programId}/${replay.id}`}
                        startTime={sessions?.watched[replay.id]}
                      />
                    </Box>
                  </Grid>
                  {index === numberOfDisplayedReplays - 1 &&
                    index < replays.length - 1 && (
                      <Grid
                        item
                        onClick={handleDisplaymore}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <Divider />
                        <Button
                          variant="contained"
                          onClick={handleDisplaymore}
                          className={classes.displayMoreButton}
                        >
                          {t("buttons.displayMore")}
                        </Button>
                      </Grid>
                    )}
                </>
              )}
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};
