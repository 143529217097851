import React, { useContext, useMemo } from "react";
import { RichText } from "@kvix/ui";
import { Dialog, Grid, makeStyles, Theme, useTheme } from "@material-ui/core";
import { useIsMobile } from "../../../../hooks/isMobile";
import { CmsContext } from "src/cms/context";

export const ContentModal = (props: any) => {
  const { pages } = useContext(CmsContext);
	const isMobile = useIsMobile();

	const page = useMemo(() => {
    if (pages) {
      return pages?.find((p: any) => p.slug === props.page)
    }

    return null;
  }, [pages, props.page]);


	const useStyles = makeStyles((theme) => ({
		paper: {
			background: theme.palette.background["default"],
			color: theme.palette.text["primary"],
			minHeight: window.innerHeight,
			width: "100%",
			paddingTop: theme.spacing(0),
			paddingBottom: theme.spacing(0),
			paddingRight: theme.spacing(0),
			paddingLeft: theme.spacing(0),
			margin: 0,
			textAlign: "justify",
			[theme.breakpoints.up("sm")]: {
				maxWidth: "375px",
				minHeight: "92vh",
				maxHeight: "92vh",
				margin: theme.spacing(1)
			},
			overflow: "hidden",
		},
		scrollPaper: {
			justifyContent: "center"
		}
	}));

	const theme = useTheme();
  const classes = useStyles()

  return (
    <Dialog
      open={props.open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={() => props.handleOpen(false)}
      fullScreen={isMobile}
			classes={{
				paper: classes.paper,
				scrollPaper: classes.scrollPaper
			}}
    >
			{ props.children }
			<Grid container style={{ padding: "0px 48px", overflowY: "scroll"}}>
				<RichText text={page?.content} />
			</Grid>
    </Dialog>
  );
};
