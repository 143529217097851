import { KvixIcon, RichText } from "@kvix/ui";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CmsContext } from "../../cms/context";
import { KvixUserContext } from "../../contexts/user";
import { useIsMobile } from "../../hooks/isMobile";

const useStyles = makeStyles((theme) => ({
  scrollButton: {
    "&.MuiFab-root": {
      position: "absolute",
      bottom: 118,
      right: 36,
      [theme.breakpoints.down("sm")]: {
        right: 18,
        bottom: 124,
      },
    },
  },
  scrollButtonIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: 34,

      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
  buttonRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 38,
  },
}));

export const TosConsent: React.FC = (props) => {
  const { t, ready } = useTranslation("tosConsent");
  const { pages } = useContext(CmsContext);
  const { user, actions } = useContext(KvixUserContext);
  const isMobile = useIsMobile();
  const classes = useStyles(props);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const contentRef = useRef<HTMLDivElement>();
  const bottomRef = useRef<HTMLDivElement>();
  const termsPage = pages?.find((p) => p.slug === "terms");
  const version = termsPage?.tosVersion;

  const versionDeprecated = useMemo(() => {
    if (version) {
      return false;
    }
    return false;
  }, [version, user]);

  const onScroll = useCallback(() => {
    const newValue =
      contentRef.current?.scrollTop + contentRef.current?.offsetHeight >=
      contentRef.current?.scrollHeight;

    if (newValue !== scrolledToBottom) {
      setScrolledToBottom(newValue);
    }
  }, [contentRef, scrolledToBottom]);

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  if (!termsPage || !ready) {
    return null;
  }

  return (
    <Dialog
      open={versionDeprecated}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle>{t("updatedTerms")}</DialogTitle>
      <DialogContent dividers ref={contentRef} onScroll={onScroll}>
        <div tabIndex={-1} id="bottom-anchor" style={{ outline: "none" }}>
          <RichText text={termsPage?.content} noPadding compact={isMobile} />
          <div className={classes.buttonRow}>
            <Button
              color="primary"
              onClick={actions.signOut}
              size={isMobile ? "small" : "medium"}
            >
              {t("decline")}
            </Button>
            <Button
              color="primary"
              onClick={() => actions.updateTos(version)}
              variant="contained"
              size={isMobile ? "small" : "medium"}
            >
              {t("accept")}
            </Button>
          </div>
          <div ref={bottomRef} />
        </div>
      </DialogContent>
      <DialogActions style={{ flexDirection: "column", alignItems: "unset" }}>
        <Button
          color="primary"
          onClick={scrollToBottom}
          variant="contained"
          disabled={scrolledToBottom}
        >
          {t("jumpDown")}
          <KvixIcon.Chevron.Down />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
