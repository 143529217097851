import { AbstractPlaylist, HttpMethods } from "@kvix/shared";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

export const useFetchPlaylist = (id: number) => {
  const [playlist, setPlaylist] = useState<AbstractPlaylist>(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`/api/playlist/${+id}`, {
          method: "GET",
        });
        if (!response.ok) {
          history.replace("/page-not-found");
        } else {
          setPlaylist(await response.json());
        }
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [history, id]);

  return playlist;
};

export const useFetchPlaylistsByUserId = (id: number) => {
  const [playlists, setPlaylists] = useState<AbstractPlaylist[]>(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`/api/playlist/user/${+id}`, {
          method: "GET",
        });
        if (!response.ok) {
          history.replace("/page-not-found");
        } else {
          setPlaylists((await response.json()) as AbstractPlaylist[]);
        }
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [history, id]);

  return playlists;
};

export const useFetchFavouritedPlaylists = (ids: number[]) => {
  const [playlists, setPlaylists] = useState<AbstractPlaylist[]>([]);

  useEffect(() => {
    if (ids && ids.length > 0) {
      fetch(`/api/playlist/favourited`, {
        method: HttpMethods.POST,
        body: JSON.stringify({
          ids,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then(setPlaylists);
      });
    } else {
      setPlaylists([]);
    }
  }, [ids]);
  return playlists;
};
