import queryString from "query-string";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import Stripe from "stripe";
import { PaymentContext } from "../../context";

export const SubscribeSteps = ["choose-plan", "payment", "train"] as const;
export type SubscribeStep = typeof SubscribeSteps[any];

interface SubscribeContextStateActions {
  setPlan: (plan: Stripe.Plan) => void;
  setCoupon: (coupon: Stripe.Coupon) => void;
  submit: () => void;
}

interface SubscribeContextState {
  plan?: Stripe.Plan;
  coupon?: Stripe.Coupon;
  promoCode?: Stripe.PromotionCode;
  stepId: SubscribeStep;
  loading: boolean;
  actions: SubscribeContextStateActions;
}

export const SubscribeContext = createContext<SubscribeContextState>(null);

export interface SubscribeLocation {
  plan?: Stripe.Plan;
  coupon?: Stripe.Coupon;
}

const supportsCoupon = (plan: Stripe.Plan) => {
  return plan && plan.interval !== "year";
};

export const SubscribeContextProvider: React.FC = (props) => {
  const payment = useContext(PaymentContext);
  const { checkout: paymentCheckout } = payment.actions;

  const location = useLocation<SubscribeLocation>();
  const history = useHistory<SubscribeLocation>();

  const queryParams = queryString.parse(location.search);
  const showSuccess = payment.hasDefaultPlan || queryParams.success === "true";

  const plan = useMemo(() => location.state?.plan, [location.state]);
  const coupon = useMemo(() => location.state?.coupon, [location.state]);

  const [loading, setLoading] = useState(false);

  const checkout = useCallback(
    async (planId?: string, couponId?: string) => {
      setLoading(true);

      try {
        await paymentCheckout({ planId, couponId });
      } catch (error) {
        setLoading(false);
        throw error;
      }

      setLoading(false);
    },
    [paymentCheckout]
  );

  const setPlan = useCallback(
    (plan: Stripe.Plan) => {
      const showCoupon = supportsCoupon(plan);

      if (showCoupon) {
        history.push(location.pathname, { plan });
      } else if (plan["activeCampaign"]) {
        checkout(plan.id, plan["activeCampaign"]["id"]);
      } else {
        checkout(plan.id);
      }
    },
    [history, location.pathname, checkout]
  );

  const setCoupon = useCallback(
    (coupon: Stripe.Coupon) => {
      history.replace(location.pathname, {
        plan: location.state.plan,
        coupon,
      });
    },
    [history, location.pathname, location.state]
  );

  const stepId = useMemo<SubscribeStep>(() => {
    if (showSuccess) {
      return "train";
    }

    if (!plan) {
      return "choose-plan";
    }

    if (!payment.hasDefaultPlan) {
      return "payment";
    }

    return "train";
  }, [payment.hasDefaultPlan, plan, showSuccess]);

  const submit = useCallback(() => checkout(plan.id, coupon?.id), [
    checkout,
    coupon,
    plan,
  ]);

  return (
    <SubscribeContext.Provider
      value={{
        plan,
        coupon,
        stepId,
        loading,
        actions: {
          setPlan,
          setCoupon,
          submit,
        },
      }}
    >
      {props.children}
    </SubscribeContext.Provider>
  );
};
