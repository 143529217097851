import { HeroCarousel } from "../../HeroCarousel";
import { getData, RawMedia } from "../adapter";

interface RawHeroCarousel {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    hero_carousel_slides: RawCarouselSlides;
  };
}

interface RawCarouselSlides {
  data: [
    {
      id: number;
      attributes: {
        buttonText: string;
        buttonUrl: string;
        createdAt: Date;
        updatedAt: Date;
        description: string;
        hash: string;
        instructor: string;
        media: RawMedia;
        mediaMobile: RawMedia;
        priority: number;
        promotion: boolean;
        title: string;
      };
    }
  ];
}
export const HeroCarouselAdapter = async (
  rawJSON: RawHeroCarousel
): Promise<HeroCarousel> => {
  const {
    id,
    attributes: { createdAt, updatedAt, hero_carousel_slides },
  } = await getData<RawHeroCarousel>(rawJSON);

  const slides = hero_carousel_slides.data.map(
    ({
      id,
      attributes: { createdAt, updatedAt, media, mediaMobile, ...rest },
    }) => ({
      ...rest,
      id: id,
      hero_carousel_slides: hero_carousel_slides,
      media: media.data.attributes,
      mediaMobile: mediaMobile.data.attributes,
      created_at: createdAt,
      updated_at: updatedAt,
    })
  );

  return {
    created_at: createdAt,
    updated_at: updatedAt,
    id: id,
    hero_carousel_slides: slides,
  };
};
