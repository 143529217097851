import { useContext } from "react";
import { ExpoContext } from "../contexts/expo";

export const useShowPromotions = () => {
  const expo = useContext(ExpoContext);
  
  if (expo.isNativeApp) {
    return false;
  }

  return true;
};
