import { Blog } from "../../Blog";
import { Blogpost } from "../../Blogpost";
import { getData, RawMedia } from "../adapter";

export interface JSONBlog {
  id: number;
  attributes: {
    id: number;
    title: string;
    content: string;
    author: string;
    noAuthorLink: boolean;
    caption: string;
    image: RawMedia;
    featured: boolean;
    slug: string;
    userId: number;
    priority?: number;
    ingress: string;
    createdAt: Date;
    updatedAt: Date;
    blogposts: {
      data: JSONBlogpost[];
    };
  };
}

interface JSONBlogpost {
  id: number;
  attributes: {
    title: string;
    content: string;
    author: string;
    noAuthorLink: boolean;
    caption: string;
    image: RawMedia;
    featured: boolean;
    slug: string;
    userId: number;
    priority?: number;
    ingress: string;
    createdAt: Date;
    updatedAt: Date;
  };
}
export const BlogAdapter = async (rawJSON: JSONBlog): Promise<Blog> => {
  const {
    id,
    attributes: { title, blogposts, createdAt, updatedAt },
  } = await getData<JSONBlog>(rawJSON);

  const posts: Blogpost[] = blogposts.data.map(
    ({ id, attributes: { image, createdAt, updatedAt, ...rest } }) => ({
      ...rest,
      id,
      image: image.data.attributes,
      created_at: createdAt,
      updated_at: updatedAt,
    })
  );
  return {
    id,
    title,
    blogposts: posts,
    created_at: createdAt,
    updated_at: updatedAt,
  };
};
