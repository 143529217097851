import { Text } from "@kvix/ui";
import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Exercisepost } from "../../../../../cms/models/Exercisepost";

const StyledDiv = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  margin: ${(props) =>
    props.isMobile ? "36px 12px 36px 0" : "36px 24px 36px 0"};

  flex-direction: column;
`;

const StyledLink = styled(Link)<{
  isMobile: boolean;
}>`
  width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
`;

const ImageContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  margin: ${(props) => (props.isTablet ? "0 0 12px 0" : "0 24px 0 0")};
  position: relative;
`;

const Image = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
`;
export const ExercisepostSmall: React.FC<{
  isTablet?: boolean;
  isMobile?: boolean;
  exercisepost: Exercisepost;
}> = (props) => {
  return (
    <StyledLink
      to={`/exercise/${props.exercisepost.slug}`}
      isMobile={props.isMobile}
    >
      <StyledDiv isTablet={props.isTablet} isMobile={props.isMobile}>
        <ImageContainer isTablet={props.isTablet} isMobile={props.isMobile}>
          <Image url={props.exercisepost.image.url} />
        </ImageContainer>
        <div style={{ overflowWrap: "break-word" }}>
          <Box mt={1}>
            <Text
              style={{ textTransform: "none", fontSize: 12 }}
              variant="caption"
              color="primary"
            >
              {props.exercisepost.caption}
            </Text>
          </Box>
          <Text variant="h4" style={{ overflowWrap: "break-word" }}>
            {props.exercisepost.title}
          </Text>
          <Text style={{ marginTop: 16 }} variant="body1">
            {props.exercisepost.ingress}
          </Text>
        </div>
      </StyledDiv>
    </StyledLink>
  );
};
