import { AbstractCategory } from "@kvix/shared";
import { ClickableTitle, KvixIcon, Text } from "@kvix/ui";
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  title: string;
  description: string;
  explore: ExploreProps;
  categories: AbstractCategory[];
  noMargin?: boolean;
}

interface ExploreProps {
  text: string;
  url: string;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: {
    // marginTop: (props: any) => (props.noMargin ? 0 : theme.spacing(10)),
    // marginBottom: (props: any) => (props.noMargin ? 0 : theme.spacing(6)),
    // backgroundColor: "Crimson",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  titleRow: {
    display: "flex",
    alignItems: "baseline",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
  categoryCardGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      gridGap: "32px",
    },
    [theme.breakpoints.down("lg")]: {
      gridGap: "32px",
    },
    [theme.breakpoints.down("md")]: {
      gridGap: "32px",
    },
    [theme.breakpoints.down("sm")]: {
      gridGap: "32px",
    },
    [theme.breakpoints.down("xs")]: {
      gridGap: "12px",
    },
  },
  categoryCard: {
    background: "#2b333f",
    color: theme.palette.common.white,
    padding: "40px",
    position: "relative",
    borderRadius: "12px",
    width: "calc(33.3% - 24px)",

    [theme.breakpoints.down("xs")]: {
      width: "calc(50% - 6px)",
      height: "52px",
      borderRadius: "4px",
    },
  },
  categoryCardText: {
    position: "absolute",
    top: "8px",
    left: "8px",
    color: theme.palette.common.white,
  },
}));

const Explore = styled(Link)`
  &.a[href]:not(.MuiLink-root):hover > h4 {
    color: blue;
  }
`;

const ExploreButton: React.FC<ExploreProps> = memo((props) => {
  return (
    <Explore to={props.url} style={{ display: "flex", alignItems: "flex-end" }}>
      <Text color="primary" variant="body2" style={{ fontWeight: 700 }}>
        {props.text}
      </Text>
      <KvixIcon.Arrow.Forward color="primary" style={{ fontSize: "16px" }} />
    </Explore>
  );
});

const CategoryCard: React.FC<{
  category: AbstractCategory;
  isDesktop: boolean;
}> = memo((props) => {
  const { category, isDesktop } = props;
  const classes = useStyles(props);
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <Link
      style={
        category.imageUrl && {
          background: `url(${category.imageUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }
      }
      className={classes.categoryCard}
      to={`/programs/${category.slug}`}
    >
      <Text
        className={classes.categoryCardText}
        color="textSecondary"
        // variant={isDesktop ? "h4" : "h5"}
        variant={"h3"}
      >
        {t(`classes.${category.title}`)}
      </Text>
    </Link>
  );
});

export const CategoryContainer: React.FC<Props> = memo((props) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Container maxWidth="xl" className={classes.root}>
      {props.title && (
        <div className={classes.titleRow}>
          <ClickableTitle title={props.title} titleUrl={"/programs"} />
          {props.explore && (
            <ExploreButton text={props.explore.text} url={props.explore.url} />
          )}
        </div>
      )}
      {props.description && (
        <Text
          variant={!isMobile ? "body1" : "body2"}
          className={classes.description}
        >
          {props.description}
        </Text>
      )}
      <Grid
        container
        className={classes.categoryCardGrid}
        style={{ justifyContent: "space-between", gridGap: "12px" }}
        direction={"row"}
      >
        {props.categories?.map((category) => (
          <CategoryCard
            category={category}
            isDesktop={!isMobile}
            key={category.id}
          />
        ))}
      </Grid>
    </Container>
  );
});
