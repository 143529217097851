import { AbstractSession, CastState } from "@kvix/shared";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { sendCastAnytime } from "../../../contexts/expo";
import { useAppCastState, useIsNativeApp } from "../../../hooks/nativeApp";
import { ReplayCasting } from "./ReplayCasting";
import { ReplayPlaceholder } from "./ReplayPlaceholder";

type showType = "showPlaying" | "showPlaceholder" | "showPlayer";

interface Props {
  replay: AbstractSession;
  poster?: string;
  source?: {
    src: string;
    type: string;
  };
  metadata: any;
  children: (playerRef?: React.MutableRefObject<any>) => React.ReactElement;
}
export const ReplayContainer: React.FC<Props> = (props) => {
  const isNativeApp = useIsNativeApp();

  if (isNativeApp) {
    return <AppReplayContainer {...props}>{props.children}</AppReplayContainer>;
  }

  return <>{props.children()}</>;
};

const AppReplayContainer: React.FC<Props> = (props) => {
  const [type, setType] = useState<showType>("showPlayer");
  const [state, , sessionId] = useAppCastState();
  const playerRef = useRef(null);
  const currentSid = +props.replay.id;

  const castAnytime = useCallback(
    (currentTime: number = 0) =>
      sendCastAnytime({
        contentType: "anytime",
        sessionId: currentSid,
        metadata: props.metadata,
        currentTime,
        ...props.source,
      }),
    [currentSid, props.metadata, props.source]
  );

  useEffect(() => {
    if (state === CastState.CONNECTED && playerRef?.current?.currentTime > 0) {
      castAnytime(playerRef.current.currentTime);
      setType("showPlaying");
      return;
    }

    if (state === CastState.PLAYING && sessionId === currentSid) {
      setType("showPlaying");
    } else if (state !== CastState.NOT_CONNECTED) {
      setType("showPlaceholder");
    } else {
      setType("showPlayer");
    }
  }, [state, sessionId, castAnytime, currentSid]);

  if (type === "showPlaying") {
    return <ReplayCasting poster={props.poster} />;
  }

  if (type === "showPlaceholder") {
    return (
      <ReplayPlaceholder
        poster={props.poster}
        onClick={() => {
          castAnytime();
        }}
      />
    );
  }

  return <>{props.children(playerRef)}</>;
};
