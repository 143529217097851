function parseQuery(qstr) {
  const query = {};
  const a = (qstr[0] === "?" ? qstr.substr(1) : qstr).split("&");
  for (let i = 0; i < a.length; i++) {
    const b = a[i].split("=");
    query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || "");
  }
  return query;
}

const valuesThatShouldBeParsedAsInt = [
  "snapshotFrames",
  "snapshotFrameDelayMs",
  "snapshotIntervalMs",
  "snapshotRepeat",
  "snapshotWidth",
  "snapshotHeight",
];
const valuesThatShouldBeParsedAsFloat = [];
const valuesThatShouldBeParsedAsBoolean = [
  "open",
  "debug",
  "agc",
  "ec",
  "ns",
  "disabled",
];
const valuesThatShouldBeParsedAsString = [];

class QueryParams {
  public static fromQueryString(queryString) {
    const queryParams = new QueryParams();
    const parsedQueryString = parseQuery(queryString);

    Object.keys(parsedQueryString).forEach((key) => {
      if (valuesThatShouldBeParsedAsInt.indexOf(key) !== -1) {
        const intValue = parseInt(parsedQueryString[key], 10);
        queryParams[key] = isNaN(intValue) ? undefined : intValue;
      } else if (valuesThatShouldBeParsedAsBoolean.indexOf(key) !== -1) {
        queryParams[key] =
          parsedQueryString[key].toLowerCase() === "false" ? false : true;
      } else if (valuesThatShouldBeParsedAsFloat.indexOf(key) !== -1) {
        const floatValue = parseFloat(parsedQueryString[key]);
        queryParams[key] = isNaN(floatValue) ? undefined : floatValue;
      } else {
        queryParams[key] = parsedQueryString[key];
      }
    });

    return queryParams;
  }

  public snapshotFrames?: number;
  public snapshotFrameDelayMs?: number;
  public snapshotIntervalMs?: number;
  public snapshotRepeat?: number;
  public snapshotWidth?: number;
  public snapshotHeight?: number;

  public disabled?: boolean;

  public agc?: boolean;
  public ec?: boolean;
  public ns?: boolean;
  public debug?: boolean;
  public open?: boolean;

  public toQueryString(): string {
    const queryStrings: string[] = [];

    valuesThatShouldBeParsedAsBoolean.forEach((key) => {
      if (this[key]) {
        queryStrings.push(`${key}`);
      }
    });

    valuesThatShouldBeParsedAsInt.forEach((key) => {
      const value = this[key];

      if (value !== undefined && value !== -1) {
        queryStrings.push(`${key}=${value}`);
      }
    });

    valuesThatShouldBeParsedAsString.forEach((key) => {
      const value = this[key];

      if (value !== undefined && value !== "") {
        queryStrings.push(`${key}=${value}`);
      }
    });

    return queryStrings.join("&");
  }
}

export default QueryParams;
