import { AbstractBaseUser, IssuedFrom } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { PaymentMethod } from "@stripe/stripe-js";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Stripe } from "stripe";
import { KvixUserContext } from "../../../../contexts/user";
import { useUserBalance } from "../hooks";
import { DonateTabState } from "./enums/DonateTabState";
import { DonateType } from "./enums/DonateType";
import { DonationAlertSeverity } from "./enums/DonationAlertSeverity";
import { AbstractDonationAlert, DonationAlert } from "./partials/DonationAlert";
import { ModalHeader } from "./partials/ModalHeader";
import { WellstarModalFooter } from "./partials/WellstarModalFooter";
import { AddCardTab } from "./sections/AddCardTab";
import { BuyTab } from "./sections/BuyTab";
import { CheckoutTab } from "./sections/CheckoutTab";
import { DonateTab } from "./sections/DonateTab";
import { NoCoinsTab } from "./sections/NoCoinsTab";
import { ReadMoreTab } from "./sections/ReadMoreTab";
import { TermsTab } from "./sections/TermsTab";
import { VoteTab } from "./sections/VoteTab";

interface Props {
  donateType: DonateType;
  close: () => void;
  donationRecipient: AbstractBaseUser;
  issuedFrom: IssuedFrom;
  sessionId?: number;
  onRedirect?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    width: "100%",
    marginTop: "18px",
    marginBottom: "18px",
    paddingLeft: "12px",
    paddingRight: "12px",
    minHeight: (props: any) => (props.user ? "500px" : 0),
    overflowY: "auto",
  },
  header: {
    marginTop: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  body: {
    marginTop: "40px",
    marginLeft: "12px",
    marginRight: "12px",
  },
  footer: {
    marginLeft: "12px",
    marginRight: "12px",
    marginBottom: "40px",
  },
  alertContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  login: {
    margin: "40px 12px 20px",
  },
  takeMeThere: {
    height: 32,
    marginTop: 20,
    padding: "0 12px",
  },
}));

export const Donate: FC<Props> = (props) => {
  const { onRedirect = () => null } = props;
  const currentCoins: number = useUserBalance();
  const [displayBalance, setDisplayBalance] = useState<number>(0);
  const [selectedPrice, setSelectedPrice] = useState<Stripe.Price | null>(null);
  const [alert, setAlert] = useState<AbstractDonationAlert>(null);
  const [coinFetchComplete, setCoinFetchComplete] = useState<boolean>(false);
  const [newPaymentMethod, setNewPaymentMethod] = useState<PaymentMethod>(null);
  const { t, ready } = useTranslation("donateView");
  const history = useHistory();
  const { user } = useContext(KvixUserContext);
  const classes = useStyles(user);

  const defaultTabState = () => {
    if (props.issuedFrom === IssuedFrom.SIDEBAR) return DonateTabState.BUY;
    else if (currentCoins === 0) return DonateTabState.NOCOINS;
    else if (props.donateType === DonateType.COIN) return DonateTabState.DONATE;
    else return DonateTabState.VOTE;
  };

  const [tabState, setTabState] = useState<DonateTabState>(null);
  const [prevTabState, setPrevTabState] = useState<DonateTabState[]>([]);

  const changeTab = (newTabState: DonateTabState, reset?: boolean) => {
    if (reset) {
      setPrevTabState([]);
    } else {
      if (newTabState === prevTabState[0]) {
        if (prevTabState.length > 1) {
          setPrevTabState(prevTabState.slice(1));
        } else {
          setPrevTabState([]);
        }
      } else {
        setPrevTabState([tabState, ...prevTabState]);
      }
    }
    setTabState(newTabState);
    closeAlert();
  };

  const headerOnClick = () => {
    if (prevTabState.length !== 0) {
      changeTab(prevTabState[0]);
    } else {
      props.close();
    }
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const setDonationAlert = (
    message: string,
    severity: DonationAlertSeverity
  ) => {
    setAlert({ message: message, severity: severity });
  };

  useEffect(() => {
    if (currentCoins !== null && !coinFetchComplete) {
      setTabState(defaultTabState());
      setCoinFetchComplete(true);
    }

    if (currentCoins !== null) {
      setDisplayBalance(currentCoins);
    }
  }, [currentCoins]);

  if (!ready) {
    return null;
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <ModalHeader
          currentCoins={displayBalance}
          onClick={headerOnClick}
          prevTabState={prevTabState}
          setTabState={changeTab}
        />
      </Grid>
      {user ? (
        <>
          <Grid className={classes.alertContainer}>
            {alert !== null &&
              ![DonateTabState.VOTE, DonateTabState.DONATE].includes(
                tabState
              ) && (
                <DonationAlert alertObject={alert} setTabState={changeTab} />
              )}
          </Grid>
          <Grid className={classes.body}>
            <DonateTab
              tabState={tabState}
              setTabState={changeTab}
              donationRecipient={props.donationRecipient}
              currentCoins={displayBalance}
              setDonationAlert={setDonationAlert}
              donationAlert={alert}
              closeAlert={closeAlert}
              issuedFrom={props.issuedFrom}
              sessionId={props.sessionId}
            />
            <VoteTab
              tabState={tabState}
              setTabState={changeTab}
              donationRecipient={props.donationRecipient}
              currentCoins={displayBalance}
              setDonationAlert={setDonationAlert}
              donationAlert={alert}
              closeAlert={closeAlert}
              issuedFrom={props.issuedFrom}
              sessionId={props.sessionId}
            />
            <BuyTab
              tabState={tabState}
              setTabState={changeTab}
              currentCoins={displayBalance}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
            />
            <CheckoutTab
              tabState={tabState}
              setTabState={changeTab}
              selectedPrice={selectedPrice}
              setSelectedPrice={setSelectedPrice}
              donateType={props.donateType}
              donationAlert={setDonationAlert}
              newPaymentMethod={newPaymentMethod}
              issuedFrom={props.issuedFrom}
            />
            <ReadMoreTab tabState={tabState} />
            <TermsTab tabState={tabState} />
            <AddCardTab
              tabState={tabState}
              donationAlert={setDonationAlert}
              setTabState={changeTab}
              setNewPaymentMethod={setNewPaymentMethod}
            />
            <NoCoinsTab
              tabState={tabState}
              setTabState={changeTab}
              onClose={() => props.close()}
            />
          </Grid>
        </>
      ) : (
        <div className={classes.login}>
          <Text variant="h4">{t("pleaseLogin")}</Text>
          <Button
            className={classes.takeMeThere}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: "/login",
                state: {
                  from: history.location.pathname,
                },
              });
              props.close();
              onRedirect();
            }}
          >
            {t("takeMeThere")}
          </Button>
        </div>
      )}
      <Grid className={classes.footer}>
        {props.donateType === DonateType.VOTE && (
          <>
            {tabState === DonateTabState.READMORE ? (
              <WellstarModalFooter />
            ) : (
              <WellstarModalFooter setTabState={changeTab} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
