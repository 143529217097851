import { KvixIcon, RichText, Text, THEOPlayer } from "@kvix/ui";
import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ExerciseContext } from "../../../../../contexts/exercise";
import { ExpoContext } from "../../../../../contexts/expo";
import { KvixUserContext } from "../../../../../contexts/user";
import { useShowPromotions } from "../../../../../hooks/promotions";
import { Footer } from "../../../../Footer";
import { Banner } from "../../../../partials/Banner/Banner";
import { Page } from "../../../../partials/Page";
import SharingModal from "../../../../partials/SharingModal";
import { PaymentContext } from "../../../Payment/context";
import { ExercisepostSmall } from "./ExercisepostSmall";

const VideoContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 800px) {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }
`;

const OtherContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? "start" : "none")};
  width: 100%;
  flex-wrap: wrap;
  padding: 24px 0;
`;

const StyledFab = styled(Fab)<{ $theme: Theme }>`
  margin-top: 14px;
  color: ${(props) => props.$theme.palette.primary.contrastText};
  text-transform: initial;
  background: ${(props) => props.$theme.palette.secondary.main};
  width: 36px;
  height: 36px;
  box-shadow: none;

  &:hover {
    background: ${(props) => props.$theme.palette.secondary.dark};
  }
`;

export const CmsExercisepost: React.FC<{ slug: string }> = (props: {
  slug: string;
}) => {
  const theme = useTheme();
  const { exercise } = useContext(ExerciseContext);
  const { slug } = props;
  const { t, ready } = useTranslation(["exercise", "seo"]);

  const exercisepost = useMemo(() => {
    if (exercise) {
      return exercise.exerciseposts.find(
        (exercisepost) => exercisepost.slug === slug
      );
    }

    return null;
  }, [exercise, slug]);

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const exercisePosts = exercise?.exerciseposts
    ?.filter((b) => b !== exercisepost)
    .sort(() => 0.5 - Math.random())
    .slice(0, 3);

  const [sharingModalOpen, setSharingModalOpen] = useState(false);
  const { isNativeApp, actions } = useContext(ExpoContext);

  const handleOnClick = () => {
    if (isNativeApp) {
      actions.share({
        url: window.location.href,
        title: t("shareThisClass"),
        subject: t("shareProgram.emailSubject"),
      });
      return;
    }

    setSharingModalOpen(true);
  };

  if (!ready) {
    return null;
  }

  return (
    <Page
      meta={
        exercise && exercisepost
          ? {
              title: t("seo:exercise.title", {
                title: exercisepost.title,
              }),
              description: exercisepost.ingress,
              ogTitle: exercisepost.title,
              ogImage: exercisepost.image.url,
            }
          : {}
      }
    >
      {exercise && exercisepost && (
        <Fragment>
          <Container maxWidth="lg">
            <VideoContainer>
              <THEOPlayer
                source={{
                  sources: {
                    src:
                      exercisepost.video.url.indexOf("//") === -1
                        ? `//${exercisepost.video.url}`
                        : exercisepost.video.url,
                    type: exercisepost.video.mime,
                  },
                }}
                poster={exercisepost.image.url}
              />
            </VideoContainer>

            <Box padding={isMobile ? "0" : "0 20%"} marginTop={3}>
              <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item>
                  <Text
                    variant="caption"
                    color="primary"
                    style={{
                      margin: "28px 0 0 0",
                      display: "block",
                      textTransform: "none",
                      fontSize: 12,
                    }}
                  >
                    {exercisepost.caption}
                  </Text>
                </Grid>
                <Grid item>
                  <StyledFab
                    $theme={theme}
                    onClick={handleOnClick}
                    size="small"
                    disableRipple
                  >
                    <KvixIcon.Share style={{ marginRight: "2px" }} />
                  </StyledFab>
                </Grid>
              </Grid>
              <Box>
                <Text variant="h1" style={{ fontSize: 42 }}>
                  {exercisepost.title}
                </Text>
              </Box>
              <Box mt={4}>
                <Text variant="body1" style={{ whiteSpace: "pre-line" }}>
                  <RichText text={exercisepost.content} />
                </Text>
              </Box>
            </Box>

            <Box mt={8} mb={8}>
              <Divider />
            </Box>

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: theme.spacing(2),
              }}
            >
              <Text variant="h4" color="textPrimary">
                {t("showMoreText")}
              </Text>
              <Link
                to="/exercise"
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <Text
                  color="primary"
                  variant="body2"
                  style={{ fontWeight: 700 }}
                >
                  {t("showMoreLink")}
                </Text>
                <KvixIcon.Arrow.Forward
                  color="primary"
                  style={{ fontSize: "16px" }}
                />
              </Link>
            </div>

            <OtherContainer isMobile={isMobile} isTablet={isTablet}>
              {exercisePosts.map((exercisepost) => (
                <ExercisepostSmall
                  isTablet={isTablet}
                  isMobile={isMobile}
                  key={exercisepost.id}
                  exercisepost={exercisepost}
                />
              ))}
            </OtherContainer>

            <Box mt={8} mb={12}>
              <Banner type="upgrade-premium" size="large" />
            </Box>
          </Container>
        </Fragment>
      )}

      <SharingModal
        open={sharingModalOpen}
        url={window.location.href}
        onClose={() => setSharingModalOpen(false)}
        texts={{
          title: t("share.title"),
          facebookQuote: t("share.facebookQuote"),
          facebookHashtag: t("share.facebookHashtag"),
          whatsappTitle: t("share.whatsappTitle"),
          emailSubject: t("share.emailSubject"),
          emailBody: t("share.emailBody"),
          smsBody: t("share.smsBody"),
        }}
      />

      <Footer />
    </Page>
  );
};
