import React from "react";
import { Button, Container, Text, KvixIcon } from "@kvix/ui";
import { makeStyles, Grid, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { useIsAndroid } from "src/hooks/isAndroid";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import headbandImg from "../../partials/assets/headband.png";

const useStyles = makeStyles((theme) => ({
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "720px",
  },
  topTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: theme.spacing(2),
  },
  image: {
    maxWidth: "72px",
    alignSelf: "center",
  },
  tosAndLoginContainer: {
    backgroundColor: "#F8F8F7",
    padding: "8px 0px 8px 0px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    flex: 1,
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.25",
    },
  },
  ContainerPadding: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(0),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingBottom: 0,
    },
    maxWidth: "270px",
  },
  checkmarkIcon: {
    height: "16.4px",
    width: "16px",
    position: "relative",
    bottom: "-4px",
  },
  big: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 26,
    width: "100%",
    marginBottom: "16px",
  },
  headerText: {
    fontSize: "20px",
    lineHeight: "32px",
    marginBottom: "4px",
  },
  bottomBar: {
    backgroundColor: "#F8F8F7",
    padding: "16px 16px 16px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    width: "100%",
    height: "auto",
    top: "auto",
    bottom: 0,
  },
}));

export const SuccessStep = ({
  showContentModal,
  headerText,
}) => {
  const classes = useStyles();
  const isAndroid = useIsAndroid();

  const { t, ready } = useTranslation(["signUp", "premiumPromptTable", "successfulRegistration"]);
  const kvixApp = "kvixapp://";

  if (!ready) {
    return null;
  }

  return (
    <PurchaseFlowConsumer>
      {({ actions }) => (
        <React.Fragment>
          <Container scrollableY disableScrollbar className={classes.topWrapper}>
            <Grid container direction="column" style={{ flex: 1, paddingLeft: 32, paddingRight: 32 }}>
              <Grid container style={{ flex: 1 }} direction="column" alignItems="center">
                <img src={headbandImg} className={classes.image} alt={"hduwahdwa"} />
                <Grid container className={classes.topTextBlock} style={{ alignSelf: "center" }}>
                  <Text variant="h5" className={classes.headerText} style={{ textTransform: "capitalize" }}>
                    {t("signUp:welcome")}
                  </Text>
                </Grid>
                <Grid className={classes.topTextBlock}>
                  <Text variant="h5" style={{ margin: "auto", fontSize: "15px", fontWeight: 500, lineHeight: "24px" }}>
                    {t("premiumPromptTable:premiumFeatures.8")}
                  </Text>
                </Grid>
                {/* <Grid className={classes.topTextBlock}>
                  <Text variant="h5">
                    {t("premiumPromptTable:premiumFeatures.0")} <KvixIcon.Checkmark.Simple color="primary" className={classes.checkmarkIcon} />
                  </Text>
                  <Text variant="h5">
                    {t("premiumPromptTable:premiumFeatures.5")} <KvixIcon.Checkmark.Simple color="primary" className={classes.checkmarkIcon} />
                  </Text>
                  <Text variant="h5">
                    {t("premiumPromptTable:premiumFeatures.6")} <KvixIcon.Checkmark.Simple color="primary" className={classes.checkmarkIcon} />
                  </Text>
                  <Text variant="h5">
                    {t("premiumPromptTable:premiumFeatures.7")} <KvixIcon.Checkmark.Simple color="primary" className={classes.checkmarkIcon} />
                  </Text>
                </Grid> */}
                {isAndroid ? (
                  <Grid container>
                    <Button>
                      <a
                        href={kvixApp}
                        className={classes.big}
                        onClick={() => {
                          actions.closePrompt();
                          return <Redirect to="/?signUpRedirect=true" />;
                        }}
                      >
                        {t("signUp:finish")}
                      </a>
                    </Button>
                  </Grid>
                ) : (
                  <Grid container>
                    <Button
                      style={{ width: "100%" }}
                      className={classes.big}
                      onClick={() => {
                        actions.closePrompt();
                        return <Redirect to="/?signUpRedirect=true" />;
                      }}
                    >
                      {t("signUp:finish")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
          <Grid container style={{ flex: 1 }}>
            <Grid className={classes.bottomBar}>
              <Text variant="h5">
                {t("successfulRegistration:haveQuestions")}<br />
                {t("successfulRegistration:checkOutOur")}&nbsp;
                <Link onClick={() => showContentModal('fragor-och-svar')} style={{ cursor: "pointer", fontWeight: 'inherit' }}>
                  {t("successfulRegistration:commonQuestions")}
                </Link>&nbsp;
                {t("successfulRegistration:orContactOur")}
                <Link href="/contact" style={{ cursor: "pointer", fontWeight: 'inherit' }}> support</Link>.&nbsp;
                {t("successfulRegistration:weAreHereForYou")}
              </Text>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </PurchaseFlowConsumer>
  );
};
