import {
    makeStyles,
    MenuItem as MuiMenuItem,
    MenuItemProps,
    PropTypes,
    Theme,
    TypographyClassKey
} from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { HeaderContext } from "../context";

interface StyleProps {
    color?: PropTypes.Color;
    size?: TypographyClassKey;
}

interface Props extends StyleProps, Omit<MenuItemProps, "button" | "color"> {
    to?: string;
    value?: string;
    children?: React.ReactNode;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    item: ({ size = "body1", color = "text" }) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: theme.palette[color].main || theme.palette[color].primary,
        fontSize: theme.typography[size].fontSize,
        paddingTop: 1,
        paddingBottom: 0,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        outline: "transparent"
    }),
    link: {
        color: "inherit !important",
        textDecoration: "none !important"
    },
    underline: ({ color = "text" }) => ({
        height: 2,
        width: "0%",
        backgroundColor: theme.palette[color].main || theme.palette[color].primary,
    }),
}));

/**
 * Extension on MUI MenuItem with React Router Link
 * @link https://v4.mui.com/api/menu-item/
 * @returns MenuItem
 */
export const MenuItem = ({
    size,
    color,
    children,
    ...itemProps
}: Props) => {
    const classes = useStyles({ size, color });

    return (
        <MuiMenuItem button={false} classes={{ root: classes.item }} {...itemProps}>
            {children}
        </MuiMenuItem>
    );
};

const MenuItemUnderlined = (props: Props) => {
    const classes = useStyles({ color: props.color });

    return (
        <HeaderContext.Consumer>
            {({ activeItem }) => (
                <motion.div whileHover="on">
                    <MenuItem {...props}>
                        {props.children}
                        {props.to === activeItem ? (
                            <div className={classes.underline} style={{ width: "100%" }} />
                        ) : (
                            <motion.div
                                className={classes.underline}
                                variants={{
                                    off: { width: "0%", originX: 0.5, originY: 0.5 },
                                    on: { width: "100%", originX: 0.5, originY: 0.5 },
                                }}
                                transition={{
                                    ease: "easeInOut",
                                    duration: 0.2,
                                }}
                            ></motion.div>
                        )}
                    </MenuItem>
                </motion.div>
            )}
        </HeaderContext.Consumer>
    );
};

/**
 * Extention on MenuItem with animated underline
 * @returns MenuItem
 */
MenuItem.Underlined = MenuItemUnderlined;
