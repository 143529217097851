import { pushConsent } from "@kvix/shared";
import { Button, KvixIcon, Text } from "@kvix/ui";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  createStyles,
  Dialog,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookie } from "react-use";
import { useIsMobile } from "../../../../hooks/isMobile";
import { useIsDarkmode } from "../../../../hooks/useIsDarkmode";

interface DataEntry {
  domain: string;
  cookies: string[];
  cookieType: string;
}
interface AccordionProps {
  open: boolean;
  type: "essential" | "functional" | "marketing";
  cookieData: DataEntry[];
  checked?: boolean;
  setChecked?: (value: boolean) => void;
}

interface cookieConsentProp {
  functional: boolean;
  market: boolean;
}
interface HandleCookieProps {
  open: boolean;
  handleOpen: (value: boolean) => void;
  parentSetOpen?: (value: boolean) => void;
  cookie?: cookieConsentProp;
  setCookie?: (value: cookieConsentProp) => void;
  manuallyOpen?: boolean;
}

const CookieTable: React.FC<{ rows: DataEntry[] }> = (props) => {
  const { t, ready } = useTranslation("cookieConsent");
  const isMobile = useIsMobile();

  if (!ready) {
    return null;
  }

  return (
    <div
      style={{
        overflowY: "hidden",
        overflowX: "auto",
      }}
    >
      <Table
        size={isMobile ? "small" : "medium"}
        style={{ background: "inherit" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t("domain")}</TableCell>
            <TableCell>{t("cookies")}</TableCell>
            <TableCell>{t("type")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((r, i) => (
            <TableRow style={{ verticalAlign: "top" }} key={i}>
              <TableCell>{r.domain}</TableCell>
              <TableCell>
                {r.cookies.map(
                  (cookie, i) => cookie + (i < r.cookies.length - 1 ? ", " : "")
                )}
              </TableCell>
              <TableCell>
                {t(+r.cookieType === 1 ? "firstParty" : "thirdParty")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const CookieAccordion: React.FC<AccordionProps> = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      accordionSummary: {
        paddingLeft: 4,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%",
          marginLeft: -12,
        },
      },
      accordionDetails: {
        flexDirection: "column",
        paddingTop: 0,
        [theme.breakpoints.down("xs")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    })
  );
  const classes = useStyles(props);
  const { t, ready } = useTranslation(["cookieConsent"]);
  const [open, setOpen] = useState<boolean>(false);

  const title = useMemo(() => {
    switch (props.type) {
      case "essential":
        return t("cookieConsent:essentialCookies");
      case "functional":
        return t("cookieConsent:functionalCookies");
      case "marketing":
        return t("cookieConsent:marketingCookies");
    }
  }, [props.type, t]);

  const description = useMemo(() => {
    switch (props.type) {
      case "essential":
        return t("cookieConsent:essentialCookiesDesc");
      case "functional":
        return t("cookieConsent:functionalCookiesDesc");
      case "marketing":
        return t("cookieConsent:marketingCookiesDesc");
    }
  }, [props.type, t]);

  const handleChange = (e: React.SyntheticEvent) => {
    props.setChecked(!props.checked);
    e.stopPropagation();
  };

  if (!ready) {
    return null;
  }

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={
          <KvixIcon.Chevron.Right style={{ transform: "rotate(90deg)" }} />
        }
      >
        <Box display="span" alignItems="center">
          <Box display="flex">
            <Checkbox
              checked={props.checked || props.type === "essential"}
              onClickCapture={handleChange}
              disabled={props.type === "essential"}
            />
            <Text style={{ fontWeight: "bold" }}>{title}</Text>
          </Box>
          <Box marginLeft={5.3} marginTop={-2}>
            <Text variant="body2">{description}</Text>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <CookieTable rows={props.cookieData} />
      </AccordionDetails>
    </Accordion>
  );
};

export const HandleCookies: React.FC<HandleCookieProps> = (props) => {
  const [cookieList, setCookieList] = useState<{
    essential: DataEntry[];
    functional: DataEntry[];
    marketing: DataEntry[];
  }>(null);

  const useStyles = makeStyles(() =>
    createStyles({
      buttonGroup: {
        display: "flex",
        justifyContent: "center",
      },
      button: {
        height: "60px",
        borderRadius: 30,
        margin: 10,
        padding: 30,
      },
    })
  );
  const { t, ready } = useTranslation(["general"]);
  const classes = useStyles();

  const isDarkMode = useIsDarkmode();

  const isMobile = useIsMobile();

  const [consentedCookies, setConsentedCookies] =
    useCookie("consented-cookies");

  const [acceptedCookie, setAcceptedCookies] = useState(
    !consentedCookies
      ? {
          functional: true,
          market: true,
        }
      : {
          functional: consentedCookies?.indexOf("functional") > -1,
          market: consentedCookies?.indexOf("market") > -1,
        }
  );

  useEffect(() => {
    (async () => {
      const jsonRes = await fetch("/assets/cookie-list.json");
      setCookieList(await jsonRes.json());
    })();
  }, []);

  const OkClick = () => {
    props.parentSetOpen(true);
    props.handleOpen(false);
  };

  const AcceptClick = () => {
    props.handleOpen(false);

    const newConsentedCookies = Object.keys(acceptedCookie).filter(
      (key) => acceptedCookie[key]
    );

    setConsentedCookies([...newConsentedCookies, "essential"].join(","), {
      expires: 365, //One year
    });
    pushConsent();

    //Send request in order to trigger ExpressSession set
    fetch("/auth/me/cookie-consent");
  };

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      open={props.open}
      disableBackdropClick
      disableEscapeKeyDown
      onClose={() => props.handleOpen(false)}
      fullScreen={isMobile}
    >
      <Text
        variant="h4"
        style={{
          fontWeight: "bold",
          marginTop: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      >
        {t("cookieConsent:manageCookies")}
      </Text>

      <Box
        borderBottom={
          isDarkMode
            ? "1px solid rgba(255, 255, 255, 0.15)"
            : "1px solid rgba(0, 0, 0, 0.17)"
        }
      >
        <CookieAccordion
          open={false}
          type="essential"
          cookieData={cookieList?.essential}
        />
        <CookieAccordion
          checked={
            props.manuallyOpen
              ? acceptedCookie.functional
              : props.cookie.functional
          }
          setChecked={(value) =>
            props.manuallyOpen
              ? setAcceptedCookies({
                  ...acceptedCookie,
                  functional: value,
                })
              : props.setCookie({
                  ...props.cookie,
                  functional: value,
                })
          }
          open={false}
          type="functional"
          cookieData={cookieList?.functional}
        />
        <CookieAccordion
          checked={
            props.manuallyOpen ? acceptedCookie.market : props.cookie.market
          }
          setChecked={(value) =>
            props.manuallyOpen
              ? setAcceptedCookies({
                  ...acceptedCookie,
                  market: value,
                })
              : props.setCookie({
                  ...props.cookie,
                  market: value,
                })
          }
          open={false}
          type="marketing"
          cookieData={cookieList?.marketing}
        />
      </Box>
      <div className={classes.buttonGroup}>
        {props.manuallyOpen ? (
          <Button
            className={classes.button}
            color="primary"
            variant="oval"
            onClick={() => AcceptClick()}
          >
            {t("general:buttons.accept")}
          </Button>
        ) : (
          <Button
            className={classes.button}
            color="primary"
            variant="oval"
            onClick={() => OkClick()}
          >
            {t("general:buttons.ok")}
          </Button>
        )}
      </div>
    </Dialog>
  );
};
