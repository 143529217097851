import { AbstractLiveSession } from "@kvix/shared";
import React, { useEffect, useState } from "react";

type StreamerAdminContextState = {
  updateStreamsLists: () => void;
  scheduledStreams: AbstractLiveSession[];
  previousStreams: AbstractLiveSession[];
};

const SCHEDULED_PATH =
  "/api/streamer-admin/session-manager/channel/sessions/scheduled";
const PREVIOUS_PATH =
  "/api/streamer-admin/session-manager/channel/sessions/previous";

const getStreams = async (
  onFinish: (result: AbstractLiveSession[]) => void,
  path: string
) => {
  const response = await fetch(path);
  const streams: AbstractLiveSession[] = await response.json();
  const streamsWithDate = streams.map((stream) => {
    return {
      ...stream,
      start: new Date(stream.start),
      end: new Date(stream.end),
    };
  });
  onFinish(streamsWithDate);
};

export const StreamerAdminContext = React.createContext(
  null as StreamerAdminContextState
);

export const StreamerAdminStateProvider: React.FC = (props) => {
  const [scheduledStreams, setScheduledStreams] = useState(null);
  const [previousStreams, setPreviousStreams] = useState(null);
  const updateStreamsLists = () => {
    getStreams(setScheduledStreams, SCHEDULED_PATH);
    getStreams(setPreviousStreams, PREVIOUS_PATH);
  };
  const [value, setValue] = useState<StreamerAdminContextState>({
    updateStreamsLists,
    scheduledStreams: [],
    previousStreams: [],
  });
  useEffect(() => {
    if (!scheduledStreams || !previousStreams) {
      updateStreamsLists();
    }
    setValue((value) => {
      return {
        ...value,
        scheduledStreams: scheduledStreams ? scheduledStreams : [],
        previousStreams: previousStreams ? previousStreams : [],
      };
    });
  }, [scheduledStreams, previousStreams]);

  return (
    <StreamerAdminContext.Provider value={value}>
      {props.children}
    </StreamerAdminContext.Provider>
  );
};
