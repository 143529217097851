import { FAQCategory } from "../../components/Common/FAQCategory";
import { Faq } from "../../Faq";
import { FaqCategoryCollectionItem } from "../../FaqCategoryCollectionItem";
import { getDataMany, RawFaqCategory } from "../adapter";

interface RawFaqCategoryColl {
  id: number;
  attributes: {
    categories: RawFaqCategory[];
    createdAt: Date;
    updatedAt: Date;
    identifier: string;
  };
}
export const FaqCategoryCollectionAdapter = async (
  rawJSON: RawFaqCategoryColl[]
): Promise<FaqCategoryCollectionItem[]> => {
  const faqCatColl: RawFaqCategoryColl[] = await getDataMany<
    RawFaqCategoryColl[]
  >(rawJSON);
  const res = faqCatColl.map(
    ({ id, attributes: { categories, createdAt, updatedAt, identifier } }) => {
      const newCats: FAQCategory[] = categories.map(
        ({ id, faqs, categoryName }) => {
          var newFaqs: Faq[];

          if (faqs.data) {
            newFaqs = faqs.data.map(
              ({ id, attributes: { createdAt, updatedAt, ...rest } }) => ({
                id: id,
                created_at: createdAt,
                updated_at: updatedAt,
                ...rest,
              })
            );
          } else {
            newFaqs = [];
          }

          return {
            faqs: newFaqs,
            created_at: createdAt,
            updated_at: updatedAt,
            id: id,
            categoryName: categoryName,
          };
        }
      );
      return {
        categories: newCats,
        created_at: createdAt,
        id: id,
        identifier: identifier,
        updated_at: updatedAt,
      };
    }
  );
  return res;
};
