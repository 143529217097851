import {
  AbstractCampaign,
  CampaignContextState,
  StripeProduct,
} from "@kvix/shared";
import { CircularProgress, Fade, makeStyles } from "@material-ui/core";
import queryString from "query-string";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { PurchaseFlowContext } from "src/contexts/PurchaseFlow";
import { KvixUserContext } from "../../../contexts/user";
import {
  useFetchAllInstructors,
  useFetchUpcomingSessions,
} from "../../../hooks/http";
import { CampaignFlow } from "../../PurchaseFlow/flows/CampaignFlow";
import { AuthErrorModal } from "../Login/AuthErrorModal";
import { PaymentContext } from "../Payment/context";
import { NewFlow } from "src/components/PurchaseFlow";

const useStyles = makeStyles((theme) => ({
  loadingIndicatorContainer: {
    position: "fixed",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    pointerEvents: "none",
    zIndex: 9999999,
  },
}));

const LoadingIndicator: React.FC = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.loadingIndicatorContainer}>
      <Fade in>
        <CircularProgress size={60} />
      </Fade>
    </div>
  );
};

export const CampaignContext = createContext<CampaignContextState>(null);

export const CampaignContextProvider: React.FC = (props) => {
  const { t, ready } = useTranslation("signUpCampaign");
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState<AbstractCampaign>(null);
  const { actions } = useContext(PurchaseFlowContext);
  const [gotoCheckout, setGotoCheckout] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(KvixUserContext);
  const payment = useContext(PaymentContext);

  useEffect(() => {
    const parsed = queryString.parseUrl(
      location.pathname + location.search + location.hash,
      {
        parseFragmentIdentifier: true,
      }
    );

    if (parsed.query.gotoCheckout && !parsed.query.errMsg) {
      delete parsed.query.gotoCheckout;
      const url = queryString.stringifyUrl(parsed);
      history.replace(url);
      setGotoCheckout(true);
    }
  }, [history, location]);

  useEffect(() => {
    const isPremium = user?.stripeProducts?.includes(StripeProduct.LIVE);

    if (gotoCheckout && campaign?.code && user) {
      if (!isPremium) {
        setLoading(true);

        try {
          payment.actions.checkout({
            campaignCode: campaign.code,
            cancel_url: window.location.href,
          });
        } catch (error) {
          setLoading(false);
          throw error;
        }
      } else {
        setLoading(false);
      }
      setGotoCheckout(false);
    }
  }, [gotoCheckout, campaign, user]);

  if (!ready) {
    return null;
  }

  return (
    <CampaignContext.Provider
      value={{
        openModal: (campaignContext: CampaignContextState) =>
          actions.prompt(<CampaignFlow campaignContext={campaignContext} />),
          // actions.prompt(<NewFlow campaignContext={campaignContext} />),
        closeModal: () => actions.closePrompt(),
        checkout: () => null,
        setCampaign,
        campaign,
        actions: {
          useFetchUpcomingSessions,
          useFetchAllInstructors: (max?: number) =>
            useFetchAllInstructors(max)[0],
        },
      }}
    >
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {props.children}
          <AuthErrorModal title={t("couldNotSignup")} />
        </>
      )}
    </CampaignContext.Provider>
  );
};
