import { Button, Text, TextField } from "@kvix/ui";
import {
  CircularProgress,
  Grid,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 52,
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "left",
    justifyContent: "left",

    "&.Mui-disabled": {
      backgroundColor: "#1F2833",
    },
  },
  big: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 26,
    width: "100%",
    marginTop: 20,
  },
  small: {
    backgroundColor:  "#1F2833",
    borderRadius: "50%",
    width: 48,
  },
  brand: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "0.25px",
    lineHeight: "20px",
    paddingLeft: "12px",
  },
  textFieldError: {
    color: theme.palette.error.main,
  },
  textFieldGridItem: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px !important",
      height: 52,
    },
    paddingBottom: 10,
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  variant: "big" | "small";
  changeToForgotPassword: () => void;
}

export const LoginWithEmailButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { variant, changeToForgotPassword } = props;
  const { t, ready } = useTranslation(["logIn", "error"]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [enableLoginButton, setEnableLoginButton] = useState(false);
  const [error, setError] = useState("");
  const { actions } = useContext(KvixUserContext);
  const [showFields, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      setError("");
    }
    if (emailError || passwordError || !email || !password) {
      setEnableLoginButton(false);
    } else {
      setEnableLoginButton(true);
    }
  }, [email, password]);

  if (!ready) {
    return null;
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setEmailError(t("error:emailRequired"));
    } else {
      setEmailError("");
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setPasswordError(t("error:passwordRequired"));
    } else {
      setPasswordError("");
    }
    setPassword(event.target.value);
  };

  const emailSignIn = () => {
    setLoading(true);
    const signIn = async () => {
      const res = await actions.signInUserEmail({ email, password });
      if (res.success) {
        actions.setUser();
      } else {
        setError(t("error:wrongEmailOrPassword"));
      }
      setLoading(false);
    };
    signIn();
  };

  if (!ready) {
    return null;
  }

  return (
    <Grid container item direction="column">
      {/* {showFields ? ( */}
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Grid item container direction="row">
            <Grid item xs={12}>
              <TextField
                className={classes.textFieldGridItem}
                fullWidth
                required
                inputMode="email"
                label={t("email")}
                onChange={handleEmailChange}
                error={emailError}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textFieldGridItem}
                fullWidth
                required
                label={t("password")}
                type="password"
                onChange={handlePasswordChange}
                error={passwordError}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
                <Text variant="h5">
                  {`${t("forgotPassword")} `}
                  <u
                    onClick={() => changeToForgotPassword()}
                    style={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    {t("clickHere")}
                  </u>
                </Text>
              </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.big}
                // disabled={!enableLoginButton}
                onClick={emailSignIn}
              >
                {loading ? (
                  <Grid item>
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Text className={classes.brand}>{t("login")}</Text>
                )}
              </Button>
              {error ? (
                <Grid item>
                  <Text className={classes.textFieldError}>{error}</Text>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </motion.div>
      {/* // ) : (
      //   <Grid item>
      //     <Button
      //       variant="contained"
      //       fullWidth
      //       onClick={() => setShowFields(true)}
      //       style={{ backgroundColor: "#1F2833" }}
      //     >
      //       {`${t("loginWith")} ${t("email")}`}
      //     </Button>
      //   </Grid>
      // )} */}
    </Grid>
  );
};
