export enum DonateTabState {
  BUY,
  DONATE,
  VOTE,
  READMORE,
  CHECKOUT,
  TERMS,
  ADDCARD,
  NOCOINS,
}
