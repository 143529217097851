import {
  PurchaseFailedMessage,
  PurchaseSuccessMessage,
  StripeProduct,
} from "@kvix/shared";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncFn } from "react-use";
import StripeInternal from "stripe";
import { KvixUserContext } from "../../../contexts/user";
import { useSocketEvent } from "../../../hooks/socket";
import { PaymentContext } from "./context";

const getProductIdFromPlan = (plan: StripeInternal.Plan) => {
  return plan.product instanceof Object ? plan.product.id : plan.product;
};

const getActivePhase = (schedule: StripeInternal.SubscriptionSchedule) => {
  const start = schedule.current_phase.start_date;
  const end = schedule.current_phase.end_date;

  const phase = schedule.phases.find((phase) => {
    const sameStart = phase.start_date === start;
    const sameEnd = phase.end_date === end;

    return sameStart && sameEnd;
  });

  return phase;
};

const getUpcomingPhase = (schedule: StripeInternal.SubscriptionSchedule) => {
  const active = getActivePhase(schedule);

  const index = schedule.phases.indexOf(active);
  const next = schedule.phases[index + 1];

  if (next) {
    return next;
  }

  return null;
};

const getPhasePriceIds = (phase: StripeInternal.SubscriptionSchedule.Phase) => {
  return phase.items.map(({ price }) => {
    if (typeof price === "string") {
      return price;
    }

    return price.id;
  });
};

export const useDefaultPaymentMethodId = (): string | null => {
  const { customer } = useContext(PaymentContext);

  if (!customer) {
    return null;
  }

  const { default_payment_method } = customer.invoice_settings;

  return default_payment_method instanceof Object
    ? default_payment_method.id
    : default_payment_method;
};

export const useDefaultPlan = () => {
  const { availablePlans } = useContext(PaymentContext);

  const defaultPlan = useMemo(() => {
    return availablePlans.find((plan) => {
      const id =
        typeof plan.product === "string" ? plan.product : plan.product.id;

      return id === StripeProduct.LIVE;
    });
  }, [availablePlans]);

  return defaultPlan;
};

export const usePlan = (planId: string) => {
  const { allPlans } = useContext(PaymentContext);

  const plan = useMemo(() => {
    return allPlans.find((plan) => plan?.id === planId);
  }, [planId, allPlans]);

  return plan;
};

export const useAvailablePlansForSubscription = (
  subscription: StripeInternal.Subscription
) => {
  const { availablePlans } = useContext(PaymentContext);

  return availablePlans.filter((plan) => {
    if (!subscription) {
      return false;
    }

    const subscriptionPlanId = getProductIdFromPlan(
      subscription.items.data[0].plan
    );
    const planId = getProductIdFromPlan(plan);

    return subscriptionPlanId === planId;
  });
};

export const usePlanPricing = (plan: StripeInternal.Plan) => {
  if (plan) {
    const originalPrice = plan.amount / 100;
    const campaignCoupon = plan["activeCampaign"] as StripeInternal.Coupon;

    if (campaignCoupon) {
      const discountAmount =
        campaignCoupon.amount_off / 100 ||
        originalPrice * (campaignCoupon.percent_off * 0.01);

      const campaignPrice = originalPrice - discountAmount;

      return { originalPrice, campaignPrice: Math.trunc(campaignPrice) };
    }

    return { originalPrice, campaignPrice: null };
  }
};

export const useScheduleWithUpcomingChanges = (
  subscription: StripeInternal.Subscription
) => {
  const { actions } = useContext(PaymentContext);

  const [changes, load] = useAsyncFn(async () => {
    const schedule = await actions.fetchSubscriptionSchedule(subscription.id);
    if (!schedule) {
      return null;
    }

    const active = getActivePhase(schedule);
    const upcoming = getUpcomingPhase(schedule);

    if (!active || !upcoming) {
      return null;
    }

    const activePlanIds = getPhasePriceIds(active);
    const upcomingPlanIds = getPhasePriceIds(upcoming);

    return {
      schedule,
      activePlanIds,
      upcomingPlanIds,
    };
  }, []);

  const [cancelState, cancel] = useAsyncFn(async () => {
    await actions.cancelSubscriptionSchedule(subscription.id);
    await load();
  }, [subscription.id]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    value: changes.value,
    loading: changes.loading,
    cancel,
    cancelLoading: cancelState.loading,
  };
};

export const useHasDefaultPlan = () => {
  const { user } = useContext(KvixUserContext);
  const stripeProducts = user?.stripeProducts || [];

  const hasDefaultPlan = useMemo(() => {
    return stripeProducts.includes(StripeProduct.LIVE);
  }, [stripeProducts]);

  return hasDefaultPlan;
};

export const useProductPrices = (productId: string) => {
  const { actions } = useContext(PaymentContext);
  return useAsync(async () => await actions.fetchProductPrices(productId));
};

export const useOnPurchase = () => {
  const [success, setSucces] = useState<PurchaseSuccessMessage>(null);
  const [error, setError] = useState<PurchaseFailedMessage>(null);

  useSocketEvent<PurchaseSuccessMessage>(
    "[payment] purchase success",
    setSucces
  );
  useSocketEvent<PurchaseFailedMessage>("[payment] purchase failed", setError);

  return { success, error };
};

export const useUserBalance = () => {
  const { balance } = useContext(KvixUserContext);

  const updatedBalance = useMemo(() => {
    if (balance === undefined) {
      return null;
    }
    return balance;
  }, [balance]);

  return updatedBalance;
};

export const useCoupon = (couponId: string) => {
  const { actions } = useContext(PaymentContext);
  return useAsync(async () => {
    if (!couponId) return null;
    return await actions.fetchCoupon(couponId)
  });
};
