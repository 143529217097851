import {
  AbstractBaseUser,
  AbstractLiveSession,
  AbstractSession,
  getSessionInstructor,
  SessionState,
} from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import { Avatar, Box, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { format, isToday, isTomorrow } from "date-fns";
import { sv } from "date-fns/locale";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchStreamerRank } from "../../../hooks/http";
import { useIsMobile } from "../../../hooks/isMobile";
import { ScheduledStreamModal } from "./ScheduledStreamModal";

const useStyles = makeStyles<Theme, Partial<{ endAdornment: boolean }>>(
  (theme) => ({
    avatar: {
      width: "4.5rem",
      height: "4.5rem",
      marginRight: 8,
      transition: "0.3s",
    },
    liveSessionImg: {
      width: "128px",
      height: "72px",
      marginRight: "10px",
      objectFit: "cover",
    },
    streamInfo: {
      fontSize: "0.75rem",
      fontWeight: 600,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: (props) =>
        `calc(100% - 82px - ${props.endAdornment ? "30px" : "0px"})`,
    },
    popper: {
      width: "200px",
      padding: "8px 14px",
      background: theme.palette.background.default,
      borderRadius: "10px",
      boxShadow: "0 0 10px rgb(0 0 0 / 0.4)",
    },
    streamInfoWhen: {
      display: "inline-flex",
      textTransform: "capitalize",
    },
    moreButton: {
      cursor: "pointer",
      background: theme.palette.background.paper,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      padding: "5px",
      color: theme.palette.primary.main,
    },
    popperItem: {
      cursor: "pointer",
    },
    popperDivider: {
      margin: "8px -14px",
    },
    streamTitle: {
      fontSize: "1.125rem",
      lineHeight: "24px",
      fontWeight: 700,
      overflow: "hidden",
      maxHeight: "3rem",
    },
    streamUsername: {
      fontWeight: 600,
      fontSize: "0.875rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    channelNumber: {
      paddingLeft: "4px",
      paddingRight: "3px",
      padding: "0 2px 0 3px",
      marginRight: "4px",
      lineHeight: "0.75rem",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      borderRadius: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "0.75rem",
      fontWeight: 700,
    },
    streamItemWrapper: {
      cursor: (props: any) => (props.onClick ? "pointer" : "inherit"),
      display: "flex",
      alignItems: "center",
      height: "5rem",
    },
    streamItemWrapperHover: {
      "&:hover $avatar": {
        height: "5rem",
        width: "5rem",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    disabled: {
      textDecoration: "line-through",
      color: theme.palette.grey[500],
    },
    streamInfoWrapper: {
      width: "100%",
    },
    buttonWrapper: {
      position: "absolute",
      right: 46,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0 20%",
        position: "static",
        display: "flex",
        justifyContent: "center",
        margin: `${theme.spacing(3)}px 0`,
      },

      [theme.breakpoints.down("xs")]: {
        padding: "0 5%",
        margin: `${theme.spacing(2)}px 0`,
      },
    },
  })
);

const ProfileImg: FC<{
  instructor: AbstractBaseUser;
  session?: AbstractLiveSession | AbstractSession;
  withHover?: boolean;
}> = (props) => {
  const classes = useStyles({});
  if (props.session?.thumbnailUrl) {
    return (
      <img
        alt=""
        className={classes.liveSessionImg}
        src={props.session.thumbnailUrl}
      />
    );
  }
  return <Avatar className={classes.avatar} src={props.instructor?.imageUrl} />;
};

interface StreamInfoProps {
  stream: AbstractLiveSession | AbstractSession;
  instructor: AbstractBaseUser;
  rank: number;
  channelNr: number;
  endAdornment: boolean;
  disabled?: boolean;
  finished?: boolean;
}
const StreamInfo: FC<StreamInfoProps> = (props) => {
  const classes = useStyles({ endAdornment: props.endAdornment });
  const { t, ready } = useTranslation("general");

  const getDate = () => {
    const startDate: Date = new Date(props.stream.start);
    const endDate: Date = new Date(props.stream.end);
    const time = `${format(startDate, "HH:mm")} - ${format(endDate, "HH:mm")}`;
    if (isToday(startDate)) {
      return `${t("today")} ${time}`;
    } else if (isTomorrow(startDate)) {
      return `${t("tomorrow")} ${time}`;
    } else {
      const day = format(startDate, "ccc dd/MM", { locale: sv }).replace(
        ".",
        ""
      );
      return `${day} ${time}`;
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.streamInfo}>
      <div>
        <span
          className={classNames(classes.streamInfoWhen, {
            [classes.disabled]: props.disabled,
          })}
        >
          {props.channelNr && (
            <Box className={classes.channelNumber}>{`K${props.channelNr}`}</Box>
          )}
          {getDate()}
          {props.finished && (
            <KvixIcon.Checkmark.Simple
              style={{ fontSize: 18, marginLeft: 4, color: "green" }}
            />
          )}
        </span>
      </div>
      <div
        className={classNames(classes.streamTitle, {
          [classes.disabled]: props.disabled,
        })}
      >
        {props.stream.title}
      </div>
      <Text
        className={classNames(classes.streamUsername, {
          [classes.disabled]: props.disabled,
        })}
      >
        {props.rank && `#${props.rank} `}
        {props.instructor?.name.custom ||
          `${props.instructor?.name.first} ${props.instructor?.name.last}`}
      </Text>
    </Box>
  );
};

interface ScheduledStreamItemProps {
  stream: AbstractLiveSession | AbstractSession;
  onClick?: (stream: AbstractLiveSession | AbstractSession) => void;
  endAdornment?: JSX.Element;
  button?: JSX.Element;
  channelNr?: number;
  showRank?: boolean;
  showModal?: boolean;
  finished?: boolean;
  withHover?: boolean;
  disabled?: boolean;
}
export const ScheduledStreamItem: FC<ScheduledStreamItemProps> = (props) => {
  const {
    stream,
    onClick,
    endAdornment,
    channelNr,
    showRank,
    showModal,
    withHover,
    finished,
  } = props;
  const streamer = getSessionInstructor(stream);
  const [streamerRank, setStreamerRank] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const rank = useFetchStreamerRank(stream.instructorId);
  const classes = useStyles({ endAdornment: !!endAdornment });
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!rank.loading) {
      setStreamerRank(rank.value);
    }
  }, [rank]);

  const displayButton = useMemo(() => {
    return (
      props.button &&
      !props.disabled &&
      !props.finished &&
      props.stream.state !== SessionState.Cancelled
    );
  }, [props.button, props.disabled, props.finished, props.stream]);

  return (
    <>
      <Box
        display="flex"
        position="relative"
        style={{ cursor: onClick || showModal ? "pointer" : "inherit" }}
        className={classNames([
          classes.streamItemWrapper,
          props.withHover && classes.streamItemWrapperHover,
        ])}
        onClick={() => {
          onClick && props.onClick(stream);
          showModal && setOpenModal(true);
        }}
      >
        <ProfileImg
          withHover={withHover}
          instructor={streamer}
          session={stream}
        />
        <StreamInfo
          stream={stream}
          instructor={streamer}
          endAdornment={!!endAdornment}
          rank={showRank ? streamerRank : null}
          channelNr={channelNr}
          finished={finished}
          disabled={stream.state === SessionState.Cancelled}
        />
        {displayButton && !isMobile && (
          <div className={classes.buttonWrapper}>{props.button}</div>
        )}
        {endAdornment && (
          <Box position="absolute" right="0">
            {endAdornment}
          </Box>
        )}
      </Box>

      {displayButton && isMobile && (
        <div className={classes.buttonWrapper}>{props.button}</div>
      )}

      {showModal && (
        <ScheduledStreamModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          instructor={streamer}
          rank={streamerRank}
        />
      )}
    </>
  );
};
