import React from "react";
import { KvixUserContext } from "../contexts/user";

export const useSocketEvent = <T>(
  event: string,
  callback: (data: T) => void
) => {
  const context = React.useContext(KvixUserContext);

  React.useEffect(() => {
    const ref = context.socket.on(event).subscribe(callback);

    return () => ref.unsubscribe();
  }, [event, callback, context.socket]);
};
