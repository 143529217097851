import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core";
import { default as React, useContext } from "react";
import styled from "styled-components";
import { ExerciseContext } from "../../../../../contexts/exercise";
import { KvixUserContext } from "../../../../../contexts/user";
import { useShowPromotions } from "../../../../../hooks/promotions";
import { Footer } from "../../../../Footer";
import { Banner } from "../../../../partials/Banner/Banner";
import { Hero } from "../../../../partials/Hero";
import { Page } from "../../../../partials/Page";
import { PaymentContext } from "../../../Payment/context";
import { ExercisepostSmall } from "../Post/ExercisepostSmall";

const OtherContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? "start" : "none")};
  width: 100%;
  flex-wrap: wrap;
  padding: 24px 0;
`;

export const CmsExerciseLanding: React.FC = (props) => {
  const { exercise } = useContext(ExerciseContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const exercisePosts = exercise?.exerciseposts;

  const showPromotions = useShowPromotions();
  const { user } = useContext(KvixUserContext);
  const { hasDefaultPlan } = useContext(PaymentContext);

  return (
    <Page>
      {exercise && exercisePosts && (
        <>
          <Hero.Exercise />

          <Container maxWidth="lg">
            <OtherContainer isMobile={isMobile} isTablet={isTablet}>
              {exercisePosts.map((exercisepost) => (
                <ExercisepostSmall
                  isTablet={isTablet}
                  isMobile={isMobile}
                  key={exercisepost.id}
                  exercisepost={exercisepost}
                />
              ))}
            </OtherContainer>

            <Box mt={8} mb={12}>
              <Banner type="upgrade-premium" size="large" />
            </Box>
          </Container>
        </>
      )}
      <Footer noGutter />
    </Page>
  );
};
