import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import { AbstractUser } from "@kvix/shared";
import { KvixIcon } from "@kvix/ui";
import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import { PurchaseFlowContext } from "../../../../contexts/PurchaseFlow";
import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { NewFlow } from "../../../PurchaseFlow";
import Cookies from 'js-cookie';

interface Props extends Pick<RouteComponentProps, "history"> {
  user: AbstractUser;
  children: (provided: {
    anchor: Element | null;
    active: boolean;
    user: AbstractUser;
  }) => React.ReactElement;
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    "& svg": {
      fontSize: 32,
      width: 32,
      height: 32,
      transition: "color 0.17s ease",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 6,
      paddingRight: 6,

      transform: "scale(0.9)",
    },
  },
}));

export const HeaderItemProfile = ({ user, history, children }: Props) => {
  const classes = useStyles();
  const { actions } = useContext(PurchaseFlowContext);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const active = Boolean(anchorEl);
  const [isHover, setHover] = useState(false);

  const rotateAnimation = {
    scale: isHover ? 1 : 1,
    rotate: isHover ? 360 : 0,
    transition: { duration: 2 },
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (!user) {
      const wpCampaignParams = Cookies.get('wp-campaign');
      if (wpCampaignParams) {
        history.replace(`${history.location.pathname}${wpCampaignParams}`);
      }

      actions.prompt(<NewFlow login registerProps={{ checkoutSource: wpCampaignParams ? 'wp' : 'kvix' }} currentTab={0} />);
      return;
    }

    pushClientEvent({
      user,
      featureType: "Profile",
      source: Source.Web
    })
    setAnchorEl((prev) => (prev == null ? event.currentTarget : null));
  };

  return (
    <IconButton
      onClick={handleClick}
      className={classes.menuButton}
      color="inherit"
      aria-label="profile"
    >
      <motion.div
        animate={rotateAnimation}
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
      >
        {!user ? (
          <KvixIcon.Person />
        ) : (
          <Avatar src={user.imageUrl} style={{ height: 40, width: 40 }} />
        )}
      </motion.div>
      {children({ anchor: anchorEl, active, user })}
    </IconButton>
  );
};
