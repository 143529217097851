import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import {
  AbstractSession,
  isBroadcaster,
  isSessionCancelled,
  isSessionJoinable,
  sortSessionsByStartDate,
} from "@kvix/shared";
import { KvixIcon } from "@kvix/ui";
import {
  Badge,
  Box,
  Container,
  Divider,
  IconButton,
  makeStyles,
  Modal,
  Theme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { isFuture } from "date-fns";
import React, {
  Fragment,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import { animated, useSpring } from "react-spring";
import { useLocalStorage, usePrevious } from "react-use";
import { APP_HEADER_PADDING } from "../../../contexts/expo";
import { KvixUserContext } from "../../../contexts/user";
import {
  useInstructorSessions,
  useStreamerSessions,
} from "../../../hooks/session";
import { ScheduledSessionCard } from "../../partials/ScheduledSessionCard";
import { ScheduledStreamItem } from "../../partials/Streamer/ScheduledStreamItem";

interface Props {
  iconClassName: string;
  isNativeApp: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  modal: {
    margin: 0,
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "100%",
    background: "transparent",
  },
  backdrop: {
    opacity: 0,
  },
  closeButton: {
    position: "fixed",
    top: theme.spacing(3),
    right: theme.spacing(5),
    zIndex: 999,
  },
  container: {
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.primary.dark,
    paddingTop: (props: Props) =>
      props.isNativeApp ? APP_HEADER_PADDING : theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  },
}));

const SlideDownTransition = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<TransitionProps>
>((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;

  const style = useSpring({
    from: {
      transform: "translate3d(0, -100%, 0)",
      // boxShadow: `0px 0px 30px rgba(0, 0, 0, 0.2)`
    },
    to: {
      transform: `translate3d(0, ${open ? 0 : -100}%, 0)`,
      // boxShadow: `0px 0px 30px rgba(0, 0, 0, ${open ? 0.2 : 0})`
    },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const HeaderItemNotifications: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { push } = useHistory();
  const userContext = useContext(KvixUserContext);
  const [visible, setVisible] = React.useState(false);

  const [hideCanceledSessions, setHideCanceledSessions] = useLocalStorage<
    number[]
  >("webnotifications-hide-canceled-sessions", []);

  const [shouldOpenOnNewNotification, setShouldOpenOnNewNotification] =
    useState(false);

  useEffect(() => {
    const timeout = window.setTimeout(
      () => setShouldOpenOnNewNotification(true),
      5000
    );

    return () => window.clearTimeout(timeout);
  }, []);

  const instructorSessions = useInstructorSessions();
  const streamerSessions = useStreamerSessions();

  const cancelledSessions = (userContext.sessions.booked || [])
    .filter(isSessionCancelled)
    .filter((session) => isFuture(session.end));

  const sessions = useMemo(() => {
    const joinableSessions = userContext.sessions.joinable || [];

    const upcomingSessions = [
      ...joinableSessions,
      ...instructorSessions,
      ...streamerSessions,
    ].filter(isSessionJoinable);

    const sessions = [...upcomingSessions, ...cancelledSessions]
      .sort(sortSessionsByStartDate)
      .filter((session) => !hideCanceledSessions.includes(session.id));

    let ids = [];
    let uniques: AbstractSession[] = [];

    for (let session of sessions) {
      if (ids.includes(session.id)) {
        continue;
      }

      ids.push(session.id);
      uniques.push(session);
    }

    return uniques;
  }, [
    instructorSessions,
    streamerSessions,
    userContext.sessions.joinable,
    cancelledSessions,
    hideCanceledSessions,
  ]);

  const previousSessions = usePrevious(sessions);

  useEffect(() => {
    if (!shouldOpenOnNewNotification) {
      return;
    }

    if (sessions.length > (previousSessions || []).length) {
      if (!visible) {
        setVisible(true);
      }
    }
  }, [previousSessions, sessions.length, shouldOpenOnNewNotification, visible]);

  const handleClick = () => {
    if (!visible && sessions.length === 0) {
      return;
    }

    setVisible((previous) => !previous);
  };

  const close = () => {
    setHideCanceledSessions(cancelledSessions.map((session) => session.id));
    setVisible(false);
  };

  const pushStreamItem = (session: AbstractSession) => {
    const pushUrl = isBroadcaster(session, userContext.user) ? 
      `/session/${session.id}/broadcast` : 
      `/channel/${session.channel.channelNumber}`;
    
    pushClientEvent({
      featureType: 'social.web.notifications.click',
      source: Source.Web,
      user: userContext.user,
      pathTo: pushUrl
    });

    push(pushUrl);
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="notifications"
        onClick={handleClick}
        className={props.iconClassName}
      >
        <Badge
          color="primary"
          badgeContent={sessions.length}
          anchorOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
        >
          <KvixIcon.Notification />
        </Badge>
      </IconButton>

      {sessions && sessions.length > 0 && (
        <Modal
          open={visible}
          onClose={close}
          onEscapeKeyDown={close}
          onBackdropClick={close}
          disableScrollLock={true}
          closeAfterTransition
          keepMounted
          BackdropProps={{ className: classes.backdrop }}
          className={classes.modal}
        >
          <SlideDownTransition in={visible} timeout={50}>
            <div className={classes.container}>
              <IconButton
                color="primary"
                className={classes.closeButton}
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  close();
                }}
              >
                <KvixIcon.Close fontSize="large" color="primary" />
              </IconButton>

              <Container maxWidth="sm">
                <div>
                  {sessions.map((session, index) => (
                    <Fragment key={session.id}>
                      {index > 0 && <Divider className={classes.divider} />}

                      {session.channelId ? (
                        <Box my={1}>
                          <ScheduledStreamItem
                            stream={session}
                            onClick={() => pushStreamItem(session)}
                          />
                        </Box>
                      ) : (
                        <ScheduledSessionCard
                          variant="light"
                          session={{
                            ...session,
                            instructorId:
                              session.instructorId ||
                              session.program.instructorId,
                          }}
                          disableLazyload={true}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              </Container>
            </div>
          </SlideDownTransition>
        </Modal>
      )}
    </>
  );
};
