import * as Sentry from "@sentry/react";
import { useParams } from "react-router";

export const useAndCaptureParamsId = (
  idType: "programId" | "userId" | "sessionId" | "channelNumber" | "playlistId"
) => {
  const params = useParams();
  const id = params[idType];

  if (isNaN(+id)) {
    console.error(`${id} is not a valid ${idType}`);
    Sentry.captureException(`${id} is not a valid ${idType}`, {
      tags: { package: "client" },
    });
  }

  return +id;
};
