import { Button, KvixIcon, Text } from "@kvix/ui";
import {
  Container,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Snackbar,
  Theme,
  useTheme,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { TFunction } from "i18next";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { Stripe } from "stripe";
import styled from "styled-components";
import { isAdmin, isInstructor, KvixUserContext } from "../../contexts/user";
import { useIsMobile } from "../../hooks/isMobile";
import { PaymentContext } from "../pages/Payment/context";

interface Props {}

const useStyles = makeStyles<Theme, { dark: boolean }>((theme) => ({
  container: {
    position: "fixed",
    margin: 0,
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    display: "flex",
    background: (props) =>
      props.dark ? theme.palette.primary.dark : theme.palette.primary.main,
    color: theme.palette.common.white,

    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  button: {
    maxWidth: "224px",
    marginRight: "16px",
  },
  exitButton: {
    position: "absolute",
    right: "0",
    top: "0",
  },
}));

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: ${(props: { isMobile: boolean }) => {
    return props.isMobile ? "column" : "row";
  }};
`;

const Actions = styled.div`
  justify-content: center;
  display: flex;
  margin: 12px 0;
`;

const TransitionDown = (props: TransitionProps) => (
  <Slide {...props} direction="down" />
);

const getMessage = (subscriptions: Stripe.Subscription[], t: TFunction) => {
  if (!subscriptions.length) {
    return null;
  }

  // Should only be one subscription
  const [subscription] = subscriptions;

  switch (subscription.status) {
    case "incomplete":
    case "incomplete_expired":
    case "past_due":
    case "unpaid":
      return t("paymentErrors.failedPayment");
    default:
      return null;
  }
};

const getRedirectPath = (subscriptions: Stripe.Subscription[]) =>
  subscriptions.length === 0 ? "/subscribe" : "/me/account";

export const SubscriptionError: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const home = useRouteMatch("/");
  const { user } = useContext(KvixUserContext);
  const { customer } = useContext(PaymentContext);
  const subscriptions = customer?.subscriptions?.data || [];
  const gotSubscription = subscriptions.length > 0;
  const classes = useStyles({ dark: gotSubscription });
  const { t, ready } = useTranslation("general");
  const theme = useTheme();
  if (!home.isExact || !user || customer === undefined) {
    return null;
  }

  if (isAdmin(user) || isInstructor(user) || !ready) {
    return null;
  }

  const redirectPath = getRedirectPath(subscriptions);
  const message = getMessage(subscriptions, t);

  const close = () => setVisible(false);

  const closeAndRedirect = () => {
    close();
    history.push(redirectPath);
  };

  return (
    <Snackbar
      style={{ margin: 0, width: "100%", top: 0 }}
      open={visible && !!message}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionComponent={TransitionDown}
    >
      <Paper className={classes.container}>
        <Container maxWidth="lg">
          <Wrapper isMobile={isMobile}>
            <Text
              variant="h6"
              style={{
                fontSize: "16px",
                alignSelf: "center",
                whiteSpace: "pre-wrap",
                marginRight: "16px",
              }}
              align="center"
            >
              {message}
            </Text>

            <Actions>
              <Button
                color={gotSubscription ? "primary" : "white"}
                variant="contained"
                fullWidth
                onClick={closeAndRedirect}
                className={classes.button}
              >
                {gotSubscription ? t("buttons.update") : t("buttons.subscribe")}
              </Button>
            </Actions>
          </Wrapper>
          <IconButton className={classes.exitButton} onClick={close}>
            <KvixIcon.Close style={{ color: theme.palette.common.white }} />
          </IconButton>
        </Container>
      </Paper>
    </Snackbar>
  );
};
