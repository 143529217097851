import { KvixIcon } from "@kvix/ui";
import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  displayingFilters: boolean;
  toggleDisplayFilters: () => void;
  numberOfActiveFilters: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 12,
    display: "flex",
    border: `1px solid ${theme.palette.getContrastText(
      theme.palette.background.default
    )}`,
    padding: "0 12px",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "capitalize",
    "&.selected": {
      background: theme.palette.background.paper,
    },
  },
  filterIndicator: {
    width: 18,
    height: 18,
    borderRadius: 9,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 10,
    position: "absolute",
    top: -5,
    right: 5,
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const FilterButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <Box position="relative">
      <Button
        disableTouchRipple
        className={`${classes.root} ${
          props.displayingFilters ? "selected" : ""
        }`}
        onClick={props.toggleDisplayFilters}
      >
        {t("filter")}
        <KvixIcon.Tune style={{ margin: "2px 0 0 4px", fontSize: "18px" }} />
      </Button>
      {props.numberOfActiveFilters > 0 && (
        <div className={classes.filterIndicator}>
          {props.numberOfActiveFilters}
        </div>
      )}
    </Box>
  );
};
