import { FollowInstructorDto, HttpMethods } from "@kvix/shared";

export const updateInstructorFollow = async (
  id: number,
  allowNotifications: boolean
): Promise<FollowInstructorDto> => {
  const response = await fetch(`/api/instructor/following/${id}`, {
    method: HttpMethods.PUT,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ allowNotifications }),
  });

  if (!response.ok) {
    throw new Error("Update follow instructor request failed");
  }

  return await response.json();
};

export const removeInstructorFollow = async (
  id: number
): Promise<FollowInstructorDto> => {
  const response = await fetch(`/api/instructor/following/${id}`, {
    method: HttpMethods.DELETE,
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Unfollow instructor request failed");
  }

  return await response.json();
};
