import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import styled from "styled-components";
import { CmsContext } from "../../cms/context";
import { CampaignLayout } from "./layouts/CampaignLayout";
import { DefaultLayout } from "./layouts/DefaultLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",

    "& > div": {
      backgroundColor:
        theme.palette.type === "dark" ? "#131415" : theme.palette.text.primary,
    },
  },
  title: {
    fontWeight: "bold",
  },
  section: {
    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },
  social: {
    marginTop: "42px",
  },
  copyright: {
    marginTop: "12px",
  },
  communityButtonRoot: {
    width: "164px",
    padding: "0 12px",
    height: 44,
    backgroundColor: theme.palette.type === "dark" ? "#425784" : "#3A5898",
    borderRadius: 8,
    position: "relative",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  brand: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: "1.25px",
    lineHeight: "20px",
    textTransform: "uppercase",
    transform: "translateY(-0.12em)",
    whiteSpace: "nowrap",
  },
  logotype: {
    marginRight: 10,
    "& img": {
      height: 22,
      width: 22,
    },
  },
}));

const Wrapper = styled.div<{ noGutter: boolean }>`
  width: "100%";
  color: white;

  margin-top: ${(props) => (props.noGutter ? null : "80px")};
  padding-top: 80px;
  padding-bottom: 80px;
`;

type Layout = "default" | "campaign";

interface Props {
  noGutter?: boolean;
  layout?: Layout;
}

export const Footer: React.FC<Props> = (props) => {
  const { noGutter, layout = "default" } = props;
  const classes = useStyles(props);
  const { footer } = useContext(CmsContext);

  if (!footer) {
    return null;
  }

  return (
    <footer className={classes.root}>
      <Wrapper noGutter={noGutter}>
        {layout === "campaign" ? (
          <CampaignLayout footer={footer} />
        ) : (
          <DefaultLayout footer={footer} />
        )}
      </Wrapper>
    </footer>
  );
};
