import {
  HttpMethods,
  IssuedFrom,
  StripeProduct,
  validatePhonenumber,
} from "@kvix/shared";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { PaymentMethod } from "@stripe/stripe-js";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Stripe from "stripe";
import styled from "styled-components";
import { KvixUserContext } from "../../../../../contexts/user";
import { PaymentContext } from "../../context";
import { useOnPurchase } from "../../hooks";
import { DonateTabState } from "../enums/DonateTabState";
import { DonateType } from "../enums/DonateType";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";
import { SwishErrorType } from "../enums/SwishErrorType";
import { SwishValidationError } from "../enums/SwishValidationError";
import { AcceptSwish } from "../partials/AcceptSwish";
import { CompletePaymentButton } from "../partials/CompletePaymentButton";
import { SavedCardList } from "../partials/SavedCardList";
import { SwishPaymentOption } from "../partials/SwishPaymentOption";
import { TabContainer } from "../partials/TabContainer";
import { TotalPriceDisplay } from "../partials/TotalPriceDisplay";

interface Props {
  tabState: DonateTabState;
  setTabState: (tabState: DonateTabState, reset?: boolean) => void;
  selectedPrice: Stripe.Price;
  setSelectedPrice: (price: Stripe.Price) => void;
  donateType: DonateType;
  donationAlert: (message: string, severity: DonationAlertSeverity) => void;
  newPaymentMethod: PaymentMethod;
  issuedFrom: IssuedFrom;
}

const PaymentMethodWrapper = styled.div`
  margin-top: 24px;
`;

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "70px",
  },
  subtitle: {
    marginTop: "30px",
    marginBottom: "20px",
  },
}));

export const CheckoutTab: FC<Props> = (props) => {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | null
  >(null);
  const { user } = useContext(KvixUserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { stripe, actions, customer } = useContext(PaymentContext);
  const onPurchase = useOnPurchase();

  // This is for stripe payments
  const [clientSecret, setClientSecret] = useState<string>(null);

  // This is for swish payments
  const [providerReferenceId, setProviderReferenceId] = useState<string>();

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [swishIsChecked, setSwishIsChecked] = useState(false);
  const [enteredPhoneNr, setEnteredPhoneNr] = useState<string>(null);
  const [openSwish, setOpenSwish] = useState(false);
  const rejectionTimeout: React.MutableRefObject<any> = useRef(null);
  const [saveNumber, setSaveNumber] = useState(false);
  const classes = useStyles();
  const { t, ready } = useTranslation(["swishErrors", "donateLiveView"]);

  useEffect(() => {
    const phonenumber = user.mobileNumber;
    if (phonenumber) {
      if (validatePhonenumber(phonenumber)) {
        setEnteredPhoneNr(phonenumber);
      } else {
        if (phonenumber.charAt(0) === "+") {
          const tempNr = phonenumber.substring(1);
          if (validatePhonenumber(tempNr)) {
            setEnteredPhoneNr(tempNr);
          }
        }
      }
    }
  }, [user, setEnteredPhoneNr]);

  useEffect(() => {
    if (clientSecret != null) {
      const paymentIntent = stripe.confirmCardPayment(clientSecret);
      paymentIntent
        .then((value) => {
          console.log(value);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  }, [clientSecret]);

  useEffect(() => {
    if (providerReferenceId) {
      if (
        loading &&
        onPurchase.success !== null &&
        onPurchase.success.product === StripeProduct.KVIX_CURRENCY &&
        onPurchase.success.signature === providerReferenceId
      ) {
        setSelectedPaymentMethodId(null);
        props.setSelectedPrice(null);
        setLoading(false);

        props.issuedFrom === IssuedFrom.SIDEBAR
          ? props.setTabState(DonateTabState.BUY, true)
          : props.donateType === DonateType.COIN
          ? props.setTabState(DonateTabState.DONATE, true)
          : props.setTabState(DonateTabState.VOTE, true);
        props.donationAlert(
          t("donateLiveView:purchaseSuccessful"),
          DonationAlertSeverity.SUCCESS
        );
        setProviderReferenceId(null);
      } else if (
        loading &&
        onPurchase.error !== null &&
        onPurchase.error.signature === providerReferenceId
      ) {
        handleSwishErrors({ name: "Swishfail", message: "DEFAULT" });
        setLoading(false);
      }
    } else {
      if (
        loading &&
        onPurchase.success !== null &&
        onPurchase.success.product === StripeProduct.KVIX_CURRENCY &&
        onPurchase.success.signature === clientSecret
      ) {
        setSelectedPaymentMethodId(null);
        props.setSelectedPrice(null);
        setLoading(false);
        props.issuedFrom === IssuedFrom.SIDEBAR
          ? props.setTabState(DonateTabState.BUY, true)
          : props.donateType === DonateType.COIN
          ? props.setTabState(DonateTabState.DONATE, true)
          : props.setTabState(DonateTabState.VOTE, true);
        props.donationAlert(
          t("donateLiveView:purchaseSuccessful"),
          DonationAlertSeverity.SUCCESS
        );
        setClientSecret(null);
      } else if (
        loading &&
        onPurchase.error !== null &&
        onPurchase.error.signature === clientSecret
      ) {
        props.donationAlert(
          onPurchase.error.error,
          DonationAlertSeverity.ERROR
        );
        setLoading(false);
      }
    }
  }, [onPurchase]);

  useEffect(() => {
    if (props.newPaymentMethod !== null) {
      selectCard(props.newPaymentMethod.id);
    }
  }, [props.newPaymentMethod]);

  useEffect(() => {
    if (selectedPaymentMethodId) {
      setSwishIsChecked(false);
      setSaveNumber(false);
    }
  }, [selectedPaymentMethodId, setSwishIsChecked, setSaveNumber]);

  useEffect(() => {
    if (swishIsChecked && validatePhonenumber(enteredPhoneNr)) {
      setSelectedPaymentMethodId(null);
    }
  }, [swishIsChecked, validatePhonenumber, setSelectedPaymentMethodId]);

  const selectCard = (cardId) => {
    setSelectedPaymentMethodId(cardId);
  };

  const handleSwishErrors = (error: Error) => {
    if ((Object as any).values(SwishValidationError).includes(error.message)) {
      props.donationAlert(
        t(`donateLiveView:swishErrors.${error.message}`),
        DonationAlertSeverity.ERROR
      );
    } else {
      props.donationAlert(
        t(`donateLiveView:swishErrors.DEFAULT`),
        DonationAlertSeverity.ERROR
      );
    }
    setTermsAgreed(false);
    setSwishIsChecked(false);
    setOpenSwish(false);
    setLoading(false);
  };

  const completePayment = async () => {
    if (swishIsChecked) {
      try {
        setLoading(true);
        if (!validatePhonenumber(enteredPhoneNr)) {
          setLoading(false);
          throw new Error(SwishErrorType.INVALID_PHONE_NUMBER);
        }
        setOpenSwish(true);
        const res = await new Promise<Response>(async (resolve, reject) => {
          rejectionTimeout.current = setTimeout(() => {
            reject("Invoice timed out");
          }, 3 * 60 * 1000 /* 3 min */);

          const res = await fetch(`/swish/request-payment`, {
            method: HttpMethods.POST,
            body: JSON.stringify({
              priceItem: props.selectedPrice,
              mobileNumber: enteredPhoneNr,
              saveMobileNumber: saveNumber,
            }),
          });

          window.clearTimeout(rejectionTimeout.current);
          resolve(res);
        });

        if (!res.ok) {
          const error = await res.json();
          throw new Error(error.message);
        } else {
          const { providerReferenceId } = await res.json();
          setProviderReferenceId(providerReferenceId);
        }
      } catch (error) {
        window.clearTimeout(rejectionTimeout.current);
        handleSwishErrors(error);
      }
    } else {
      setLoading(true);
      const checkout = actions.checkoutPrice(
        customer.id,
        selectedPaymentMethodId,
        props.selectedPrice.id
      );
      checkout
        .then((value) => {
          setClientSecret(value);
        })
        .catch((reason) => {
          throw new Error(reason);
        });
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <TabContainer
      tabState={props.tabState}
      visibleState={DonateTabState.CHECKOUT}
    >
      {openSwish ? (
        <AcceptSwish paymentRequest={providerReferenceId} />
      ) : (
        <>
          <Typography className={classes.title} variant={"h4"}>
            {t("donateLiveView:buy.checkoutTitle")}
          </Typography>
          <Typography className={classes.subtitle} variant={"body2"}>
            {t("donateLiveView:buy.checkoutSubtitle")}
          </Typography>
          <TotalPriceDisplay
            donateType={DonateType.COIN}
            setTabState={props.setTabState}
            quantitySelected={props.selectedPrice?.transform_quantity.divide_by}
            price={props.selectedPrice?.unit_amount / 100}
          />
          <PaymentMethodWrapper>
            <SwishPaymentOption
              swishIsChecked={swishIsChecked}
              setSwishIsChecked={setSwishIsChecked}
              enteredPhoneNr={enteredPhoneNr}
              setEnteredPhoneNr={setEnteredPhoneNr}
              saveNumber={saveNumber}
              setSaveNumber={setSaveNumber}
            />
            <SavedCardList
              onSelect={selectCard}
              selectedPaymentMethodId={selectedPaymentMethodId}
              loading={loading}
              setLoading={setLoading}
              donationAlert={props.donationAlert}
              setTabState={props.setTabState}
            />
          </PaymentMethodWrapper>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color={"secondary"}
                  checked={termsAgreed}
                  onChange={(e) => {
                    setTermsAgreed(e.target.checked);
                  }}
                />
              }
              label={
                <Typography variant="inherit">
                  {t("donateLiveView:buy.checkoutTOS.part1")}
                  <Link
                    onClick={() => {
                      props.setTabState(DonateTabState.TERMS);
                    }}
                  >
                    {t("donateLiveView:buy.checkoutTOS.part2")}
                  </Link>
                  .
                </Typography>
              }
            />
          </FormGroup>
        </>
      )}
      <CompletePaymentButton
        disabled={
          (selectedPaymentMethodId === null && !swishIsChecked) ||
          loading ||
          !termsAgreed
        }
        onClick={completePayment}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>{t("donateLiveView:buy.checkoutButton")}</>
        )}
      </CompletePaymentButton>
    </TabContainer>
  );
};
