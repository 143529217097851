import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import imageDesktop from "./assets/Streamer01-1040-400.jpg";
import imageMobile from "./assets/Streamer01-640-480.jpg";
import Template from "./template";

interface Props {
  size?: "small" | "large";
  marginTop?: number;
  marginBottom?: number;
}

export const BecomeAStreamerBanner = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("banner");
  const history = useHistory();
  if (!ready) {
    return null;
  }

  const plan = t("getFree.kvixFree");
  const price = t("getFree.freeForever");
  const button = t("becomeAStreamer.button");
  const title = t("becomeAStreamer.title");
  const subtitle = t("becomeAStreamer.subtitle");
  const buttonSubText = t("becomeAStreamer.buttonSubText");

  const size = props.size || "large";
  const image = isMobile ? imageMobile : imageDesktop;

  return (
    <Template
      size={size}
      price={{
        value: price,
      }}
      backgroundImage={image}
      plan={{
        value: title,
        outlined: false,
      }}
      title={subtitle}
      button={{
        link: {
          pathname: "/become-a-streamer",
          search: "membership=free",
          state: {
            from: history.location.pathname,
            background: history.location,
          },
        },
        text: button,
      }}
      buttonSubText={buttonSubText}
    />
  );
};
