import { KvixIcon } from "@kvix/ui";
import { Box, Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  numberOfActiveFilters: number;
  openMobileFilters: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    border: `1px solid ${theme.palette.getContrastText(
      theme.palette.background.default
    )}`,
    padding: "0 12px",
    alignItems: "center",
    justifyContent: "space-between",
    height: 36,
    fontSize: 14,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  filterIndicator: {
    width: 18,
    height: 18,
    borderRadius: 9,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 10,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto",
    fontWeight: 700,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  FilterButtonWidth: {
    width: "calc(50%)",
    marginRight: "16px",
  },
}));

export const FilterButton: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("content");

  if (!ready) {
    return null;
  }

  return (
    <Box position="relative" className={classes.FilterButtonWidth}>
      <Button
        disableTouchRipple
        className={classes.root}
        onClick={props.openMobileFilters}
      >
        {t("filter")}
        <KvixIcon.Tune
          style={{ marginTop: "2px", marginRight: 0, fontSize: "18px" }}
        />
      </Button>
      {props.numberOfActiveFilters > 0 && (
        <div className={classes.filterIndicator}>
          {props.numberOfActiveFilters}
        </div>
      )}
    </Box>
  );
};
