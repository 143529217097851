import { AbstractBaseUser, CompetitionRoundState } from "@kvix/shared";
import { useContext, useMemo } from "react";
import { CompetitionContext } from "../contexts/competition";

export const useIsInActiveRunningCompetition = (user: AbstractBaseUser) => {
  const { activeCompetitionRound } = useContext(CompetitionContext);

  return useMemo(() => {
    if (
      activeCompetitionRound?.state !== CompetitionRoundState.RUNNING ||
      !user?.competitionRounds
    ) {
      return false;
    }

    return user.competitionRounds.some(
      (round) => activeCompetitionRound.id === round.id
    );
  }, [activeCompetitionRound, user]);
};
