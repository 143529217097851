import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import {
  AbstractAppSettings,
  AbstractBaseUser,
  AbstractExitReason,
  AbstractLiveSession,
  AbstractSession,
  AbstractUser,
  AlgoliaAnytimeArticle,
  AuthenticateDto,
  CheckingAccountDto,
  createAuthenticationRequestInit,
  CreateUserDto,
  CreateUserEmailDto,
  FollowInstructorDto,
  HttpMethods,
  pushGaEvent,
  Role,
  SessionState,
  signOut,
  StripeProduct,
  validateUser,
} from "@kvix/shared";
import * as Sentry from "@sentry/react";
import { isBefore } from "date-fns";
import { ValidationError } from "express-validator";
import React, { FC, useContext } from "react";
import { getI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AuthError } from "../errors/AuthError";
import { socket } from "../kvix-socket";
import QueryParams from "../queryParams";
import * as InstructorApi from "../service/instructor";
import * as Playlist from "../service/playlist";
import * as Program from "../service/program";
import * as Session from "../service/session";
import logger from "../utils/Logger";
import { LanguageContext, LanguageContextState } from "./language";
import { constructUserData } from "../components/partials/GaEvents";

interface Actions {
  refetchUser(): Promise<void>;
  authenticate(
    user: AuthenticateDto,
    url: string
  ): Promise<{
    success: boolean;
    user?: AbstractBaseUser;
    errors?: ValidationError[] | string[];
  }>;
  signIn(user: AbstractBaseUser): Promise<void>;
  signOut(): Promise<void>;
  createUser(newUser: CreateUserDto): Promise<{
    errors?: ValidationError[] | string[];
    success?: boolean;
    user?: AbstractBaseUser;
  }>;
  createUserEmail(newUser: CreateUserEmailDto, startRegisterFrom: string): Promise<{
    errors?: ValidationError[] | string[];
    success?: boolean;
    user?: AbstractBaseUser;
  }>;
  setUser(): Promise<{
    user?: AbstractBaseUser;
  }>;
  signInUserEmail(user: AuthenticateDto): Promise<{
    errors?: ValidationError[] | string[];
    success?: boolean;
    user?: AbstractBaseUser;
  }>;
  removeUser(exitReason: Partial<AbstractExitReason>): Promise<void>;
  bookSessions(sessions: AbstractSession[]): Promise<void>;
  cancelSessions(sessions: AbstractSession[]): Promise<void>;
  updateWatchedSessions(sessionId: number, currentTime: number): void;
  updateAudioConstraints(audio: AudioContraints): void;
  updateSnapshotSettings(settings: SnapshotSettings): void;
  requestEmailChange(email: string): Promise<void>;
  requestForgottenPassword(email: string): Promise<void>;
  changeEmail(
    email: string,
    verificationCode: string
  ): Promise<{ errors?: string[] }>;
  updateInstructorFollow(
    id: number,
    allowNotifications: boolean
  ): Promise<FollowInstructorDto>;
  removeInstructorFollow(id: number): Promise<FollowInstructorDto>;
  toggleFavourite(options: {
    programId?: number;
    session?: AbstractSession | AlgoliaAnytimeArticle;
    playlistId?: number;
  }): void;
  updateUserInfo(userInfo: Partial<UserInfo>): Promise<string>;
  updateUserPassword(
    updatePasswordInfo: ChangePasswordInterface
  ): Promise<string>;
  updateProfileInfo(profileInfo: Partial<ProfileInfo>): Promise<string>;
  updateTos(version: number): Promise<void>;
  updateAdvertisment(acceptAdvertisment: boolean): Promise<void>;
  updateWatchtimeToServer(
    sessionId: number,
    trackedTime: number
  ): Promise<void>;
  __test__getSessions(): Promise<void>;
  __test__setCurrentUserInstructor(instructor: boolean): void;
  getCheckingAccountHistory(): Promise<CheckingAccountDto[]>;
  updateAppSettings(
    userId: number,
    settings: AbstractAppSettings
  ): Promise<void>;
}

interface AudioContraints {
  autoGainControl: boolean;
  noiseSuppression: boolean;
  echoCancellation: boolean;
}

export interface ProfileInfo {
  custom: string;
  bio: string;
  title: string;
  homepageUrl: string;
  facebookUrl: string;
  youtubeUrl: string;
  instagramUrl: string;
  tiktokUrl: string;
  linkedinUrl: string;
  image: Blob;
}

export interface UserInfo {
  firstname: string;
  lastname: string;
  newAvatar: Blob | string;
  gender: string;
  dateOfBirth: Date;
  identityNumber: string;
  mobileNumber: string;
  country: string;
  city: string;
  zipcode: string;
  address1: string;
  address2: string;
}

export interface ChangePasswordInterface {
  userId: number;
  oldPassword: string;
  newPassword: string;
}

export interface RequestForgottenPassword {
  email: string;
}

interface SnapshotSettings {
  frames: number;
  frameDelayMs: number;
  repeat: number;
  intervalMs: number;
  width: number;
  height: number;
  disabled?: boolean;
}

type WatchedSessions = { [key: number]: number };

export interface Sessions {
  booked: AbstractSession[];
  joinable: AbstractSession[];
  watched: WatchedSessions;
}

export interface State {
  readonly socket: typeof socket;
  readonly actions: Actions;
  email?: string;
  user: AbstractUser | null;
  balance: number;
  sessions: Sessions;
  favouritedProgramIds: number[];
  favouritedSessionIds: number[];
  favouritedPlaylistIds: number[];
  followedInstructors: FollowInstructorDto;
  open: boolean;
  loaded: boolean;
  audio: AudioContraints;
  snapshot: SnapshotSettings;
}

export const isAdmin = (user: AbstractUser | AbstractBaseUser) => {
  return !!user?.roles?.includes(Role.Admin);
};
export const isInstructor = (user: AbstractUser | AbstractBaseUser) => {
  return !!user?.roles?.includes(Role.Instructor);
};

export const isBasicInstructor = (user: AbstractUser | AbstractBaseUser) => {
  return !!user?.roles?.includes(Role.BasicInstructor);
};

export const isModerator = (user: AbstractUser | AbstractBaseUser) => {
  return !!user?.roles?.includes(Role.Moderator);
};
export const hasPremiumAccess = (user: AbstractUser | AbstractBaseUser) => {
  if (!user) {
    return false;
  }
  return (
    isAdmin(user) ||
    isInstructor(user) ||
    user["stripeProducts"].includes(StripeProduct.LIVE)
  );
};

export const hasRequiredProduct = (
  user: AbstractUser | AbstractBaseUser,
  product: StripeProduct
) => {
  if (isAdmin(user) || isInstructor(user) || !product) {
    return true;
  }

  return user["stripeProducts"].includes(product);
};

export const useHasCompletedSignup = (
  user: AbstractUser | AbstractBaseUser
) => {
  if (
    user?.roles?.includes(Role.BasicInstructor) ||
    user?.roles?.includes(Role.Instructor)
  ) {
    return !!(
      user?.dateOfBirth &&
      user?.gender &&
      user?.name.first &&
      user?.name.last &&
      // user?.identityNumber &&
      // user?.mobileNumber &&
      user?.country &&
      user?.city &&
      user?.zipcode &&
      user?.address1
    );
  }

  return !!(
    user?.dateOfBirth &&
    user?.gender &&
    user?.name.first &&
    user?.name.last
  );
};

export const KvixUserContext = React.createContext<State>(null as any);

export const getProvider = (
  user: AbstractUser
): "apple" | "google" | "facebook" | undefined => {
  if (!user) {
    return undefined;
  }
  return user.appleId
    ? "apple"
    : user.googleId
    ? "google"
    : user.facebookId
    ? "facebook"
    : undefined;
};

export const nameRegex = /^.{1,30}$/iu;
export const identityNumberRegex = /^\d{6}[-|(\s)]{0,1}\d{4}$/;

interface Props extends RouteComponentProps {
  languangeContext: LanguageContextState;
}

class StateProvider extends React.Component<Props, State> {
  private queryParams = QueryParams.fromQueryString(window.location.search);

  constructor(props) {
    super(props);
    logger.setLoggingLevel(4);
    logger.info("App started!");

    this.state = {
      socket,
      user: null,
      balance: null,
      sessions: {
        booked: [],
        joinable: [],
        watched: {},
      },
      favouritedProgramIds: [],
      favouritedSessionIds: [],
      favouritedPlaylistIds: [],
      followedInstructors: null,
      actions: {
        refetchUser: this.refetchUser.bind(this),
        authenticate: this.authenticate.bind(this),
        updateAudioConstraints: this.updateAudioConstraints.bind(this),
        updateSnapshotSettings: this.updateSnapshotSettings.bind(this),
        signIn: this.signIn.bind(this),
        signOut: this.signOut.bind(this),
        createUser: this.createUser.bind(this),
        createUserEmail: this.createUserEmail.bind(this),
        setUser: this.setUser.bind(this),
        signInUserEmail: this.signInUserEmail.bind(this),
        removeUser: this.removeUser.bind(this),
        bookSessions: this.bookSessions.bind(this),
        cancelSessions: this.cancelSessions.bind(this),
        updateWatchedSessions: this.updateWatchedSessions.bind(this),
        requestEmailChange: this.requestEmailChange.bind(this),
        changeEmail: this.changeEmail.bind(this),
        requestForgottenPassword: this.requestForgottenPassword.bind(this),
        updateInstructorFollow: this.updateInstructorFollow.bind(this),
        removeInstructorFollow: this.removeInstructorFollow.bind(this),
        toggleFavourite: this.toggleFavourite.bind(this),
        updateUserInfo: this.updateUserInfo.bind(this),
        updateUserPassword: this.updateUserPassword.bind(this),
        updateProfileInfo: this.updateProfileInfo.bind(this),
        updateTos: this.updateTos.bind(this),
        updateAdvertisment: this.updateAdvertisment.bind(this),
        __test__getSessions: this.__test__getSessions.bind(this),
        __test__setCurrentUserInstructor:
          this.__test__setCurrentUserInstructor.bind(this),
        getCheckingAccountHistory: this.getCheckingAccountHistory.bind(this),
        updateAppSettings: this.updateAppSettings.bind(this),
        updateWatchtimeToServer: this.updateWatchtimeToServer.bind(this),
      },
      loaded: false,
      open: this.queryParams.open || false,
      audio: {
        autoGainControl: this.queryParams.agc || false,
        noiseSuppression: this.queryParams.ns || false,
        echoCancellation: this.queryParams.ec || false,
      },
      snapshot: {
        frames: this.queryParams.snapshotFrames || 30,
        frameDelayMs: this.queryParams.snapshotFrameDelayMs || 100,
        repeat: this.queryParams.snapshotRepeat || -1,
        intervalMs: this.queryParams.snapshotIntervalMs || 3000,
        width: this.queryParams.snapshotWidth || 160,
        height: this.queryParams.snapshotHeight || 90,
        disabled: this.queryParams.disabled || false,
      },
    };
  }

  private async setUser() {
    let user: AbstractUser;
    try {
      const response = await fetch("/auth/me");
      user = await response.json();
    } catch (error) {}

    socket.connect();
    this.setState({
      loaded: true,
      user,
    });

    if (user) {
      Sentry.setUser({
        username: `${user.name?.first} ${user.name?.last}`,
        email: user.email,
        id: `${user.id}`,
      });
      if (user.appSettings?.language) {
        switch (user.appSettings.language) {
          case "sv-SE":
            this.props.languangeContext.setToSwedish();
            break;
          case "en-GB":
            this.props.languangeContext.setToEnglish();
            break;
          default:
            break;
        }
      } else {
        this.updateAppSettings(user.id, {
          language: this.props.languangeContext.currentLanguage,
        });
      }
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    socket
      .on<AbstractSession[]>("update joinable sessions")

      .subscribe((sessions) => {
        this.setState((state) => ({
          ...state,
          sessions: {
            ...state.sessions,
            joinable: sessions,
          },
        }));
      });

    socket
      .on<AbstractSession[]>("update booked sessions")
      .subscribe((sessions) => {
        this.setState((state) => ({
          ...state,
          sessions: {
            ...state.sessions,
            booked: sessions,
          },
        }));
      });

    socket
      .on<number[]>("update favourited program ids")
      .subscribe((programIds) => {
        this.setState((state) => ({
          ...state,
          favouritedProgramIds: programIds,
        }));
      });

    socket
      .on<number[]>("update favourited session ids")
      .subscribe((sessionIds) => {
        this.setState((state) => ({
          ...state,
          favouritedSessionIds: sessionIds,
        }));
      });

    socket
      .on<number[]>("update favourited playlist ids")
      .subscribe((playlistIds) => {
        this.setState((state) => ({
          ...state,
          favouritedPlaylistIds: playlistIds,
        }));
      });

    socket
      .on<WatchedSessions>("update watched sessions")
      .subscribe((sessions) => {
        this.setState((state) => ({
          ...state,
          sessions: {
            ...state.sessions,
            watched: sessions,
          },
        }));
      });

    socket.on<number>("update user balance").subscribe((balance) => {
      this.setState((state) => ({
        ...state,
        balance,
      }));
    });

    socket
      .on<StripeProduct[]>("update stripeProducts")
      .subscribe((stripeProducts) => {
        this.setState((state) => ({
          ...state,
          user: {
            ...state.user,
            stripeProducts,
          },
        }));
      });

    socket
      .on<FollowInstructorDto>("update followed instructors")
      .subscribe((followedInstructors) => {
        this.setState((state) => ({
          ...state,
          followedInstructors,
        }));
      });

    socket.on<any>("invalid user object").subscribe(() => {
      this.signOut();
    });

    socket.on<any>("invalid subscription").subscribe(() => {
      console.log("invalid subscription");
      this.props.history.push("/subscribe");
    });

    if (validateUser(user)) {
      this.signIn(user);
    }
    return user;
  }

  public async componentDidMount() {
    const user: AbstractUser = await this.setUser();
  }

  public render() {
    return (
      <KvixUserContext.Provider value={this.state}>
        {this.state.loaded && this.props.children}
      </KvixUserContext.Provider>
    );
  }

  private async authenticate(
    authenticationData: AuthenticateDto,
    url: string
  ): Promise<void> {
    const body = new URLSearchParams();
    body.append("username", authenticationData.email);
    body.append("password", authenticationData.password);

    const options = createAuthenticationRequestInit(authenticationData);
    console.log("options", options);
    const response = await fetch(url, options);

    return response.json();
  }

  private async signIn(user: AbstractUser): Promise<void> {
    const { email, ...rest } = user;
    socket.signIn(rest);
  }

  private async signOut() {
    Sentry.configureScope((scope) => scope.setUser(null));
    signOut();
  }

  private async createUser(newUser: CreateUserDto) {
    const body = JSON.stringify(newUser);
    const response = await fetch("/auth/email/register", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });

    return response.json();
  }

  private async createUserEmail(newUser: CreateUserEmailDto, startRegisterFrom: string) {
    const body = JSON.stringify({ ...newUser, startRegisterFrom });
    const response = await fetch("/auth/email/register", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });
    const result = await response.json();

    /** GTM track Email Register event **/
    pushGaEvent(
      { category: "Account", action: "Registered" },
      constructUserData(result.user).userData,
    );

    return result;
  }

  private async signInUserEmail(loginUser: CreateUserEmailDto) {
    const body = JSON.stringify({
      username: loginUser.email.toLowerCase(),
      password: loginUser.password,
    });
    const response = await fetch("/auth/email/login", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });

    return response.json();
  }

  private async updateWatchtimeToServer(
    sessionId: number,
    trackedTime: number
  ) {
    let curTime;
    try {
      curTime = this.state.sessions.watched[sessionId];
    } catch (e) {
      console.log(
        "Could not save watchtime because session was not found in sessions"
      );
      return;
    }

    const response = await fetch(
      `/api/statistic/watchedReplay/${sessionId}/${trackedTime}/${curTime}`,
      {
        method: HttpMethods.POST,
        redirect: "follow",
      }
    );
    return response;
  }

  private async removeUser(exitReason: Partial<AbstractExitReason>) {
    const body = JSON.stringify(exitReason);

    const response = await fetch("/auth/unregister", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });
    return response.json();
  }

  private async bookSessions(sessions: AbstractSession[]) {
    try {
      await Session.bookSessions(sessions);

      const booked = [...this.state.sessions.booked, ...sessions];
      this.setState({
        sessions: {
          ...this.state.sessions,
          booked,
        },
      });

      pushClientEvent({
        featureType: "program.booked.start",
        source: Source.Web,
        user: this.state.user,
      });
    } catch (error) {
      if (error instanceof AuthError) {
        this.props.history.push({
          pathname: "/signup",
          state: { from: this.props.history.location.pathname },
        });
      }
      throw new Error(error);
    }
  }

  private async cancelSessions(sessions: AbstractSession[]) {
    await Session.cancelSessions(sessions);

    const booked = this.state.sessions.booked.filter(
      (b) => !sessions.includes(b)
    );

    const joinable = this.state.sessions.joinable.filter(
      (j) => !sessions.includes(j)
    );

    this.setState({
      sessions: {
        ...this.state.sessions,
        booked,
        joinable,
      },
    });

    pushClientEvent({
      featureType: "program.booked.stop",
      source: Source.Web,
      user: this.state.user,
    });
  }

  private updateWatchedSessions(sessionId: number, currentTime: number) {
    this.setState({
      sessions: {
        ...this.state.sessions,
        watched: {
          ...this.state.sessions.watched,
          [`${sessionId}`]: currentTime,
        },
      },
    });
  }

  private updateSnapshotSettings(snapshot: SnapshotSettings) {
    this.setState({ snapshot });
  }

  private updateAudioConstraints(audio: AudioContraints) {
    this.setState({ audio });
  }

  private async requestEmailChange(email: string) {
    const body = JSON.stringify({
      email,
      language: getI18n().language,
    });
    const response = await fetch("/auth/me/email/requestchange", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });
    return await response.json();
  }

  private async changeEmail(email: string, verificationCode: string) {
    const body = JSON.stringify({ email, verificationCode });
    const response = await fetch("/auth/me/email/confirmchange", {
      method: HttpMethods.PUT,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });
    return response.json();
  }

  private async requestForgottenPassword(email: string) {
    const body = JSON.stringify({
      email,
      language: getI18n().language,
    });
    const response = await fetch("/auth/me/password/requestchange", {
      method: HttpMethods.POST,
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body,
    });
    return await response.json();
  }

  private async updateProfileInfo(profileInfo: ProfileInfo) {
    try {
      let formData = new FormData();
      Object.entries(profileInfo).forEach(([key, value]) => {
        if (key === "custom") {
          formData.append("name.custom", value.toString());
        } else if (key === "image") {
          formData.append("image", value);
        } else if (key === "tiktokUrl") {
          formData.append("instructorProfile.ticktockUrl", value);
        } else {
          formData.append(`instructorProfile.${key}`, value);
        }
      });
      const res = await fetch(`/api/streamer-admin/profile/`, {
        method: HttpMethods.PUT,
        body: formData,
      });

      if (res.ok) {
        const updatedUser: Partial<AbstractUser> = await res.json();

        this.setState({
          user: {
            ...this.state.user,
            name: {
              ...this.state.user.name,
              custom: updatedUser.name.custom,
            },
            instructorProfile: updatedUser.instructorProfile,
            imageUrl: updatedUser.imageUrl,
          },
        });
        return;
      }

      const errorBody = await res.json();
      throw new Error(errorBody.message);
    } catch (error) {
      throw error;
    }
  }

  private async updateUserInfo(userInfo: UserInfo) {
    try {
      let formData = new FormData();

      Object.entries(userInfo).forEach(([key, value]) => {
        if (key === "dateOfBirth") {
          formData.append(key, value.toString());
        } else if (key === "newAvatar") {
          formData.append("image", value);
        } else {
          formData.append(key, value);
        }
      });

      const res = await fetch("/api/user/updateUserInfo", {
        method: HttpMethods.POST,
        body: formData,
      });

      if (res.ok) {
        const updatedUser: AbstractUser = await res.json();

        this.setState({
          user: {
            ...this.state.user,
            dateOfBirth: updatedUser.dateOfBirth,
            gender: updatedUser.gender,
            imageUrl: updatedUser.imageUrl,
            name: updatedUser.name,
            identityNumber: updatedUser.identityNumber,
            mobileNumber: updatedUser.mobileNumber,
            country: updatedUser.country,
            address1: updatedUser.address1,
            address2: updatedUser.address2,
            zipcode: updatedUser.zipcode,
            city: updatedUser.city,
          },
        });
        return res;
      }
      const resBody = await res.json();
      throw new Error(resBody.message);
    } catch (error) {
      throw error;
    }
  }

  private async updateUserPassword(
    changePasswordInfo: ChangePasswordInterface
  ) {
    try {
      let formData = new FormData();

      Object.entries(changePasswordInfo).forEach(([key, value]) =>
        formData.append(key, value)
      );

      const res = await fetch("/api/user/updatePassword", {
        method: HttpMethods.POST,
        body: formData,
      });

      if (res.ok) {
        const updatedUser: AbstractUser = await res.json();

        this.setState({
          user: {
            ...this.state.user,
            password: updatedUser.password,
          },
        });
        return res;
      }
      const resBody = await res.json();
      throw new Error(resBody.message);
    } catch (error) {
      throw error;
    }
  }

  private async updateInstructorFollow(
    id: number,
    allowNotifications: boolean
  ): Promise<void> {
    try {
      const followedInstructors = await InstructorApi.updateInstructorFollow(
        id,
        allowNotifications
      );

      this.setState({ followedInstructors });
    } catch (error) {
      console.error(error);
    }
  }

  private async removeInstructorFollow(id: number): Promise<void> {
    try {
      const followedInstructors = await InstructorApi.removeInstructorFollow(
        id
      );

      this.setState({ followedInstructors });
    } catch (error) {
      console.error(error);
    }
  }

  private toggleFavourite(options: {
    programId?: number;
    session?: AbstractLiveSession | AlgoliaAnytimeArticle;
    playlistId?: number;
  }) {
    const { programId, session, playlistId } = options;

    if (programId) {
      return this.toggleFavouriteProgram(programId);
    }

    if (session) {
      return this.toggleFavouriteLiveSession(session);
    }

    if (playlistId) {
      return this.toggleFavouritePlaylist(playlistId);
    }
  }
  private toggleFavouriteProgram(programId: number) {
    try {
      Program.toggleFavouriteProgram(programId);
      const currentFavourites = [...this.state.favouritedProgramIds];
      const existingFavourite = currentFavourites.indexOf(programId) >= 0;

      if (existingFavourite) {
        currentFavourites.splice(currentFavourites.indexOf(programId), 1);
      } else {
        currentFavourites.push(programId);
      }

      this.setState({
        favouritedProgramIds: currentFavourites,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private toggleFavouritePlaylist(playlistId: number) {
    try {
      Playlist.toggleFavouritePlaylist(playlistId);
      const currentFavourites = [...this.state.favouritedPlaylistIds];
      const existingFavourite = currentFavourites.indexOf(playlistId) >= 0;

      if (existingFavourite) {
        currentFavourites.splice(currentFavourites.indexOf(playlistId), 1);
      } else {
        currentFavourites.push(playlistId);
      }

      this.setState({
        favouritedPlaylistIds: currentFavourites,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private toggleFavouriteLiveSession(
    session?: AbstractLiveSession | AlgoliaAnytimeArticle
  ) {
    try {
      const getStartTime = () => {
        const dateString = session["start"] || session["startTimestamp"];

        return new Date(dateString);
      };

      const isCancelled = session["state"] === SessionState.Cancelled;

      Session.toggleFavouriteLiveSession(session.id);
      const currentFavourites = [...this.state.favouritedSessionIds];
      const existingFavourite = currentFavourites.indexOf(session.id) >= 0;

      if (existingFavourite) {
        currentFavourites.splice(currentFavourites.indexOf(session.id), 1);
      } else {
        const now = new Date();
        if (!isCancelled && isBefore(getStartTime(), now)) {
          currentFavourites.push(session.id);
        }
      }
      this.setState({
        favouritedSessionIds: currentFavourites,
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async updateTos(version: number) {
    try {
      const body = JSON.stringify({ version });
      const response = await fetch("/auth/me/updateTosVersion", {
        method: HttpMethods.PUT,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body,
      });
      if (response.ok) {
        const { newVersion } = await response.json();
        this.setState({ user: { ...this.state.user, tosVersion: newVersion } });
      } else {
        this.setState({ user: { ...this.state.user, tosVersion: version } });
      }

      return;
    } catch (error) {
      console.error(error);
    }
  }

  private async updateAdvertisment(acceptAdvertisment: boolean) {
    try {
      if (acceptAdvertisment === this.state.user.acceptAdvertisment) {
        return;
      }
      const response = await fetch(`/auth/me/updateAdvertisment`, {
        method: HttpMethods.PUT,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ acceptAdvertisment }),
      });

      if (response.ok) {
        this.setState({ user: { ...this.state.user, acceptAdvertisment } });
      }

      return;
    } catch (error) {
      console.error(error);
    }
  }

  private async updateAppSettings(
    userId: number,
    appSettings: AbstractAppSettings
  ) {
    try {
      const response = await fetch(`/api/user/updateAppSettings`, {
        method: HttpMethods.POST,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, appSettings }),
      });

      if (response.ok) {
        this.setState({ user: { ...this.state.user, appSettings } });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  private async __test__getSessions() {
    await socket.getSessions();
  }

  private __test__setCurrentUserInstructor(instructor: boolean) {
    const { user } = this.state;
    const updatedUser = {
      ...user,
      instructor,
    };
    this.setState({ user: updatedUser });
  }

  private async getCheckingAccountHistory() {
    try {
      const response = await fetch("/api/product/checkingAccountHistory", {
        method: HttpMethods.GET,
      });

      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async refetchUser() {
    try {
      const response = await fetch("/auth/me");
      const user = await response.json();
      const state = { ...this.state, user };
      this.setState(state);
    } catch (error) {}
  }
}

const StateConsumer = KvixUserContext.Consumer;
const UserStateProviderFC: FC<RouteComponentProps> = (props) => {
  const languangeContext = useContext(LanguageContext);
  if (!languangeContext.currentLanguage) {
    return null;
  }

  return (
    <StateProvider {...props} languangeContext={languangeContext}>
      {props.children}
    </StateProvider>
  );
};
const KvixUserStateProvider = withRouter(UserStateProviderFC);

export { KvixUserStateProvider, StateConsumer as KvisUserStateConsumer };
