import { pushConsent } from "@kvix/shared";
import { Button, Text } from "@kvix/ui";
import {
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCookie, useLocation } from "react-use";
import { LanguageContext } from "../../../../contexts/language";
// import sweImg from "../../assets/Sweden.png";
// import engImg from "../../assets/UK.png";
import { HandleCookies } from "./HandleCookies";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainerMain: {
      maxWidth: "375px",
      alignSelf: "center",
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down("lg")]: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(3),
      },
    },
    firstTextBlock: {
      height: "auto",
      overflow: "hidden",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      marginBottom: theme.spacing(4),
    },
    secondTextBlock: {
      height: "auto",
      overflow: "hidden",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginBottom: theme.spacing(4),
    },
    lasMerTextBlock: {
      height: "auto",
      overflow: "hidden",
      padding: 0,
      display: "flex",
      flexDirection: "column",
    },

    avatarGroup: {
      display: "flex",
      justifyContent: "center",
      overflow: "visible",
      marginBottom: theme.spacing(4),
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "25px",
    },
    avatar: {
      boxShadow: "5px",
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    avatarButton: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      margin: theme.spacing(1),
    },
    button: {
      height: "52px !important",
      borderRadius: "26px",
      margin: 10,
      minWidth: "50%",
    },
  })
);
export const LanguageCookieModal: FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const { t, ready } = useTranslation("dialog");
  const [handleCookiesOpen, setHandleCookiesOpen] = useState<boolean>(false);

  const isMobile = /kvixapp/.test(navigator.userAgent);
  const { setToEnglish, setToSwedish, currentLanguage } =
    useContext(LanguageContext);

  const setLanguage = (language: string) => {
    language === "sv-SE" ? setToSwedish() : setToEnglish();
  };

  const [consentedCookies, setConsentedCookies] =
    useCookie("consented-cookies");

  const [acceptedCookies, setAcceptedCookies] = useState(
    !consentedCookies
      ? {
          functional: true,
          market: true,
        }
      : {
          functional: consentedCookies?.indexOf("functional") > -1,
          market: consentedCookies?.indexOf("market") > -1,
        }
  );

  useEffect(() => {
    if (document.referrer.includes('kvix.getkvix.com')) {
      setConsentedCookies("functional,market,essential", {
        expires: 365,
      });
      pushConsent();
    } else {
      setShow(location.pathname !== "/page/cookie-policy" && !consentedCookies);
    }
  }, [location]);

  const AcceptClick = () => {
    setShow(false);

    const newConsentedCookies = Object.keys(acceptedCookies).filter(
      (key) => acceptedCookies[key]
    );

    setConsentedCookies([...newConsentedCookies, "essential"].join(","), {
      expires: 365, //One year
    });
    pushConsent();

    //Send request in order to trigger ExpressSession set
    fetch("/auth/me/cookie-consent");
  };

  const handleCookieClick = () => {
    setHandleCookiesOpen(true);
    setShow(false);
  };

  if (!ready) {
    return null;
  }

  if (isMobile) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={show}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={() => setShow(false)}
        fullScreen={isMobile}
      >
        <Grid
          container
          className={classes.gridContainerMain}
          direction="column"
          justify="center"
          alignItems="center"
        >
          {/* <DialogContent className={classes.firstTextBlock}>
            <Text variant="h3" align="center">
              {t("cookieAndChangeLanguage.greetings")}
            </Text>

            <Text align="center" style={{ fontWeight: "bold" }}>
              {t("cookieAndChangeLanguage.text1")}
            </Text>
            <div className={classes.avatarGroup}>
              <IconButton
                onClick={() => setLanguage("en-GB")}
                className={classes.avatarButton}
                style={{
                  boxShadow:
                    currentLanguage === "en-GB" ? "0px 12px 20px -6px" : "",
                }}
              >
                <Avatar alt="UK" src={engImg} className={classes.avatar} />
              </IconButton>
              <IconButton
                onClick={() => setLanguage("sv-SE")}
                className={classes.avatarButton}
                style={{
                  boxShadow:
                    currentLanguage === "sv-SE" ? "0px 12px 20px -6px" : "",
                }}
              >
                <Avatar alt="Sweden" src={sweImg} className={classes.avatar} />
              </IconButton>
            </div>
          </DialogContent> */}
          <DialogContent className={classes.secondTextBlock}>
            <Text variant="h5" align="center" style={{ fontWeight: "bold" }}>
              {t("cookieAndChangeLanguage.title2")}
            </Text>

            <Text align="center" style={{ fontWeight: "regular" }}>
              {t("cookieAndChangeLanguage.text2")}
            </Text>
          </DialogContent>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              size="large"
              variant="oval"
              color="secondary"
              onClick={() => handleCookieClick()}
            >
              {t("cookieAndChangeLanguage.button1")}
            </Button>

            <Button
              className={classes.button}
              color="primary"
              variant="oval"
              onClick={() => AcceptClick()}
            >
              {t("cookieAndChangeLanguage.button2")}
            </Button>
          </div>
          <div className={classes.lasMerTextBlock}>
            <Link to="/page/cookie-policy">
              <Text variant="h6" align="center" style={{ fontWeight: "bold" }}>
                {t("cookieAndChangeLanguage.cookie")}
              </Text>
            </Link>
          </div>
        </Grid>
      </Dialog>
      <HandleCookies
        open={handleCookiesOpen}
        handleOpen={setHandleCookiesOpen}
        parentSetOpen={setShow}
        cookie={acceptedCookies}
        setCookie={setAcceptedCookies}
      />
    </div>
  );
};
