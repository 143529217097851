import { AlgoliaArticle, ArticleType } from "@kvix/shared";
import { CardList, Placeholder, Text } from "@kvix/ui";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import LazyLoad, { forceCheck } from "react-lazyload";
import { useUpdateEffect } from "react-use";
import { DisplayArrangement } from "../..";
import { AlgoliaContext, SortOption } from "../../../../../contexts/algolia";
import { LanguageContext } from "../../../../../contexts/language";
import { KvixUserContext } from "../../../../../contexts/user";
import { useIsLiked } from "../../../../../hooks/isLiked";
import { Card } from "../../Card";
import { ListCard } from "../../ListCard";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 12,
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },

    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  animation: {
    animation: "$fadeIn 500ms linear",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 1,
    },
  },
  listCardWrapper: {
    // marginBottom: 13,
  },
}));

interface Props {
  title: string;
  filterString: string;
  filterByChip: (key: string, value: any) => void;
  type: ArticleType;
  arrangement: DisplayArrangement;
  toggleFavourite?: (options: {
    programId?: number;
    sessionId?: number;
  }) => void;
  sortBy?: SortOption;
  hitsPerPage?: number;
}

const Row: React.FC<Props> = (props) => {
  const [data, setData] = useState<AlgoliaArticle[]>(null);
  const { user, sessions } = useContext(KvixUserContext);
  const language = useContext(LanguageContext);
  const { search } = useContext(AlgoliaContext);
  const isLiked = useIsLiked();
  const { title, filterString, filterByChip, arrangement, hitsPerPage } = props;
  const classes = useStyles(props);

  useEffect(() => {
    if (filterString) {
      setData(null);
      (async () => {
        const result = await search({
          query: "",
          filters: filterString,
          sortBy: props.sortBy || null,
          ...(hitsPerPage && { hitsPerPage }),
        });
        setData(result.hits as AlgoliaArticle[]);
      })();
    }
  }, [filterString, setData, language.currentLanguage]);

  useUpdateEffect(() => {
    forceCheck();
  }, [props.arrangement, data]);

  if (!data) {
    return <Placeholder.CardFeed cards={4} rows={1} />;
  }

  if (data?.length < 1) {
    return null;
  }

  return (
    <>
      {arrangement === DisplayArrangement.CARD ? (
        <>
          <CardList title={title} condensedTitle>
            {data?.map((article) => (
              <Card
                options={article}
                type={article.type}
                key={article.objectID}
                filterByChip={filterByChip}
                toggleFavourite={props.toggleFavourite}
                displayMeta={[
                  ArticleType.BASIC_INSTRUCTOR,
                  ArticleType.INSTRUCTOR,
                ].includes(article.type)}
              />
            ))}
          </CardList>
        </>
      ) : (
        <>
          <Text className={classes.title}>{title}</Text>
          {data?.map((article, index) => (
            <div className={classes.listCardWrapper} key={article.objectID}>
              <ListCard
                article={article}
                type={article.type}
                filterByChip={filterByChip}
                isLiked={isLiked(article.id, article.type)}
                isLast={index === data.length - 1}
                currentTime={sessions.watched[article.id]}
                user={user}
                toggleFavourite={props.toggleFavourite}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export const ResultRow: React.FC<Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  let CardHeight = isMobile ? 311.98 : 399;
  let ListCardHeight = 151;

  if (props.type === ArticleType.INSTRUCTOR) {
    CardHeight = 267;
    ListCardHeight = 125;
  }

  return (
    <LazyLoad
      height={
        props.arrangement === DisplayArrangement.CARD
          ? CardHeight
          : ListCardHeight
      }
    >
      <Row {...props} />
    </LazyLoad>
  );
};
