import { Text } from "@kvix/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Blogpost } from "../../../../../cms/models/Blogpost";

const Wrapper = styled.div<{ isTablet: boolean }>`
  display: flex;

  margin: 0px 0px 12px 0;
  flex-direction: ${(props) => (props.isTablet ? "column" : "row")};
`;

const ImageContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  overflow: hidden;
  min-width: ${(props) => (props.isTablet ? "100%" : "126px")};
  padding-bottom: ${(props) => (props.isTablet ? "100%" : "126px")};
  margin: ${(props) => (props.isTablet ? "0 0 12px 0" : "0 24px 0 0")};
  position: relative;
`;

const Image = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
`;
export const BlogpostFC: React.FC<{
  isTablet?: boolean;
  isMobile?: boolean;
  blogpost: Blogpost;
}> = (props) => {
  const { t, ready } = useTranslation("blog");

  if (!ready) {
    return null;
  }

  return (
    <Link
      to={`/blog/${props.blogpost.slug}`}
      style={{ width: props.isMobile ? "calc(50% - 16px)" : "100%" }}
    >
      <Wrapper isTablet={props.isTablet}>
        <ImageContainer isTablet={props.isTablet} isMobile={props.isMobile}>
          <Image imageUrl={props.blogpost.image.url} />
        </ImageContainer>
        <div>
          <Text variant="caption" color="primary">
            {props.blogpost.caption}
          </Text>
          <Text variant="h6" style={{ overflowWrap: "break-word" }}>
            {props.blogpost.title}
          </Text>
          <Text variant="subtitle2" color="textSecondary">
            {t("byAuthor") + props.blogpost.author}
          </Text>
        </div>
      </Wrapper>
    </Link>
  );
};
