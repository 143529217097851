import { AbstractSession, HttpMethods, SessionState } from "@kvix/shared";
import { AuthError } from "../errors/AuthError";

export const bookSessions = async (sessions: AbstractSession[]) => {
  const response = await fetch(`/api/session/book`, {
    method: HttpMethods.POST,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sessions),
  });
  if (response.status === 401) {
    throw new AuthError(response.statusText);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const cancelSessions = async (sessions: AbstractSession[]) => {
  try {
    const response = await fetch(`/api/session/cancel`, {
      method: HttpMethods.PUT,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sessions),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const toggleFavouriteLiveSession = async (sessionId: number) => {
  const response = await fetch(`/api/session/toggleFavourite`, {
    method: HttpMethods.PUT,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sessionId }),
  });

  if (response.status === 401) {
    throw new AuthError(response.statusText);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const getSessionState = async (sessionId: number) => {
  const response = await fetch(`/api/session/${sessionId}`, {
    method: HttpMethods.GET,
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.status === 401) {
    throw new AuthError(response.statusText);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const { state } = await response.json();

  return state as SessionState;
};
