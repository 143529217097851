import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  h2: {
    fontWeight: 700,
    fontSize: 28,
    lineHeight: "32px",
  },
  h3: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: "28px",
  },
  h4: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "22px",
  },
  h5: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
  },
  h6: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "12px",
  },
}));
