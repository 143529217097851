import { useEffect } from "react";

export const useLockLandscape = () => {
  const ua = navigator.userAgent;
  const isAndroid = /android/i.test(ua);

  const handleLockLandscape = () => {
    const isFullscreen = document.fullscreenElement !== null;

    if (isFullscreen) {
      window.screen.orientation.lock("landscape-primary").then(
        () => {},
        (error) => {
          console.error(error);
        }
      );
    }
  };

  useEffect(() => {
    if (isAndroid) {
      //Only works on Android
      document.addEventListener("fullscreenchange", handleLockLandscape);

      return () => {
        document.removeEventListener("fullscreenchange", handleLockLandscape);
      };
    }
  }, [isAndroid]);
};
