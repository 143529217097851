import { Text } from "@kvix/ui";
import { Button, Grid, makeStyles, Slider } from "@material-ui/core";
import {
  Pause,
  PlayArrow,
  VolumeDown,
  VolumeMute,
  VolumeUp,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { FullscreenControl } from "../../../partials/Fullscreen";

interface Props {
  onPlay: () => void;
  onPause: () => void;
  isPlaying: boolean;
  onVolumeChange: (volume: number) => void;
  visible: boolean;
  isLive: boolean;
  duration: number;
  currentTime: number;
  setCurrentTime: (time: number) => void;
  sliderValue: number[];
  setSliderValue: (value: number) => void;
  onChromeCastClick: () => void;
  onLiveClick: () => void;
}

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      alignItems: "center",
      background: "linear-gradient(0, rgba(0, 0, 0, 0.3), transparent)",
    },
    leftToolbar: {
      display: "flex",
      alignItems: "center",
      width: "50%",
    },
    rightToolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "50%",
    },
    upperToolbar: {
      paddingLeft: "30px",
      paddingRight: "30px",
      marginBottom: "-10px",
    },
    bottomToolbar: {
      position: "relative",
      paddingLeft: "10px",
      paddingRight: "10px",
      width: "inherit",
      justifyContent: "space-between",
    },
    button: {
      width: "30px",
      height: "30px",
      color: "rgba(255,255,255, 1)",
    },
    slider: {
      '& .MuiSlider-thumb[data-index="0"]': {
        visibility: "hidden",
      },
      '& .MuiSlider-thumb[data-index="1"]': {
        backgroundColor: theme.palette.primary.main,
      },
      '& .MuiSlider-thumb[data-index="2"]': {
        visibility: "hidden",
      },

      "& .MuiSlider-track": {
        background: () => {
          const sliderVal = (props.sliderValue[1] / props.sliderValue[2]) * 100;
          return `linear-gradient(90deg, rgba(250,37,94,1) ${sliderVal}%, rgba(255,255,255, 1) ${sliderVal}%)`;
        },
      },
      "& .MuiSlider-rail": {
        backgroundColor: "rgba(255,255,255, 1)",
      },
      color: "rgba(255,255,255, 1)",
    },
    volumeSlider: {
      width: "100px",
      marginLeft: "20px",
      "& .MuiSlider-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiSlider-track": {
        backgroundColor: theme.palette.primary.main,
      },
      color: "rgba(255,255,255, 1)",
    },
    durationInformation: {
      color: "rgba(255,255,255, 1)",
      marginLeft: "20px",
      fontSize: "16px",
    },
  }));
export const VideoJSToolbar: React.FC<Props> = (props) => {
  const classes = useStyles(props)();
  const [hoverVolume, setHoverVolume] = useState(false);
  const [volume, setVolume] = useState<number>(100);
  const [prevVolume, setPrevVolume] = useState<number>(volume);

  useEffect(() => {
    props.onVolumeChange(volume / 100);
  }, [volume]);

  const handlePlayButton = () => {
    if (props.isPlaying) {
      props.onPause();
    } else {
      props.onPlay();
    }
  };

  useEffect(() => {}, [props.sliderValue]);

  const handleVolumeIcon = useCallback(
    (classes) => {
      if (volume === 0) {
        return (
          <VolumeMute
            className={classes.button}
            onClick={() => handleMuteButton()}
          />
        );
      }
      if (volume > 50) {
        return (
          <VolumeUp
            className={classes.button}
            onClick={() => handleMuteButton()}
          />
        );
      }
      return (
        <VolumeDown
          className={classes.button}
          onClick={() => handleMuteButton()}
        />
      );
    },
    [volume]
  );

  const secondsToHms = useCallback((seconds: number) => {
    if (seconds >= 3600) {
      return new Date(seconds * 1000).toISOString().slice(11, 19);
    } else {
      return new Date(seconds * 1000).toISOString().slice(14, 19);
    }
  }, []);

  const handleMuteButton = useCallback(() => {
    if (volume === 0) {
      setVolume(prevVolume);
    } else {
      setPrevVolume(volume);
      setVolume(0);
    }
  }, [volume, prevVolume]);

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);
  };

  const handleChangeTime = (event, newValue) => {
    let newVal = 0;
    newValue.forEach((value) => {
      if (value % 1 === 0 && value !== 0) {
        newVal = value;
        return;
      }
    });
    const val = (newVal / 100) * props.duration;
    props.setCurrentTime(val);
  };

  const style = useSpring({
    transform: `translate3d(0, ${props.visible ? 0 : 50}%, 0)`,
    opacity: props.visible ? 1 : 0,
    width: "100%",
    config: {
      tension: props.visible ? 320 : 100,
      friction: props.visible ? 25 : 10,
    },
  });

  return (
    <animated.div style={style}>
      <Grid container className={classes.root} direction="row">
        {/* Scrubbing */}
        {!props.isLive ? (
          <Grid container item className={classes.upperToolbar}>
            <div style={{ width: "100%" }}>
              <Grid item xs>
                <Slider
                  value={props.sliderValue}
                  onChange={handleChangeTime}
                  aria-labelledby="continuous-slider"
                  className={classes.slider}
                />
              </Grid>
            </div>
          </Grid>
        ) : null}
        <Grid container item direction="row" className={classes.bottomToolbar}>
          <Grid item container direction="row" className={classes.leftToolbar}>
            {/* Playbutton */}
            {!props.isLive ? (
              <Grid item>
                <Button onClick={handlePlayButton}>
                  {props.isPlaying ? (
                    <Pause className={classes.button} />
                  ) : (
                    <PlayArrow className={classes.button} />
                  )}
                </Button>
              </Grid>
            ) : null}

            {/* Volume */}
            <Grid
              item
              onMouseOver={() => setHoverVolume(true)}
              onMouseLeave={() => setHoverVolume(false)}
            >
              <Grid item style={{ cursor: "pointer", marginBottom: "-5px" }}>
                {handleVolumeIcon(classes)}
                {hoverVolume ? (
                  <Slider
                    value={volume}
                    onChange={handleChangeVolume}
                    aria-labelledby="continuous-slider"
                    className={classes.volumeSlider}
                  />
                ) : null}
              </Grid>
            </Grid>

            {/* Duration */}
            <Grid item>
              {props.isLive ? (
                <Text
                  className={classes.durationInformation}
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onLiveClick()}
                >
                  LIVE
                </Text>
              ) : (
                <Text className={classes.durationInformation}>
                  {secondsToHms(props.currentTime)} /{" "}
                  {secondsToHms(props.duration)}
                </Text>
              )}
            </Grid>
          </Grid>
          <Grid container item direction="row" className={classes.rightToolbar}>
            {/* Chromecast WEB */}
            {/*
            <Grid
              item
              style={{
                paddingRight: "10px",
                marginBottom: "-5px",
                cursor: "pointer",
              }}
              onClick={() => handleChromeCast()}
            >

              <Button is="castbutton">
                <CastIcon className={classes.button} />
              </Button>
            </Grid>
            */}
            {/* Fullscreen */}
            <Grid item style={{ paddingRight: "10px" }}>
              <FullscreenControl size="large" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </animated.div>
  );
};
