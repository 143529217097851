import { Hostname } from "@kvix/shared"
import { SocialOauthButtons, Text } from "@kvix/ui"
import { Grid, makeStyles, useTheme, Link, Typography, Box } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import { RegisterProps } from "../types"
import { RegisterWithEmailButton } from "./Email/emailRegisterButton"
import successImg from "../../partials/assets/devices.png";

export const Register = ({
  origin,
  checkoutSource,
  changeToLogin,
  showContentModal,
  headerText,
  checkoutParams,
  plan,
  recruit,
}: RegisterProps) => {
  const { t, ready, i18n } = useTranslation([
    "signUp",
    "preCheckoutStep",
    "checkoutFlow",
    "giftcard",
    "premiumPromptTable",
  ]);
  const useStyles = makeStyles((theme) => ({
    ContainerPadding: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      height: "100%",
      width: "375px",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: { width: "100%", height: "100%" },
      "@media (max-width:360px)": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    topTextBlock: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    foot: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      width: "100%",
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      boxSizing: "border-box",
    },
    fontWeightMedium: {
      fontSize: "15px",
      lineHeight: "24px",
      fontWeight: 500,
      backgoundColor: "yellow",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    marginTopBottom: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    image: {
      maxWidth: "72px",
    },
    stripeImage: {
      maxWidth: "128px",
      marginTop: "35px",
      marginBottom: "9px",
    },
    title: { marginBottom: theme.spacing(1) },
    tosAndLoginContainer: {
      borderRadius: 4,

      "& .MuiTypography-root": {
        fontSize: "12px",
        fontWeight: 600,
        letterSpacing: "0.25",
      },
    },
    tosAndLoginContainerText: {
      fontSize: 13,
      fontWeight: 600,
    },
    isPremiumText: {
      color: theme.palette.primary.main,
      fontSize: 13,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "20px",
      width: "100%",
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "26px",
      paddingBottom: theme.spacing(3),
    },
    breakLine: {
      whiteSpace: "pre-line",
    },
    subText1: { fontSize: 14, fontWeight: "bold", marginBottom: "24px" },
    subText2: {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.secondary.main,
      marginTop: 8,
      marginBottom: theme.spacing(2),
    },
    big: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 26,
      width: "100%",
      marginBottom: "16px",
    },
    checkmarkIcon: {
      height: "16.4px",
      width: "16px",
      position: "relative",
      bottom: "-4px",
    },
    bottomBar: {
      backgroundColor: "#F8F8F7",
      padding: "16px 16px 16px 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignSelf: "center",
      width: "100%",
      height: "auto",
      top: "auto",
      bottom: 0,
    },
    checkmarkListContainer: {
      margin: "30px 0px 30px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "90px",
      justifyContent: "space-between",
    },
    headerText: {
      fontSize: "20px",
      lineHeight: "32px",
      marginBottom: "4px",
    },
  }))

  const theme = useTheme();
  const classes = useStyles();

  if (!ready) {
    return null
  }

  return (
    <React.Fragment>
      <Box className={classes.ContainerPadding}>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <img src={successImg} className={classes.image} alt={"hduwahdwa"} />
          <Text
            variant="h5"
            className={classes.headerText}
            style={{ textTransform: "capitalize" }}
          >
            {t("signUp:createAnAccount")}
          </Text>
            { checkoutSource === 'wp' && i18n.exists(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.${checkoutParams.coupon?.id}`) && checkoutParams.coupon?.id &&checkoutParams.couponId &&
              <>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.${checkoutParams.coupon?.id}`).split('*')[0]}&nbsp;
                </Text>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.${checkoutParams.coupon?.id}`).split('*')[1]}
                </Text>
              </>
            }
            { checkoutSource === 'wp' && i18n.exists(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.default`) && !checkoutParams.coupon?.id &&
              <>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.default`).split('*')[0]}&nbsp;
                </Text>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.${checkoutParams && checkoutParams.lookupKey ? checkoutParams.lookupKey : plan.type === 'one_time' ? plan.lookup_key : plan.metadata.lookup_key}.body.default`).split('*')[1]}
                </Text>
              </>
            }
            { checkoutSource !== 'wp' && i18n.exists(`preCheckoutStep:register.body.default`) &&
              <>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.body.default`).split('*')[0]}&nbsp;
                </Text>
                <Text variant="h5" className={classes.fontWeightMedium}>
                  {t(`preCheckoutStep:register.body.default`).split('*')[1]}
                </Text>
              </>
            }
          <Grid container style={{ marginTop: 15 }} justify="center">
            <Text variant="h5" style={{ paddingTop: "8px", marginBottom: "8px" }}>
              {`${t("signUpWith")} ${t("email")}`}
            </Text>
            <RegisterWithEmailButton variant="big" />
            <Typography variant="h5" align="center" style={{ marginBottom: 8 }}>
            {`${t("signUp:registerWith")}`}
            </Typography>
            <SocialOauthButtons
              text={''}
              type="register"
              origin={origin}
              host={Hostname.auth.register()}
            />
          </Grid>
          <Grid
            container
            className={classes.foot}
            style={{ maxWidth: "280px" }}
          >
            <Grid item className={classes.tosAndLoginContainer}>
              <Text variant="h5">
                {t("tocTextBeforeLink")}
                <Link
                  onClick={() => showContentModal("terms")}
                  style={{ cursor: "pointer" }}
                >
                  <u>{t("tocLinkText")}</u>
                </Link>
                {t("tocTextAfterLink")}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid style={{ flex: 1 }}></Grid>
      <Grid container style={{ flex: 1 }}>
        <Grid className={classes.bottomBar}>
          <Text variant="h5">
            {t("checkoutFlow:register.alreadyAMember")}&nbsp;
            <u
              onClick={() => changeToLogin()}
              style={{
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              {t("checkoutFlow:register.clickHere")}
            </u>
          </Text>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
