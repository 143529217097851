import { Text } from "@kvix/ui";
import { Box, Grid, makeStyles } from "@material-ui/core";
import {
  Add,
  Facebook,
  HomeSharp,
  Instagram,
  LinkedIn,
  YouTube,
} from "@material-ui/icons";
import classnames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import { getLinkUrl } from "../../../../utils/url";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    height: "44px",
    width: "44px",
    borderRadius: "50%",
    background: theme.palette.primary.dark,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fill: theme.palette.common.white,
  },
  iconsHeader: {
    marginBottom: "25px",
  },
  addButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

type SocialMediaChannelsProps = {
  onClickAdd: () => void;
};
export const SocialMediaChannels = ({
  onClickAdd,
}: SocialMediaChannelsProps) => {
  const classes = useStyles();
  const { user } = useContext(KvixUserContext);
  const { instructorProfile } = user;
  const { t, ready } = useTranslation("streamerAdminAccount");
  const channels = [
    {
      url: instructorProfile?.homepageUrl,
      icon: <HomeSharp className={classes.icon} />,
    },
    {
      url: instructorProfile?.instagramUrl,
      icon: <Instagram className={classes.icon} />,
    },
    {
      url: instructorProfile?.facebookUrl,
      icon: <Facebook className={classes.icon} />,
    },
    {
      url: instructorProfile?.youtubeUrl,
      icon: <YouTube className={classes.icon} />,
    },
    {
      url: instructorProfile?.ticktockUrl,
      icon: (
        <img
          alt=""
          src="/assets/brands/tiktok-neg.svg"
          style={{ height: "20px", width: "20px" }}
          className={classes.icon}
        />
      ),
    },
    {
      url: instructorProfile?.linkedinUrl,
      icon: <LinkedIn className={classes.icon} />,
    },
  ].filter((channel) => channel.url);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Text variant="h5" className={classes.iconsHeader}>
        {t("yourSocialMedia")}
      </Text>
      <Grid container spacing={2}>
        {channels.map((channel, index) => {
          return (
            <Grid item key={index}>
              <a
                href={getLinkUrl(channel.url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box className={classes.iconWrapper}>{channel.icon}</Box>
              </a>
            </Grid>
          );
        })}
        <Grid item>
          <Box
            onClick={onClickAdd}
            className={classnames(classes.iconWrapper, classes.addButton)}
          >
            <Add />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
