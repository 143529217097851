import { Footer } from "../../Footer";
import { getData } from "../adapter";

interface RawFooter {
  id: number;
  attributes: {
    createdAt: Date;
    publishedAt: Date;
    updatedAt: Date;
    columns: [
      {
        id: number;
        title: string;
        created_at: Date;
        updated_at: Date;
        links: [
          {
            id: number;
            openAsModal: boolean;
            text: string;
            url: string;
            created_at: Date;
            updated_at: Date;
          }
        ];
      }
    ];
  };
}
export const FooterAdapter = async (rawJSON: RawFooter): Promise<Footer> => {
  const {
    id,
    attributes: { createdAt, updatedAt, columns },
  } = await getData<RawFooter>(rawJSON);

  return {
    id,
    created_at: createdAt,
    updated_at: updatedAt,
    columns: columns,
  };
};
