import { Text } from "@kvix/ui";
import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BlogContext } from "../../../../../contexts/blog";
import { Footer } from "../../../../Footer";
import { Banner } from "../../../../partials/Banner/Banner";
import { Page } from "../../../../partials/Page";
import { BlogpostFC } from "./Blogpost";
import { FeaturedBlogpost } from "./FeaturedBlogpost";

const Blogposts = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  width: 100%;
`;

const FeaturedContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "100%" : "60%")};
  padding: ${(props) => (props.isMobile ? "0" : "24px")};
`;

const OtherContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isTablet ? "row" : "column")};
  justify-content: ${(props) => (props.isMobile ? "space-between" : "none")};
  width: ${(props) => (props.isMobile ? "100%" : "40%")};
  flex-wrap: ${(props) => (props.isTablet ? "wrap" : "none")};
  padding: ${(props) => (props.isTablet ? "24px 0" : "24px 0 24px 24px")};
`;
export const CmsBlogLanding: React.FC = (props) => {
  const { blog } = useContext(BlogContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, ready } = useTranslation("blog");

  const featuredBlogpost =
    blog?.blogposts?.find((b, i) => b.featured) || blog?.blogposts[0];

  const restOfBlogposts = blog?.blogposts?.filter(
    (b) => b !== featuredBlogpost
  );

  if (!ready) {
    return null;
  }

  return (
    <Page>
      {blog && featuredBlogpost && restOfBlogposts && (
        <Fragment>
          <Container maxWidth="lg">
            <Blogposts isMobile={isMobile}>
              <FeaturedContainer isMobile={isMobile}>
                <FeaturedBlogpost blogpost={featuredBlogpost} />
              </FeaturedContainer>
              <OtherContainer isMobile={isMobile} isTablet={isTablet}>
                {restOfBlogposts.length > 0 && (
                  <Text
                    variant="h5"
                    style={{
                      marginBottom: "24px",
                      marginTop: isMobile ? "48px" : "0",
                      width: "100%",
                    }}
                  >
                    {t("morePosts")}
                  </Text>
                )}
                {restOfBlogposts.map((blogpost) => (
                  <BlogpostFC
                    key={blogpost.id}
                    blogpost={blogpost}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                ))}
              </OtherContainer>
            </Blogposts>
            <Box pb={4} style={{ paddingTop: 32 }}>
              <Banner type="upgrade-premium" size="small" />
            </Box>
          </Container>
        </Fragment>
      )}
      <Footer />
    </Page>
  );
};
