import { AbstractAnswer } from "@kvix/shared";
import { Button, FaQ, Text } from "@kvix/ui";
import { Box, Container, makeStyles, Theme, useTheme } from "@material-ui/core";
import queryString from "query-string";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useBannerComments } from "../../../hooks/http";
import { useIsMobile } from "../../../hooks/isMobile";
import { getAssetsPath } from "../../../utils/getAssetsPath";
import { Footer } from "../../Footer";
import image from "../../partials/assets/recruitment_devices.jpg";
import { Banner } from "../../partials/Banner/Banner";
import { Page } from "../../partials/Page";
import { Promote } from "../Start/components/Promote";
import { NewFlow } from "../../../components/PurchaseFlow";
import { PurchaseFlowContext } from "../../../contexts/PurchaseFlow";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  container: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  video: {
    pointerEvents: "none",
    userSelect: "none",
    willChange: "transform",
    width: "100%",
    borderRadius: "16px",

    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 64px)",
      marginLeft: "-32px",
      borderRadius: 0,
    },
  },
  faqContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: `${theme.spacing(8)}px 0`,
    backgroundImage: "url(/assets/about-images/faq-cover.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  faqAccordion: {
    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
}));

const HelloMyFriend: React.FC<{
  recruitCode: string;
  isMobile: boolean;
  theme: Theme;
}> = (props) => {
  const { t, ready } = useTranslation(["startPage", "signUp"]);
  const { actions } = useContext(PurchaseFlowContext);

  if (!ready) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      marginBottom={
        props.isMobile ? props.theme.spacing(1) : props.theme.spacing(1.5)
      }
    >
      <Box display="flex" width="50%" minWidth="400px" justifyContent="center">
        <Box
          width={props.isMobile ? "276px" : "400px"}
          textAlign="left"
          marginBottom={props.isMobile ? props.theme.spacing(1) : 0}
        >
          <Text
            variant="h1"
            gutterBottom
            style={!props.isMobile && { fontSize: "80px", lineHeight: "1.15" }}
          >
            {t("startPage:recruitLandingPage:heyFriend")}
          </Text>
          <Text gutterBottom style={{ fontWeight: 500 }}>
            {t("startPage:recruitLandingPage:welcomeText")}
          </Text>
          {/* <Link to={`/signup?recruit=${props.recruitCode}`}> */}
          <Button variant="contained" style={{ margin: "10px 0" }} onClick={() => actions.prompt(<NewFlow registerProps={{ checkoutSource: 'kvix' }} recruit={true} currentTab={0} />)}>
            {t("startPage:hero:common:getKvixFree")}
          </Button>
          <Text
            variant="subtitle2"
            style={{ marginTop: "6px", fontWeight: 700 }}
          >
            {t("signUp:noCreditCard")}
          </Text>
        </Box>
      </Box>
      <Box width="50%" minWidth="400px">
        <img src={image} alt="Kvix mobile app" style={{ width: "100%" }} />
      </Box>
    </Box>
  );
};

const MakeYourselfAtHome: React.FC<{ theme: Theme; isMobile: boolean }> = (
  props
) => {
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
      marginBottom={props.isMobile ? "40px" : props.theme.spacing(0.5)}
    >
      <Box maxWidth="612px">
        <Text variant={props.isMobile ? "h3" : "h2"} gutterBottom>
          {t("recruitLandingPage.feelAtHomeTitle")}
        </Text>

        <Text style={{ marginBottom: props.theme.spacing(8), fontWeight: 500 }}>
          {t("recruitLandingPage.feelAtHomeText")}
        </Text>
      </Box>
      <div style={{ width: "100%" }}>{props.children}</div>
    </Box>
  );
};

const EveryKindOfFit: React.FC<{ theme: Theme; isMobile: boolean }> = (
  props
) => {
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      marginBottom={
        props.isMobile ? props.theme.spacing(1) : props.theme.spacing(1.5)
      }
    >
      <Box maxWidth="612px">
        <Text variant={props.isMobile ? "h3" : "h2"} gutterBottom>
          {t("recruitLandingPage.TrainSmarterTitle")}
        </Text>
        <Text style={{ marginBottom: props.theme.spacing(8), fontWeight: 500 }}>
          {t("recruitLandingPage.TrainSmarterText")}
        </Text>
      </Box>
      <img
        src="/assets/about-images/programs-sample.png"
        alt="Kvix programs"
        style={{ width: "100%", pointerEvents: "none" }}
      />
    </Box>
  );
};

const ATeamWhoGivesADamn: React.FC<{ theme: Theme; isMobile: boolean }> = (
  props
) => {
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      marginBottom={
        props.isMobile ? props.theme.spacing(1) : props.theme.spacing(1.5)
      }
    >
      <Box maxWidth="612px">
        <Text
          variant={props.isMobile ? "h3" : "h2"}
          gutterBottom
          style={{ whiteSpace: props.isMobile ? "pre-line" : "inherit" }}
        >
          {t("recruitLandingPage.ATeamWhoGivesADamnTitle")}
        </Text>
        <Text style={{ marginBottom: props.theme.spacing(8), fontWeight: 500 }}>
          {t("recruitLandingPage.ATeamWhoGivesADamnText")}
        </Text>
      </Box>
      <img
        src="/assets/about-images/instructors-sample.png"
        alt="Kvix programs"
        style={{ width: "100%", pointerEvents: "none" }}
      />
    </Box>
  );
};

const WeLoveYouToo: React.FC<{
  theme: Theme;
  isMobile: boolean;
  comments: AbstractAnswer[];
}> = (props) => {
  const { t, ready } = useTranslation("startPage");

  if (!ready) {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      marginBottom={props.isMobile ? 0 : props.theme.spacing(0.5)}
      textAlign="left"
    >
      <Box maxWidth="612px" marginBottom="14px">
        <Text
          variant={props.isMobile ? "h3" : "h2"}
          style={{ whiteSpace: props.isMobile ? "pre-line" : "inherit" }}
        >
          {t("recruitLandingPage.WeLoveYouTooTitle")}
        </Text>
      </Box>
      <Banner type="quotes" comments={props.comments} />
    </Box>
  );
};

export const RecruitLandingPage: React.FC = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const queryParams = queryString.parse(history.location.search);
  const recruitCode = queryParams.code as string;
  const isMobile = useIsMobile();
  const theme = useTheme();
  const comments = useBannerComments();
  const { t, ready } = useTranslation("startPage");
  const questions = t("recruitLandingPage.faq.questions", {
    returnObjects: true,
  }) as [];

  if (!ready) {
    return null;
  }

  return (
    <Page>
      <div className={classes.root}>
        <HelloMyFriend
          recruitCode={recruitCode}
          isMobile={isMobile}
          theme={theme}
        />
        <Container maxWidth="lg" className={classes.container}>
          <MakeYourselfAtHome isMobile={isMobile} theme={theme}>
            <video className={classes.video} muted autoPlay playsInline loop>
              <source
                type="video/mp4"
                src={getAssetsPath("instructor-catch-ups.mp4")}
              ></source>
            </video>
          </MakeYourselfAtHome>
          <Box
            marginBottom={isMobile ? theme.spacing(1) : theme.spacing(1.5)}
            width="100%"
          >
            <Promote noPadding hideButton />
          </Box>
          <EveryKindOfFit theme={theme} isMobile={isMobile} />
          <ATeamWhoGivesADamn theme={theme} isMobile={isMobile} />
          <WeLoveYouToo theme={theme} isMobile={isMobile} comments={comments} />
        </Container>
        <FaQ isMobile={isMobile} questions={questions} />
        <Banner
          type="landing-page-upgrade-free"
          customUrl={recruitCode ? `/signup?recruit=${recruitCode}` : null}
        />
      </div>
      <Footer />
    </Page>
  );
};
