import { IssuedFrom, StripeProduct } from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import {
  Box,
  Drawer,
  FormControl,
  FormLabel,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompetitionContext } from "../../../contexts/competition";
import { APP_HEADER_PADDING, ExpoContext } from "../../../contexts/expo";
import { hasPremiumAccess, KvixUserContext } from "../../../contexts/user";
import { useShowPromotions } from "../../../hooks/promotions";
import { CoinImageSize } from "../../pages/Payment/BuyCoins/enums/CoinImageSize";
import { DonateType } from "../../pages/Payment/BuyCoins/enums/DonateType";
import { CoinImage } from "../../pages/Payment/BuyCoins/partials/CoinImage";
import { VoteModal } from "../../pages/Payment/BuyCoins/VoteModal";
import { useUserBalance } from "../../pages/Payment/hooks";
import { DownloadApp } from "../../partials/AppPromotion/DownloadButton";
import { HeaderContext } from "../context";
import { MenuItem } from "../Items/MenuItem";

interface Props {
  iconClassName: string;
  children: (close: () => void) => React.ReactNode;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
    marginLeft: "auto",
    backgroundColor: theme.palette.background.paper,
    width: 400,

    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      paddingTop: theme.spacing(6) + APP_HEADER_PADDING,
    },
  },
  container: {
    position: "relative",
    minHeight: 400,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 12,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(12),
  },
  closeButton: {
    position: "fixed",
    top: theme.spacing(2) + APP_HEADER_PADDING,
    right: theme.spacing(3),
  },
  koinsContainer: {
    position: "absolute",
    top: theme.spacing(3.5),
    display: "flex",
    alignItems: "center",
    color: theme.palette.background.default,
    lineHeight: 1,
    fontSize: 20,
    fontWeight: 600,
    cursor: "pointer",

    "&.disabled": {
      pointerEvents: "none",
    },
  },
  footer: {
    paddingRight: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  koinsIndicator: {
    background: theme.palette.getContrastText(theme.palette.background.default),
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 14px",
    marginRight: 6,

    "&.morePadding": {
      padding: "7px 5px 7px 14px",
    },

    "& > span": {
      marginRight: 10,
    },
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
}));

const AppInfo: React.FC = (props) => {
  const { isNativeApp, constants } = useContext(ExpoContext);
  const { t, ready } = useTranslation("sidebar");

  if (!isNativeApp || !ready) {
    return null;
  }

  return (
    <div style={{ paddingBottom: "8px" }}>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          style={{ fontWeight: "bold" }}
          focused={false}
        >
          {t("appVersion")}
        </FormLabel>
        <Text gutterBottom>{constants.version}</Text>
      </FormControl>
    </div>
  );
};

export const HeaderItemSidebar = (props: Props) => {
  const classes = useStyles(props);
  const { user } = useContext(KvixUserContext);
  const { t, ready } = useTranslation(["sidebar", "giftcard"]);
  const theme = useTheme();
  const showPromotions = useShowPromotions();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const context = useContext(HeaderContext);
  const [showBuyKoins, setShowBuyKoins] = React.useState(false);
  const { activeCompetitionRound } = useContext(CompetitionContext);
  const donateType = activeCompetitionRound ? DonateType.VOTE : DonateType.COIN;
  const userKoinBalance = useUserBalance() || 0;
  const [visible, setVisible] = React.useState(false);
  const anchor = "right";

  const handleClick = () => {
    setVisible((previous) => !previous);
  };

  const close = () => {
    setVisible(false);
  };

  if (!ready) {
    return null;
  }

  return (
    <React.Fragment key={anchor}>
      <IconButton
        className={props.iconClassName}
        color="inherit"
        aria-label="Toggle Sidebar"
        onClick={handleClick}
      >
        <KvixIcon.Menu.Hamburger />
      </IconButton>
      <Drawer anchor={anchor} open={visible} onClose={close}>
        <Box className={classes.root}>
          <VoteModal
            issuedFrom={IssuedFrom.SIDEBAR}
            isMobile={isMobile}
            displayVote={showBuyKoins}
            setDisplayVote={setShowBuyKoins}
            onRedirect={close}
            donateType={donateType}
          />
          <div className={classes.container}>
            <IconButton
              color="primary"
              className={classes.closeButton}
              onClick={close}
            >
              <KvixIcon.Close fontSize="large" color="action" />
            </IconButton>

            {user && (
              <div
                className={classNames(classes.koinsContainer, {
                  disabled: context.isNativeApp,
                })}
                onClick={
                  !context.isNativeApp ? () => setShowBuyKoins(true) : null
                }
              >
                <div
                  className={classNames(classes.koinsIndicator, {
                    morePadding: userKoinBalance < 1,
                  })}
                >
                  <span>
                    {userKoinBalance < 1 && !context.isNativeApp
                      ? t("buyKvixKoins")
                      : userKoinBalance}
                  </span>{" "}
                  <CoinImage size={CoinImageSize.XXL} />
                </div>
                {!context.isNativeApp && userKoinBalance > 0 && (
                  <span
                    style={{
                      color: theme.palette.getContrastText(
                        theme.palette.background.default
                      ),
                      marginTop: -2,
                    }}
                  >
                    +
                  </span>
                )}
              </div>
            )}

            {props.children(close)}

            <div className={classes.spacer} />

            {!hasPremiumAccess(user) && showPromotions && (
              <>
                <MenuItem
                  color="primary"
                  alignItems="flex-start"
                  onClick={close}
                >
                  <Link to={"/signup"} children={t("giftcard:title")} />
                </MenuItem>
                <div className={classes.spacer} />
              </>
            )}

            <MenuItem alignItems="flex-start" onClick={close}>
              <Link to="/feedback" children={t("giveUsFeedBack")} />
            </MenuItem>

            {showPromotions && (
              <MenuItem alignItems="flex-start" onClick={close}>
                <Link to="/buy-gift-card" children={t("buyGiftcard")} />
              </MenuItem>
            )}
            {isMobile && !context.isNativeApp && user && user.stripeProducts.includes(StripeProduct.LIVE) && (
              <div style={{ marginTop: "auto" }}>
                <DownloadApp
                  customText={t("downloadApp")}
                  textProps={{ fontSize: 20, fontWeight: 400 }}
                />
              </div>
            )}
          </div>
          <div className={classes.footer}>
            <AppInfo />
          </div>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
