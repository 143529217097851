import { StripeProduct, validateSlug } from "@kvix/shared";
import { KvixIcon, Text } from "@kvix/ui";
import { IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { isInstructor, KvixUserContext } from "../../contexts/user";
import { useIsMobile } from "../../hooks/isMobile";
import { getAssetsPath } from "../../utils/getAssetsPath";
import { HeaderContext } from "../Header/context";
import { DownloadApp } from "./AppPromotion/DownloadButton";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    top: 0,
    width: "100%",
    height: 80,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    zIndex: 1299,
    alignItems: "center",
    padding: "0 10px",

    [theme.breakpoints.down("xs")]: {
      padding: "0 0px",
    },
  },
  container: {
    height: 56,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  left: { height: "100%", paddingLeft: 22, marginRight: 18 },
  right: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "space-between",
  },
  rightWrapper: {
    height: 56,
    display: "flex",
    flexDirection: "column",
  },
  download: {
    height: 28,
    width: 100,
    backgroundColor: "#f4255f",

    "& .MuiButton-label": {
      fontSize: 12,
    },
  },
  image: {
    height: 56,
    width: 56,
    borderRadius: 4,
  },
  close: {
    color: theme.palette.common.black,
    margin: "0px 12px 35px",
  },
  title: {
    margin: "0 auto",
    color: theme.palette.common.black,

    [theme.breakpoints.down(350)]: {
      fontSize: 12,
    },
  },
}));

interface Props {
  setPromotionActive: React.Dispatch<React.SetStateAction<boolean>>;
}
export const AppPromotion: React.FC<Props> = (props) => {
  const { t, ready } = useTranslation("promoteApp");
  const { isNativeApp } = useContext(HeaderContext);
  const history = useHistory();
  const disabledPages = [
    "login",
    "signup",
    "live",
    "broadcast",
    "registration-completion",
    "buy-wellstar",
    "wellstar",
    "c",
  ];
  const { user } = useContext(KvixUserContext);
  const [closed, setClosed] = useState(false);
  const isMobile = useIsMobile();
  const classes = useStyles();

  const shouldDisplay = useMemo(() => {
    const matched = disabledPages.some((page) =>
      validateSlug(history.location.pathname, page, 1)
    );
    return (
      isMobile && !closed && !isInstructor(user) && !isNativeApp && !matched && user?.stripeProducts.includes(StripeProduct.LIVE)
    );
  }, [
    isMobile,
    isInstructor,
    user,
    isNativeApp,
    disabledPages,
    history,
    closed,
  ]);

  useEffect(() => {
    props.setPromotionActive(shouldDisplay);
  }, [shouldDisplay]);

  if (!ready) {
    return null;
  }

  return (
    <>
      {shouldDisplay && (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.left}>
              <img
                className={classes.image}
                src={getAssetsPath("kvixLogo.png")}
              />
            </div>
            <div className={classes.right}>
              <div className={classes.rightWrapper}>
                <Text variant="h6" className={classes.title}>
                  {t("title")}
                </Text>
                <DownloadApp />
              </div>
              <IconButton
                className={classes.close}
                onClick={() => setClosed(true)}
              >
                <KvixIcon.Close />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
