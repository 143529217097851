import { AbstractUser } from "@kvix/shared";
import { useMemo } from "react";
import { AccessLevel, useUserAccessLevel } from "./useUserAccessLevel";

export const useUserAccess = (user: AbstractUser, exception = false) => {

  let hasAccess = false;
  const accessLevel = useUserAccessLevel(user);  

  switch(accessLevel) {
    case AccessLevel.NONE:
      hasAccess = false;
      break;
    case AccessLevel.VIEW:
      hasAccess = false;
      break;
    case AccessLevel.FULL:
      hasAccess = true;
      break;
  }

  return useMemo(() => hasAccess || exception, [user, exception]);
}