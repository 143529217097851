import { ServiceMalfunction } from "../../ServiceMalfunction";
import { getData } from "../adapter";

interface RawServiceMalfunction {
  id: number;
  attributes: {
    createdAt: Date;
    updatedAt: Date;
    statusText: string;
    visible: boolean;
  };
}
export const ServiceMalfunctionAdapter = async (
  rawJSON: RawServiceMalfunction
): Promise<ServiceMalfunction> => {
  const {
    id,
    attributes: { createdAt, updatedAt, ...rest },
  } = await getData<RawServiceMalfunction>(rawJSON);
  return {
    ...rest,
    id: id,
    created_at: createdAt,
    updated_at: updatedAt,
  };
};
