import { ErrorType } from "@kvix/shared";
import { Text } from "@kvix/ui";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DefaultFlow } from "src/components/PurchaseFlow";
import { PurchaseFlowConsumer } from "src/contexts/PurchaseFlow";

interface Props {
  title: string;
}

export const AuthErrorModal: React.FC<Props> = (props) => {
  const [flowTab, setFlowTab] = useState<"login" | "register">("login");
  const [message, setMessage] = useState<string>();
  const [open, setOpen] = useState(false);
  const { t, ready } = useTranslation("authError");

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.errMsg) {
      switch (parsed.errMsg) {
        case ErrorType.EmailIsRequired:
          setMessage(t("emailIsRequired"));
          setFlowTab("login");
          break;
        case ErrorType.EmailAlreadyExistRegister:
          setMessage(t("emailAlreadyExistRegister"));
          setFlowTab("register");
          break;
        case ErrorType.EmailAlreadyExistLogin:
          parsed.provider === "facebook"
            ? setMessage(t("tryGoogle"))
            : setMessage(t("tryFacebook"));

          setFlowTab("login");
          break;
        case ErrorType.UserDoesNotExist:
          setMessage(t("userDoesNotExist"));
          setFlowTab("register");
          break;
        default:
          setMessage(parsed.errMsg as string);
          setFlowTab("register");
      }
      setOpen(true);
    }
  }, [setMessage, setOpen, t]);

  const onClose = () => {
    setOpen(false);
  };

  if (!ready) {
    return null;
  }

  return (
    <PurchaseFlowConsumer>
      {({ actions }) => (
        <Dialog
          open={open}
          onBackdropClick={() => {
            onClose();
            actions.prompt(<DefaultFlow login={flowTab === "login"} />);
          }}
        >
          <DialogTitle>{props.title}</DialogTitle>
          <DialogContent>
            <Text variant="body1" gutterBottom>
              {message}.
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                onClose();
                actions.prompt(<DefaultFlow login={flowTab === "login"} />);
              }}
            >
              {t("buttonText")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </PurchaseFlowConsumer>
  );
};
