import { AbstractLiveSession } from "@kvix/shared";
import { Divider } from "@material-ui/core";
import { differenceInMinutes } from "date-fns";
import React, { Fragment, useCallback } from "react";
import { useHistory } from "react-router";
import { ScheduledStreamItem } from "../../partials/Streamer/ScheduledStreamItem";

interface StreamerScheduleProps {
  sessions: AbstractLiveSession[];
}

export const StreamerSchedule: React.FC<StreamerScheduleProps> = ({
  sessions,
}) => {
  const { push } = useHistory();

  const isClickable = useCallback((session: AbstractLiveSession) => {
    const now = new Date();
    return differenceInMinutes(new Date(session.start), now) <= 10;
  }, []);

  const onClick = (session: AbstractLiveSession) => {
    return push(`/channel/${session.channel?.channelNumber}`);
  };

  return (
    <>
      {sessions
        .sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        )
        .map((session) => {
          return (
            <Fragment key={session.id}>
              <ScheduledStreamItem
                stream={session}
                {...(isClickable(session) ? { onClick } : null)}
              />
              <Divider style={{ margin: "12px 0" }} />
            </Fragment>
          );
        })}
    </>
  );
};

export default StreamerSchedule;
