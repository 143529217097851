import { KvixIcon, StackView } from "@kvix/ui";
import { IconButton } from "@material-ui/core";
import React from "react";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { FlowProps } from "../types";

export const Flow = ({ children }: FlowProps) => {
  return (
    <PurchaseFlowConsumer>
      {({ actions }) => (
        <div>
          <StackView
            prevAction={(prev, enabled) =>
              enabled && (
                <IconButton onClick={prev}>
                  <KvixIcon.Chevron.Left />
                </IconButton>
              )
            }
            nextAction={() => (
              <IconButton onClick={() => actions.closePrompt()}>
                <KvixIcon.Close />
              </IconButton>
            )}
          >
            {({ next }) =>
              React.Children.map(children, (child) =>
                child === null
                  ? undefined
                  : React.cloneElement(child, {
                      ...child.props,
                      next,
                    })
              )
            }
          </StackView>
        </div>
      )}
    </PurchaseFlowConsumer>
  );
};