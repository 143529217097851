import { Button, Text } from "@kvix/ui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ShareButtons } from "./SocialShare/components/ShareButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": { alignItems: "center" },
    "& .MuiTypography-root": { marginBottom: 30, marginTop: 38 },
  },
  grid: {
    "& div.MuiGrid-item": {
      fontWeight: 700,
      margin: 10,
    },
    "& span.MuiTypography-caption": {
      color: theme.palette.text.primary,
    },
    "& button": {
      outline: "none",
      border: 0,
    },
  },
}));

const SharingModal: React.FC<{
  open: boolean;
  url: string;
  onClose: () => void;
  texts: {
    title: string | JSX.Element;
    facebookQuote: string;
    facebookHashtag: string;
    whatsappTitle: string;
    emailSubject: string;
    emailBody: string;
    smsBody: string;
  };
}> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("general");

  if (!ready) {
    return null;
  }

  return (
    <Dialog
      className={classes.root}
      open={props.open}
      onBackdropClick={props.onClose}
    >
      <Text variant="h4" color="textPrimary" gutterBottom>
        {props.texts.title}
      </Text>
      <DialogContent>
        <ShareButtons
          url={props.url}
          onClick={props.onClose}
          texts={props.texts}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="text"
          onClick={props.onClose}
        >
          {t("general:buttons.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharingModal;
