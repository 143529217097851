import loadable from "@loadable/component";
import React from "react";

const retry = <T extends object>(
  fn: () => Promise<T>,
  retriesLeft = 5,
  interval = 1000
) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        console.warn(`Failed to fetch chunk, retrying in ${interval}ms...`);

        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const lazy = <T extends object>(
  fn: () => Promise<T>,
  fallback?: JSX.Element
): React.ComponentType<T> => {
  return loadable(() => retry(fn), {
    fallback,
  });
};
