import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DonateTabState } from "../enums/DonateTabState";
import { TabContainer } from "../partials/TabContainer";

interface Props {
  tabState: DonateTabState;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "60px",
  },
  info: {
    marginTop: "12px",
  },
}));

export const ReadMoreTab: FC<Props> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation(["about", "donateLiveView"]);

  if (!ready) {
    return null;
  }

  return (
    <TabContainer
      tabState={props.tabState}
      visibleState={DonateTabState.READMORE}
    >
      <div className={classes.title}>
        <Text variant="h5">{t("donateLiveView:about.title")}</Text>
      </div>
      <div className={classes.info}>
        <Text variant="body1">{t("donateLiveView:about.infoText")}</Text>
      </div>
    </TabContainer>
  );
};
