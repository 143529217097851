import React, { useContext, useEffect, useRef } from "react";
import { History } from "history";
import queryString from "query-string";
import { useCookie } from "react-use";
import moment from 'moment';
import { pushClientEvent, Source } from "@kvix/event-catcher-client";
import { AbstractUser, StripeProduct } from "@kvix/shared";
import { PurchaseFlowContext } from "../../../contexts/PurchaseFlow";
import { KvixUserContext, useHasCompletedSignup } from "../../../contexts/user";
import { PaymentContext } from "../../../components/pages/Payment/context";
import { NewFlow } from "../flows/NewFlow";
import { useCoupon } from "src/components/pages/Payment/hooks";

type Props = {
  history: History<{ accessBlocked?: boolean }>;
  user: AbstractUser;
  children: React.ReactNode;
};

export const PurchaseFlowCatcher = ({ history, user, children }: Props) => {
  const hasCompletedRegistration = useHasCompletedSignup(user);
  const searchParams = queryString.parse(history.location.search);
  const { actions } = useContext(PurchaseFlowContext);
  const userContext = useContext(KvixUserContext);
  const documentRef = useRef<Document>(null);
  const { availablePlans, availablePrices } = useContext(PaymentContext);
  const [wpCampaignParams, setWPCampaignParams, removeWPCampaignParams] = useCookie('wp-campaign');
  const couponData = useCoupon(searchParams?.coupon as string);

  const isCampaignPage = (path: string) => {
    return path.includes("/c/");
  };

  const isAccountPage = (path: string) => {
    return path.includes("/me/account");
  };

  /** ON ROUTE CHANGE **/
  useEffect(() => {
    let subscribed = true;
    documentRef.current = document;
    const controller = new AbortController();

    /** CANCEL ASYNC CALL **/
    if (!subscribed) {
      controller.abort();
    }

    /** CATCH USER SIGNUP **/
    if (
      user &&
      !user.stripeProducts.includes(StripeProduct.LIVE) &&
      "signUpRedirect" in searchParams &&
      searchParams.signUpRedirect
    ) {
      actions.cleanUp();
      if (searchParams.productSignup) {
        history.replace(`${history.location.pathname}?productSignup=true`);
      } else {
        actions.prompt(
          <NewFlow registerProps={{ checkoutSource: 'kvix' }} currentTab={1} />
        );
      }
    }

    /** HANDLE PRODUCTS FROM WP **/
    if (searchParams?.campaignSource && searchParams?.campaignSource === 'wp' && !searchParams.errMsg) {
      if (availablePlans.length > 0 && availablePrices.length > 0) {
        let productPricing = null;

        if (searchParams.productPrice) {
          productPricing = availablePrices.find((price: any) => price.lookup_key === searchParams.productPrice);
        } else {
          productPricing = searchParams?.coupon ? 
          availablePlans.find((p: any) => p.metadata.hasOwnProperty(searchParams?.coupon))
          : 
          searchParams.plan ?
          // TODO: why plans is not returning lookup_key???
          availablePlans.find((p: any) => p.metadata.lookup_key === searchParams.plan)
          :
          availablePlans.find((p: any) => p.id === availablePlans[0].product['default_price']);
        }

        let product = null;
        let promoText = null;
        let checkoutParams = null;
        let registerParams = null;

        product = productPricing.product;

        // TODO: temporary solution for new translations
        // if (!searchParams.productPrice && !searchParams.plan) {
        //   promoText = (searchParams?.coupon ? productPricing['metadata'][searchParams?.coupon as string] : productPricing['metadata'].default).split('*');
        // } else {
        promoText = 'temp';
        // }        

        if (!wpCampaignParams) {
          setWPCampaignParams(window.location.search, { expires:  moment().add(1, 'h').toDate()});
        }
        
        if (user) {
          if (!user.stripeProducts.includes(StripeProduct.LIVE)) {     
            if (product) {
              checkoutParams = {
                referrer: `${window.location.origin}`,
                beforeRedirect: () => actions.closePrompt(),
              };

              searchParams.productPrice ? checkoutParams.priceId = productPricing.id : checkoutParams.planId = productPricing.id;

              if (searchParams?.coupon && couponData.value) {
                checkoutParams['coupon'] = couponData.value;
                checkoutParams['couponId'] = couponData.value.id;
              }

               // TODO: temporary solution for new translations
              if (promoText !== 'temp') {
                registerParams = { 
                  title: promoText[0].trim(), 
                  text: promoText[1].trim(), 
                }
              } else {
                checkoutParams['lookupKey'] = searchParams.productPrice ? searchParams.productPrice : searchParams.plan;
                
                if (productPricing.metadata && productPricing.metadata.trial_period_days) {
                  checkoutParams['trialPeriodDays'] = productPricing.metadata.trial_period_days;
                }
                
                registerParams = { 
                  title: 'temp'
                }
              }
            }

            actions.prompt(
              <NewFlow 
                registerProps={{ checkoutSource: 'wp' }}
                checkoutParams={checkoutParams}
                plan={productPricing} 
                currentTab={1} 
                { ...registerParams }
              />
            );
          }
        } else {
          checkoutParams = { lookupKey: searchParams.productPrice ? searchParams.productPrice : searchParams.plan };
          if (searchParams?.coupon && couponData.value) {
            checkoutParams['coupon'] = couponData.value;
            checkoutParams['couponId'] = couponData.value.id;
          };
          
          actions.prompt(
            <NewFlow
              registerProps={{ checkoutSource: 'wp' }}
              checkoutParams={checkoutParams}
              plan={productPricing}
              currentTab={0}
            />
          );
        }
      }
    }

    if (searchParams.productSignupSuccess) {
      actions.cleanUp();
    }

    /** HANDLE GLOBAL SUBSCRIPTION SUCCESS **/
    if (searchParams.subscribed) {
      userContext.actions.refetchUser();

      if (wpCampaignParams) {
        removeWPCampaignParams();
      }

      actions.prompt(
        <NewFlow currentTab={2} />
      );
    }

    /** HANDLE GLOBAL UNCOMPLETE REGISTRATION **/
    if (
      user &&
      user.stripeProducts.includes(StripeProduct.LIVE) &&
      !hasCompletedRegistration &&
      !isCampaignPage(history.location.pathname) &&
      !isAccountPage(history.location.pathname)
    ) {
      history.push("/me/account?completeRegistration=true");
    }

    pushClientEvent({
      featureType: "paqe.visit",
      source: Source.Web,
      user: null,
      pathFrom: history.location.pathname,
    });
    return () => {
      subscribed = false;
      documentRef.current = null;
    };
  }, [history.location.key, documentRef.current, user, availablePlans, availablePrices, couponData]);

  return <React.Fragment>{children}</React.Fragment>;
};
