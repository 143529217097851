import { SearchResponse } from "@algolia/client-search";
import { AlgoliaArticle, ArticleType } from "@kvix/shared";
import { Placeholder, Text } from "@kvix/ui";
import {
  Box,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DisplayArrangement } from "../..";
import { AlgoliaContext, SortOption } from "../../../../../contexts/algolia";
import { KvixUserContext } from "../../../../../contexts/user";
import { useIsLiked } from "../../../../../hooks/isLiked";
import { Card } from "../../Card";
import { ListCard } from "../../ListCard";
import { SortSelect } from "./SortSelect";

const useStyles = makeStyles((theme) => ({
  clearFilters: {
    textTransform: "none",
    fontWeight: 500,

    "&:hover": {
      background: "transparent",
    },
  },
  cardWrapper: {
    marginRight: "18px",
    marginBottom: "32px",

    [theme.breakpoints.down("xs")]: {
      marginRight: "12px",
      marginBottom: "18px",
    },
  },
  listCardWrapper: {
    // marginBottom: 13,
  },
  animation: {
    animation: "$fadeIn 500ms linear",
  },
  paginationWrapper: {
    "& ul li": {
      [theme.breakpoints.down(375)]: {
        padding: 3,
      },
    },
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

interface Props {
  filterString: string;
  searchQuery: string;
  filterByChip: (key: string, value: any) => void;
  resetFilter: () => void;
  type: ArticleType;
  arrangement: DisplayArrangement;
  toggleFavourite?: (options: {
    programId?: number;
    sessionId?: number;
  }) => void;
  sortBy: SortOption;
  setSortBy: React.Dispatch<React.SetStateAction<SortOption>>;
  contentRef: React.MutableRefObject<HTMLDivElement>;
}

export const ResultBlock: React.FC<Props> = (props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState<SearchResponse<unknown>>(null);
  const { search } = useContext(AlgoliaContext);
  const { user, sessions } = useContext(KvixUserContext);
  const { t, ready } = useTranslation("content");
  const {
    filterString,
    searchQuery,
    filterByChip,
    resetFilter,
    type,
    arrangement,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down(411));
  const isLiked = useIsLiked();
  const searchTimeout = useRef<number>();

  const debounceOneSecond = 1000;

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    if (filterString) {
      (async () => {
        searchTimeout.current = window.setTimeout(async () => {
          const now = new Date();

          const searchResult = await search({
            query: searchQuery,
            filters:
              type === ArticleType.SESSION
                ? filterString.concat(` AND endTimestamp > ${now.getTime()}`)
                : filterString,
            sortBy: props.sortBy,
            page: currentPage,
          });

          setData(searchResult);
        }, debounceOneSecond);
      })();
    }
  }, [filterString, searchQuery, props.sortBy, currentPage]);

  const sortOptions = useMemo(() => {
    switch (type) {
      case ArticleType.PROGRAM:
        return [
          SortOption.POPULARITY,
          SortOption.RATING,
          SortOption.ALPHABETICAL,
        ];

      case ArticleType.ANYTIME:
        return [SortOption.LATEST, SortOption.POPULARITY];

      case ArticleType.SESSION:
        return [SortOption.POPULARITY];

      case ArticleType.INSTRUCTOR:
        return [
          SortOption.POPULARITY,
          SortOption.RATING,
          SortOption.ALPHABETICAL,
        ];
    }
  }, [type]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as SortOption;
    props.setSortBy(value);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value - 1);
    setTimeout(() => {
      props.contentRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }, 100);
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      {data ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Text variant="h6" gutterBottom>{`${t("results")} (${
                data.nbHits
              })`}</Text>
              {!isMobile && (
                <Button
                  variant="text"
                  color="primary"
                  disableTouchRipple
                  className={classes.clearFilters}
                  onClick={resetFilter}
                >
                  {t("clearLong")}
                </Button>
              )}
            </div>
            <SortSelect
              handleSelect={handleSelect}
              sortBy={props.sortBy}
              sortOptions={sortOptions}
            />
          </Box>

          {isMobile || arrangement === DisplayArrangement.LIST ? (
            // TODO: Fix animation
            // This animation is jumpy on Android and Pc
            // <motion.div
            //   style={{ translateY: 60 }}
            //   animate={{ translateY: 0 }}
            //   transition={{ duration: 0.5 }}
            //   className={classes.animation}
            // >
            <>
              {data.hits?.map((article: AlgoliaArticle) => (
                <div className={classes.listCardWrapper} key={article.objectID}>
                  <ListCard
                    article={article}
                    type={article.type}
                    filterByChip={filterByChip}
                    toggleFavourite={props.toggleFavourite}
                    isLiked={isLiked(article.id, article.type)}
                    currentTime={sessions.watched[article.id]}
                    user={user}
                  />
                </div>
              ))}
            </>
          ) : (
            <Box display="flex" flexWrap="wrap">
              {data.hits?.map((article: AlgoliaArticle) => (
                <div className={classes.cardWrapper} key={article.objectID}>
                  <Card
                    options={article}
                    type={article.type}
                    filterByChip={filterByChip}
                  />
                </div>
              ))}
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="center"
            marginTop={2}
            marginBottom={5}
            className={classes.paginationWrapper}
          >
            <Pagination
              count={data.nbPages}
              variant="outlined"
              shape="rounded"
              color="primary"
              onChange={handlePageChange}
              size={isSmallDevice ? "small" : "medium"}
              siblingCount={isSmallDevice ? 0 : 1}
            />
          </Box>
        </>
      ) : (
        <Placeholder.CardFeed cards={4} rows={3} />
      )}
    </>
  );
};
