import { RichText, Text } from "@kvix/ui";
import {
  Box,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { format } from "date-fns";
import React, { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { BlogContext } from "../../../../../contexts/blog";
import { Footer } from "../../../../Footer";
import { Banner } from "../../../../partials/Banner/Banner";
import { Page } from "../../../../partials/Page";
import { BlogpostFC } from "./Blogpost";

const Image = styled.img`
  width: 100%;
`;

const OtherContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? "start" : "none")};
  width: 100%;
  flex-wrap: wrap;
  padding: 24px 0;
  margin-top: 60px;
`;
export const CmsBlogpost: React.FC<{ slug: string }> = (props: {
  slug: string;
}) => {
  const { blog } = useContext(BlogContext);
  const { slug } = props;
  const { t, ready } = useTranslation(["blog", "seo"]);

  const blogpost = useMemo(() => {
    if (blog) {
      return blog.blogposts.find((blogpost) => blogpost.slug === slug);
    }

    return null;
  }, [blog, slug]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const restOfBlogposts = blog?.blogposts?.filter((b) => b !== blogpost);

  if (!ready) {
    return null;
  }

  return (
    <Page
      meta={
        blog && blogpost
          ? {
              title: t("seo:blog.title", {
                title: blogpost.title,
              }),
              description: blogpost.ingress ? blogpost.ingress : undefined,
              ogTitle: blogpost.title,
              ogImage: blogpost.image.url,
            }
          : {}
      }
    >
      {blog && !blogpost && <Redirect to="/page-not-found" />}

      {blog && blogpost && (
        <Fragment>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Image src={blogpost.image.url} />
              </Grid>
              <Grid item sm={6}>
                <Box
                  bgcolor={
                    isMobile
                      ? theme.palette.background.default
                      : theme.palette.background.paper
                  }
                  padding={isMobile ? "0" : "96px 24px"}
                >
                  <Text
                    variant="caption"
                    color="primary"
                    style={{ margin: "24px 0", display: "block" }}
                  >
                    {blogpost.caption}
                  </Text>
                  <Text variant="h4">{blogpost.title}</Text>
                </Box>
                {!blogpost.noAuthorLink ? (
                  <Link to={`/instructor/${blogpost.userId}`}>
                    <Text
                      variant="caption"
                      color="textSecondary"
                      style={{
                        padding: isMobile ? "24px 0" : "24px",
                        display: "block",
                      }}
                    >
                      {`${blogpost.author} - ${format(
                        new Date(blogpost.created_at),
                        "dd/MM/yyyy"
                      )}`}
                    </Text>
                  </Link>
                ) : (
                  <Text
                    variant="caption"
                    color="textSecondary"
                    style={{
                      padding: isMobile ? "24px 0" : "24px",
                      display: "block",
                    }}
                  >
                    {`${blogpost.author} - ${format(
                      new Date(blogpost.created_at),
                      "dd/MM/yyyy"
                    )}`}
                  </Text>
                )}
              </Grid>
            </Grid>
            <Box
              padding={isMobile ? "0" : "0 20%"}
              marginTop={isMobile ? "0" : "48px"}
            >
              <Text
                variant="body1"
                style={{ whiteSpace: "pre-line" }}
                component={"span"}
              >
                <RichText text={blogpost.content} />
              </Text>
            </Box>

            <OtherContainer isMobile={isMobile} isTablet={isTablet}>
              {restOfBlogposts.length > 0 && (
                <Text
                  variant="h5"
                  style={{
                    marginBottom: "24px",
                    marginTop: isMobile ? "48px" : "0",
                    width: "100%",
                  }}
                >
                  {t("morePosts")}
                </Text>
              )}
              {restOfBlogposts.slice(0, isMobile ? 2 : 4).map((blogpost) => (
                <BlogpostFC
                  isTablet={isTablet}
                  isMobile={isMobile}
                  key={blogpost.id}
                  blogpost={blogpost}
                />
              ))}
              <Box pb={4} style={{ width: "100%", paddingTop: 32 }}>
                <Banner type="upgrade-premium" size="small" />
              </Box>
              {restOfBlogposts
                .slice(isMobile ? 2 : 4, isMobile ? 4 : 8)
                .map((blogpost) => (
                  <BlogpostFC
                    isTablet={isTablet}
                    isMobile={isMobile}
                    key={blogpost.id}
                    blogpost={blogpost}
                  />
                ))}
            </OtherContainer>
          </Container>
        </Fragment>
      )}

      <Footer />
    </Page>
  );
};
