import {
  CastData,
  CastState,
  Metadata,
  pushConsent,
  ReactNativeMessage,
} from "@kvix/shared";
import { useContext, useEffect, useState } from "react";
import { useCookie } from "react-use";
import { ExpoContext, sendCastLive } from "../contexts/expo";

export const useAppCastData = () => {
  const [state, setState] = useState<CastData>({
    state: CastState.NOT_CONNECTED,
  });

  useEffect(() => {
    const onReactNativeMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.type === "cast-state-changed") {
        setState(event.data);
      }
    };

    window.addEventListener("message", onReactNativeMessage);

    return () => window.removeEventListener("message", onReactNativeMessage);
  }, []);

  return state;
};

export const useAppCastState = (): [
  CastState | null,
  string | null,
  number | null
] => {
  const { isNativeApp, cast } = useContext(ExpoContext);
  return [isNativeApp ? cast.state : null, cast.receiverName, cast.sessionId];
};

export const useIsNativeApp = () => {
  return !!window.KvixApp;
};

export const useNativeAppCastLive = (
  sessionId: number,
  metadata: Metadata,
  streamId: string,
  ) => {
  const isNativeApp = useIsNativeApp();
  const [state] = useAppCastState();

  useEffect(() => {
    if (!isNativeApp) {
      return;
    }
    if (state !== CastState.NOT_CONNECTED) {
      sendCastLive({
        contentType: "live",
        sessionId,
        metadata,
        streamId,
        clientUid: "dummy"
      });
    }
  }, [isNativeApp, state, metadata, sessionId, streamId]);
};

export const useTrackingPermission = () => {
  const [, setConsentedCookies] = useCookie("consented-cookies");

  useEffect(() => {
    const onReactNativeMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      const type: ReactNativeMessage = event.data.type;
      if (type === "tracking-permission") {
        const granted: boolean = event.data.granted;

        if (granted) {
          setConsentedCookies("functional,market,essential", {
            expires: 365,
          });
        } else {
          setConsentedCookies("essential", {
            expires: 365,
          });
        }

        pushConsent();
        fetch("/auth/me/cookie-consent");
      }
    };

    window.addEventListener("message", onReactNativeMessage);

    return () => window.removeEventListener("message", onReactNativeMessage);
  }, [setConsentedCookies]);
};
