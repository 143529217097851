export enum Platform {
  IOS = "ios",
  ANDROID = "android",
  WEB = "web",
}

export const getPlatform = () =>
  !!window.KvixApp
    ? /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
      ? Platform.IOS
      : Platform.ANDROID
    : Platform.WEB;
