export const logLevels = {
  all: 4,
  debug: 4,
  info: 3,
  warn: 2,
  error: 1,
  none: 0
};

export class Logger {
  public debug: (message: object | string | number) => void;
  public info: (message: object | string | number) => void;
  public warn: (message: object | string | number) => void;
  public error: (message: object | string | number) => void;

  private loggingLevel = logLevels.none;
  private remoteLog?: (level: string, message: object) => any;

  constructor() {
    this.setupNativeLoggerBinding();
  }

  public setLoggingLevel(loggingLevel: number): void {
    this.loggingLevel = loggingLevel;
    this.setupNativeLoggerBinding();
  }

  public setRemoteLogFunction(
    remoteLogFunction: (level: string, message: object) => any
  ) {
    this.remoteLog = remoteLogFunction;
  }

  private setupNativeLoggerBinding(): void {
    const globalConsole = window && window.console;

    console.log("setupNativeLoggerBinding");

    for (const consoleFunction in globalConsole) {
      if (typeof globalConsole[consoleFunction] === "function") {
        if (
          !logLevels[consoleFunction] ||
          logLevels[consoleFunction] <= this.loggingLevel
        ) {
          this[consoleFunction] = globalConsole[consoleFunction].bind(
            globalConsole,
            `${consoleFunction}: `
          );
        } else {
          this[consoleFunction] = () => {};
        }
      }
    }

    Object.keys(logLevels).forEach(logLevel => {
      if (logLevels[logLevel]) {
        if (logLevels[logLevel] <= this.loggingLevel) {
          if (globalConsole[logLevel]) {
            const logFunctionName = globalConsole[logLevel] ? logLevel : "log";
            this[logLevel] = message => {
              if (this.remoteLog) {
                this.remoteLog(logLevel, message);
              }
              globalConsole[logFunctionName](`${logLevel}: `, message);
            };
          }
        } else {
          this[logLevel] = () => {};
        }
      }
    });
  }
}

const logger = new Logger();

export default logger;
