import { Button, KvixIcon, Text } from "@kvix/ui";
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CardVendorIcon from "./CardVendorIcon";

const PhoneNumberContainer = styled.div`
  margin-left: 10px;
  letter-spacing: 0.5px;
`;

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    padding: "0 10px",
    height: "40px",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(31, 40, 51, 1)",
    color: "rgba(31, 40, 51, 1)",
    fontWeight: 400,
    backgroundColor: theme.palette.common.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSvgIcon-root": {
      color: "rgba(31, 40, 51, .3)",
    },
  },
  saveNumber: {
    marginBottom: "12px",
  },
  checked: {
    "& .MuiSvgIcon-root": {
      color: "rgba(31, 40, 51, 1)",
    },
  },
  inputField: {
    "&.MuiFormControl-root": {
      height: 30,
      margin: "2px 5px 0px 0",
    },
    "& .MuiInput-root": {
      background: theme.palette.common.white,
    },
    "& .MuiInputBase-inputMarginDense": {
      color: theme.palette.common.black,
    },
  },
}));

interface SwishPaymentProps {
  swishIsChecked: boolean;
  setSwishIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  enteredPhoneNr: string;
  setEnteredPhoneNr: React.Dispatch<React.SetStateAction<string>>;
  saveNumber: boolean;
  setSaveNumber: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SwishPaymentOption: FC<SwishPaymentProps> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("donateView");
  const {
    swishIsChecked,
    setSwishIsChecked,
    enteredPhoneNr,
    setEnteredPhoneNr,
    saveNumber,
    setSaveNumber,
  } = props;

  const handleClick = () => {
    setSwishIsChecked(true);
  };

  const handleSaveNumber = () => {
    if (swishIsChecked) {
      setSaveNumber(!saveNumber);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disableRipple
        className={classNames([
          classes.buttonRoot,
          swishIsChecked && classes.checked,
        ])}
        variant={"contained"}
        color={"inherit"}
      >
        <KvixIcon.Checkmark.Circled />
        <PhoneNumberContainer>
          {swishIsChecked && (
            <TextField
              autoFocus
              className={classes.inputField}
              size="small"
              value={enteredPhoneNr}
              onChange={(e) => setEnteredPhoneNr(e.target.value)}
              placeholder={t("phoneNumber")}
              InputProps={{
                endAdornment: <KvixIcon.PhoneIcon />,
                disableUnderline: true,
              }}
            />
          )}
          {!swishIsChecked && enteredPhoneNr && <Text>{enteredPhoneNr}</Text>}
        </PhoneNumberContainer>
        <CardVendorIcon.Swish />
      </Button>
      <FormControlLabel
        className={classes.saveNumber}
        checked={saveNumber}
        onClick={handleSaveNumber}
        control={<Checkbox />}
        label={t("savePhone")}
      />
    </>
  );
};
