import { HttpMethods } from "@kvix/shared";
import { AuthError } from "../errors/AuthError";

export const toggleFavouriteProgram = async (programId: number) => {
  const response = await fetch(`/api/program/toggleFavourite`, {
    method: HttpMethods.PUT,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ programId }),
  });

  if (response.status === 401) {
    throw new AuthError(response.statusText);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
};
