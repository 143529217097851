import { AbstractCategory } from "@kvix/shared";
import { makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DesktopCategory } from "./DesktopCategory";

interface Props {
  categories: AbstractCategory[];
  currentCategory: string;
  setCategory: (category: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    padding: "12px 0",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const DesktopCategoryContainer: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { categories } = props;
  const ref = useRef(null);
  const { t, ready } = useTranslation("content");

  useEffect(() => {
    const slider = ref.current;
    let startX: number,
      scrollLeft: number,
      isDown = false;

    setTimeout(() => {
      if (slider?.scrollWidth > slider?.clientWidth) {
        slider.style.boxShadow = "inset rgba(0, 0, 0, 0.5) -5px 0px 5px -6px";
      }
    }, 100);

    slider?.addEventListener("mousedown", (e: MouseEvent) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener("mouseleave", (e: MouseEvent) => {
      isDown = false;
    });
    slider?.addEventListener("mouseup", (e: MouseEvent) => {
      isDown = false;
    });
    slider?.addEventListener("mousemove", (e: MouseEvent) => {
      if (!isDown) return;
      const overflowRight =
        slider.scrollLeft < slider.scrollWidth - slider.clientWidth;
      const overflowLeft = slider.scrollLeft > 0;

      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1;
      slider.scrollLeft = scrollLeft - walk;

      if (overflowRight) {
        slider.style.boxShadow = "inset rgba(0, 0, 0, 0.5) -5px 0px 5px -6px";
      }

      if (overflowLeft) {
        slider.style.boxShadow = "inset rgba(0, 0, 0, 0.5) 5px 0 5px -6px ";
      }

      if (overflowLeft && overflowRight) {
        slider.style.boxShadow =
          "inset rgba(0, 0, 0, 0.5) 5px 0 5px -6px, inset rgba(0, 0, 0, 0.5) -5px 0px 5px -6px";
      }
    });
  }, [ref, categories]);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.root} ref={ref}>
      {categories?.map((category, index) => (
        <Fragment key={category.id}>
          {index === 0 && (
            <div onClick={() => props.setCategory(null)}>
              <DesktopCategory selected={props.currentCategory === null}>
                {t("allCategories")}
              </DesktopCategory>
            </div>
          )}
          <div onClick={() => props.setCategory(category.slug)}>
            <DesktopCategory selected={props.currentCategory === category.slug}>
              {t(`classes.${category.title}`)}
            </DesktopCategory>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
