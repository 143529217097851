import { Button, Text } from "@kvix/ui";
import { Box, Container, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Dealspost } from "../../../../cms/models/Dealspost";
import { DealsContext, DealsContextProvider } from "../../../../contexts/deals";
import { Footer } from "../../../Footer";
import { Page } from "../../../partials/Page";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    maxWidth: "774px",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "340px",
    },
    [theme.breakpoints.down(350)]: {
      maxWidth: "274px",
    },
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: "120px",
    fontWeight: 700,
    display: "flex",
    flexWrap: "wrap",
    letterSpacing: "-2px",
    lineHeight: 1,

    [theme.breakpoints.down("sm")]: {
      fontSize: "53px",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "40px",
    },
  },
  outlinedText: {
    color: "transparent",
    "-webkit-text-stroke-width": "2px",
    "-webkit-text-stroke-color": theme.palette.primary.main,

    [theme.breakpoints.down("sm")]: {
      "-webkit-text-stroke-width": "1.5px",
      letterSpacing: 0,
    },
  },
  subtitleText: {
    marginTop: "30px",
    fontSize: "24px",
    lineHeight: 1.3,
    paddingLeft: "1px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(350)]: {
      fontSize: "14px",
    },
  },
  dealContainer: {
    whiteSpace: "pre-wrap",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #c5c6c7",
    borderRadius: "5px",
    overflow: "hidden",

    [theme.breakpoints.down(850)]: { flexWrap: "wrap" },
  },
  dealImage: {
    flex: "0 0 33%",

    [theme.breakpoints.down(850)]: { flex: "0 0 100%" },

    "& div": {
      width: "100%",
      height: "100%",
      paddingBottom: "71.42%",
      backgroundImage: (props: any) => `url("${props.dealspost?.image?.url}")`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },
  dealText: {
    flexBasis: "60%",
    padding: "32px 0 0 32px",
    wordBreak: "break-word",
    "& *": { lineHeight: 1.5 },
    "& h2": { fontSize: 22, margin: "0 0 0 0" },
    "& h3": { fontSize: 16, margin: "0 0 8px 0" },

    [theme.breakpoints.down(850)]: { padding: "32px 32px 32px 32px" },
    [theme.breakpoints.down(600)]: { flex: "0 0 100%" },
  },
  dealAction: {
    display: "flex",
    flexBasis: "40%",
    "& div": {
      margin: "auto 32px 32px auto",
    },

    [theme.breakpoints.down(600)]: { flex: "0 0 100%" },
    [theme.breakpoints.down(450)]: {
      "& div": {
        margin: "auto 32px 32px 32px",
      },
      "& div, & div *": { width: "100%" },
    },
  },
}));

const CmsDealsCard: React.FC<{ dealspost: Dealspost }> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("deals");

  if (!ready) {
    return null;
  }

  return (
    <>
      <Box mb={5} className={classes.dealContainer}>
        <div className={classes.dealImage}>
          <div />
        </div>
        <div className={classes.dealText}>
          <Text variant="h2">{props.dealspost.title}</Text>
          <Text variant="h3">{props.dealspost.subtitle}</Text>
          <Text variant="body1">{props.dealspost.description}</Text>
        </div>
        <div className={classes.dealAction}>
          <div>
            <Text
              style={{
                display: "block",
                margin: "16px 0",
                textAlign: "center",
              }}
              variant="caption"
            >
              {props.dealspost.instruction}
            </Text>
            <Link
              to={{ pathname: props.dealspost.link }}
              target="_blank"
              rel="noopener"
              style={{ display: "block", textAlign: "right" }}
            >
              <Button>{t("actionText")}</Button>
            </Link>
          </div>
        </div>
      </Box>
    </>
  );
};

const CmsDealsLanding: React.FC = (props) => {
  const { t, ready } = useTranslation(["deals", "seo"]);
  const { deals } = useContext(DealsContext);
  const classes = useStyles(props);

  const dealsPosts = deals?.dealsposts;

  if (!ready) {
    return null;
  }

  return (
    <Page
      meta={{
        title: t("seo:deals.title", { title: `${t("title1")} ${t("title2")}` }),
        description: t("description"),
        ogTitle: `${t("title1")} ${t("title2")}`,
      }}
    >
      {deals && dealsPosts && (
        <>
          <Container maxWidth="lg">
            <>
              <Box mb={8}>
                <h1 className={classes.textContainer}>
                  <div className={`${classes.text} ${classes.outlinedText}`}>
                    {t("title1")}
                  </div>
                  <div className={classes.text}>{t("title2")}</div>
                </h1>
                <div className={classes.subtitleText}>{t("description")}</div>
              </Box>

              {dealsPosts
                .sort((a, b) => (a.priority > b.priority ? 1 : -1))
                .map((dealspost, index) => (
                  <CmsDealsCard key={index} dealspost={dealspost} />
                ))}
            </>
          </Container>
        </>
      )}
      <Footer noGutter />
    </Page>
  );
};

export const CmsDeals: React.FC = (props) => {
  return (
    <DealsContextProvider>
      <CmsDealsLanding />
    </DealsContextProvider>
  );
};
