import { Container, TabPanel, Text } from "@kvix/ui"
import { makeStyles } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { Redirect } from "react-router"
import { useTranslation } from "react-i18next"
import queryString from "query-string";
import {
  PurchaseFlowConsumer,
  PurchaseFlowContext,
} from "../../../contexts/PurchaseFlow"
import { KvixUserContext } from "../../../contexts/user"
import { useDefaultPlan, usePlanPricing } from "../../pages/Payment/hooks"
import { ForgottenPassword } from "../components/ForgotPassword"
import { Register } from "../components/Register"
import { SignIn } from "../components/SignIn"
import { RegisterStepProps } from "../types"

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(0),
      background: "none",
      height: "100%",
    },
    paddingBottom: 0,
  },
  image: {
    maxWidth: "100px",
    marginLeft: "40%",
    marginRight: "60%",
  },
  fontWeightMedium: {
    opacity: 1,
    fontFamily: "AvenirNext-Bold",
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: "1px",
    textAlign: "center",
    lineHeight: "34px",
    textTransfrom: "uppercase",
  },
  tabPanelRoot: {
    height: "100% !important",
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit'
  }
}))

export const RegisterStep = ({
  tabState = "register",
  productSignup = false,
  setIsLogin,
  showContentModal,
  headerText,
  recruit,
  ...registerOverrides
}: RegisterStepProps) => {
  const { t, ready } = useTranslation(["logIn", "error"])
  const [tab, setTab] = useState(tabState === "register" ? 0 : 1)
  const plan = useDefaultPlan()
  const pricing = usePlanPricing(plan)
  const { actions } = useContext(PurchaseFlowContext)
  const { user } = useContext(KvixUserContext)
  const classes = useStyles()
  const setProductSignup = productSignup
    ? `&productSignup=${productSignup}`
    : "";
  const searchParams = queryString.parse(window.location.search);

  if (!ready) {
    return null
  }

  if (user && registerOverrides.checkoutSource !== "wp") {
    actions.closePrompt()
    return (
      <Redirect
        to={`${window.location.pathname}?signUpRedirect=true${setProductSignup}`}
      />
    )
  }

  return (
    <PurchaseFlowConsumer>
      {({ context: { currentLocation } }) => (
        <Container style={{ alignItems: "center", height: "100%" }} disableScrollbar>
          <Container className={classes.ContainerPadding} scrollableY disableScrollbar pb={3}>
            { tab === 0 &&
              <div className={classes.tabWrapper}>
                <Register
                  origin={currentLocation.pathname.includes('recruitme') ? `?recruitedBy=${searchParams.utm_content}&signUpRedirect=true` : `${currentLocation.pathname}${encodeURIComponent(
                    currentLocation.search
                  )}${
                    registerOverrides?.checkoutSource === "wp"
                      ? ""
                      : "?signUpRedirect=true"
                  }`}
                  plan={plan}
                  pricing={pricing}
                  changeToLogin={() => {
                    if (setIsLogin) {
                      setIsLogin(true)
                    }
                    setTab(1)
                  }}
                  showContentModal={showContentModal}
                  productSignup={productSignup}
                  headerText={headerText}
                  recruit={recruit}
                  {...registerOverrides}
                />
              </div>
            }
            { tab === 1 &&
               <div className={classes.tabWrapper}>
                <SignIn
                  origin={`${currentLocation.pathname}${encodeURIComponent(
                    currentLocation.search
                  )}`}
                  checkoutSource={registerOverrides?.checkoutSource}
                  changeToRegister={() => {
                    if (setIsLogin) {
                      setIsLogin(false)
                    }
                    setTab(0)
                  }}
                  headerText={headerText}
                  changeToForgotPassword={() => setTab(2)}
                  plan={plan}
                  {...registerOverrides}
                />
              </div>
            }
            { tab === 2 &&
              <div className={classes.tabWrapper}>
                <ForgottenPassword />
              </div>
            }
          </Container>
        </Container>
      )}
    </PurchaseFlowConsumer>
  )
}
