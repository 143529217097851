import { Button, Text } from "@kvix/ui";
import { Box, makeStyles } from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import { useIsMobile } from "../../../../hooks/isMobile";
import { Bio } from "./Bio";
import { EditProfile } from "./EditProfile";
import { SocialMediaChannels } from "./SocialMediaChannels";

const useStyles = makeStyles((theme) => ({
  profileImage: {
    height: "120px",
    width: "120px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userName: {
    maxWidth: "85%",
    wordBreak: "break-word",
    marginBottom: "25px",
  },
  userTitle: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "40px",
  },
  imageButtonWrapper: {
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column-reverse",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      flexDirection: "row",
    },
  },
  editButton: {
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "inherit",
    },
  },
}));

export const ProfileInfo = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t, ready } = useTranslation("streamerAdminAccount");
  const [showEditProfile, setShowEditProfile] = useState(false);
  const { user } = useContext(KvixUserContext);
  const editProfileRef = useRef<HTMLDivElement>(null);
  const { instructorProfile } = user;

  const onEditClick = () => {
    setShowEditProfile(true);

    setTimeout(() => {
      if (isMobile && editProfileRef.current) {
        const offset = 100;

        window.scrollTo({
          behavior: "smooth",
          top:
            document.documentElement.scrollHeight -
            editProfileRef.current.clientHeight -
            offset,
        });
      }
    }, 150);
  };

  if (!ready) {
    return null;
  }

  return (
    <Box>
      {showEditProfile ? (
        <div ref={editProfileRef}>
          <EditProfile onClose={() => setShowEditProfile(false)} />
        </div>
      ) : (
        <>
          <Box className={classes.imageButtonWrapper}>
            {!isMobile && (
              <img
                alt=""
                className={classes.profileImage}
                src={user.imageUrl}
              />
            )}
            <Button
              className={classes.editButton}
              variant="oval"
              lowercase
              style={{ width: "75px" }}
              onClick={onEditClick}
            >
              {t("change")}
            </Button>
          </Box>
          <Text variant="h2" className={classes.userName}>
            {user?.name.custom}
          </Text>
          <Text variant="h6" className={classes.userTitle}>
            {instructorProfile?.title}
          </Text>
          <Bio />
          <SocialMediaChannels onClickAdd={() => setShowEditProfile(true)} />
        </>
      )}
    </Box>
  );
};
