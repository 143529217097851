import { KvixIcon } from "@kvix/ui";
import {
  Dialog,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router";
import { Contact } from "../../../pages/Contact";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperFullScreen": {
      background: theme.palette.text.primary,
    },
  },
  dialogContainer: {
    margin: "auto",
  },
  closeDialogButton: {
    position: "absolute",
    top: 0,
    right: 10,
    color: theme.palette.common.white,
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
  },
}));

export const ContactModal: FC = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(560));
  const history = useHistory();

  const handleClose = () => {
    history.goBack();
  };

  return (
    <Dialog
      className={classes.dialog}
      open
      onClose={handleClose}
      fullScreen={isMobile}
    >
      <IconButton
        color="inherit"
        className={classes.closeDialogButton}
        onClick={handleClose}
      >
        <KvixIcon.Close />
      </IconButton>
      <Contact asModal />
    </Dialog>
  );
};
