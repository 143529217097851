import { AbstractLiveSession } from "@kvix/shared";
import { KvixIcon, SessionInfo } from "@kvix/ui";
import { Box, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    width: "100%",
    overflow: "hidden",
    alignItems: "center",
  },
  previewThumbnail: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  previewOverlay: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  previewPlay: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.3)",
    borderRadius: "50%",
    marginRight: 10,
    transition: "all 0.125s ease-out",
  },
  profileImage: {
    height: "72px",
    width: "128px",
    marginRight: "10px",
  },
}));

const Preview = ({ imgSrc, hover }: { imgSrc: string; hover?: boolean }) => {
  const classes = useStyles();
  return (
    <div className={classes.previewThumbnail}>
      <img alt="" className={classes.profileImage} src={imgSrc} />{" "}
      <motion.div
        initial={{ opacity: 0, y: hover ? 0 : -10 }}
        animate={{ opacity: !hover ? 0 : 1, y: 0 }}
      >
        <span className={classes.previewOverlay}>
          <KvixIcon.Play className={classes.previewPlay} color="primary" />
        </span>
      </motion.div>
    </div>
  );
};

interface PreviousStreamItemProps {
  stream: AbstractLiveSession;
  onClick?: (stream: AbstractLiveSession) => void;
  withHover?: boolean;
  showChannel?: boolean;
}

export const PreviousStreamItem = ({
  stream,
  onClick = () => null,
  withHover = false,
  showChannel,
}: PreviousStreamItemProps) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = useState(!withHover);
  return (
    <Box
      onMouseOver={() => {
        if (withHover) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={() => {
        if (withHover) {
          setIsHovering(false);
        }
      }}
      className={classes.root}
      onClick={() => onClick(stream)}
    >
      <Preview hover={isHovering} imgSrc={stream.thumbnailUrl} />
      <SessionInfo session={stream} showChannel={showChannel} />
    </Box>
  );
};
