import { AbstractCategory, ArticleType } from "@kvix/shared";
import {
  Box,
  Container,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useMemo } from "react";
import { DisplayArrangement, SearchFilters } from "../..";
import { SortOption } from "../../../../../contexts/algolia";
import { KvixUserContext } from "../../../../../contexts/user";
import { ResultBlock } from "./ResultBlock";
import { ResultRowContainer } from "./ResultRowContainer";

interface Props {
  filter: SearchFilters;
  searchQuery: string;
  categories: AbstractCategory[];
  setFilter: (value: SearchFilters) => void;
  type: ArticleType;
  arrangement: DisplayArrangement;
  sortBy: SortOption;
  setSortBy: React.Dispatch<React.SetStateAction<SortOption>>;
  contentRef: React.MutableRefObject<HTMLDivElement>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
  SliderContainer: {
    // backgroundColor: "DarkOliveGreen",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(10),
    },

    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
}));

export const Result: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { filter, searchQuery, setFilter, type, setSearchQuery } = props;
  const { actions, user } = useContext(KvixUserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const filterString = useMemo(() => {
    let string = "";

    for (const key in filter) {
      if (filter[key]) {
        string = string.concat(
          `${string.length > 0 ? " AND " : ""}${key}:"${filter[key]}"`
        );
      }
    }

    return string.concat(` AND deletedAt != 1`);
  }, [filter]);

  const activeFiltering = useMemo(() => {
    if (filter) {
      const filterKeys = [
        "instructorName",
        "level",
        "equipment",
        "focus",
        "duration",
      ];
      return filterKeys.some((key) => filter[key]);
    }
  }, [filter]);

  const currentCategory = useMemo(() => {
    if (props.categories) {
      return (
        props.categories?.find(
          (category) => category.slug === props.filter.categories
        ) || null
      );
    }
  }, [props.categories, props.filter]);

  const handleFilterByChip = <T extends keyof SearchFilters>(
    key: T,
    value: SearchFilters[T]
  ) => {
    const newFilter = { ...filter };
    newFilter[key] = value;

    setFilter(newFilter);
  };

  const handleResetFilter = () => {
    setSearchQuery("");

    setFilter({
      type: filter.type,
      categories: filter.categories,
      duration: null,
      equipment: null,
      focus: null,
      instructorName: null,
      level: null,
    });
  };

  return (
    // <Container maxWidth="xl" style={{ ...(isMobile && { padding: "0 16px" }) }}>
    <Container className={classes.SliderContainer} maxWidth="xl">
      <Box mt={2}>
        {activeFiltering || searchQuery.length > 0 ? (
          <ResultBlock
            filterString={filterString}
            searchQuery={searchQuery}
            filterByChip={handleFilterByChip}
            resetFilter={handleResetFilter}
            type={type}
            arrangement={props.arrangement}
            toggleFavourite={user ? actions.toggleFavourite : null}
            sortBy={props.sortBy}
            setSortBy={props.setSortBy}
            contentRef={props.contentRef}
          />
        ) : (
          <ResultRowContainer
            arrangement={props.arrangement}
            categories={props.categories}
            filterString={filterString}
            filterByChip={handleFilterByChip}
            toggleFavourite={user ? actions.toggleFavourite : null}
            type={type}
            currentCategory={currentCategory}
          />
        )}
      </Box>
    </Container>
  );
};
