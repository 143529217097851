import React, { useContext, useEffect, useState } from "react";
import { DealsAdapter } from "../cms/models/adapters/single/Deals";
import { Deals } from "../cms/models/Deals";
import { LanguageContext } from "./language";

export const STRAPI_URL = window.config.STRAPI_URL;

interface DealsContextState {
  deals: Deals;
}

const useDeals = (): Deals => {
  const language = useContext(LanguageContext);
  const [deals, setDeals] = useState<Deals>();
  const asyncLoad = async () => {
    try {
      const response = await fetch(`${STRAPI_URL}/api/deal?populate=deep`);

      if (response.ok) {
        const json = await response.json();
        setDeals(await DealsAdapter(json));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    asyncLoad();
  }, [language?.currentLanguage]);
  return deals;
};

export const DealsContext = React.createContext<DealsContextState>(null);

export const DealsContextProvider: React.FC = (props) => {
  const deals = useDeals();

  const value = {
    deals,
  };

  return (
    <DealsContext.Provider value={value}>
      {props.children}
    </DealsContext.Provider>
  );
};
