import { Text } from "@kvix/ui";
import { Button, makeStyles } from "@material-ui/core";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CompetitionContext } from "../../../../../contexts/competition";
import { ExpoContext } from "../../../../../contexts/expo";
import { DonateTabState } from "../enums/DonateTabState";
import { TabContainer } from "../partials/TabContainer";

interface Props {
  tabState: DonateTabState;
  setTabState: (tabState: DonateTabState, reset: boolean) => void;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "100px",
    marginBottom: "20px",
  },
  info: {
    marginTop: "12px",
    marginBottom: "40px",
  },
  buttons: {
    marginBottom: "40px",
    height: "100%",
  },
  button: {
    width: "100%",
    marginBottom: "12px",
  },
}));

export const NoCoinsTab: FC<Props> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("donateView");
  const { isNativeApp } = useContext(ExpoContext);
  const { activeCompetitionRound } = useContext(CompetitionContext);

  if (!ready) {
    return null;
  }

  return (
    <TabContainer
      tabState={props.tabState}
      visibleState={DonateTabState.NOCOINS}
    >
      <div className={classes.title}>
        <Text variant="h5">
          {activeCompetitionRound
            ? t("noCoins.titleVote")
            : t("noCoins.titleDonate")}
        </Text>
      </div>
      <div className={classes.info}>
        <Text variant="body1">
          {isNativeApp
            ? t("noCoins.infoTextOnApp")
            : activeCompetitionRound
            ? t("noCoins.infoTextVote")
            : t("noCoins.infoTextDonate")}
        </Text>
      </div>
      <div className={classes.buttons}>
        {isNativeApp ? (
          <Button className={classes.button} onClick={props.onClose}>
            {t("noCoins.okButton")}
          </Button>
        ) : (
          <>
            <Button
              variant={"contained"}
              color={"secondary"}
              className={classes.button}
              onClick={() => props.setTabState(DonateTabState.BUY, true)}
            >
              {t("noCoins.yesButton")}
            </Button>
            <Button className={classes.button} onClick={props.onClose}>
              {t("noCoins.maybeLaterButton")}
            </Button>
          </>
        )}
      </div>
    </TabContainer>
  );
};
