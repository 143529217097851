import { Page } from "../../Page";
import { getDataMany } from "../adapter";

interface RawPage {
  id: number;
  attributes: {
    content: string;
    createdAt: Date;
    updatedAt: Date;
    showInSidebar: boolean;
    slug: string;
    title: string;
    tosVersion: number;
  };
}

export const PageAdapter = async (rawJSON: RawPage[]): Promise<Page[]> => {
  const rawPages = await getDataMany<RawPage[]>(rawJSON);
  const pages: Page[] = rawPages.map(
    ({ id, attributes: { createdAt, updatedAt, ...rest } }) => {
      return {
        ...rest,
        id: id,
        created_at: createdAt,
        updated_at: updatedAt,
      };
    }
  );
  return pages;
};
