import { AbstractCategory } from "@kvix/shared";
import { KvixIcon } from "@kvix/ui";
import { FormControl, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  categories: AbstractCategory[];
  currentCategory: string;
  setCategory: (category: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 36,
    fontWeight: 500,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.getContrastText(
        theme.palette.background.default
      ),
      borderRadius: "8px",
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
  FormControlWidth: {
    width: "calc(50%)",
    marginRight: "16px",
  },
  endAdornment: {
    position: "absolute",
    right: 6,
    top: 7,
    pointerEvents: "none",
  },
}));

export const CategorySelect: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("content");

  const categories = useMemo(() => {
    return [
      { id: -1, title: t("categories"), slug: null },
      ...props.categories,
    ];
  }, [props.categories]);

  if (!ready) {
    return null;
  }

  return (
    <FormControl className={classes.FormControlWidth}>
      <Select
        variant="outlined"
        className={classes.root}
        displayEmpty
        value={props.currentCategory}
        onChange={(event) => props.setCategory(event.target.value as string)}
        endAdornment={
          <KvixIcon.Chevron.Down className={classes.endAdornment} />
        }
      >
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.slug}>
            {category.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
