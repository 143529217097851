import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import { PaymentMethod } from "@stripe/stripe-js";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DonateTabState } from "../enums/DonateTabState";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";
import { AddCardForm } from "../partials/AddCardForm";
import { TabContainer } from "../partials/TabContainer";

interface Props {
  tabState: DonateTabState;
  setTabState: (DonateTabState) => void;
  donationAlert: (message: string, severity: DonationAlertSeverity) => void;
  setNewPaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "70px",
    marginBottom: "20px",
  },
}));

export const AddCardTab: FC<Props> = (props) => {
  const classes = useStyles();
  const { t, ready } = useTranslation("donateLiveView");
  const [loading, setLoading] = useState<boolean>(false);

  const addCardSuccess = () => {
    props.setTabState(DonateTabState.CHECKOUT);
  };

  if (!ready) {
    return null;
  }

  return (
    <TabContainer
      tabState={props.tabState}
      visibleState={DonateTabState.ADDCARD}
    >
      <div className={classes.title}>
        <Text variant="h4">{t("card.title")}</Text>
      </div>
      <AddCardForm
        donationAlert={props.donationAlert}
        loading={loading}
        setLoading={setLoading}
        onSuccess={addCardSuccess}
        setNewPaymentMethod={props.setNewPaymentMethod}
      />
    </TabContainer>
  );
};
