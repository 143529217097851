import { SessionState } from "@kvix/shared";
import { Text } from "@kvix/ui";
import { Avatar, Container, Grid, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { add } from "date-fns";
import { isPast } from "date-fns/esm";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, RouteChildrenProps, useHistory } from "react-router";
import { APP_HEADER_PADDING } from "../../../contexts/expo";
import { useIsInActiveRunningCompetition } from "../../../hooks/competition";
import { useFetchStreamer } from "../../../hooks/http";
import { useIsMobile } from "../../../hooks/isMobile";
import { useIsNativeApp } from "../../../hooks/nativeApp";
import { useAndCaptureParamsId } from "../../../hooks/useAndCaptureParamsId";
import { Footer } from "../../Footer";
import { HeaderContext } from "../../Header/context";
import { FollowButton } from "../../partials/Instructor/FollowButton";
import { Page } from "../../partials/Page";
import { FullName } from "../../partials/SocialShare/components/ShareModal";
import { ShareButton } from "../../partials/SocialShare/ShareButton";
import {
  hasSocialMediaProfile,
  SocialMediaProfiles,
} from "../../partials/SocialShare/SocialMediaProfiles";
import { PreviousStreamsList } from "../../partials/Streamer/PreviousStreamsList";
import { StreamerPoster } from "./StreamerPoster";
import StreamerSchedule from "./StreamerSchedule";
import StreamerVoteButton from "./StreamerVoteButton";

const useStyles = makeStyles((theme) => ({
  root: { padding: 0, marginBottom: "48px" },

  rightColumn: {
    paddingLeft: "100px",

    "& .MuiGrid-root": {
      marginTop: 0,
    },

    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      width: "100%",

      "&.vote-and-follow": {
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },

    "& .schedule--heading": {
      position: "sticky",
      backgroundColor: theme.palette.background.default,
      top: ({ headerVisible }: any) =>
        headerVisible ? APP_HEADER_PADDING + 55 : 0,
      boxShadow: ({ headerVisible }: any) =>
        headerVisible ? "none" : "0px 6px 8px -8px rgb(0 0 0 / 30%)",
      transition: "all 0.2s",
      "& .schedule--heading-vote": {
        width: "100%",
        paddingBottom: 16,

        "& button": {
          borderRadius: 32,
          whiteSpace: "normal",
        },
      },
      "& .schedule--heading-follow": {
        marginLeft: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: theme.spacing(2),

        [theme.breakpoints.down("sm")]: {
          paddingRight: 0,
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& .schedule--heading-vote": {
          width: "auto",
          paddingBottom: 0,
        },
      },
    },

    "& .vote": {
      position: "sticky",
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      paddingTop: 16,
      paddingBottom: 24,

      "& button": {
        width: "100%",
        borderRadius: 32,
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0 15px 15px 15px",
    },
  },
  sectionTitle: {
    fontSize: "18px",
    lineHeight: "28px",
  },
  avatar: {
    margin: `24px 0 4px 0`,
    width: 120,
    height: 120,
  },
  bioText: {
    whiteSpace: "pre-line",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  displayMoreButton: {
    textTransform: "none",
    height: "36px",
    padding: "0 14px",
    marginTop: theme.spacing(2),
  },
  ml: {
    marginLeft: 24,
  },
  zIndexTop: {
    zIndex: 10,
  },
  zIndexMiddle: {
    zIndex: 5,
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    "& .share--button-icon": {
      paddingRight: ({ isNative }: any) => (isNative ? 0 : 12),
    },
  },
  buttonGroupDesktop: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  pushToRight: {
    marginLeft: "auto",
  },
  generalVerticalPadding: {
    paddingTop: "48px",
  },
  smallVerticalPadding: {
    paddingTop: "12px",
    paddingBottom: "4px",
  },
}));

export default (props: RouteChildrenProps) => {
  const { isVisible: headerVisible } = useContext(HeaderContext);
  const isNative = useIsNativeApp();
  const { t, ready } = useTranslation(["streamerPage", "seo"]);
  const classes = useStyles({ ...props, headerVisible, isNative });
  const history = useHistory();
  const userId = useAndCaptureParamsId("userId");
  const daysForward = add(new Date(), { days: 7, hours: 2 });

  if (isNaN(userId)) {
    return <Redirect to="/page-not-found" />;
  }

  const streamer = useFetchStreamer(+userId);
  const voteActive = useIsInActiveRunningCompetition(streamer);
  const isMobile = useIsMobile();

  const previousStreams = useMemo(() => {
    if (!streamer) {
      return [];
    }

    return streamer.sessions
      .filter(
        (session) =>
          isPast(new Date(session.end)) &&
          (session.hlsUrl || session.mp4Url) &&
          session.state === SessionState.Finished &&
          session.channelId
      )
      .sort(
        (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime()
      );
  }, [streamer]);

  if (!streamer || !ready) {
    return null;
  }

  const upcoming = streamer.sessions?.filter((session) => {
    const start = new Date(session.start);
    return start > new Date() && start < daysForward;
  });

  const profile = streamer.instructorProfile;

  return (
    <Page
      meta={{
        title: t("seo:instructor.title", {
          name: streamer.name.custom,
        }),
        description: profile?.bio,
        ogTitle: streamer.name?.custom || streamer.name?.first,
        ogImage: profile?.imageUrl,
      }}
      noGutter={isMobile}
    >
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <StreamerPoster instructor={streamer} />
          </Grid>

          <Grid item xs={12} md={6} className={classes.rightColumn}>
            <Grid container direction="column">
              {!isMobile && (
                <>
                  <Grid container>
                    <Grid item>
                      <Avatar
                        src={streamer.imageUrl}
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item className={classes.pushToRight}>
                      <div className={classes.buttonGroupDesktop}>
                        {profile && <FollowButton instructor={streamer} />}
                        <ShareButton />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={classNames([
                      classes.zIndexTop,
                      classes.generalVerticalPadding,
                    ])}
                  >
                    <Text
                      variant="h2"
                      style={{ wordBreak: "break-word", zIndex: 10 }}
                    >
                      {streamer.name.custom || (
                        <FullName
                          firstName={streamer.name.first}
                          lastName={streamer.name.last}
                        />
                      )}
                    </Text>
                    {profile?.areas.length > 0 && (
                      <>
                        {profile?.areas.map((area, i) => (
                          <Text
                            key={i}
                            weight={700}
                            style={{
                              marginTop: "4px",
                              wordBreak: "break-word",
                              fontSize: 18,
                            }}
                          >
                            {area}
                          </Text>
                        ))}
                      </>
                    )}
                  </Grid>
                </>
              )}

              {isMobile && (
                <Grid
                  item
                  container
                  className={classNames([
                    "schedule--heading",
                    "vote-and-follow",
                    classes.zIndexMiddle,
                    classes.smallVerticalPadding,
                  ])}
                  direction={"row"}
                >
                  <Grid item className={"schedule--heading-vote"}>
                    <StreamerVoteButton
                      hideName={isMobile}
                      streamer={streamer}
                      voteActive={voteActive}
                    />
                  </Grid>
                  <Grid item className={"schedule--heading-follow"}>
                    <div className={classes.buttonGroup}>
                      {profile && <FollowButton instructor={streamer} />}
                      <ShareButton />
                    </div>
                  </Grid>
                </Grid>
              )}

              {upcoming?.length > 0 && (
                <>
                  <Grid item className={classes.generalVerticalPadding}>
                    <Text variant="h4" className={classes.sectionTitle}>
                      {t("schedule")}
                    </Text>
                  </Grid>

                  <Grid item className={classes.generalVerticalPadding}>
                    <StreamerSchedule sessions={upcoming} />
                  </Grid>
                </>
              )}

              <Grid item className={classes.generalVerticalPadding}>
                <Text
                  variant="h4"
                  className={classes.sectionTitle}
                  gutterBottom
                >
                  {t("bio")}
                </Text>

                <Text className={classes.bioText}>
                  {profile?.bio || t("noBio")}
                </Text>
              </Grid>

              <Grid
                item
                style={{ width: "100%" }}
                className={classes.generalVerticalPadding}
              >
                {previousStreams?.length > 0 && (
                  <>
                    <Grid item style={{ marginTop: "24px" }}>
                      <Text
                        variant="h4"
                        className={classes.sectionTitle}
                        gutterBottom
                      >
                        {t("previousStreams")}
                      </Text>
                      <PreviousStreamsList
                        previousStreams={previousStreams}
                        showChannel={false}
                        onClick={(stream) =>
                          history.push({
                            pathname: `/replay/${stream.id}`,
                            state: {
                              background: history.location,
                              from: history.location.pathname,
                            },
                          })
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              {profile && hasSocialMediaProfile(profile) && (
                <Grid item className={classes.generalVerticalPadding}>
                  <Text
                    variant="h4"
                    className={classes.sectionTitle}
                    gutterBottom
                  >
                    {t("socialMedia")}
                  </Text>
                  <SocialMediaProfiles instructorProfile={profile} />
                </Grid>
              )}
            </Grid>
            {!isMobile && (
              <div className="vote">
                <StreamerVoteButton
                  streamer={streamer}
                  voteActive={voteActive}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Page>
  );
};
