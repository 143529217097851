import { Button, KvixIcon, Text } from "@kvix/ui";
import { Box, makeStyles, useTheme } from "@material-ui/core";
import { useOnce } from "@react-spring/shared";
import classNames from "classnames";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentContext } from "../../context";
import { DonateTabState } from "../enums/DonateTabState";
import CardVendorIcon from "./CardVendorIcon";

interface CustomerCardList {
  loading: boolean;
  setLoading: (Boolean) => void;
  selectedPaymentMethodId: string;
  onSelect: (cardId: string) => void;
  donationAlert: (
    message: string,
    severity: "error" | "warning" | "info" | "success"
  ) => void;
  setTabState: (tabState: DonateTabState) => void;
}

interface CardButtonProps {
  card;
  className;
  onClick: (e) => void;
}

const useStyles = makeStyles((theme) => ({
  cardButton: {
    padding: "0 10px",
    marginBottom: "12px",
    height: "40px",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(31, 40, 51, 1)",
    color: "rgba(31, 40, 51, 1)",
    fontWeight: 400,
    backgroundColor: theme.palette.common.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiSvgIcon-root": {
      color: "rgba(31, 40, 51, .3)",
    },
  },
  selectedCard: {
    "& .MuiSvgIcon-root": {
      color: "rgba(31, 40, 51, 1)",
    },
  },
  boxMargin: {
    minHeight: "180px",
  },
  addCardButton: {
    fontWeight: 600,
  },
  cardText: {
    marginLeft: 10,
    letterSpacing: "0.5px",
  },
}));

const CardButton: FC<CardButtonProps> = (props) => {
  return (
    <Button
      key={props.card.id}
      onClick={props.onClick}
      className={props.className}
      variant={"contained"}
      color={"inherit"}
    >
      <KvixIcon.Checkmark.Circled />
      {props.children}
      {props.card.card.brand === "visa" ? (
        <CardVendorIcon.Visa />
      ) : (
        <CardVendorIcon.Mastercard />
      )}
    </Button>
  );
};

export const SavedCardList: FC<CustomerCardList> = (props) => {
  const classes = useStyles();
  const { savedCards, actions } = useContext(PaymentContext);
  const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(true);
  const { t, ready } = useTranslation("donateLiveView");
  const theme = useTheme();

  useOnce(() => {
    actions.fetchSavedCards();
  });

  useEffect(() => {
    setFetchingPaymentMethods(savedCards[1]);
  }, [savedCards]);

  const selectCard = (cardId: string) => {
    props.onSelect(cardId);
  };

  if (!ready) {
    return null;
  }

  return (
    <Box className={classes.boxMargin}>
      <div style={{ color: theme.palette.common.black, marginBottom: "6px" }}>
        {fetchingPaymentMethods ? (
          t("card.fetchData")
        ) : savedCards[0].length > 0 ? (
          <div>
            {savedCards[0].map(
              (card, index) =>
                card.type === "card" && (
                  <CardButton
                    key={index}
                    onClick={() => {
                      selectCard(card.id);
                    }}
                    className={classNames(classes.cardButton, [
                      props.selectedPaymentMethodId === card.id &&
                        classes.selectedCard,
                    ])}
                    card={card}
                  >
                    <Text className={classes.cardText}>
                      {t("card.cardText") + card.card.last4}
                    </Text>
                  </CardButton>
                )
            )}
          </div>
        ) : (
          t("card.noCards")
        )}
      </div>

      <Button
        onClick={() => {
          props.setTabState(DonateTabState.ADDCARD);
        }}
        className={classNames([classes.cardButton, classes.addCardButton])}
        color={"secondary"}
        disabled={props.loading}
        variant={"text"}
      >
        {t("card.addCardButton")}
      </Button>
    </Box>
  );
};
