import { AbstractUser } from "@kvix/shared";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FadeIn } from "../../../partials/Transitions/SlideUp";
import { HeaderContext } from "../../context";
import { MenuItem } from "../../Items/MenuItem";
import { HeaderItemNotifications } from "../../Items/Notifications";
import { HeaderItemProfile } from "../../Items/Profile/Profile";
import { ProfileMenu } from "../../Items/Profile/ProfileMenu";
import { HeaderItemSearch } from "../../Items/Search/Search";
import { HeaderItemSidebar } from "../../Items/Sidebar";
import { SiteMenu } from "../../Items/SiteMenu";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    "& svg": {
      fontSize: 32,
      width: 32,
      height: 32,

      transition: "color 0.17s ease",
      color: theme.palette.text.primary,
    },

    "&[disabled] svg": {
      color: ({ context }: any) => {
        if (context.isDarkMode) {
          return "rgba(255, 255, 255, 0.3)";
        }

        return null;
      },
    },

    [theme.breakpoints.down("xs")]: {
      paddingLeft: 6,
      paddingRight: 6,

      transform: "scale(0.9)",
    },
  },
  link: {
    color: "inherit !important",
    textDecoration: "none !important",
  },
}));

interface Props {
  searchExpanded: boolean;
  setSearchExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hideToolbarItems: boolean;
  user: AbstractUser;
}

export const DefaultLayout: React.FC<Props> = (props) => {
  const history = useHistory();
  const context = useContext(HeaderContext);
  const classes = useStyles({ context });
  const { searchExpanded, setSearchExpanded, user } = props;
  const theme = useTheme();
  const menuBreakpoint = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <HeaderContext.Consumer>
      {({ setActiveItem }) => (
        <React.Fragment>
          {!menuBreakpoint && (
            <SiteMenu translation="sidebar">
              {(item, index) => (
                <MenuItem.Underlined key={index} {...item}>
                  <Link
                    to={item.to}
                    className={classes.link}
                    onClick={() => setActiveItem(item.to)}
                    children={item.value}
                  />
                </MenuItem.Underlined>
              )}
            </SiteMenu>
          )}
          {!props.hideToolbarItems && (
            <FadeIn>
              <HeaderItemProfile user={user} history={history}>
                {(provided) => <ProfileMenu {...provided} />}
              </HeaderItemProfile>
              {user && (
                <HeaderItemNotifications
                  iconClassName={classes.menuButton}
                  isNativeApp={context.isNativeApp}
                />
              )}
            </FadeIn>
          )}
          <HeaderItemSearch
            iconClassName={classes.menuButton}
            backgroundVisible={context.isBackgroundVisible}
            expanded={searchExpanded}
            setExpanded={setSearchExpanded}
          />
          {menuBreakpoint && (
            <HeaderItemSidebar iconClassName={classes.menuButton}>
              {(close) => (
                <SiteMenu direction="column" translation="sidebar">
                  {(item, index) => (
                    <MenuItem
                      key={index}
                      size="h5"
                      alignItems="flex-start"
                      onClick={close}
                      {...item}
                    >
                      <Link
                        to={item.to}
                        className={classes.link}
                        children={item.value}
                      />
                    </MenuItem>
                  )}
                </SiteMenu>
              )}
            </HeaderItemSidebar>
          )}
        </React.Fragment>
      )}
    </HeaderContext.Consumer>
  );
};

function setActive(to: string): void {
  throw new Error("Function not implemented.");
}
