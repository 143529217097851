import { AbstractAnswer } from "@kvix/shared";
import { Paper } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Comment as CommentComponent } from "../Comment";

interface CommentsProps {
  comment: AbstractAnswer;
  onInstructoPage?: boolean;
}

const CommentContainer = styled(Paper)`
  height: 200px;
  overflow: hidden;
  padding: 20px;
  margin-right: 18px;
`;

export const CommentCard: React.FC<CommentsProps> = (props) => {
  return (
    <>
      {props.comment.user.name.last && (
        <CommentContainer>
          <CommentComponent
            comment={props.comment}
            maxNumberOfLines={4}
            asCompact
          />
        </CommentContainer>
      )}
    </>
  );
};
