import React, { FC } from "react";
import styled from "styled-components";
import { DonateTabState } from "../enums/DonateTabState";

interface Props {
  tabState: DonateTabState;
  visibleState: DonateTabState;
  classNames?;
}

const Container = styled.div`
  pointer-events: all;
  height: 100%;
  width: 100%;
`;

export const TabContainer: FC<Props> = (props) => {
  return (
    <Container
      hidden={props.tabState !== props.visibleState}
      className={props.classNames}
    >
      {props.children}
    </Container>
  );
};
