import {
  Backdrop as MuiBackdrop,
  Dialog,
  Paper as MuiPaper,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useFetchProgram } from "../../../hooks/http";
import { ReplayContent } from "./Content";

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    overflow: visible;
    background: transparent;
    box-shadow: none;

    video,
    canvas {
      box-shadow: 8px 0 24px 10px rgba(0, 0, 0, 0.15);
    }
  }
`;

const Backdrop = styled(MuiBackdrop)`
  &.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);

    @media screen and (max-width: 800px) {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
`;

export const ReplayModal: React.FC = (props) => {
  const { sessionId, programId } = useParams();
  const program = useFetchProgram(+programId);
  const history = useHistory();

  const replay = useMemo(() => {
    return program?.sessions.find((s) => s.id === +sessionId);
  }, [program, sessionId]);

  const back = (event?: React.SyntheticEvent) => {
    if (event) {
      event.stopPropagation();
    }
    history.goBack();
  };

  if (!replay || !program) {
    return null;
  }

  return (
    <Dialog
      open
      maxWidth="md"
      PaperComponent={Paper}
      BackdropComponent={Backdrop}
      onBackdropClick={back}
    >
      <ReplayContent
        replay={replay}
        imageUrl={program.imageUrl}
        name={program.instructor.name}
        title={program.title}
      />
    </Dialog>
  );
};
