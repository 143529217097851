import { AbstractAnswer } from "@kvix/shared";
import React, { FC, useContext } from "react";
import { KvixUserContext } from "../../../contexts/user";
import { useShowPromotions } from "../../../hooks/promotions";
import { AccessLevel, useUserAccessLevel } from "../../PurchaseFlow";
import { SponsorBannerProps } from "./variants/Sponsor";
import { BecomeAStreamerBanner } from "./variants/Upgrade/BecomeAStreamerBanner";
import { SubscribeBanner } from "./variants/Upgrade/SubscribeBanner";

interface Props {
  type:
    | "upgrade-free"
    | "upgrade-premium"
    | "quotes"
    | "sponsor"
    | "landing-page-upgrade-free"
    | "subscribe"
    | "become-a-streamer";
  size?: "small" | "large";
  customUrl?: string;
  comments?: AbstractAnswer[];
  forceShow?: boolean;
  marginTop?: number;
  marginBottom?: number;
  sponsorProps?: SponsorBannerProps;
}

export const Banner: FC<Props> = (props) => {
  const { user } = useContext(KvixUserContext);
  const accessLevel = useUserAccessLevel(user);
  const showPromotions = useShowPromotions();

  if (!showPromotions) {
    return null;
  }

  if (accessLevel === AccessLevel.FULL && Math.random() >= 0.5) {
    return null;
  }

  return (
    <>
      {accessLevel === AccessLevel.FULL ? (
        <BecomeAStreamerBanner
          marginTop={props.marginTop}
          marginBottom={props.marginBottom}
          size={props.size}
        />
      ) : (
        <SubscribeBanner
          marginTop={props.marginTop}
          marginBottom={props.marginBottom}
          size={props.size}
        />
      )}
    </>
  );
};
