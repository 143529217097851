import { KvixThemeProvider, RefDialogProvider } from "@kvix/ui";
import "@kvix/ui/public/fonts.scss";
import {
  Box,
  CircularProgress,
  CssBaseline,
  Fade,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { Location } from "history";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Switch, useHistory } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import styled, { ThemeProvider as SCThemeProvider } from "styled-components";
import { CmsContextProvider } from "../cms/context";
import { AlgoliaContextProvider } from "../contexts/algolia";
import { CompetitionContextProvider } from "../contexts/competition";
import { ExpoContextProvider } from "../contexts/expo";
import { KvixInstructorStateProvider } from "../contexts/instructor";
import { LanguageContextProvider } from "../contexts/language";
import { LocationContextProvider } from "../contexts/location";
import { PurchaseFlowProvider } from "../contexts/PurchaseFlow";
import {
  isBasicInstructor,
  KvixUserContext,
  KvixUserStateProvider,
  useHasCompletedSignup,
} from "../contexts/user";
import { useShowPromotions } from "../hooks/promotions";
import "../styles/App.scss";
import { lazy } from "../utils/lazy-load";
import { ErrorBoundary, LazyRouteFallback } from "./ErrorBoundary";
import { Header } from "./Header";
import { HeaderContextProvider } from "./Header/context";
import { PageNotFound } from "./pages/404";
import { Campaign } from "./pages/Campaign";
import { CampaignContextProvider } from "./pages/Campaign/context";
import { CmsBlog } from "./pages/CMS/Blog/index";
import { CmsDeals } from "./pages/CMS/Deals/index";
import { CmsExercise } from "./pages/CMS/Exercise/index";
import { Contact } from "./pages/Contact";
import { BuyGiftcard } from "./pages/Giftcard";
import Instructor from "./pages/Instructor";
import { PaymentContextProvider } from "./pages/Payment/context";
import { Redeem } from "./pages/Payment/Redeem";
import { RecruitLandingPage } from "./pages/Recruit/landingPage";
import { ReplayModal } from "./pages/Replay/Modal";
import { Anytime } from "./pages/Start/Anytime";
import { Instructors } from "./pages/Start/Instructors";
import { Programs } from "./pages/Start/Programs";
import { Sessions } from "./pages/Start/Sessions";
import { Streamer } from "./pages/Streamer";
import { StreamerAdmin } from "./pages/StreamerAdmin";
import StreamerReplayModal from "./pages/StreamerReplay/Modal";
import { GaEvents } from "./partials/GaEvents";
import { ContactModal } from "./partials/Modal/Contact/Contact";
import { LanguageCookieModal } from "./partials/Modal/LanguageCookiePrompt/LanguageCookiePrompt";
import { KvixRoute } from "./partials/Route";
import { ServiceMalfunction } from "./partials/ServiceMalfunction";
import { SubscriptionError } from "./partials/SubscriptionError";
import { TosConsent } from "./partials/TosConsent";
import { PurchaseFlowCatcher } from "./PurchaseFlow";
import { SignupButton } from "./SignupButton";

const LoadingIndicatorContainer = styled.div`
  position: fixed;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: none;
  z-index: 9999999;
`;

const LoadingIndicator = (
  <LoadingIndicatorContainer>
    <Fade in>
      <CircularProgress size={60} />
    </Fade>
  </LoadingIndicatorContainer>
);

const useDisplayHeader = () => {
  const history = useHistory();
  const [displayHeader, setDisplayHeader] = useState(true);
  const hiddenRoutes = ["live", "channel", "broadcast"];
  const isRouteHidden = (pathname: string) => {
    const slugs = pathname.split("/");
    const intersection = slugs.filter((value) => hiddenRoutes.includes(value));
    return intersection.length > 0;
  };

  useEffect(() => {
    setDisplayHeader(!isRouteHidden(history.location.pathname));

    history.listen(() => {
      setDisplayHeader(!isRouteHidden(history.location.pathname));
    });
  }, [history, isRouteHidden, setDisplayHeader]);

  return displayHeader;
};

const Index = lazy(
  () =>
    import(
      /* webpackChunkName: "index" */
      /* webpackPrefetch: true */
      "./pages/Index"
    ),
  LoadingIndicator
);

const Login = lazy(
  () =>
    import(
      /* webpackChunkName: "login" */
      /* webpackPrefetch: true */
      "./pages/Login"
    ),
  LoadingIndicator
);

const Signup = lazy(
  () =>
    import(
      /* webpackChunkName: "signup" */
      /* webpackPrefetch: true */
      "./pages/Signup"
    ),
  LoadingIndicator
);

const Profile = lazy(
  () =>
    import(
      /* webpackChunkName: "profile" */
      /* webpackPrefetch: true */
      "./pages/Profile"
    ),
  LoadingIndicator
);

const Subscribe = lazy(
  () =>
    import(
      /* webpackChunkName: "subscribe" */
      /* webpackPrefetch: true */
      "./pages/Subscribe"
    ),
  LoadingIndicator
);

const SuccessfulStreamerRegistration = lazy(
  () =>
    import(
      /* webpackChunkName: "successfulStreamerRegistration" */
      /* webpackPrefetch: true */
      "./pages/SuccessfulStreamerRegistration"
    ),
  LoadingIndicator
);

const VOD = lazy(
  () =>
    import(
      /* webpackChunkName: "vod" */
      /* webpackPrefetch: true */
      "./pages/VOD"
    ),
  LoadingIndicator
);

const Program = lazy(
  () =>
    import(
      /* webpackChunkName: "program" */
      /* webpackPrefetch: true */
      "./pages/Program"
    ),
  LoadingIndicator
);

const Playlist = lazy(
  () =>
    import(
      /* webpackChunkName: "playlist" */
      /* webpackPrefetch: true */
      "./pages/Playlist"
    ),
  LoadingIndicator
);

const About = lazy(
  () =>
    import(
      /* webpackChunkName: "about" */
      /* webpackPrefetch: true */
      "./pages/About"
    ),
  LoadingIndicator
);

const ReplayPage = lazy(
  () =>
    import(
      /* webpackChunkName: "replay" */
      /* webpackPrefetch: true */
      "./pages/Replay/Page"
    ),
  LoadingIndicator
);

const StreamerReplayPage = lazy(
  () =>
    import(
      /* webpackChunkName: "replay" */
      /* webpackPrefetch: true */
      "./pages/StreamerReplay/Page"
    ),
  LoadingIndicator
);

const CmsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "cms" */
      /* webpackPrefetch: true */
      "./pages/CMS/Page"
    ),
  LoadingIndicator
);

const SessionRouter = lazy(
  () =>
    import(
      /* webpackChunkName: "session" */
      /* webpackPrefetch: true */
      "./pages/Session"
    ),
  LoadingIndicator
);

const Channel = lazy(
  () =>
    import(
      /* webpackChunkName: "session" */
      /* webpackPrefetch: true */
      "./pages/Channel"
    ),
  LoadingIndicator
);

const CannyWidget = lazy(
  () =>
    import(
      /* webpackChunkName: "canny" */
      /* webpackPrefetch: true */
      "./pages/Canny"
    ),
  LoadingIndicator
);

const Investor = lazy(
  () =>
    import(
      /* webpackChunkName: "investor" */
      /* webpackPrefetch: true */
      "./pages/CMS/Investor/Investor"
    ),
  LoadingIndicator
);

const IPOPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ipo" */
      /* webpackPrefetch: true */
      "./pages/CMS/IPO/IPOPage"
    ),
  LoadingIndicator
);

const ResetPassword = lazy(
  () => import("./pages/ResetPassword"),
  LoadingIndicator
);

const BecomeAStreamer = lazy(
  () =>
    import(
      /* webpackChunkName: "becomeastreamer" */
      /* webpackPrefetch: true */
      "./pages/BecomeAStreamer"
    ),
  LoadingIndicator
);

const PageRouting: React.FC = () => {
  const { user } = useContext(KvixUserContext);
  const history = useHistory<{
    background: Location;
    accessBlocked?: boolean;
  }>();
  const showPromotions = useShowPromotions();
  const background =
    history.location.state && history.location.state.background;

  return (
    <PurchaseFlowCatcher history={history} user={user}>
      <GaEvents history={history} user={user}>
        {(redirect) => (
          <ErrorBoundary FallbackComponent={LazyRouteFallback}>
            <ServiceMalfunction />

            <Switch location={background || history.location}>
              {redirect && <Redirect to={redirect} />}

              {/* Shared routes */}
              <KvixRoute path="/" exact component={Index} />
              <KvixRoute path="/about" component={About} />
              <KvixRoute path="/contact" component={Contact} />
              <KvixRoute path="/c/:campaignCode" component={Campaign} />
              <KvixRoute path="/c" component={Campaign} />
              <KvixRoute path="/page/:slug" component={CmsPage} />
              <KvixRoute path="/blog/:slug/" component={CmsBlog} />
              <KvixRoute path="/blog" component={CmsBlog} />
              <KvixRoute path="/exercise/:slug/" component={CmsExercise} />
              <KvixRoute path="/exercise" component={CmsExercise} />
              <KvixRoute path="/sweet-deals" component={CmsDeals} />
              <KvixRoute path="/program/:programId" component={Program} />
              <KvixRoute path="/playlist/:playlistId" component={Playlist} />
              <KvixRoute
                path="/replay/:programId/:sessionId"
                component={ReplayPage}
              />
              <KvixRoute
                path="/replay/:sessionId"
                component={StreamerReplayPage}
              />
              <KvixRoute
                path="/session/:sessionId/:viewType"
                component={SessionRouter}
              />
              <KvixRoute
                path="/channel/:channelNumber"
                beforeEnter={() => !!user}
                component={Channel}
              />
              <KvixRoute path="/instructor/:userId" component={Instructor} />
              <KvixRoute path="/sessions" component={Sessions} />
              <KvixRoute path="/anytime" component={Anytime} />
              <KvixRoute path="/programs" component={Programs} />
              <KvixRoute path="/ipo" component={IPOPage} />
              <KvixRoute path="/instructors" component={Instructors} />
              <KvixRoute path="/investor" component={Investor} />
              <KvixRoute path="/page-not-found" component={PageNotFound} />
              <KvixRoute path="/streamer/:userId/" component={Streamer} />
              <KvixRoute
                path="/become-a-streamer"
                component={BecomeAStreamer}
              />

              <KvixRoute path="/resetpassword" component={ResetPassword} />

              {showPromotions && (
                <KvixRoute path="/buy-gift-card" component={BuyGiftcard} />
              )}
              {/* Routes for logged in user */}
              <KvixRoute
                path="/me"
                component={Profile}
                beforeEnter={() => !!user}
              />
              <KvixRoute
                path="/successful-streamer-registration"
                component={SuccessfulStreamerRegistration}
                beforeEnter={() => user && isBasicInstructor(user)}
              />
              <KvixRoute path="/subscribe" component={Subscribe} />
              <KvixRoute
                path="/redeem"
                component={Redeem}
                beforeEnter={() => !!user}
              />
              <KvixRoute
                path="/program/:programId/vod/:vodId"
                component={VOD}
                beforeEnter={() => !!user}
              />
              <KvixRoute
                path="/streamer-admin"
                component={StreamerAdmin}
                beforeEnter={() => !!user}
              />
              <KvixRoute
                path="/feedback"
                component={CannyWidget}
                beforeEnter={() => !!user}
              />

              {/* Redirect guests to signup */}
              {!user && <KvixRoute path="/signup" component={Signup} />}
              {!user && <KvixRoute path="/login" component={Login} />}
              {!user && (
                <KvixRoute path="/recruitme" component={RecruitLandingPage} />
              )}
              {!user && history.location.pathname === "/subscribe" && (
                <Redirect
                  to={{ pathname: "/signup", search: "membership=premium" }}
                />
              )}
              <Redirect to="/" />
            </Switch>

            {background && (
              <>
                {/* <KvixRoute path="/signup" component={SignupModal} /> */}
                <KvixRoute path="/contact" component={ContactModal} />

                <KvixRoute
                  path="/replay/:programId/:sessionId"
                  component={ReplayModal}
                />
                <KvixRoute
                  path="/replay/:sessionId"
                  component={StreamerReplayModal}
                />
              </>
            )}
          </ErrorBoundary>
        )}
      </GaEvents>
    </PurchaseFlowCatcher>
  );
};

const ScrollHandler: React.FC = () => {
  const history = useHistory<{ scroll: number } | undefined>();
  const [scrollToPosition, setScrollToPosition] = useState<number>(0);
  const { push, replace } = history;

  window.onbeforeunload = () => {
    setScrollToPosition(0);
  };

  useEffect(() => {
    history.push = (path: string, state?: any) => {
      push(path, { ...state, scroll: window.scrollY });
    };

    history.replace = (path: string, state?: any) => {
      replace(path, { ...state, scroll: window.scrollY });
    };

    const unregister = history.listen((location, action) => {
      if (
        !location.pathname.startsWith("/replay") &&
        !location.pathname.startsWith("/signup") &&
        !location.pathname.startsWith("/contact")
      ) {
        setScrollToPosition(action !== "POP" ? 0 : location.state?.scroll ?? 0);
      }
    });

    return () => {
      unregister();
    };
  }, [history]);

  useLayoutEffect(() => {
    if (scrollToPosition !== null) {
      window.scrollTo(0, scrollToPosition);
      setScrollToPosition(null);
    }
  }, [scrollToPosition]);

  return null;
};

const ConditionalHeader: React.FC<{
  setPromotionActive: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const displayHeader = useDisplayHeader();

  if (!displayHeader) {
    return null;
  }

  return displayHeader ? <Header {...props} /> : <></>;
};

const App: React.FC = () => {
  const [promotionActive, setPromotionActive] = useState(false);

  return (
    <StylesProvider injectFirst>
      <KvixThemeProvider allowDarkMode={false}>
        {(theme) => (
          <ThemeProvider theme={theme}>
            <SCThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <LocationContextProvider>
                  <LanguageContextProvider>
                    <KvixUserStateProvider>
                      <KvixInstructorStateProvider>
                        <ExpoContextProvider>
                          <CmsContextProvider>
                            <PaymentContextProvider>
                              <AlgoliaContextProvider>
                                <CompetitionContextProvider>
                                  <RefDialogProvider>
                                    <PurchaseFlowProvider>
                                      <CampaignContextProvider>
                                        <HeaderContextProvider>
                                          <Box
                                            mt={promotionActive ? "80px" : 0}
                                          >
                                            <ScrollHandler />
                                            <ConditionalHeader
                                              setPromotionActive={
                                                setPromotionActive
                                              }
                                            />
                                            <PageRouting />

                                            <LanguageCookieModal />
                                            <SubscriptionError />

                                            <TosConsent />
                                            <SignupButton placement="footer" />
                                          </Box>
                                        </HeaderContextProvider>
                                      </CampaignContextProvider>
                                    </PurchaseFlowProvider>
                                  </RefDialogProvider>
                                </CompetitionContextProvider>
                              </AlgoliaContextProvider>
                            </PaymentContextProvider>
                          </CmsContextProvider>
                        </ExpoContextProvider>
                      </KvixInstructorStateProvider>
                    </KvixUserStateProvider>
                  </LanguageContextProvider>
                </LocationContextProvider>
              </BrowserRouter>
            </SCThemeProvider>
          </ThemeProvider>
        )}
      </KvixThemeProvider>
    </StylesProvider>
  );
};

export default App;
