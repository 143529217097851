import { Deals } from "../../Deals";
import { Dealspost } from "../../Dealspost";
import { getData, RawMedia } from "../adapter";

export interface JSONDeals {
  id: number;
  attributes: {
    title: string;
    dealsposts: {
      data: JSONDealspost[];
    };
    createdAt: Date;
    updatedAt: Date;
  };
}

interface JSONDealspost {
  id: number;
  attributes: {
    id: number;
    title: string;
    subtitle: string;
    description: string;
    image: RawMedia;
    instruction: string;
    link: string;
    priority?: number;
    createdAt: Date;
    updatedAt: Date;
  };
}
export const DealsAdapter = async (rawJSON: JSONDeals): Promise<Deals> => {
  const {
    id,
    attributes: { title, dealsposts, createdAt, updatedAt },
  } = await getData<JSONDeals>(rawJSON);

  const posts: Dealspost[] = dealsposts.data.map(
    ({ id, attributes: { image, createdAt, updatedAt, ...rest } }) => ({
      ...rest,
      id: id,
      image: image.data.attributes,
      created_at: createdAt,
      updated_at: updatedAt,
    })
  );
  return {
    id,
    title,
    dealsposts: posts,
    created_at: createdAt,
    updated_at: updatedAt,
  };
};
