import { AbstractSession } from "@kvix/shared";
import { formatISO, parseJSON } from "date-fns";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { KvixUserContext } from "../../../../../../contexts/user";
import { useIsMobile } from "../../../../../../hooks/isMobile";
import { useKvixCast } from "../../../../../../hooks/useKvixCast";

interface SourceObject {
  src: string;
  type: string;
}

const TIMESPAN_SEND_DATA = 30;
export const useVideoJs = (
  playerRef: HTMLVideoElement,
  session: AbstractSession,
  onEndedCallback: () => void
) => {
  const [player, setPlayer] = useState<VideoJsPlayer>();
  const [volume, setVolume] = useState<number>(0);
  const [source, setSource] = useState<SourceObject>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [scrubberValue, setScrubberValue] = useState<number>(0);
  const [bufferScrubberValue, setBufferScrubberValue] = useState<number>(0);
  const isMobile = useIsMobile();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [curTimeRounded, setCurTimeRounded] = useState<number>(0);
  const { actions } = useContext(KvixUserContext);
  const timerCounterRef = useRef(0);

  const isVod = useMemo(
    () =>
      !!(session.state === "finished" && (session.hlsUrl || session.mp4Url)),
    [session]
  );

  const metadata = {
    image: "",
    releaseDate: formatISO(parseJSON(session.start), {
      representation: "date",
    }),
    subtitle: "Subtitle",
    title: session.title,
  };

  const isCasting = useKvixCast(isVod ? "anytime" : "live", session, metadata);

  const videoPlayerOptions: VideoJsPlayerOptions = useMemo(
    () => ({
      autoplay: !isVod || !videojs.browser.IS_SAFARI,
      controls: isMobile || /kvixapp/.test(navigator.userAgent),
      liveui: true,
      techOrder: ["html5"],
      sources: [source],
      userActions: { hotkeys: true },
      playsinline: true,
      responsive: true,
      html5: {
        vhs: {
          overrideNative: !/AppleWebKit.*kvixapp/.test(navigator.userAgent),
        },
      },
    }),
    [isVod, source]
  );

  const extractSource = useMemo(() => {
    if (isVod) {
      return session.hlsUrl
        ? {
            src: session.hlsUrl,
            type: "application/x-mpegURL",
          }
        : ({
            src: session.mp4Url,
            type: "video/mp4",
          } as SourceObject);
    } else if (session.wowzaStream) {
      return {
        src: session.wowzaStream.streamTargets[0],
        type: "application/x-mpegURL",
      } as SourceObject;
    }
  }, [session, isVod]);

  /* Player functions */
  const play = useCallback(async () => {
    if (player && player.paused()) {
      player.play();
    }
  }, [player]);

  const pause = useCallback(async () => {
    if (player && !player.paused()) {
      player?.pause();
    }
  }, [player]);

  const handleChromeCastClick = useCallback(async () => {}, []);

  /* Player Events */
  const onReady = useCallback(() => {}, [player]);

  const sendTrackedData = useCallback(() => {
    if (!isCasting && timerCounterRef.current > 0) {
      actions.updateWatchtimeToServer(session.id, timerCounterRef.current);
    }
  }, [session, timerCounterRef]);

  useEffect(() => {
    if (timerCounterRef.current >= TIMESPAN_SEND_DATA) {
      sendTrackedData();
      timerCounterRef.current = 0;
    }
  }, [timerCounterRef.current]);

  useEffect(() => {
    return () => {
      sendTrackedData();
    };
  }, []);

  const setPlayerTime = useCallback(
    (time: number) => {
      if (player) {
        setCurrentTime(time);
        player.currentTime(time);
      }
    },
    [player]
  );

  useEffect(() => {
    if (session.state === "finished") {
      setIsFinished(true);
    }
  }, [session.state, isFinished]);

  useEffect(() => {
    if (isVod) {
      timerCounterRef.current = timerCounterRef.current + 1;
      actions.updateWatchedSessions(session.id, curTimeRounded);
    }
  }, [curTimeRounded]);

  const onTimeUpdate = useCallback(() => {
    setCurrentTime(player.currentTime());
    setCurTimeRounded(Math.round(player.currentTime()));
    const bufferedSliderVal = (player.bufferedEnd() / player.duration()) * 100;
    const sliderVal = (player.currentTime() / player.duration()) * 100;
    setBufferScrubberValue(bufferedSliderVal);
    setScrubberValue(sliderVal);
  }, [player]);

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, [player]);

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, [player]);

  const onVolumeChange = useCallback(() => {
    setVolume(player.volume());
  }, [player]);

  const onError = useCallback((e) => {
    console.log(e);
  }, []);

  const onDurationChange = useCallback(() => {
    const dur = player?.liveTracker.seekableEnd();
    if (dur !== Infinity) {
      setDuration(dur);
    } else {
      setDuration(player.duration());
    }
  }, [player]);

  const onEnded = useCallback(() => {
    if (onEndedCallback && isFinished) {
      onEndedCallback();
    }
  }, [onEndedCallback, isFinished]);

  const onSeek = useCallback(() => {
    player.liveTracker.seekToLiveEdge();
  }, [player]);

  const onLoadedMetadata = useCallback(() => {
    onDurationChange();
  }, [player]);

  useEffect(() => {
    if (player && player.volume() !== volume) {
      player.volume(volume);
    }
  }, [volume, player]);

  useEffect(() => {
    if (!source || source.src !== extractSource.src) {
      setSource(extractSource);
    }
  }, [source, extractSource]);

  useEffect(() => {
    if (source && playerRef && !player) {
      const videoJs = videojs(playerRef, videoPlayerOptions, onReady);
      videoJs.addClass("vjs-big-play-centered");
      videoJs.addClass("video-js");
      videoJs.addClass("google-cast-launcher");

      if (!isVod) {
        videoJs.addClass("vjs-live");
      }

      setPlayer(videoJs);
    }
  }, [source, playerRef, player, videoPlayerOptions, onReady, isVod]);

  useEffect(() => {
    if (player) {
      player.on("timeupdate", onTimeUpdate);
      player.on("pause", onPause);
      player.on("play", onPlay);
      player.on("volumechange", onVolumeChange);
      player.on("durationchange", onDurationChange);
      player.on("error", onError);
      player.on("ended", onEnded);
      if (isVod) {
        player.one("loadedmetadata", onLoadedMetadata);
      }
    }

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef = null;
      }
    };
  }, [player]);

  useEffect(() => {
    if (player) {
      player.ready(() => {
        player.tech().on("retryplaylist", () => {
          onEnded();
        });

        if (player && source && source.src && isVod) {
          player.src(source.src);
          player.load();
          if (videojs.browser.IS_SAFARI) {
            player.play();
          }
        }
      });
    }
  }, [player, isFinished, source, isVod]);

  return {
    isVod,
    isPlaying,
    setVolume,
    play,
    pause,
    currentTime,
    duration,
    setPlayerTime,
    scrubberValue,
    setScrubberValue,
    bufferScrubberValue,
    handleChromeCastClick,
    onSeek,
  };
};
