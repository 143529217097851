import { ArticleType, mapToCard } from "@kvix/shared";
import { CardList } from "@kvix/ui";
import {
  Box,
  Container,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import {
  useBannerComments,
  useFetchAllInstructors,
  useFetchCategories,
} from "../../../../hooks/http";
import { Footer } from "../../../Footer";
import { Banner } from "../../../partials/Banner/Banner";
import { CategoryContainer } from "../../../partials/CategoryContainer";
import { Content } from "../../../partials/Content";
import { Card } from "../../../partials/Content/Card";
import { Hero } from "../../../partials/Hero";
import { Page } from "../../../partials/Page";

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    // backgroundColor: "red",
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
}));

const SessionFeed: React.FC = (props) => {
  const classes = useStyles(props);
  const NO_OF_CARDS = 4;

  const { t, ready } = useTranslation("startPage");
  const [instructors] = useFetchAllInstructors(NO_OF_CARDS);
  const pickerCategories = useFetchCategories();
  const comments = useBannerComments();

  const { user } = useContext(KvixUserContext);

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <Content type={ArticleType.SESSION} />

      <Container
        className={classes.ContainerPadding}
        maxWidth="xl"
        style={{ position: "relative" }}
      >
        {!user && <Banner type="quotes" comments={comments} />}

        <CardList
          title={t("feeds.instructors.title")}
          description={t("feeds.instructors.description")}
        >
          {instructors?.value?.map((instructor) => (
            <Card
              type={ArticleType.INSTRUCTOR}
              options={mapToCard({ instructor })}
              key={instructor.id}
            />
          ))}
        </CardList>
      </Container>
      <CategoryContainer
        title={t("feeds.programs.title")}
        description={t("feeds.programs.description")}
        explore={{ text: t("feeds.programs.explore"), url: "/programs" }}
        categories={pickerCategories?.value && pickerCategories.value}
      />
      <Banner type="upgrade-premium" size="large" />
    </Fragment>
  );
};

export const Sessions = React.memo(() => {
  return (
    <Page>
      <Hero.Sessions />
      <SessionFeed />
      <Footer />
    </Page>
  );
});
