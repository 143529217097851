import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Grid,
  makeStyles,
  Stepper,
  StepLabel,
  Step,
  IconButton,
} from "@material-ui/core"
import { KvixIcon } from "@kvix/ui"
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow"
import { Flow } from "../components/Flow"
import { RegisterStep } from "../steps/Register"
import { DefaultFlowProps } from "../types"
// import { PrePaymentStep } from "../steps/PrePayment"
import { SuccessStep } from "../steps/Success"
import { CheckoutStep } from "../steps/Checkout"
import { ContentModal } from "src/components/partials/Modal/ContentModal/ContentModal"

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    width: "375px",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      width: "375px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  fontWeightMedium: {
    opacity: 1,
    fontFamily: "AvenirNext-Bold",
    fontSize: "14px",
    fontWeight: 700,
    fontStyle: "normal",
    letterSpacing: "1px",
    textAlign: "center",
    lineHeight: "34px",
    textTransform: "uppercase",
  },
  stepper: {
    background: "none",
    width: "270px",
    margin: "0 auto",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "4px",
    paddingBottom: "8px",
    "& .MuiStepConnector-lineHorizontal": {
      borderTopWidth: "2px",
    },
    "& .MuiStepConnector-active span, .MuiStepConnector-completed span": {
      borderColor: "#FA255E",
    },
    "& .MuiStepIcon-root": {
      fontSize: "20px",
    },
    "& .MuiStep-horizontal": {
      width: "20px",
      padding: 0,
    },
    "& .MuiStepIcon-text": {
      fontSize: 12,
    },
    "& .MuiStepConnector-line": {
      borderColor: "#9E9E9E",
    }
  },
}))

export const NewFlow = ({
  login = false,
  currentTab = 0,
  checkoutParams,
  checkoutProps,
  registerProps,
  plan,
  recruit,
  ...registerOverrides
}: DefaultFlowProps) => {
  const { t } = useTranslation(["signUp, payment, login"]);
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(login);
  const [showContentModal, setShowContentModal] = useState("");
  const [dialogHeight, setDialogHeight] = useState(getDialogHeight());

  let steps = []

  const defaultSteps = [
    isLogin ? t("login") : t("signUp:createAnAccount"),
    t("payment:choosePlan"),
    t("signUp:youAreReady"),
  ]

  const campaignSteps = [
    isLogin ? t("login") : t("signUp:createAnAccount"),
    t("signUp:addPayment"),
    t("signUp:youAreReady"),
  ]

  switch (registerProps?.checkoutSource) {
    case "wp":
      steps = campaignSteps
      break
    default:
      steps = defaultSteps
  }

  function getDialogHeight() {
    return window.innerWidth <= 600 ? window.innerHeight - 50 : 760;
  }

  const updateHeights = () => {
    setDialogHeight(getDialogHeight());
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeights);

    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  }, []);

  return (
    <PurchaseFlowConsumer>
      {() => (
        <>
          <Flow>
            <Grid className={classes.dialogContainer} style={{ height: dialogHeight }}>
              <Stepper activeStep={currentTab} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
              {currentTab === 0 && (
                <RegisterStep
                  tabState={login ? "login" : "register"}
                  setIsLogin={setIsLogin}
                  showContentModal={setShowContentModal}
                  headerText={steps[currentTab]}
                  plan={plan}
                  checkoutParams={checkoutParams}
                  recruit={recruit}
                  {...registerProps}
                />
              )}
              {/* {currentTab === 1 && registerProps?.checkoutSource === "wp" && (
                <PrePaymentStep
                  checkoutSource={registerProps?.checkoutSource}
                  checkoutParams={checkoutParams}
                  showContentModal={setShowContentModal}
                  headerText={steps[currentTab]}
                  {...registerOverrides}
                />
              )} */}
              {/* {currentTab === 1 && registerProps?.checkoutSource === "kvix" && ( */}
              {currentTab === 1 && (
                <CheckoutStep
                  checkoutSource={registerProps?.checkoutSource}
                  headerText={steps[currentTab]}
                  checkoutParams={checkoutParams}
                  plan={plan}
                />
              )}
              {currentTab === 2 && (
                <SuccessStep
                  showContentModal={setShowContentModal} 
                  headerText={steps[currentTab]}
                />
              )}
            </Grid>
          </Flow>
          <ContentModal open={!!showContentModal} page={showContentModal}>
            <IconButton
              onClick={() => setShowContentModal("")}
              style={{ marginLeft: "auto" }}
            >
              <KvixIcon.Close />
            </IconButton>
          </ContentModal>
        </>
      )}
    </PurchaseFlowConsumer>
  )
}
