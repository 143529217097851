import { Text } from "@kvix/ui";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import StripeInternal from "stripe";

interface Props {
  template: "signup" | "banner" | "plan" | "lobby";
  plan: StripeInternal.Plan;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    width: 72,
    height: 72,
    position: "absolute",
    textAlign: "center",
    borderRadius: 36,
    zIndex: 99,
    "&.signup": {
      marginTop: -28,
      left: 10,
    },
    "&.banner": {
      right: 12,
      marginTop: -14,

      [theme.breakpoints.down("xs")]: {
        right: 4,
        marginTop: -14,
      },
    },
    "&.plan": {
      right: -32,
      top: -17,
      boxShadow: "0 0 20px rgba(250,37,94,0.3)",
    },
    "&.lobby": {
      top: -80,
      left: 0,
    },
  },
  campaignSave: {
    fontSize: 12,
    fontWeight: 600,
    marginTop: 10,
  },
  amountContainer: {
    display: "flex",
    marginTop: -20,
    textAlign: "center",
    paddingLeft: 4,
    justifyContent: "center",
  },
  campaignAmount: {
    fontSize: 36,
    letterSpacing: "-3px",
    fontWeight: 700,
  },
  amountSuffix: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 15,
    marginLeft: 1,
  },
}));

export const CampaignIndicator: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("banner");
  const { plan, template } = props;

  if (!plan || !plan["activeCampaign"] || !ready) {
    return null;
  }

  return (
    <div className={`${classes.root} ${template}`}>
      <Text className={classes.campaignSave} color="inherit">
        {t("campaignIndicator.save")}
      </Text>
      <div className={classes.amountContainer}>
        <Text className={classes.campaignAmount} color="inherit">
          {plan["activeCampaign"]["amount_off"]
            ? Math.trunc(
                (plan["activeCampaign"]["amount_off"] / plan.amount) * 100
              )
            : plan["activeCampaign"]["percent_off"]}
        </Text>
        <Text className={classes.amountSuffix} color="inherit">
          %
        </Text>
      </div>
    </div>
  );
};
