import { AbstractSession, AbstractUserName } from "@kvix/shared";
import { formatISO, parseJSON } from "date-fns";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { KvixUserContext } from "../../../contexts/user";
import { useKvixCast } from "../../../hooks/useKvixCast";
import { useVideoThumbnail } from "../../../hooks/videoThumbnail";
import { useLockLandscape } from "../../../hooks/windowOrientation";
import { ReplayContainer } from "../../partials/NativeApp/ReplayContainer";
import { VideoJS } from "../Session/Livestream/Player/VideoJS/VideoJS";

const ContainerStyle = styled.div`
  width: 100%;

  @media screen and (max-width: 800px) {
    width: calc(100% + 64px);
    margin-left: -32px;
    margin-right: -32px;
  }
`;

const receiverApplicationId = window.config.RECEIVER_APPLICATION_ID;

interface Props {
  replay: AbstractSession;
  title: string;
  name: AbstractUserName;
  imageUrl: string;
}

export const ReplayContent: React.FC<Props> = (props) => {
  const { replay, title, name, imageUrl } = props;
  const [uri] = useVideoThumbnail(replay);
  const sessionId = +replay.id;
  const { actions, sessions } = useContext(KvixUserContext);

  const metadata = {
    image: imageUrl,
    releaseDate: formatISO(parseJSON(replay.start), {
      representation: "date",
    }),
    subtitle: `${name.first} ${name.last}`,
    title: title,
  };

  const source = useMemo(
    () => ({
      sources: {
        ...(replay?.hlsUrl
          ? {
              src: replay?.hlsUrl,
              type: "application/x-mpegURL",
            }
          : {
              src: replay?.mp4Url,
              type: "video/mp4",
            }),
      },
    }),
    [replay]
  );

  const fallbackSource = useMemo(() => {
    //Save mp4 source as fallback
    if (replay?.mp4Url) {
      return {
        sources: {
          src: replay?.mp4Url,
          type: "video/mp4",
        },
      };
    }
  }, [replay]);

  useKvixCast("anytime", replay, metadata);

  const tracking = useMemo(
    () => ({
      watchedReplay: { sessionId },
      gaEvent: {
        category: "Anytime Video Watched",
        action: name.custom || `${name.first} ${name.last}`,
        label: title,
      },
    }),
    [name.custom, name.first, name.last, sessionId, title]
  );

  const updateWatchedTime = (currentTime: number) => {
    actions.updateWatchedSessions(replay.id, currentTime);
  };

  //Enables landscape mode in fullscreen for Android devices.
  useLockLandscape();

  return (
    <ContainerStyle>
      <ReplayContainer
        replay={replay}
        poster={uri}
        source={source.sources}
        metadata={metadata}
      >
        {(playerRef) => <VideoJS session={replay} />}
      </ReplayContainer>
    </ContainerStyle>
  );
};
