import { ArticleType } from "@kvix/shared";
import { Box, Container, makeStyles } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";
import { useBannerComments, useFetchCategories } from "../../../../hooks/http";
import { Footer } from "../../../Footer";
import { Banner } from "../../../partials/Banner/Banner";
import { CategoryContainer } from "../../../partials/CategoryContainer";
import { Content } from "../../../partials/Content";
import { Hero } from "../../../partials/Hero";
import { Page } from "../../../partials/Page";

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    [theme.breakpoints.down("xl")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}));

const InstructorFeed: React.FC = (props) => {
  const classes = useStyles(props);
  const { t, ready } = useTranslation("startPage");
  const pickerCategories = useFetchCategories();
  const comments = useBannerComments();
  const { user } = useContext(KvixUserContext);

  if (!ready) {
    return null;
  }

  return (
    <Fragment>
      <Content type={ArticleType.INSTRUCTOR} />
      <Container className={classes.ContainerPadding} maxWidth="xl">
        {!user && <Banner type="quotes" comments={comments} />}
        <CategoryContainer
          title={t("feeds.programs.title")}
          description={t("feeds.programs.description")}
          explore={{ text: t("feeds.programs.explore"), url: "/programs" }}
          categories={pickerCategories?.value && pickerCategories.value}
        />

        <Box mt={4}>
          <Banner type="upgrade-premium" size="large" />
        </Box>
      </Container>
    </Fragment>
  );
};

export const Instructors = React.memo(() => {
  return (
    <Page>
      <Hero.Instructors />
      <InstructorFeed />
      <Footer />
    </Page>
  );
});
