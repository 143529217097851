import {
  AbstractAnswer,
  AbstractBaseUser,
  AbstractCampaign,
  AbstractCategory,
  AbstractCompetitionRound,
  AbstractInstructorNumbers,
  AbstractInstructorProfile,
  AbstractLiveSession,
  AbstractProgram,
  AbstractSession,
  AbstractTag,
  AbstractUser,
  AbstractVod,
  AlgoliaAnytimeArticle,
  FollowInstructorDto,
  HttpMethods,
  parseDates,
} from "@kvix/shared";
import { differenceInMinutes, subMonths } from "date-fns";
import queryString from "query-string";
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useAsync, useAsyncFn, useEffectOnce } from "react-use";
import { AsyncState } from "react-use/lib/useAsync";
import { AlgoliaContext, SortOption } from "../contexts/algolia";
import { getLocation } from "../utils/location";
import { useSocketEvent } from "./socket";

export const useFetchProgram = (id: number) => {
  const [program, setProgram] = useState<AbstractProgram>(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`/api/program/${+id}`, {
          method: HttpMethods.GET,
        });
        if (!response.ok) {
          history.replace("/page-not-found");
        } else {
          setProgram(await response.json());
        }
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [history, id]);

  return program;
};

export const useFetchAllPrograms = (
  max?: number
): AsyncState<AbstractProgram[]> => {
  const programs = useAsync<AbstractProgram[]>(async () => {
    const response = await fetch(
      `/api/program/all?${queryString.stringify({ max })}`,
      {
        method: HttpMethods.GET,
      }
    );
    const data: AbstractProgram[] = await response.json();

    const statistic = {
      session: {},
      program: {},
      ...(data.length > 0 &&
        (await fetch("/api/statistic", {
          method: HttpMethods.POST,
          body: JSON.stringify({
            programId: data.map(({ id }) => id),
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((response) => response.ok && response.json()))),
    };

    for (const key in data) {
      if (statistic.program[data[key].id]) {
        data[key].statistic = statistic.program[data[key].id];
      }
    }

    return data;
  }, []);

  return programs;
};

export const useFetchRelatedPrograms = (params: {
  id: number;
  max: number;
}) => {
  const [programs, setPrograms] = useState<AbstractProgram[]>([]);

  useEffect(() => {
    if (params.id) {
      fetch(
        `/api/program/${params.id}/related?${queryString.stringify({
          max: params.max,
        })}`,
        {
          method: HttpMethods.GET,
        }
      ).then((response) => {
        response.json().then(setPrograms);
      });
    }
  }, [params.id, params.max]);
  return programs;
};
export const useFetchFeaturedPrograms = (params: { max: number }) => {
  const [programs, setPrograms] = useState<AbstractProgram[]>([]);

  useEffect(() => {
    fetch(
      `/api/program/featured?${queryString.stringify({
        max: params.max,
      })}`,
      {
        method: HttpMethods.GET,
      }
    ).then((response) => {
      response.json().then(setPrograms);
    });
  }, [params.max]);
  return programs;
};

export const useFetchFavouritedPrograms = (ids: number[]) => {
  const [programs, setPrograms] = useState<AbstractProgram[]>([]);

  useEffect(() => {
    if (ids && ids.length > 0) {
      fetch(`/api/program/favourited`, {
        method: HttpMethods.POST,
        body: JSON.stringify({
          ids,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then(setPrograms);
      });
    } else {
      setPrograms([]);
    }
  }, [ids]);
  return programs;
};

export const useFetchFavouritedSessions = (ids: number[]) => {
  const [sessions, setSessions] = useState<AbstractLiveSession[]>([]);

  useEffect(() => {
    if (ids && ids.length > 0) {
      fetch(`/api/session/favourited`, {
        method: HttpMethods.POST,
        body: JSON.stringify({
          ids,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then(setSessions);
      });
    } else {
      setSessions([]);
    }
  }, [ids]);
  return sessions;
};

export const useFetchFavouritedInstructors = (
  followedInstructors: FollowInstructorDto
) => {
  const [instructors, setInstructors] = useState<AbstractUser[]>([]);

  useEffect(() => {
    if (followedInstructors) {
      fetch(`/api/instructor/favourited`, {
        method: HttpMethods.POST,
        body: JSON.stringify({ ids: Object.keys(followedInstructors) }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        response.json().then(setInstructors);
      });
    } else {
      setInstructors([]);
    }
  }, [followedInstructors]);
  return instructors;
};

export const useFetchProgramSessions = (
  id: number
): [AbstractSession[], boolean] => {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState<AbstractSession[]>([]);

  useEffect(() => {
    if (typeof id === "number") {
      setLoading(true);

      fetch(`/api/program/${id}/sessions`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          response.json().then((data) => {
            parseDates(data);
            setSessions(data);
            setLoading(false);
          });
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return [sessions, loading];
};

export const useFetchFeaturedProgramComments = (params: { id: number }) => {
  const [comments, setComments] = useState<AbstractAnswer[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `/api/program/${params.id}/comments/featured`,
          {
            method: HttpMethods.GET,
          }
        );
        if (response.ok) {
          setComments(await response.json());
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [params.id]);

  return comments;
};

export const useBannerComments = () => {
  const [comments, setComments] = useState<AbstractAnswer[]>([]);

  useEffect(() => {
    fetch(`/api/answer/banner`, {
      method: HttpMethods.GET,
    }).then((response) => {
      response.json().then(setComments);
    });
  }, []);
  return comments;
};
export const useFetchLatestSessions = (): AsyncState<AbstractLiveSession[]> => {
  const anytimeSessions = useAsyncWithUpdate(async () => {
    const response = await fetch(`/api/session/latest`, {
      method: HttpMethods.GET,
    });
    if (response.ok) {
      const data: AbstractLiveSession[] = await response.json();

      const statistic = {
        session: {},
        program: {},
        ...(data.length > 0 &&
          (await fetch("/api/statistic", {
            method: HttpMethods.POST,
            body: JSON.stringify({ sessionId: data.map(({ id }) => id) }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }).then((response) => response.ok && response.json()))),
      };

      for (const key in data) {
        if (statistic.session[data[key].id]) {
          data[key].statistic = statistic.session[data[key].id];
        }
      }

      return data;
    }
  }, []);

  return anytimeSessions;
};

export const useFetchRecommendedSessionReplays = (
  programId: number,
  max?: number
): AsyncState<AbstractLiveSession[]> => {
  const anytimeSessions = useAsync(async () => {
    const url = max
      ? `/api/session/recommendedSessions/${programId}?${queryString.stringify({
          max,
        })}`
      : `/api/session/recommendedSessions/${programId}`;

    const response = await fetch(url, {
      method: HttpMethods.GET,
    });

    return await response.json();
  }, [max]);

  return anytimeSessions;
};

export const useFetchFreeSessions = (
  shouldFetch: boolean
): AsyncState<AbstractLiveSession[]> => {
  const anytimeSessions = useAsyncWithUpdate(async () => {
    if (!shouldFetch) {
      return [];
    }

    const response = await fetch(`/api/session/free`, {
      method: HttpMethods.GET,
    });

    return await response.json();
  }, []);

  return anytimeSessions;
};
export const useFetchLimitedLatestSessions = (
  max?: number
): AsyncState<AbstractLiveSession[]> => {
  const anytimeSessions = useAsyncWithUpdate(async () => {
    const url = max
      ? `/api/session/latestLimited?${queryString.stringify({ max })}`
      : `/api/session/latestLimited`;

    const response = await fetch(url, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      const data: AbstractLiveSession[] = await response.json();
      const statistic = {
        session: {},
        program: {},
        ...(data.length > 0 &&
          (await fetch("/api/statistic", {
            method: HttpMethods.POST,
            body: JSON.stringify({
              sessionId: data.map(({ id }) => id),
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }).then((response) => response.ok && response.json()))),
      };

      for (const key in data) {
        if (statistic.session[data[key].id]) {
          data[key].statistic = statistic.session[data[key].id];
        }
      }

      return data;
    }
  }, [max]);

  return anytimeSessions;
};

export const useFetchHighlightedVods = () => {
  const [vods, setVods] = useState<AbstractVod[]>([]);

  useEffect(() => {
    fetch(`/api/vod/featured`, {
      method: HttpMethods.GET,
    })
      .then((response) => {
        response.json().then((data) => {
          parseDates(data);
          setVods(data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return vods;
};

export const useFetchRelatedVods = (params: { id: number; max: number }) => {
  const [vods, setVods] = useState<AbstractVod[]>([]);

  useEffect(() => {
    fetch(
      `/api/program/${params.id}/relatedvods?${queryString.stringify({
        max: params.max,
      })}`,
      {
        method: HttpMethods.GET,
      }
    ).then((response) => {
      response.json().then(setVods);
    });
  }, [params.id, params.max]);
  return vods;
};

export const useFetchProgramVods = (id: number) => {
  const [vods, setVods] = useState<AbstractVod[]>([]);

  useEffect(() => {
    if (typeof id === "number") {
      fetch(`/api/program/${id}/vods`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          response.json().then((data) => {
            parseDates(data);
            setVods(data);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  return vods;
};

export const useFetchSessionsStartingThisWeek = (): [
  AsyncState<AbstractLiveSession[]>,
  string,
  Dispatch<SetStateAction<string>>
] => {
  const queryParams = queryString.parse(window.location.search);
  const [filter, setFilter] = useState<string>(
    (queryParams.select as string) || "all"
  );
  const programs = useAsyncWithUpdate(async () => {
    const response = await fetch(`/api/session/week?filter=${filter}`, {
      method: HttpMethods.GET,
    });

    const data: AbstractLiveSession[] = await response.json();

    const statistic = {
      session: {},
      program: {},
      ...(data.length > 0 &&
        (await fetch("/api/statistic", {
          method: HttpMethods.POST,
          body: JSON.stringify({ sessionId: data.map(({ id }) => id) }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((response) => response.ok && response.json()))),
    };

    for (const key in data) {
      if (statistic.session[data[key].id]) {
        data[key].statistic = statistic.session[data[key].id];
      }
    }

    return data;
  }, [filter]);

  return [programs, filter, setFilter];
};

export const useFetchUpcomingSessions = (max: number) => {
  const sessions = useAsyncWithUpdate(async () => {
    const response = await fetch(
      `/api/session/upcoming?${queryString.stringify({ max })}`,
      {
        method: HttpMethods.GET,
      }
    );

    const data: AbstractLiveSession[] = await response.json();

    const statistic = {
      session: {},
      program: {},
      ...(data.length > 0 &&
        (await fetch("/api/statistic", {
          method: HttpMethods.POST,
          body: JSON.stringify({ sessionId: data.map(({ id }) => id) }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((response) => response.ok && response.json()))),
    };

    for (const key in data) {
      if (statistic.session[data[key].id]) {
        data[key].statistic = statistic.session[data[key].id];
      }
    }

    return data;
  }, []);

  return sessions;
};

export const useFetchSessionsForDay = (day: Date) => {
  return useAsync<AbstractLiveSession[]>(async () => {
    const query = queryString.stringify({
      selectedDate: day.toISOString(),
    });

    const response = await fetch(`/api/session/day?${query}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Could not fetch current channel sessions");
    }
  });
};

export const useFetchChannelSessionsForDay = (day: Date) => {
  return useAsync<AbstractLiveSession[]>(async () => {
    const response = await fetch(
      `/api/channel/day?selectedDate=${day.toISOString()}`,
      {
        method: HttpMethods.GET,
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Could not fetch current channel sessions");
    }
  });
};

export const useFetchLatestWatchedSessions = (max?: number) => {
  return useAsync<AbstractLiveSession[]>(async () => {
    const queryParams = max ? `?max=${max}` : "";

    const response = await fetch(`/api/session/watched${queryParams}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Could not fetch Latest Watched Sessions");
    }
  });
};

export const useFetchCurrentLivesessions = () => {
  return useAsync<AbstractLiveSession[]>(async () => {
    const response = await fetch(`/api/session/current`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Could not fetch current sessions");
    }
  });
};

export const useFetchTrendingAnytime = (max?: number) => {
  const { search } = useContext(AlgoliaContext);
  const oneMonthAgo = subMonths(new Date(), 1);

  return useAsync<AlgoliaAnytimeArticle[]>(async () => {
    const response = await search({
      query: "",
      filters: `type:"anytime" AND startTimestamp > ${oneMonthAgo.getTime()}`,
      hitsPerPage: max,
      sortBy: SortOption.POPULARITY,
    });

    return response.hits as AlgoliaAnytimeArticle[];
  });
};

export const useFetchCurrentChannelSessions = () => {
  const [currentChannelSessions, setCurrentChannelSessions] = useState<
    AbstractLiveSession[]
  >([]);

  useSocketEvent("channel session state updated", () => {
    fetchCurrentChannelSessions();
  });

  useEffectOnce(() => {
    fetchCurrentChannelSessions();
  });

  const fetchCurrentChannelSessions = async () => {
    const response = await fetch(`/api/channel/current`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      setCurrentChannelSessions(await response.json());
    } else {
      throw new Error("Could not fetch current channel sessions");
    }
  };

  return currentChannelSessions;
};

export const useFetchNextChannelSessions = () => {
  const [nextChannelSessions, setNextChannelSessions] = useState<
    AbstractLiveSession[]
  >([]);

  useSocketEvent("channel session state updated", () => {
    fetchNextChannelSessions();
  });

  useEffectOnce(() => {
    fetchNextChannelSessions();
  });

  const fetchNextChannelSessions = async () => {
    const response = await fetch(`/api/channel/next`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      setNextChannelSessions(await response.json());
    } else {
      throw new Error("Could not fetch current channel sessions");
    }
  };

  return nextChannelSessions;
};

export const useFetchLastChannelSessions = () => {
  const [lastChannelSessions, setLastChannelSessions] = useState<
    AbstractLiveSession[]
  >([]);

  useEffectOnce(() => {
    fetchLastChannelSessions();
  });

  const fetchLastChannelSessions = async () => {
    const response = await fetch(`/api/channel/last`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      setLastChannelSessions(await response.json());
    } else {
      throw new Error("Could not fetch current channel sessions");
    }
  };

  return lastChannelSessions;
};

export const useFetchInstructor = (id: number) => {
  const [instructor, setInstructor] = useState<AbstractBaseUser>();
  const history = useHistory();

  useEffect(() => {
    if (typeof id === "number") {
      fetch(`/api/user/${id}/instructor`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              setInstructor(data);
            });
          } else {
            history.replace("/page-not-found");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [history, id]);
  return instructor;
};

export const useFetchInstructorNumbers = (id: number) => {
  const [vodSessionNumbers, setVodSessionNumbers] =
    useState<AbstractInstructorNumbers>({ nrOfSessions: 0, nrOfVods: 0 });

  useEffect(() => {
    if (typeof id === "number") {
      fetch(`/api/user/${id}/instructorNumbers`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          response.json().then((data) => {
            setVodSessionNumbers(data);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  return vodSessionNumbers;
};

export const useFetchInstructorProfile = (id: number) => {
  const [instructorProfile, setInstructorProfile] =
    useState<AbstractInstructorProfile>();

  useEffect(() => {
    if (typeof id === "number") {
      fetch(`/api/user/${id}/instructorProfile`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          response.json().then((data) => {
            setInstructorProfile(data);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  return instructorProfile;
};

export const useFetchInstructorComments = (id: number) => {
  return useAsync<AbstractAnswer[]>(async () => {
    const response = await fetch(`/api/user/${id}/instructor/comments`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return response.json();
    }

    return [];
  }, [id]);
};

export const useFetchAllInstructors = (
  max?: number,
  options?: {
    basicOnly?: boolean;
  }
): [
  AsyncState<AbstractBaseUser[]>,
  string,
  Dispatch<SetStateAction<string>>
] => {
  const queryParams = queryString.parse(window.location.search);
  const { basicOnly } = options || {};
  const [filter, setFilter] = useState<string>(
    (queryParams.select as string) || "all"
  );
  const instructors = useAsync<AbstractBaseUser[]>(async () => {
    const url = max
      ? `/api/user/${
          basicOnly ? "basic-" : ""
        }instructors?${queryString.stringify({ max })}`
      : `/api/user/${basicOnly ? "basic-" : ""}instructors?filter=${filter}`;
    const response = await fetch(url, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      const res = await response.json();
      return res;
    }

    return [];
  }, [filter]);

  return [instructors, filter, setFilter];
};

export const useFetchRelatedInstructors = (params: {
  id: number;
  max: number;
}) => {
  return useAsync<AbstractBaseUser[]>(async () => {
    const response = await fetch(
      `/api/instructor/${params.id}/related?${queryString.stringify({
        max: params.max,
      })}`,
      {
        method: HttpMethods.GET,
      }
    );

    if (response.ok) {
      return response.json();
    }

    return [];
  }, [params.id, params.max]);
};

export const useFetchStreamer = (id: number) => {
  const [streamer, setStreamer] = useState<AbstractBaseUser>();
  const history = useHistory();

  useEffect(() => {
    if (typeof id === "number") {
      fetch(`/api/user/${id}/streamer`, {
        method: HttpMethods.GET,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              setStreamer(data);
            });
          } else {
            history.replace("/page-not-found");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [history, id]);
  return streamer;
};

export const useFetchActiveCompetitionRound = () => {
  return useAsync<AbstractCompetitionRound>(async () => {
    const response = await fetch(`/api/competition-rounds/active`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    }
  });
};

export const useFetchStreamerRank = (userId: number) => {
  return useAsync<number>(async () => {
    const response = await fetch(
      `/api/competition-rounds/active/leaderboard/${userId}`,
      {
        method: HttpMethods.GET,
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result.rank;
    }
  });
};

export const useFetchCategories = () => {
  return useAsync<AbstractCategory[]>(async () => {
    const response = await fetch(`/api/category/all`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return response.json();
    }

    return [];
  });
};

export const useFetchUsedTags = () => {
  return useAsync<AbstractTag[]>(async () => {
    const response = await fetch(`/api/tag/used`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return response.json();
    }

    return [];
  });
};

export const useFetchRecruitments = () => {
  return useAsync<AbstractBaseUser[]>(async () => {
    const response = await fetch(`/api/user/recruitments`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return response.json();
    }

    return [];
  });
};

export const useFetchReplay = (sessionId: number) => {
  const replay = useAsync<AbstractLiveSession>(async () => {
    const response = await fetch(`/api/replay/${sessionId}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch replay");
    }
  });
  return replay;
};

export const useFetchSession = (sessionId: number) => {
  const replay = useAsync<AbstractLiveSession>(async () => {
    const response = await fetch(`/api/session/${sessionId}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch session");
    }
  });
  return replay;
};

export const useFetchChannelSessionsWeekPaginated = (
  limit: number,
  skip: number,
  deps: DependencyList = []
) => {
  return useAsyncWithUpdate(async () => {
    const query = queryString.stringify({
      limit,
      skip,
    });
    const response = await fetch(`/api/channel/paginated-week?${query}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Could not fetch paginated channel sessions");
    }
  }, deps);
};

const useAsyncWithUpdate = <T, Args extends any[] = any[]>(
  fn: (...args: Args | []) => Promise<T>,
  deps: DependencyList = [],
  minutes: number = 15
) => {
  const lastCalled = useRef<Date>();
  const [state, callback] = useAsyncFn(
    async () => {
      const result = await fn();
      lastCalled.current = new Date();
      return result;
    },
    deps,
    { loading: true }
  );

  useEffect(() => {
    callback();

    const onVisibilityChanged = async () => {
      if (document.visibilityState === "visible") {
        const minutesDiff = differenceInMinutes(new Date(), lastCalled.current);
        if (minutesDiff >= minutes) {
          callback();
        }
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChanged);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChanged);
    };
  }, [callback, minutes]);

  return state;
};

export const useFetchCampaignFromCode = (code: string, provider: string) => {
  const campaign = useAsync<AbstractCampaign>(async () => {
    const response = await fetch(`/api/campaign/${provider}/${code}`, {
      method: HttpMethods.GET,
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to fetch session");
    }
  });
  return campaign;
};

export const useFetchCampaignIdsByUserId = (id: number) => {
  const [ids, setIds] = useState<number[]>(null);

  useEffect(() => {
    (async () => {
      if (id !== null) {
        try {
          const response = await fetch(
            `/api/campaign/campaignPurchases/user/${id}`,
            {
              method: HttpMethods.GET,
            }
          );

          if (response.ok) {
            setIds(await response.json());
          }
        } catch (error) {
          console.log({ error });
        }
      }
    })();
  }, [id]);

  return ids;
};

export const useFetchCountry = () => {
  const [location, setLocation] = useState<string>("");
  useEffect(() => {
    const fetchLocation = async () => {
      const loc = await getLocation();
      setLocation(loc);
    };
    fetchLocation();
  }, [location]);

  return location;
};
