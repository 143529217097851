import { Text } from "@kvix/ui";
import { Box, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { KvixUserContext } from "../../../../contexts/user";

const useStyles = makeStyles((theme) => ({
  bioHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  bioText: {
    marginBottom: "40px",
    fontWeight: 500,
  },
}));

export const Bio = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation("streamerAdminAccount");
  const { user } = useContext(KvixUserContext);

  if (!ready) {
    return null;
  }

  return (
    <Box>
      <Text className={classes.bioHeader}>{t("bioHeader")}</Text>
      <Text className={classes.bioText}>{user?.instructorProfile?.bio}</Text>
    </Box>
  );
};
