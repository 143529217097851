import { IAPSubscriptionData } from "@kvix/shared";
import { BackgroundImage, Badge, Container, RadioGroup, Text } from "@kvix/ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseFlowConsumer } from "src/contexts/PurchaseFlow";
import img from "../../partials/assets/premium.png";
import { useStyles } from "../styles";

export const IosStep = () => {
  const classes = useStyles();
  const { t, ready } = useTranslation(["premiumPromptApp", "wellstarStreams"]);

  const mapBadgeProps = useCallback((subscription: IAPSubscriptionData) => {
    const price = subscription.price;
    const currency = subscription.currency.toUpperCase();

    switch (subscription.subscriptionPeriodUnitIOS) {
      case "MONTH": {
        return {
          title: t("account:account.planDetails.planMapping.month"),
          text: `${currency} ${price} / ${t("signUpCampaign:interval.month")}`,
          usp: t("signUp:allPlansInclude"),
        };
      }
      case "YEAR": {
        return {
          title: t("account:account.planDetails.planMapping.year"),
          text: `${currency} ${price} / ${t("signUpCampaign:interval.year")}`,
          usp: `${t("signUp:allPlansInclude")}`,
        };
      }
    }
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <PurchaseFlowConsumer>
      {({ actions, iapSubscriptions }) => (
        <Container scrollableY disableScrollbar pb={3}>
          <BackgroundImage src={img} sizes={["120px", "120px"]} mt={1} />
          <Text component="h3" className={classes.h3}>
            {t("premiumPromptApp:title")}
          </Text>
          <Container pb={2}>
            {iapSubscriptions.length > 0 ? (
              <>
                <RadioGroup
                  onChange={(value) => {
                    actions.triggerIapPurchase(value);
                    actions.closePrompt();
                  }}
                  options={iapSubscriptions.map((sub) => ({
                    value: sub.productId,
                    component: (
                      <Badge variant={"secondary"} {...mapBadgeProps(sub)} />
                    ),
                    checkedComponent: (
                      <Badge variant={"primary"} {...mapBadgeProps(sub)} />
                    ),
                  }))}
                />
              </>
            ) : (
              <Text>{t("premiumPromptApp:text")}</Text>
            )}
          </Container>
        </Container>
      )}
    </PurchaseFlowConsumer>
  );
};
