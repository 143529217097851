import { locale } from "./locale";

export const formatCurrency = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency
  });

  return formatter.format(amount);
};
