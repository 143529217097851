import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CoinImageSize } from "../enums/CoinImageSize";
import { DonateType } from "../enums/DonateType";
import { DonationAlertSeverity } from "../enums/DonationAlertSeverity";
import { CoinImage } from "./CoinImage";

interface Props {
  setValue: (value: number) => void;
  setDonationAlert: (message: string, severity: DonationAlertSeverity) => void;
  currentCoins: number;
  isCompleted: boolean;
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
  disableButtons?: boolean;
  donateType: DonateType;
}

interface SelectionButtonProps {
  coinValue?: number;
  setCoinValue?: (coinValue: number) => void;
  onClick: () => void;
  state;
  buttonType: "number" | "custom";
  disable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  optionsWrapper: {
    marginTop: 20,
    width: "100%",
  },
  optionsRow: {
    marginBottom: "14px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    "& > button:nth-of-type(2)": {
      margin: "0px 10px 0px",
    },
  },
  valueButton: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    borderRadius: 30,
    width: "100%",
    height: 36,
    border: theme.palette.text.primary,
    borderStyle: "solid",
    borderWidth: "2px",
    fontWeight: 600,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
  },
  customButtonInput: {
    width: "100%",
    borderWidth: 0,
    backgroundColor: "transparent",
    textAlign: "center",
    fontWeight: 400,
    letterSpacing: "1.25px",
    marginLeft: 0,
    color: theme.palette.background.default,
    "&:focus": {
      color: theme.palette.background.default,
      outline: "none",
      backgroundColor: "transparent",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  selected: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
  },
  buttonSubTitle: {
    marginLeft: "5px",
    fontWeight: 500,
    color: "inherit",
    lineHeight: 1,
  },
}));

const SelectionButton: FC<SelectionButtonProps> = (props) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [customValue, setCustomValue] = useState("");
  const { t, ready } = useTranslation("donateButtons");

  useEffect(() => {
    if (props.state !== "custom" && customValue !== "") {
      setCustomValue("");
    }
  }, [props.state]);

  const coinClick = useCallback(() => {
    if (inputRef.current && props.state === "custom") {
      inputRef.current.focus();
    }
    props.onClick();
  }, [props]);

  const customCoinChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const isPositiveInteger = /^\+?[1-9][\d]*$/.test(value);

      if (!value || isPositiveInteger) {
        setCustomValue(value);
        props.setCoinValue(Number(value));
      }
    },
    [props, setCustomValue]
  );

  if (!ready) {
    return null;
  }

  if (props.buttonType === "custom") {
    return (
      <Button
        onClick={coinClick}
        disableRipple
        disabled={props.disable}
        className={classNames(classes.valueButton, {
          [classes.selected]: props.state === "custom",
        })}
        variant="contained"
      >
        {props.state === "custom" ? (
          <input
            ref={inputRef}
            placeholder={t("donationAlert")}
            autoFocus
            type="number"
            value={customValue}
            className={classes.customButtonInput}
            onChange={customCoinChange}
            max={100}
          ></input>
        ) : (
          props.children
        )}
      </Button>
    );
  } else {
    return (
      <Button
        onClick={coinClick}
        disabled={props.disable}
        className={classNames(classes.valueButton, {
          [classes.selected]: props.state === props.coinValue,
        })}
        variant="contained"
      >
        {props.children}
      </Button>
    );
  }
};

export const Selection: FC<Props> = (props) => {
  const classes = useStyles();
  const [state, setState] = useState(null);
  const { t, ready } = useTranslation("donateLiveView");
  const action = (amount: number) => {
    if (props.currentCoins >= amount * costInCoins) {
      setState(amount);
      props.setValue(amount);
    } else {
      setState(null);
      props.setValue(null);
      props.setDonationAlert(
        t("alert.notEnoughCoins"),
        DonationAlertSeverity.ERROR
      );
    }
  };
  const costInCoins = props.donateType === DonateType.VOTE ? 50 : 1;
  const data =
    props.donateType === DonateType.VOTE
      ? [1, 2, 4, 6, 8]
      : [1, 10, 50, 100, 500];

  useEffect(() => {
    if (props.isCompleted) {
      setState(null);
      props.setValue(null);
    }
  }, [props.isCompleted]);

  if (!ready) {
    return null;
  }

  return (
    <div className={classes.optionsWrapper}>
      <Grid className={classes.optionsRow} container spacing={1}>
        {data.map((amount, index) => (
          <Grid item xs={4} key={index}>
            <SelectionButton
              coinValue={amount}
              disable={props.disableButtons}
              onClick={() => action(amount)}
              state={state}
              buttonType={"number"}
            >
              {amount}
              <Typography className={classes.buttonSubTitle} variant={"body2"}>
                {props.donateType === DonateType.VOTE && (
                  <>{amount === 1 ? t("vote.vote") : t("vote.votes")}</>
                )}
                {props.donateType === DonateType.COIN && (
                  <CoinImage size={CoinImageSize.LG} />
                )}
              </Typography>
            </SelectionButton>
          </Grid>
        ))}
        <Grid item xs={4}>
          <SelectionButton
            onClick={() => {
              setState("custom");
              props.setValue(null);
            }}
            disable={props.disableButtons}
            state={state}
            buttonType={"custom"}
            setCoinValue={(coinValue) => {
              props.setValue(coinValue);
            }}
          >
            <Typography className={classes.buttonSubTitle} variant={"body2"}>
              {t("shared.custom")}
            </Typography>
          </SelectionButton>
        </Grid>
      </Grid>
    </div>
  );
};
