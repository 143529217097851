import { Text } from "@kvix/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Blogpost } from "../../../../../cms/models/Blogpost";

const Wrapper = styled.div<{ isTablet: boolean }>`
  display: flex;
  margin: 12px 12px 12px 0;

  flex-direction: column;
`;

const ImageContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  margin: ${(props) => (props.isTablet ? "0 0 12px 0" : "0 24px 0 0")};
  position: relative;
`;

const Image = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
`;
export const BlogpostFC: React.FC<{
  isTablet?: boolean;
  isMobile?: boolean;
  blogpost: Blogpost;
}> = (props) => {
  const { t, ready } = useTranslation("blog");

  if (!ready) {
    return null;
  }

  return (
    <Link
      to={`/blog/${props.blogpost.slug}`}
      style={{ width: props.isMobile ? "50%" : props.isTablet ? "33%" : "25%" }}
    >
      <Wrapper isTablet={props.isTablet}>
        <ImageContainer isTablet={props.isTablet} isMobile={props.isMobile}>
          <Image url={props.blogpost.image.url} />
        </ImageContainer>
        <div style={{ overflowWrap: "break-word" }}>
          <Text variant="caption" color="primary">
            {props.blogpost.caption}
          </Text>
          <Text variant="h6" style={{ overflowWrap: "break-word" }}>
            {props.blogpost.title}
          </Text>
          {!props.blogpost.noAuthorLink ? (
            <Link to={`/instructor/${props.blogpost.userId}`}>
              <Text variant="caption" color="textSecondary">
                {t("byAuthor") + props.blogpost.author}
              </Text>
            </Link>
          ) : (
            <Text variant="caption" color="textSecondary">
              {t("byAuthor") + props.blogpost.author}
            </Text>
          )}
        </div>
      </Wrapper>
    </Link>
  );
};
