import { Grid, makeStyles, useTheme } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import queryString from "query-string";
import React, { useCallback } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useIsMobile } from "../../../../hooks/isMobile";

const socialOptions = {
  size: 32,
  title: "",
  description: "",
};

const useStyles = makeStyles((theme) => ({
  grid: {
    "& div.MuiGrid-item": {
      fontWeight: 700,
    },
    "& span.MuiTypography-caption": {
      color: theme.palette.text.primary,
    },
    "& button": {
      outline: "none",
      border: 0,
    },
  },
}));

export const ShareButtons: React.FC<{
  url: string;
  onClick: (shareEvent: string) => void;
  texts: {
    facebookQuote: string;
    facebookHashtag: string;
    whatsappTitle: string;
    emailSubject: string;
    emailBody: string;
    smsBody: string;
  };
}> = (props) => {
  const classes = useStyles(props);
  const isMobile = useIsMobile();
  const utmTags = { utm_medium: "share", utm_campaign: "kvix" };
  const parsedUrl = queryString.parseUrl(props.url);
  const theme = useTheme();
  const getUrl = useCallback((source: string) => {
    const url = queryString.stringifyUrl({
      ...parsedUrl,
      query: { ...parsedUrl.query, ...utmTags, utm_source: source },
    });
    return url;
  }, []);

  return (
    <Grid className={classes.grid} container justify={"space-between"}>
      <Grid item>
        <FacebookShareButton
          quote={props.texts.facebookQuote}
          hashtag={props.texts.facebookHashtag}
          url={getUrl("facebook")}
          onClick={() => props.onClick("facebook")}
        >
          <FacebookIcon path={null} size={socialOptions.size} round={true} />
        </FacebookShareButton>
      </Grid>

      <Grid item>
        <FacebookMessengerShareButton
          appId={window.config.FACEBOOK_APP_ID}
          url={getUrl("messenger")}
          onClick={() => props.onClick("facebook-message-app")}
        >
          <FacebookMessengerIcon
            path={null}
            size={socialOptions.size}
            round={true}
          />
        </FacebookMessengerShareButton>
      </Grid>

      <Grid item>
        <WhatsappShareButton
          title={props.texts.whatsappTitle}
          url={getUrl("whatsapp")}
          onClick={() => props.onClick("whatsapp")}
        >
          <WhatsappIcon path={null} size={socialOptions.size} round={true} />
        </WhatsappShareButton>
      </Grid>

      <Grid item>
        <a
          href={`mailto:?&subject=${encodeURIComponent(
            props.texts.emailSubject.replace(/{url}/g, getUrl("email"))
          )}&body=${encodeURIComponent(
            props.texts.emailBody.replace(/{url}/g, getUrl("email"))
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => props.onClick("email")}
        >
          <EmailIcon path={null} size={socialOptions.size} round={true} />
        </a>
      </Grid>

      <Grid item>
        <a
          href={`sms:?&body=${encodeURIComponent(
            props.texts.smsBody.replace(/{url}/g, getUrl("sms"))
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => props.onClick("textmessage")}
        >
          <SmsOutlinedIcon
            style={{
              height: socialOptions.size,
              width: socialOptions.size,
              color: theme.palette.common.white,
              backgroundColor: "#7F7F7F",
              borderRadius: 16,
              padding: 7,
            }}
          />
        </a>
      </Grid>

      <Grid item>
        <CopyToClipboard text={getUrl("clipboard")} onCopy={() => props.onClick("link")}>
          <LinkIcon
            style={{
              height: socialOptions.size,
              width: socialOptions.size,
              color: theme.palette.common.white,
              backgroundColor: "#7F7F7F",
              borderRadius: 16,
              padding: 7,
              cursor: "pointer",
            }}
          />
        </CopyToClipboard>
      </Grid>
    </Grid>
  );
};
