import { Hostname, StripeProduct } from "@kvix/shared";
import {
  Button,
  Container,
  LoginWithAppleButton,
  LoginWithFacebookButton,
  LoginWithGoogleButton,
  Text,
  TabPanel,
} from "@kvix/ui";
import { Grid, makeStyles, useTheme } from "@material-ui/core";
import { TFunctionKeys, TOptions } from "i18next";
import queryString from "query-string";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { TosText } from "src/components/partials/TermsOfService/tos";
import { CmsContext } from "../../../cms/context";
import { PurchaseFlowConsumer } from "../../../contexts/PurchaseFlow";
import { KvixUserContext } from "../../../contexts/user";
import { HeaderContext } from "../../Header/context";
import { useCoupon, usePlan, usePlanPricing } from "../../pages/Payment/hooks";
import gaspImg from "../../partials/assets/gasp.png";
import { ReactComponent as PoweredByStripeLogotype } from "../../partials/assets/powered_by_stripe.svg";
import { ReactComponent as PoweredByStripeDarlLogotype } from "../../partials/assets/powered_by_stripe_dark.svg";
import premiumImg from "../../partials/assets/premium.png";
import { RegisterWithEmailButton } from "../components/Email/emailRegisterButton";
import { CampaignStepProps } from "../types";
import { ForgottenPassword } from "../components/ForgotPassword";
import { LoginWithEmailButton } from "../components/Email/emailLoginButton";

const useStyles = makeStyles((theme) => ({
  ContainerPadding: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.spacing(0),
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      paddingBottom: theme.spacing(4),
    },
  },
  topTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: theme.spacing(4),
  },
  fontWeightMedium: {
    fontWeight: 600,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  image: {
    maxWidth: "100px",
  },
  title: { marginBottom: theme.spacing(1) },
  tosAndLoginContainer: {
    borderRadius: 4,

    "& .MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.25",
    },
  },
  tosAndLoginContainerText: {
    fontSize: 13,
    fontWeight: 600,
  },
  isPremiumText: {
    color: theme.palette.primary.main,
    fontSize: 13,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "20px",
    width: "100%",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "26px",
    paddingBottom: theme.spacing(3),
  },
  socialButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "20px",
    width: "100%",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "26px",
    paddingBottom: theme.spacing(3),
  },

  subText1: { fontSize: 14, fontWeight: "bold", marginBottom: "24px" },

  button: {
    margin: "20px 0 ",
    padding: "0 20px ",
    height: "44px",
    borderRadius: "30px",
    textTransform: "capitalize",
    backgroundColor: theme.palette.primary.main,
    "&:active": {
      animation: "$scaleButton linear 300ms ",
    },
  },

  loginLinkContainer: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    width: "100%",
  }
}));

export const CampaignStep = ({
  title,
  usp,
  campaignContext,
}: CampaignStepProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(KvixUserContext);
  const { pages } = useContext(CmsContext);
  const { isDarkMode } = useContext(HeaderContext);
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const isPremium = user?.stripeProducts.includes(StripeProduct.LIVE);
  const translation = useTranslation([
    "signUpCampaign",
    "checkoutFlow",
    "signUp",
    "logIn",
  ]);
  const plan = usePlan(campaignContext?.campaign?.data?.plan);
  const pricing = usePlanPricing(plan);
  const coupon = useCoupon(campaignContext?.campaign?.data?.coupon);

  const calculateTrialDays = useCallback(() => {
    if (
      campaignContext?.campaign?.data?.trial_period_days ||
      campaignContext?.campaign?.data?.trial_period_days === 0
    ) {
      return campaignContext?.campaign.data.trial_period_days;
    } else if (plan?.trial_period_days || plan?.trial_period_days === 0) {
      return plan.trial_period_days;
    }
    return 0;
  }, [campaignContext?.campaign, plan]);

  const t = useCallback(
    (key: TFunctionKeys, options?: TOptions) => {
      const price = pricing?.campaignPrice || pricing?.originalPrice || 0;
      const interval =
        translation.t("interval", { returnObjects: true })[plan?.interval] ||
        "";
      const trialDays = calculateTrialDays();
      const firstName = user?.name?.first || "";
      const amountOff = coupon?.value?.amount_off / 100 || 0;
      const percentOff = coupon?.value?.percent_off || 0;

      const priceAmountOff = `${
        campaignContext.campaign.currency === "GBP" ? "£" : ""
      }${(price - (amountOff || price * (percentOff / 100))).toLocaleString(
        undefined,
        { maximumFractionDigits: 2 }
      )} ${campaignContext.campaign.currency === "SEK" ? "kr" : ""}`;

      return translation.t(key, {
        price: `${
          campaignContext.campaign.currency === "GBP" ? "£" : ""
        }${price} ${campaignContext.campaign.currency === "SEK" ? "kr" : ""}`,
        interval,
        trialDays,
        firstName,
        "price-amountOff": priceAmountOff,
        "duration-amount": coupon?.value?.duration_in_months || 0,
        ...options,
      });
    },
    [translation, campaignContext?.campaign, plan, pricing, coupon, user]
  );

  const subText1 = useMemo(() => {
    const line: string[] = [];
    coupon?.value?.duration !== "forever"
      ? line.push(`${t("offer.ordinaryPrice")}.`)
      : line.push(`${t("offer.couponDescriptions.forever")}.`);

    line.push(t("offer.curingPeriod"));

    return line.join("\n");
  }, [campaignContext?.campaign, coupon, plan, t]);

  const getMonthsInDays = (months: number) => {
    let res = 0;
    switch (months) {
      case 1:
        res = 30;
        break;
      case 2:
        res = 60;
        break;
      case 3:
        res = 90;
        break;
      default:
        break;
    }
    return res;
  };

  const offerText = useMemo(() => {
    const line: string[] = [];
    const trialDays = calculateTrialDays();

    let couponDays = 0;
    let amountOff = 0;
    let duration = coupon?.value?.duration;

    if (campaignContext?.campaign?.data?.coupon) {
      if (coupon?.value?.percent_off === 100) {
        if (coupon?.value?.duration === "repeating") {
          couponDays = getMonthsInDays(coupon?.value?.duration_in_months || 0);
        }
      }
    }

    if (trialDays > 0 || couponDays > 0) {
      couponDays += trialDays;
      line.push(translation.t("offer.trialBased", { trialDays: couponDays }));
    }

    if (duration && duration !== "forever") {
      line.push(t(`offer.couponDescriptions.${duration}`));
    }

    return line.join("\n");
  }, [campaignContext?.campaign, coupon, plan, t]);

  const signupUrl = useCallback(
    (
      provider: "facebook" | "google" | "email" | "apple",
      type: "login" | "register"
    ) => {
      const host = Hostname.auth.register();
      const from = location.pathname;
      const termsPage = pages?.find((p) => p.slug === "terms");
      const tosVersion = termsPage?.tosVersion;

      const queryParams = {
        tosVersion,
        gotoCheckout: "true",
      };

      const queryParamsString = encodeURIComponent(
        queryString.stringify(queryParams, {
          skipNull: true,
        })
      );

      const url = `/auth/${provider}/${type}?redirectPath=${from}?${queryParamsString}`;

      return `${host}${url}`;
    },
    [location.pathname, pages]
  );

  if (!translation.ready) {
    return null;
  }

  return (
    <PurchaseFlowConsumer>
      {({ context, actions }) => (
        <Container
          className={classes.ContainerPadding}
          scrollableY
          disableScrollbar
        >
          {isPremium ? (
            <>
              <img
                src={gaspImg}
                className={classes.image}
                alt={t("kvixPremium")}
              />
              <Text className={classes.title}>
                {t("offerNotAvailable.isPremium.title")}
              </Text>
              <div className={classes.buttonContainer}>
                {t("offerNotAvailable.isPremium.text")}
              </div>
              <div className={classes.tosAndLoginContainer}>
                <Text className={classes.tosAndLoginContainerText}>
                  {t("offerNotAvailable.isPremium.whatNow")}
                </Text>
                <Link to="/me/account" onClick={campaignContext?.closeModal}>
                  <Text className={classes.isPremiumText}>
                    {t("offerNotAvailable.isPremium.checkSubscription")}
                  </Text>
                </Link>
                <Link to={"/"} onClick={campaignContext?.closeModal}>
                  <Text className={classes.isPremiumText}>
                    {t("offerNotAvailable.isPremium.startpage")}
                  </Text>
                </Link>
              </div>
            </>
          ) : (
            <>
              {!user ? (
                <>
                  <img
                    src={premiumImg}
                    className={classes.image}
                    alt={t("kvixPremium")}
                  />
                  <TabPanel index={0} value={tab}>
                    <Grid container>
                      <Grid container className={classes.topTextBlock}>
                        <Text variant="h3">
                          {title ? title : t("offer.title")}
                        </Text>
                        <Text variant="h3">{offerText}</Text>
                        <Text variant="h5" className={classes.fontWeightMedium}>
                          {subText1}
                        </Text>
                      </Grid>
                      <RegisterWithEmailButton variant="big" />
                      <Text variant="h5" className={classes.marginTopBottom}>
                        {t("or")}
                      </Text>
                      <div className={classes.socialButtonsContainer}>
                        <LoginWithGoogleButton
                          variant="big"
                          href={signupUrl("google", "register")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("signUp:signUpWith")} Google`}
                        />
                        <LoginWithFacebookButton
                          variant="big"
                          href={signupUrl("facebook", "register")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("signUp:signUpWith")} Facebook`}
                        />
                        <LoginWithAppleButton
                          variant="big"
                          href={signupUrl("apple", "register")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("signUp:signUpWith")} Apple`}
                        />
                      </div>
                      <Container className={classes.tosAndLoginContainer}>
                        <TosText />
                      </Container>
                      <Text variant="h5" className={classes.loginLinkContainer}>
                        {t("checkoutFlow:register.alreadyAMember")}&nbsp;
                        <u
                          onClick={() => setTab(1)}
                          style={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                        >
                          {t("checkoutFlow:register.clickHere")}
                        </u>
                      </Text>
                    </Grid>
                  </TabPanel>
                  <TabPanel index={1} value={tab}>
                    <Grid container direction="column">
                      <Grid container className={classes.topTextBlock}>
                        <Text variant="h3">{t("logIn:welcomeBack")}</Text>
                      </Grid>
                      <Grid item>
                        <Text variant="h5" className={classes.marginBottom}>
                          {t("logIn:login")}
                        </Text>
                        <LoginWithEmailButton
                          changeToForgotPassword={() => setTab(2)}
                          variant="big"
                        />
                      </Grid>
                      <Text variant="h5" className={classes.marginTopBottom}>
                        {t("or")}
                      </Text>
                      <div className={classes.socialButtonsContainer}>
                        <LoginWithGoogleButton
                          variant="big"
                          href={signupUrl("google", "login")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("logIn:loginWith")} Google`}
                        />
                        <LoginWithFacebookButton
                          variant="big"
                          href={signupUrl("facebook", "login")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("logIn:loginWith")} Facebook`}
                        />
                        <LoginWithAppleButton
                          variant="big"
                          href={signupUrl("apple", "login")}
                          onClick={() => actions.closePrompt()}
                          actionText={`${t("logIn:loginWith")} Apple`}
                        />
                      </div>
                      <Text variant="h5">
                        {t("logIn:notAMember")}&nbsp;
                        <u
                          onClick={() => setTab(0)}
                          style={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                        >
                          {t("logIn:clickHere")}
                        </u>
                      </Text>
                    </Grid>
                  </TabPanel>
                  <TabPanel index={2} value={tab}>
                    <ForgottenPassword />
                  </TabPanel>
                </>
              ) : null}

              {user ? (
                <>
                  <img
                    src={premiumImg}
                    className={classes.image}
                    alt={t("kvixPremium")}
                  />
                  <Grid container className={classes.topTextBlock}>
                    <Text variant="h3">{title ? title : t("offer.title")}</Text>
                    <Text variant="h3">{offerText}</Text>
                    <Text variant="h5" className={classes.fontWeightMedium}>
                      {subText1}
                    </Text>
                  </Grid>
                  <Container pr={2} pl={2}>
                    <Link
                      onClick={() => actions.closePrompt()}
                      to={{
                        pathname: `${location.pathname}?gotoCheckout=true`,
                        state: {
                          from: `${location.pathname}?signUpRedirect=true`,
                        },
                      }}
                    >
                      <Button className={classes.button}>
                        {t("continue")}
                      </Button>
                    </Link>
                    <Container>
                      {isDarkMode ? (
                        <PoweredByStripeDarlLogotype />
                      ) : (
                        <PoweredByStripeLogotype />
                      )}
                    </Container>
                  </Container>
                </>
              ) : null}
            </>
          )}
        </Container>
      )}
    </PurchaseFlowConsumer>
  );
};
